import { StringUtils } from "@alpha/com.bizentro.daaf.front.framework";
import React from "react";

function FormWrapper({ title = "", ...props }) {
  return (
    <div className="form-wrapper" style={props.style} {...props}>
      {!StringUtils.isEmpty(title) && (
        <div className="tab-title">
          {title}
          {props.button}
        </div>
      )}
      {props.children}
    </div>
  );
}

export default FormWrapper;
