import React from "react";
import { Col, FormLabel, FormText } from "react-bootstrap";

/**
 *
 * @props label : Form Label
 * @props text : Form Text( Text가 없으면 Form이 사라짐)
 * @props different : true인 경우 text 색상을 붉은색 강조표시
 */
const HistoryForm = ({ label, text, different, ...props }) => {
  return (
    <>
      <Col xs={4}>
        <FormLabel className="mb-0 py-2">{label}</FormLabel>
      </Col>
      <Col xs={8}>
        <text className={`${different && "text-danger fw-bold"}`}>
          {text}
        </text>
      </Col>
    </>
  );
};

export default HistoryForm;
