import {
  ArrayUtils,
  ComboBox,
  DaafEnums,
  DaafMessage,
  DaafModal,
  DaafModalTemplate,
  ObjectUtils,
  StringUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import DForm from "components/common/form/Form";
import Grid from "components/grid/Grid";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import TrdUtil from "utils/TrdUtils";

const TableRelationPopup = ({
  targetTable: _targetTable,
  sourceTable: _sourceTable,
  relation,
  newRelation,
  tableList: _tableList = [],
  refChangeable = true,
  ...props
}) => {
  const [sourceColumnOption, setSourceColumnOption] = useState([]);
  const [targetColumnOption, setTargetColumnOption] = useState([]);

  const sourceTable = _sourceTable;
  const tableListRef = useRef(_tableList);
  const [targetTable, setTargetTable] = useState({});
  const [tableList, setTableList] = useState([]);

  const [relationNm, setRelationNm] = useState("");
  const [relationNmInvalid, setRelationNmInvalid] = useState(false);
  const [relationType, setRelationType] = useState(
    relation?.relationType || "N"
  );
  const [cascadeType, setCascadeType] = useState("N");
  const [isIdentifying, setIsIdentifying] = useState(false);
  const [sourceColumn, setSourceColumn] = useState({});
  const [targetColumn, setTargetColumn] = useState({});
  const [joinColumns, setJoinColumns] = useState([]);
  const sourcePkColumns = useRef([]);

  useEffect(() => {
    //소스 테이블 설정
    if (sourceTable.trdTableField) {
      setSourceColumnOption(sourceTable.trdTableField);
    }
    //타겟 테이블 설정
    if (_targetTable) {
      setTargetTable(_targetTable);
    }

    // 신규 관계 설정인 경우
    if (newRelation) {
      let sourcePkColumns = sourceColumnOption.filter((c) => c.pkYn === "Y");
      setJoinColumns(sourcePkColumns);
    } else if (relation) {
      //기존에 관계가 설정되어있는 경우
      setRelationType(relation.relationType);
      setJoinColumns(relation.joinColumns || []);
      setCascadeType(relation.cascadeType);
    }
    /**
     * Source Table 빼고 목록화
     */
    if (_tableList) {
      tableListRef.current = _tableList.filter(
        (t) => t.tableMstId !== sourceTable.tableMstId
      );
      setTableList(tableListRef.current);
    }
    if (relation?.relationNm) {
      setRelationNm(relation.relationNm);
    }
  }, []);

  useEffect(() => {
    if (!ObjectUtils.isEmpty(targetTable)) {
      if (targetTable.trdTableField) {
        setTargetColumnOption(targetTable.trdTableField);
      }
      // 테이블 전체 목록에서 타겟 테이블 검색 후
      if (tableList) {
        const tt = tableList.find(
          (t) => t.tablePhysicalNm === targetTable.tablePhysicalNm
        );
        if (tt) setTargetTable(tt);
      }
      if (StringUtils.isEmpty(relationNm)) {
        setRelationNm(
          `fk_${sourceTable.tablePhysicalNm}_to_${targetTable.tablePhysicalNm}`
        );
      }
    }
  }, [targetTable]);

  useEffect(() => {
    if (relationType === "I") {
      setIsIdentifying(true);
    } else {
      setIsIdentifying(false);
    }
  }, [relationType]);

  /**
   * 각 컬럼 자동 조인
   * 컬럼명이 같으면 자동으로 조인한다.
   */
  const onAutoJoin = () => {
    let joinArr = [];
    let sortSeq = 1;
    for (const sc of sourceColumnOption) {
      let sourcePhysicalNm = sc.physicalNm;
      if (sc.element) sourcePhysicalNm = sc.element.elementCd;

      const isJoin = targetColumnOption.find((tc) => {
        if (tc.pkYn === "Y") {
          let targetPhysicalNm = tc.physicalNm;
          if (tc.element) targetPhysicalNm = tc.element.elementCd;
          if (targetPhysicalNm === sourcePhysicalNm) return true;
        }
      });
      if (isJoin) {
        const relationObj = {
          fromField: sc,
          toField: isJoin,
          sortSeq: sortSeq++,
        };
        joinArr.push(relationObj);
      }
    }
    setJoinColumns(joinArr);
  };

  /**
   * 참조 추가
   */
  const onAddJoinColumn = () => {
    //기등록된 칼럼은 보이지 않도록

    const newJoinColumns = [...joinColumns];
    if (
      ObjectUtils.isEmpty(sourceColumn) ||
      ObjectUtils.isEmpty(targetColumn)
    ) {
      return DaafMessage.alert(
        "컬럼을 선택해주세요",
        DaafEnums.MessageType.WARN
      );
    }
    const isSourceDup = newJoinColumns.find((c) =>
      StringUtils.equalsIgnoreCase(
        TrdUtil.getFieldPhysicalNm(c.fromField, sourceColumn)
      )
    )
      ? true
      : false;
    const isTargetDup = newJoinColumns.find((c) =>
      TrdUtil.getFieldPhysicalNm(c.toField, targetColumn)
    )
      ? true
      : false;
    if (isSourceDup && isTargetDup) {
      return DaafMessage.alert(
        "이미 등록된 관계가 있습니다.",
        DaafEnums.MessageType.WARN
      );
    }
    //서로 관계하는 컬럼이 Data Type 또는 Domain 다르면 연결되지 않음
    //도메인이 없는 경우 관계 설정이 되지 않음
    if (!sourceColumn.element) {
      return DaafMessage.alert(
        `${TrdUtil.getFieldLogicalNm(
          sourceColumn
        )} 컬럼의 Element를 설정해주세요.`,
        "warning"
      );
    }
    if (!targetColumn.element) {
      return DaafMessage.alert(
        `${TrdUtil.getFieldLogicalNm(
          targetColumn
        )} 컬럼의 Element를 설정해주세요.`,
        "warning"
      );
    }
    if (
      StringUtils.equalsIgnoreCase(
        sourceColumn.element.domain.domainId,
        targetColumn.element.domain.domainId
      )
    ) {
      const relationObj = {
        fromField: sourceColumn,
        toField: targetColumn,
        sortSeq: newJoinColumns.length + 1,
      };
      newJoinColumns.push(relationObj);

      setJoinColumns(newJoinColumns);
    } else {
      return DaafMessage.alert(
        "같은 도메인을 사용하는 필드(컬럼)끼리 외래키를 설정 할 수 있습니다.",
        DaafEnums.MessageType.WARN
      );
    }
  };

  /**
   * 관계 선택 함수
   * @param {*} relation
   */
  const onChangeRelation = (value) => {
    setRelationType(value);
    if (!ObjectUtils.isEmpty(targetTable) && value === "I") {
      autoTableRelationSet(value, targetTable);
    }
  };

  /**
   * 관계 삭제
   * @param {*} e
   * @param {*} idx
   */
  const onRemoveJoin = (e, idx) => {
    const newJoin = [...joinColumns];
    newJoin.splice(idx, 1);
    setJoinColumns(newJoin);
    e.preventDefault();
  };

  /**
   * 확인 버튼
   * @param {*} e
   */
  const onClickConfirm = (e) => {
    if (StringUtils.isEmpty(relationNm) || relationNmInvalid) {
      return DaafMessage.alert(
        "관계 명을 입력해주세요",
        DaafEnums.MessageType.WARN
      );
    }
    if (ArrayUtils.isEmpty(joinColumns)) {
      return DaafMessage.alert(
        "컬럼 관계가 이루어지지 않았습니다.",
        DaafEnums.MessageType.WARN
      );
    }
    if (props.callback) {
      props.callback({
        relationNm,
        relationType,
        joinColumns,
        cascadeType,
        props: isIdentifying
          ? {
              keyColumns: sourcePkColumns.current,
              targetTable,
            }
          : {
              targetTable,
            },
      });
    }
  };

  const onChangeTable = (selectedTable) => {
    setTargetTable(selectedTable);
    if (relationType === "I" && !ObjectUtils.isEmpty(selectedTable)) {
      autoTableRelationSet(relationType, selectedTable);
    }
  };

  /**
   * 릴레이션 타입이 identifying 일때
   * 자동으로 JoinColumns 형성하는 로직
   * @param {*} relationType
   * @param {*} targetTable
   */
  const autoTableRelationSet = (relationType, targetTable) => {
    if (relationType === "I" && !ObjectUtils.isEmpty(targetTable)) {
      sourcePkColumns.current = sourceColumnOption.filter(
        (c) => c.pkYn === "Y"
      );
      sourcePkColumns.current = sourcePkColumns.current.map((field) => {
        const obj = { ...field };
        delete obj.fieldId;
        return obj;
      });
      /**
       * 1. 타겟 테이블의 필드 목록을 확인한다.
       * 2. 타겟 테이블의 필드 목록에서 sourceTable에서 PK 칼럼과 겹치는 컬럼이 있는지 확인한다.
       * 3. 참조 정보(joinColumns) 생성
       * 4. 겹치지 않는 필드를 타겟 테이블 필드로 추가한다.
       * 4-1. 추가시 tableFieldId는 uuid로 전환한다.
       */

      // 참조 정보 생성
      const joinColumnsInfo = sourcePkColumns.current.map((f) => {
        const toField = { ...f };
        toField.fieldId = StringUtils.getUuid();
        return {
          fromField: f,
          toField,
        };
      });
      setJoinColumns(joinColumnsInfo);
    }
  };

  const onChangeRelationNm = (e) => {
    const value = e.target.value;
    const validPattern = /^[A-Za-z0-9_]*$/;
    setRelationNm(value);
    if (validPattern.test(value)) {
      setRelationNmInvalid(false);
    } else {
      setRelationNmInvalid(true);
    }
  };

  return (
    <DaafModal>
      <DaafModal.Header title={"테이블 연결 설정"} />
      <DaafModal.Body>
        <DaafModalTemplate className={"erd-table-relation-wrapper"}>
          <Row>
            <Col>
              <Form.Group className="mt-3" as={Col} xs={12}>
                <Form.Label className={"required"}>관계 명</Form.Label>
                <Form.Control
                  value={relationNm}
                  onChange={onChangeRelationNm}
                  placeholder="input Relation Name..."
                  isInvalid={relationNmInvalid}
                />
                <Form.Control.Feedback type="invalid">
                  영어와 밑줄('_') 만 사용할 수 있습니다.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mt-3" as={Col} xs={12}>
                <Form.Label>연결 방식 선택</Form.Label>
                <ComboBox.Common
                  value={relationType}
                  mstCd="D0006"
                  onChange={(data) => onChangeRelation(data.id)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className="mt-3" as={Col} xs={12}>
                <Form.Label>CASCADE 유형 선택</Form.Label>
                <DForm.Select
                  defaultValue={cascadeType}
                  onChange={(e) => setCascadeType(e.target.value)}
                >
                  {[
                    { value: "N", label: "없음" },
                    { value: "A", label: "전체" },
                    { value: "U", label: "업데이트시에 적용" },
                    { value: "D", label: "삭제시 적용" },
                  ].map((option) => {
                    return (
                      <option value={option.value} key={option.value}>
                        {option.label}
                      </option>
                    );
                  })}
                </DForm.Select>
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className="mt-3 mb-3" as={Col} xs={12}>
            <div>
              * 개체 A, B 사이의 관계에서 A 개체의 기본키가 B 개체의 &nbsp;
              {StringUtils.equalsIgnoreCase(relationType, "nonIdentifying") ? (
                <strong>
                  비기본키 영역에서{" "}
                  <span style={{ color: "tomato" }}>외래키</span>
                </strong>
              ) : (
                <strong>
                  외래키이면서 동시에{" "}
                  <span style={{ color: "tomato" }}>기본키</span>
                </strong>
              )}
              가 되는 관계
            </div>
          </Form.Group>
          {refChangeable && tableList && (
            <Form.Group>
              <Form.Label>참조 테이블 설정</Form.Label>
              <DForm.ComboBox
                onChange={onChangeTable}
                options={tableList}
                getOptionLabel={(table) => {
                  let tableNm = table.tablePhysicalNm;

                  if (table.tableLogicalNm) {
                    tableNm = `${table.tableLogicalNm} | ${tableNm}`;
                  }
                  if (table.trdArea) {
                    tableNm = `[ ${table.trdArea.areaNm} ] ${tableNm}`;
                  }
                  return tableNm;
                }}
                getOptionValue={(table) => {
                  return table.tableMstId;
                }}
              />
              {/* <option value="">선택</option>
                {tableList.map((table) => {
                  let tableNm = table.tablePhysicalNm;

                  if (table.tableLogicalNm) {
                    tableNm = `${table.tableLogicalNm} | ${tableNm}`;
                  }
                  if (table.trdArea) {
                    tableNm = `[ ${table.trdArea.areaNm} ] ${tableNm}`;
                  }

                  return <option value={table.tableMstId}>{tableNm}</option>;
                })} */}
            </Form.Group>
          )}

          <Form.Group className="mt-3 mb-3" as={Col} xs={12}>
            <Row className="mb-3">
              <Col xs={8}>
                <Form.Label>Join Column</Form.Label>
              </Col>

              {!isIdentifying && !ObjectUtils.isEmpty(targetTable) && (
                <Col
                  xs={4}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button onClick={onAutoJoin}>필드 자동 조인</Button>
                </Col>
              )}
            </Row>

            <Grid
              columns={[
                {
                  field: "fromfieldPhysicalNm",
                  headerName: sourceTable.tablePhysicalNm,
                  renderCell: (data) => {
                    let physicalNm = data.fromField.physicalNm;
                    if (data.fromField.element) {
                      physicalNm = data.fromField.element.elementCd;
                    }
                    return physicalNm;
                  },
                },
                {
                  field: "toFieldPhysicalNm",
                  headerName: targetTable?.tablePhysicalNm,
                  renderCell: (data) => {
                    let physicalNm = data.toField.physicalNm;
                    if (data.fromField.element) {
                      physicalNm = data.toField.element.elementCd;
                    }
                    return physicalNm;
                  },
                },
                {
                  field: "remark",
                  style: { width: "100px" },
                  renderCell: (data, idx) => {
                    return (
                      !isIdentifying && (
                        <Button
                          variant="outline-danger"
                          size="sm"
                          onClick={(e) => onRemoveJoin(e, idx)}
                        >
                          삭제
                        </Button>
                      )
                    );
                  },
                },
              ]}
              rows={joinColumns}
            />
          </Form.Group>

          {!isIdentifying && (
            <Col xs={12}>
              <Form.Label>참조 관계 컬럼 선택 </Form.Label>
              <Row className="gy-3 join-field-choice">
                <Col xs={3} className="join-entity-name">
                  <span>{sourceTable.tablePhysicalNm}</span>
                </Col>
                <Col xs={7}>
                  <FieldSearcher
                    onChange={setSourceColumn}
                    fieldList={sourceColumnOption}
                  />
                </Col>
                <Col xs={2} className="join-from">
                  에서
                </Col>
                {ObjectUtils.isEmpty(targetTable) ? (
                  <>
                    <Col xs={3} />
                    <Col xs={7}>
                      <span className="blink">참조 테이블을 선택 해주세요</span>
                    </Col>
                    <Col xs={3} />
                  </>
                ) : (
                  <>
                    <Col className="join-entity-name" xs={3}>
                      <span>{targetTable?.tablePhysicalNm}</span>
                    </Col>
                    <Col xs={7}>
                      <FieldSearcher
                        onChange={setTargetColumn}
                        fieldList={targetColumnOption}
                      />
                    </Col>
                    <Col xs={2} className="join-from">
                      <Button
                        variant="outline-primary"
                        onClick={onAddJoinColumn}
                      >
                        참조 추가
                      </Button>
                    </Col>
                  </>
                )}
              </Row>
            </Col>
          )}
        </DaafModalTemplate>
      </DaafModal.Body>
      <DaafModal.Footer>
        <DaafModal.Footer.Button onClick={onClickConfirm}>
          확인
        </DaafModal.Footer.Button>
      </DaafModal.Footer>
    </DaafModal>
  );
};

export default TableRelationPopup;

const FieldSearcher = ({ field, fieldList, onChange: _onChange, ...props }) => {
  const onChange = (e) => {
    const field = fieldList.find((f) =>
      StringUtils.equalsIgnoreType(
        TrdUtil.getFieldPhysicalNm(f),
        e.target.value
      )
    );

    if (_onChange) _onChange(field);
  };

  return (
    <DForm.Select onChange={onChange}>
      <option value="">선택</option>
      {fieldList.map((field) => {
        let value = TrdUtil.getFieldPhysicalNm(field);
        let optionText = `${TrdUtil.getFieldLogicalNm(field)} | ${value}`;
        if (field.pkYn === "Y") {
          optionText += ` | 🔑 `;
        }
        if (field.pkYn === "N" && field.notNullYn === "Y") {
          optionText += ` | ⭐ `;
        }

        return <option value={value}>{optionText}</option>;
      })}
    </DForm.Select>
  );
};
