import {
  CodeService,
  DaafModal,
  DaafModalTemplate,
  DaafPopup,
  ObjectUtils,
  StringUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import Message from "@alpha/com.bizentro.daaf.front.framework/dist/component/Message";
import { MessageType } from "@alpha/com.bizentro.daaf.front.framework/dist/enums/enum";
import { MultiLanguage } from "app/Enums";
import Form from "components/common/form/Form";
import { useEffect, useState } from "react";
import { Col, FormControl, Row } from "react-bootstrap";
import MultiLangService from "services/MultiLangService";
import useAppEnv from "utils/hook/useAppEnv";

/**
 * DD 다국어 저장 및 조회하는 팝업
 *
 * @param title 팝업창 Title 명
 * @param tableNm 다국어 저장시 테이블 명
 * @param keyValue 다국어 저장시 참조할 id값
 * @param columnNm 어떤 컬럼에 저장될 다국어인지 판단하기 위해 넣는 props
 * @returns
 */
const DDMultiLangPopup = ({ title, tableNm, keyValue, columnNm, ...props }) => {
  const [fullNmList, setFullNmList] = useState([]);
  const [shortNmList, setShortNmList] = useState([]);
  const [descriptionList, setDescriptionList] = useState([]);

  const [languageList, setLanguageList] = useState([]);

  const { app, env } = useAppEnv();

  useEffect(() => {
    getMultiLangData();
  }, []);

  /**
   * 다국어리스트를 조회하는 함수
   */
  const getMultiLangData = async () => {
    const multiLangData = await MultiLangService.getMultiLangList({
      tableNm: tableNm,
      tableKeyValue: keyValue,
    });

    // data setting
    setFullNmList(
      multiLangData.data.filter((item) => item.columnNm === columnNm.fullNm)
    );
    StringUtils.equalsIgnoreCase(tableNm, MultiLanguage.TableName.ELEMENT) &&
      setShortNmList(
        multiLangData.data.filter((item) => item.columnNm === columnNm.shortNm)
      );
    setDescriptionList(
      multiLangData.data.filter(
        (item) => item.columnNm === columnNm.description
      )
    );

    CodeService.getCodeCombo({ codeMstCd: "D0008" }, (res) => {
      setLanguageList(res.data);
    });
  };

  /**
   * onBlur시 저장될 column에 따라 변경된 다국어를 set하는 함수
   *
   * @param {Event} e
   * @param {Object} objData
   * @param {String} langCd
   */
  const onBlurMultiLangData = (e, objData, langCd) => {
    const data = {
      ...objData,
      appEnvId: env.appEnvId,
      tableNm: tableNm,
      tableKeyValue: keyValue,
      langCd: langCd,
      columnNm: e.target.id,
      langText: e.target.value,
    };
    if (StringUtils.equalsIgnoreCase(data.columnNm, columnNm.fullNm)) {
      if (ObjectUtils.isEmpty(objData) && !StringUtils.isEmpty(data.langText)) {
        setFullNmList([...fullNmList, data]);
      } else {
        setFullNmList(
          fullNmList.map((f) => {
            if (f.langCd === data.langCd) {
              f = { ...data };
            }
            return f;
          })
        );
      }
    } else if (
      StringUtils.equalsIgnoreCase(data.columnNm, columnNm.shortNm) &&
      StringUtils.equalsIgnoreCase(tableNm, MultiLanguage.TableName.ELEMENT)
    ) {
      if (ObjectUtils.isEmpty(objData)) {
        setShortNmList([...shortNmList, data]);
      } else {
        setShortNmList(
          shortNmList.map((f) => {
            if (f.langCd === data.langCd) {
              f = { ...data };
            }
            return f;
          })
        );
      }
    } else if (
      StringUtils.equalsIgnoreCase(data.columnNm, columnNm.description)
    ) {
      if (ObjectUtils.isEmpty(objData)) {
        setDescriptionList([...descriptionList, data]);
      } else {
        setDescriptionList(
          descriptionList.map((f) => {
            if (f.langCd === data.langCd) {
              f = { ...data };
            }
            return f;
          })
        );
      }
    }
  };

  /**
   * 팝업창 확인버튼으로 종료시
   */
  const closePopup = () => {
    MultiLangService.saveMultiLangList({
      multiLangList: [...fullNmList, ...shortNmList, ...descriptionList],
    })
      .then((res) => {
        if (res.isError) {
          Message.alert("저장에 실패했습니다.", MessageType.ERROR);
        } else {
          Message.alert("저장 되었습니다", MessageType.SUCCESS);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    DaafPopup.close();
  };

  return (
    <DaafModal>
      <DaafModal.Header title={title} />
      <DaafModal.Body>
        <DaafModalTemplate>
          <Row className="mb-3">
            <Col className="multiLang-bottom" xs={2}>
              <Form.Label>{"Language"}</Form.Label>
            </Col>

            <Col className="multiLang-bottom">
              <Form.Label>{"Full Name"}</Form.Label>
            </Col>
            {StringUtils.equalsIgnoreCase(
              tableNm,
              MultiLanguage.TableName.ELEMENT
            ) && (
              <Col className="multiLang-bottom">
                <Form.Label>{"Short Name"}</Form.Label>
              </Col>
            )}
            <Col className="multiLang-bottom">
              <Form.Label>{"Desciption"}</Form.Label>
            </Col>
          </Row>
          {languageList.map((item, index) => {
            const fullNmObj = fullNmList.find((f) =>
              StringUtils.equalsIgnoreCase(f.langCd, item.id)
            );
            const shortNmObj = shortNmList.find((s) =>
              StringUtils.equalsIgnoreCase(s.langCd, item.id)
            );
            const descriptionObj = descriptionList.find((d) =>
              StringUtils.equalsIgnoreCase(d.langCd, item.id)
            );

            return (
              <Row className="mb-3" key={index}>
                <Col xs={2}>
                  <Form.Label>{item.text}</Form.Label>
                </Col>
                <Col>
                  <FormControl
                    id={columnNm.fullNm}
                    defaultValue={fullNmObj?.langText || ""}
                    onBlur={(e) => onBlurMultiLangData(e, fullNmObj, item.id)}
                  ></FormControl>
                </Col>
                {StringUtils.equalsIgnoreCase(
                  tableNm,
                  MultiLanguage.TableName.ELEMENT
                ) && (
                  <Col>
                    <FormControl
                      id={columnNm.shortNm}
                      defaultValue={shortNmObj?.langText || ""}
                      onBlur={(e) =>
                        onBlurMultiLangData(e, shortNmObj, item.id)
                      }
                    ></FormControl>
                  </Col>
                )}
                <Col>
                  <FormControl
                    as={"textarea"}
                    id={columnNm.description}
                    defaultValue={descriptionObj?.langText || ""}
                    onBlur={(e) =>
                      onBlurMultiLangData(e, descriptionObj, item.id)
                    }
                  ></FormControl>
                </Col>
              </Row>
            );
          })}
        </DaafModalTemplate>
      </DaafModal.Body>
      <DaafModal.Footer>
        <DaafModal.Footer.Button onClick={closePopup}>
          확인
        </DaafModal.Footer.Button>
      </DaafModal.Footer>
    </DaafModal>
  );
};

export default DDMultiLangPopup;
