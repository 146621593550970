/**
 * 커맨드 초기화
 * @param {*} output
 * @returns
 */
export function initCommand(output) {
  return {
    type: "INIT_COMMAND",
    payload: output,
  };
}

/**
 * 클립보드 뺴고 초기화
 * 워크플로우에서 사용
 * @param {*} output
 * @returns
 */
export function initDo() {
  return {
    type: "INIT_DO",
  };
}

/**
 * Undo 목록에 쌓기
 * @param {*} output
 * @returns
 */
export function stackUndo(output) {
  return {
    type: "STACK_UNDO",
    payload: output,
  };
}

/**
 * Redo 목록에 쌓기
 * @param {*} output
 * @returns
 */
export function stackRedo(output) {
  return {
    type: "STACK_REDO",
    payload: output,
  };
}
/**
 * Undo 실행
 * @returns
 */
export function undo() {
  return {
    type: "UNDO",
  };
}

/**
 * Redo 실행
 * @returns
 */
export function redo() {
  return {
    type: "REDO",
  };
}
/**
 * 클립보드 Redux에 내용 복사
 * 복사, 잘라내기시 사용
 * @param {*} node
 * @returns
 */
export function setClipboard(node) {
  return {
    type: "SET_CLIPBOARD",
    payload: node,
  };
}
