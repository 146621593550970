import { useState } from "react";
import ContentsMenuMini from "./ContentsMenuMini";

/**
 * 각 메뉴 컴포넌트
 * @param {*} param0
 * @returns
 */
const ContentsMenu = ({ Icon, text, subMenus = [], mini, ...props }) => {
  const [isCollapse, setIsCollapse] = useState(true);

  const onClickMenu = () => {
    if (props.onClick) {
      props.onClick(props.id);
    } else {
      setIsCollapse(!isCollapse);
    }
  };

  const isSelected = props.selectedMenu.id
    ? props.selectedMenu.id === props.id
      ? true
      : false
    : props.selectedMenu.text === text
    ? true
    : false;

  if (mini) {
    return (
      <ContentsMenuMini
        Icon={Icon}
        text={text}
        subMenus={subMenus}
        isSelected={isSelected}
        {...props}
      />
    );
  } else {
    return (
      <div className={`contents ${isCollapse ? "hidden" : "show"}`}>
        <div className="contents-header" onClick={onClickMenu}>
          <span className="icon">
            <Icon />
          </span>
          <div className="contents-name">{text}</div>
        </div>
        {subMenus.length > 0 && (
          <div
            style={{
              height: isCollapse ? 0 : `${subMenus.length * 40}px`,
            }}
            className={`contents-menu ${isCollapse ? "hidden" : "show"}`}
          >
            <ul>
              {subMenus.map((menu) => {
                return (
                  <li key={menu.text} onClick={menu.onClick}>
                    {menu.text}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    );
  }
};

export default ContentsMenu;
