import produce from "immer";

const initialState = {
  prev: [],
  latest: [],
  undoIndex: -1,
  clipboard: {},
  undoable: false,
  redoable: false,
};

export default function CommandReducer(state = initialState, action) {
  switch (action.type) {
    case "INIT_COMMAND":
      if (action.payload) {
        return produce(state, (draft) => {
          draft.prev = [action.payload];
          draft.latest = [action.payload];
          draft.undoIndex = -1;
        });
      } else {
        return initialState;
      }
    case "INIT_DO":
      return produce(state, (draft) => {
        draft.prev = [];
        draft.latest = [];
        draft.undoIndex = -1;
      });

    case "STACK_UNDO":
      return produce(state, (draft) => {
        if (draft.undoIndex < -1) {
          draft.prev = [
            ...state.prev.slice(0, state.undoIndex + 1),
            action.payload,
          ];
        } else {
          draft.prev = [...state.prev, action.payload];
          if (draft.prev.length > 10) draft.prev = draft.prev.slice(1);
        }
        draft.undoIndex = -1;
        draft.undoable = true;
      });
    case "STACK_REDO":
      return produce(state, (draft) => {
        if (draft.undoIndex < -1) {
          draft.latest = [
            ...state.latest.slice(0, state.undoIndex),
            action.payload,
          ];
        } else {
          draft.latest = [...state.latest, action.payload];
          if (draft.latest.length > 10) draft.latest = draft.latest.slice(1);
        }
      });
    case "UNDO":
      return produce(state, (draft) => {
        if (draft.prev.length + draft.undoIndex > 0) {
          draft.undoIndex = draft.undoIndex - 1;
          draft.undoable = true;
        } else {
          draft.undoable = false;
        }
        draft.redoable = true;
      });

    case "REDO":
      return produce(state, (draft) => {
        if (draft.undoIndex + 1 > -1) {
          draft.redoable = false;
          return draft;
        } else {
          draft.undoIndex = draft.undoIndex + 1;
          draft.redoable = true;
        }
        draft.undoable = true;
      });

    case "SET_CLIPBOARD":
      return produce(state, (draft) => {
        draft.clipboard = action.payload;
      });
    default:
      return state;
  }
}
