import { useState, ReactNode } from "react";

/**
 * 공통 컴포넌트 Tab
 * 헤더가 전환되면 헤더에서 지정된 컴포넌트 또는 Body로 들어오는 컴포넌트를 노출한다.
 * Body 컴포넌트를 우선적으로 확인 하고 없으면 Header에 들어있는 body를 표출한다.
 * @param {*} param0
 * @returns
 */
const Tab = ({ header, body, ...props }) => {
  const [selected, setSelected] = useState("");

  return (
    <div className="tab-wrapper">
      <div className="tab-header">{header}</div>
      {body}
    </div>
  );
};

/**
 * 공통 컴포넌트 Tab에서 사용되는 헤더
 * - props
 * 1. id : Header에서 타이틀 클릭시 쓰이는 id 값 required
 * 2. body : 별도로 body 컴포넌트를 넣지 않을 때 표출되는 Tab의 body 컴포넌트
 * @param {{id : string, body: ReactNode,}} param0
 * @returns
 */
const TabHeader = ({ id, onClick, selected, ...props }) => {
  return (
    <div
      className={`tab-title ${selected ? "selected" : ""}`}
      onClick={onClick ? onClick : () => {}}
    >
      {props.children}
    </div>
  );
};

/**
 * 공통 컴포넌트 Tab에서 사용되는 바디
 * - props
 * 1.
 * @param {*} props
 * @returns
 */
const TabBody = (props) => {
  return <div className="tab-body">{props.children}</div>;
};

export default Object.assign(Tab, { Header: TabHeader, Body: TabBody });
