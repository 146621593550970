import {
  Api,
  DaafEnums,
  DaafMessage,
  SessionUtils,
  UserService,
} from "@alpha/com.bizentro.daaf.front.framework";
import { AppPath, Enums } from "app/Enums";
import { useNavigate } from "react-router-dom";

function UnissoLogin() {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const token = params.get("AccessToken");

  const ssoLogin = (accessToken) => {
    Api.post("/unite/studio/checkSSO", { accessToken: accessToken }, (res) => {
      UserService.SSOLogin(
        { userId: res.data },
        (res) => {
          const { isSuccess, message } = res.data;
          if (isSuccess === "Y") {
            SessionUtils.set(res.data);
            navigate(AppPath.Env.Main.url);
          } else {
            DaafMessage.alert(message, DaafEnums.MessageType.ERROR);
            navigate(AppPath.Login);
          }
        },
        (err) => {
          DaafMessage.alert(
            "접속 중 오류가 발생하였습니다.",
            Enums.MessageType.ERROR
          );
          navigate(AppPath.Login);
        }
      );
    });
  };

  const ssoLoginTest = () => {
    // u1erp gun.han ID -> 테스트용 토큰
    const token = "";
    ssoLogin(token);
  };

  ssoLogin(token);

  return (
    <div>
      연결 중입니다.
      {/* <Button onClick={ssoLoginTest}>확인</Button> */}
    </div>
  );
}

export default UnissoLogin;
