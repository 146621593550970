import {
  DaafModal,
  DaafModalTemplate,
  ObjectUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import ElementListComponent from "page/element/ElementListComponent";

import { useState } from "react";

const ElementSelectPopup = ({ callback, ...props }) => {
  const [selectedElement, setSelectedElement] = useState({});

  const onRowDoubleClick = (data) => {
    if (callback) {
      callback(data);
    }
  };

  const onClickConfirm = () => {
    if (!ObjectUtils.isEmpty(selectedElement) && callback) {
      callback(selectedElement);
    }
  };

  return (
    <DaafModal>
      <DaafModal.Header title="Element 선택" />
      <DaafModal.Body>
        <DaafModalTemplate>
          <ElementListComponent
            onRowDoubleClick={onRowDoubleClick}
            onRowClick={(data) => setSelectedElement(data)}
            elementType={"element"}
          />
        </DaafModalTemplate>
      </DaafModal.Body>
      <DaafModal.Footer>
        <DaafModal.Footer.Button
          onClick={onClickConfirm}
          variant="outline-success"
        >
          추가
        </DaafModal.Footer.Button>
      </DaafModal.Footer>
    </DaafModal>
  );
};

export default ElementSelectPopup;
