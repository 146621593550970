import {
  ArrayUtils,
  DaafEnums,
  DaafMessage,
  DaafPopup,
  JsonUtils,
  LocalStorageService,
  ObjectUtils,
  StringUtils,
  UserUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import { AppPath, Enums } from "app/Enums";
import NewTrdPopup from "components/popup/trd/NewTrdPopup";
import TableRegisterPopup from "components/popup/trd/TableRegisterPopup";
import TableRelationPopup from "components/popup/trd/TableRelationPopup";
import TrdCommandLine from "components/trd/TrdCommandLine";
import { createContext, useCallback, useEffect, useRef, useState } from "react";
import { MdOutlineNightlightRound, MdOutlineWbSunny } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ReactFlow, {
  ConnectionMode,
  ControlButton,
  Controls,
  MiniMap,
  useEdgesState,
  useNodesState,
  useOnViewportChange,
} from "reactflow";
import { createTrd, updateTrdViewport } from "store/actions/TrdAction";
import Tablelist from "../tablelist";
import TrdBuilderBar from "./components/TrdBuilderBar";
import {
  TrdAreaTemplateType,
  TrdMemoNodeType,
  TrdTableType,
} from "./components/TrdNodeTypes";
import TrdReduxHelper from "./render/TrdReduxHelper";
import TrdRelationEdge from "./render/TrdRelationEdge";
import TrdRenderUtils from "./render/TrdRenderUtils";
import useTrdCallback from "./render/useTrdCallback";
import useTrdRender from "./render/useTrdRender";
import useAppEnv from "utils/hook/useAppEnv";
import TrdTableListPopup from "components/popup/trd/TrdTableListPopup";
import { Tooltip } from "react-tooltip";

export const ErdPopupSize = {
  Register: "1300px",
};

const nodeTypes = {
  [Enums.ErdType.TABLE]: TrdTableType,
  [Enums.ErdType.MEMO]: TrdMemoNodeType,
  [Enums.ErdType.AREA_TEMPLATE]: TrdAreaTemplateType,
};
const edgeTypes = {
  floating: TrdRelationEdge,
};

export const TrdContext = createContext({
  theme: "light",
  setTheme: () => {},
  actionContent: "",
  setActionContent: () => {},
  openNew: () => {},
  onOpenImportTablePopup: () => {},
  /**
   * 툴팁 컴포넌트 추가하는 함수
   * @param {Object} props
   * @param {import("react-tooltip").PlacesType} props.place
   * @param {String} props.tooltipId
   * @param {String} props.text
   * @returns
   */
  renderTooltip: (props) => {},
});

const TrdBuilder = ({ ...props }) => {
  const flowRef = useRef();
  const nodeChangeRef = useRef();
  const reactFlowWrapper = useRef();
  const dispatch = useDispatch();
  const trd = useSelector((state) => state.trd);
  const [relationCallback] = useTrdCallback();
  const location = useLocation();

  //state
  const userTheme = LocalStorageService.get(
    Enums.LocalStorageName.EDITOR_THEME
  );
  const [editorTheme, setEditorTheme] = useState(
    userTheme
      ? userTheme.userId === UserUtils.getId()
        ? userTheme.theme
        : "light"
      : "light"
  );
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const [erdNode, erdEdge] = useTrdRender(editorTheme);
  const inDebounce = useRef();
  const [actionContent, setActionContent] = useState(null);

  //뷰포트 기억할때 쓰는 함수
  const debounceScroll = (func, delay) => {
    if (inDebounce.current) clearTimeout(inDebounce.current);
    inDebounce.current = setTimeout(() => func(), delay);
  };

  /**
   * 뷰포트 바뀔때 redux에 저장
   */
  const viewPortLogger = useOnViewportChange({
    onEnd: useCallback(
      (viewport) =>
        debounceScroll(() => {
          if (!StringUtils.isEmpty(trd.info.trdId) && trd.activate.areaId)
            dispatch(updateTrdViewport(viewport, trd.activate.areaId));
        }, 250),
      [trd.activate]
    ),
  });

  useEffect(() => {
    return () => {
      setReactFlowInstance(null);
      setEdges([]);
      setNodes([]);
    };
  }, []);

  useEffect(() => {
    setNodes(erdNode);
    setEdges(erdEdge);
  }, [erdNode, erdEdge]);

  useEffect(() => {
    if (reactFlowInstance) {
      if (trd.activate.areaId) {
        reactFlowInstance.setViewport(
          TrdRenderUtils.getArea(trd, trd.activate.areaId).areaViewport
        );
      }
    }
  }, [trd.activate, reactFlowInstance]);

  /**
   * 테마 변경
   * @param {*} e
   * @param {*} theme
   */
  const onChangeTheme = (e, theme) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setEditorTheme(theme);
    LocalStorageService.set(Enums.LocalStorageName.EDITOR_THEME, {
      userId: UserUtils.getId(),
      theme,
    });
  };

  /**
   * 새 ERD
   */
  const openNew = useCallback(() => {
    const callback = (erdData) => {
      dispatch(createTrd(erdData));
    };
    DaafPopup.open(<NewTrdPopup callback={callback} />, {
      style: { content: { width: "550px" } },
    });
  }, []);

  /**
   * React Flow 캔버스 클릭 이벤트
   */
  const onClick = useCallback(
    async (event) => {
      event.preventDefault();
      if (!actionContent) return false;
      if (ObjectUtils.isEmpty(trd.areaList)) {
        setActionContent(null);
        openNew();
        return DaafMessage.alert("신규 ERD를 설정해주세요.", "warning");
      }

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });

      // check if the dropped element is valid
      if (!actionContent) return false; //데이터가 없는 경우

      if (StringUtils.equalsIgnoreCase(Enums.ErdType.TABLE, actionContent)) {
        onDropTable(position, { nodes, edges }, trd.activate);
      } else if (
        StringUtils.equalsIgnoreCase(Enums.ErdType.MEMO, actionContent)
      ) {
        onDropMemo(position);
      }
      setActionContent(null);
    },
    [reactFlowInstance, trd.areaList, nodes, edges, actionContent]
  );

  /**
   * 메모를 올렸을때
   * @param {*} position
   */
  const onDropMemo = (position) => {
    TrdReduxHelper.addMemo(
      dispatch,
      {
        compId: StringUtils.getUuid(),
        description: "",
        position,
        style: { width: 300, height: 300 },
        type: Enums.ErdType.MEMO,
      },
      trd
    );
  };

  /**
   * Table 을 생성하는 경우
   * @param {*} position
   */
  const onDropTable = (position, nodeInfo, area) => {
    const callback = (tableData, changeNodes = []) => {
      const prevNode = JsonUtils.findNode(
        trd.areaList,
        "tablePhysicalNm",
        tableData.tablePhysicalNm
      );
      if (!ObjectUtils.isEmpty(prevNode)) {
        DaafMessage.alert(
          "이미 등록된 테이블 입니다.",
          DaafEnums.MessageType.WARN
        );
      } else {
        TrdReduxHelper.addTrdTable(
          dispatch,
          tableData,
          changeNodes,
          trd,
          area.areaId
        );
        DaafPopup.close();
      }
    };

    DaafPopup.open(
      <TableRegisterPopup
        trd={trd}
        area={area}
        callback={callback}
        tableInfo={{ position }}
      />,
      {
        style: { content: { width: ErdPopupSize.Register } },
      }
    );
  };

  /**
   * Flow Panel 위에서 드래그 되었을때 이벤트
   */
  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onNodeCustomChange = (nodeChange) => {
    if (trd.activate.sectorType === "A") return false;
    let changedEntities = [];
    if (nodeChange[0].type === "dimensions") {
      debounceScroll(() => onNodesChange(nodeChange), 200);
    } else {
      onNodesChange(nodeChange);
    }
    if (!ArrayUtils.isEmpty(nodeChange)) {
      if (nodeChange[0].type === "position") {
        if (nodeChange.length === 1 && nodeChange[0].id === "track") {
        } else if (nodeChange[0].dragging === true) {
          nodeChangeRef.current = nodeChange;
        } else if (nodeChange[0].dragging === false) {
          if (nodeChangeRef.current) {
            changedEntities = getChangedNode("position");
            nodeChangeRef.current = null;
          }
        }
      } else if (nodeChange[0].type === "dimensions") {
        if (
          nodeChange[0].updateStyle === true &&
          nodeChange[0].resizing === true
        ) {
          nodeChangeRef.current = nodeChange;
        } else if (nodeChange[0].resizing === false) {
          if (nodeChangeRef.current) {
            changedEntities = getChangedNode("dimensions");
            nodeChangeRef.current = null;
          }
        }
      }
      if (changedEntities.length > 0) {
        TrdReduxHelper.updateNodes(dispatch, changedEntities, trd);
      }
    }
  };

  /**
   * 바뀐 노드 목록 리턴
   * onChangeNode에서 사용
   * @param {String} key
   * @returns {Array}
   */
  const getChangedNode = (key) => {
    const changedEntities = [];
    for (const _nodeInfo of nodeChangeRef.current) {
      let changedContents = {};
      const { id } = _nodeInfo;
      const node = nodes.find((node) => node.id === id);
      const { type } = node;
      switch (type) {
        //테이블일떄
        case Enums.ErdType.TABLE:
          changedContents = trd.tableList.find(
            (table) => table.tableMstId === node.data.tableMstId
          );
          break;
        //메모 일떄
        case Enums.ErdType.MEMO:
          let area = TrdRenderUtils.getArea(trd, trd.activate.areaId, true);
          changedContents = area.areaMemo.find((m) => m.compId === id);

          break;
        default:
          break;
      }
      if (!ObjectUtils.isEmpty(changedContents))
        if (StringUtils.equalsIgnoreType(key, "dimensions")) {
          //사이즈는 key값이 dimensions으로 들어오기 때문에 적용될 key를 style로 변경함
          changedContents = TrdRenderUtils.override(
            changedContents,
            "style",
            _nodeInfo["dimensions"]
          );
        } else {
          changedContents = TrdRenderUtils.override(
            changedContents,
            key,
            _nodeInfo[key]
          );
        }

      if (changedContents) {
        changedEntities.push(changedContents);
      }
    }
    return changedEntities;
  };

  /**
   * 테이블간 연결 로직
   */
  const onConnect = useCallback(
    (connection) => {
      const { source, target, sourceHandle, targetHandle } = connection;
      //원천 테이블
      const sourceNode = nodes.find((t) => t.id === source);
      const sourceTable = { ...sourceNode.data };
      //타겟 테이블
      const targetNode = nodes.find((t) => t.id === target);
      const targetTable = { ...targetNode.data };
      const callback = ({
        relationNm,
        relationType,
        cascadeType,
        joinColumns,
        props,
      }) => {
        relationCallback({
          relationNm,
          sourceTable,
          targetTable,
          cascadeType,
          relationType,
          joinColumns,
          props: {
            ...props,
            cb: () => {
              DaafPopup.close();
            },
          },
        });
      };

      DaafPopup.open(
        <TableRelationPopup
          callback={callback}
          sourceTable={sourceTable}
          targetTable={targetTable}
          newRelation={true}
          tableList={trd.tableList}
          refChangeable={false}
        />,
        {
          style: { content: { width: "600px" } },
        }
      );
    },
    [setEdges, trd, nodes, edges]
  );

  const cusorType = StringUtils.equalsIgnoreCase(actionContent, "table")
    ? "table-drop-cursor"
    : StringUtils.equalsIgnoreCase(actionContent, "memo")
    ? "table-drop-cursor"
    : "";

  /**
   * 다른 TRD에서 테이블 가져오는 경우
   * @param {*} callback
   */
  const onOpenImportTablePopup = (callback) => {
    const _callback = (data) => {
      importTable(data);
      DaafPopup.close();
    };
    DaafPopup.open(<TrdTableListPopup trd={trd} callback={_callback} />, {
      style: { content: { width: "550px" } },
    });
  };

  /**
   * 테이블 호출
   * @param {*} table
   * @returns
   */
  const importTable = (tableList) => {
    const currentArea = trd.areaList.find(
      (area) => area.areaId === trd.activate.areaId
    );
    const importTableList = [];
    tableList.forEach((table) => {
      const importTableInfo = {
        ...table,
        relation: [],
        trdArea: currentArea,
        tableMstId: StringUtils.getUuid(),
        trdMst: currentArea.trdMst,
        position: { x: 0, y: 0 },
        importTableId: table.tableMstId,
        trdTableField: table.trdTableField.map((field) => {
          return {
            ...field,
            fieldId: StringUtils.getUuid(),
          };
        }),
      };
      //필드 삭제 -> 노드 렌더링 단계에서 호출할 예정
      if (currentArea?.areaViewport) {
        importTableInfo.position.x = currentArea.areaViewport.x + 200;
        importTableInfo.position.y = currentArea.areaViewport.y + 100;
      }
      //이미 있으면 입력 방지
      const dupCheck = trd.tableList.find(
        (t) =>
          t.tablePhysicalNm === importTableInfo.tablePhysicalNm &&
          t.useYn === "Y"
      );
      if (dupCheck)
        return DaafMessage.alert(
          `${table.tablePhysicalNm} 테이블은 이미 등록된 테이블 입니다.`,
          "warning"
        );
      else importTableList.push(importTableInfo);
    });

    TrdReduxHelper.addTrdTable(
      dispatch,
      importTableList,
      [],
      trd,
      trd.activate.areaId
    );
  };

  /**
   * 툴팁 컴포넌트 추가하는 함수
   * @param {Object} props
   * @param {import("react-tooltip").PlacesType} props.place
   * @param {String} props.tooltipId
   * @param {String} props.text
   * @returns
   */
  const renderTooltip = (props) => {
    return (
      <Tooltip
        id={props.tooltipId}
        place={props.place || "right"}
        style={{ padding: "5px", fontSize: "12px" }}
      >
        {props.text}
      </Tooltip>
    );
  };

  const contextValue = {
    theme: editorTheme,
    setTheme: setEditorTheme,
    actionContent,
    setActionContent,
    openNew,
    onOpenImportTablePopup,
    renderTooltip,
  };

  return (
    <TrdContext.Provider value={contextValue}>
      <div
        className={`trd-editor-wrapper ${editorTheme}`}
        ref={reactFlowWrapper}
      >
        {location.pathname === AppPath.TRD.Editor_List.url ? (
          <Tablelist />
        ) : (
          <ReactFlow
            className={cusorType}
            nodes={nodes}
            nodeTypes={nodeTypes}
            edges={edges}
            maxZoom={5}
            minZoom={0.2}
            onNodesChange={onNodeCustomChange}
            onInit={setReactFlowInstance}
            onDragOver={onDragOver}
            zoomOnDoubleClick
            snapToGrid
            onConnect={onConnect}
            edgeTypes={edgeTypes}
            multiSelectionKeyCode={"Control"}
            connectionMode={ConnectionMode.Loose}
            proOptions={{ hideAttribution: true }}
            ref={flowRef}
            onClick={onClick}
          >
            <Controls position={"bottom-right"}>
              {editorTheme === "light" ? (
                <ControlButton
                  title="어둡게"
                  onClick={(e) => onChangeTheme(e, "dark")}
                >
                  <MdOutlineNightlightRound />
                </ControlButton>
              ) : (
                <ControlButton
                  title="밝게"
                  onClick={(e) => onChangeTheme(e, "light")}
                >
                  <MdOutlineWbSunny />
                </ControlButton>
              )}
            </Controls>
            <MiniMap
              position={"bottom-right"}
              style={{
                outline: "1px solid gray",
                borderRadius: "5px",
                boxShadow: "1px 1px 3px black",
                right: 50,
              }}
              zoomable
              pannable
              nodeColor={"#3c3c3c"}
              // onNodeClick={onMiniMapNodeClick}
              maskColor={"#D3D3D3cc"}
            />
            <TrdCommandLine />
            <TrdBuilderBar />
          </ReactFlow>
        )}
      </div>
    </TrdContext.Provider>
  );
};

export default TrdBuilder;
