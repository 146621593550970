/**
 * Redux Action 에서 쓰이는 Type 지정 하는 곳
 * Object 형태로 생성
 * value는 대문자 snake 케이스로 작성한다.
 * 예시 ) state를 저장하는 경우
 *          State.save = "STATE_SAVE"
 */

const ACTION_TYPE = {
  AppEnv: {
    set: "SET_APP_ENV",
  },
  Menu: {
    FullScreen: {
      set: "SET_FULL_SCREEN",
      close: "CLOSE_FULL_SCREEN",
    },
  },

  /**
   * Data Dictionary 관련
   */
  Domain: {
    params: "DOMAIN_PARAMS",
  },

  Element: {
    params: "ELEMENT_PARAMS",
  },

  /**
   * ERD 관련
   */
  TRD: {
    INIT: "INIT_ERD",
    UPDATE: "UPDATE_ERD",
    EDIT: "EDIT_ERD",
    CREATE: "CREATE_ERD",
    SELECT: "SELECT_ERD",
    UPDATE_VIEWPORT: "UPDATE_ERD_VIEWPORT",
    REVERSE_ENGINEERING: "IS_REVERSE_ENGINEERING",
    REVERSE_ENGINEERING_SET: "REVERSE_ENGINEERING_SET",
    LOAD: "TRD_LOAD",
    PATCH: "TRD_PATCH",
  },
};

export default ACTION_TYPE;
