import {
  ArrayUtils,
  ComboBox,
  ObjectUtils,
  UserService,
} from "@alpha/com.bizentro.daaf.front.framework";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import useAppEnv from "utils/hook/useAppEnv";

const DynamicSelect = ({
  data,
  idKey,
  valueKey,
  alwaysDisplayValue,
  onChange,
  onInputChange,
  placeholder,
  textRender,
  value,
  filterBy,
  ...props
}) => {
  const [editMode, setEditMode] = useState(false);

  if (editMode) {
    return (
      <ComboBox.Static
        data={data}
        idKey={idKey}
        valueKey={valueKey}
        alwaysDisplayValue={alwaysDisplayValue}
        onChange={onChange}
        onInputChange={onInputChange}
        placeholder={placeholder}
        textRender={textRender}
        value={value}
        filterBy={filterBy}
      />
    );
  } else {
    return (
      <div
        style={{ width: "100%", height: "100%" }}
        onDoubleClick={(e) => setEditMode(!editMode)}
      >
        {value}
      </div>
    );
  }
};

/**
 * 사용자 목록 Dynamic Select
 * @param {*} param0
 * @returns
 */
const User = ({ value, onChange, textRender, initialLoad, ...props }) => {
  const [editMode, setEditMode] = useState(initialLoad ? initialLoad : false);
  const [userList, setUserList] = useState([]);
  const { app, env } = useAppEnv();

  useEffect(() => {
    getUserList();
  }, []);

  const getUserList = () => {
    UserService.getUserList({}, (res) => {
      setUserList(res.data);
    });
  };

  const defaultTextRender = (option) => {
    return <>{`${option.userId} (${option.userNm})`}</>;
  };

  if (editMode) {
    return (
      <ComboBox.Static
        data={userList}
        idKey={"userId"}
        valueKey={"userNm"}
        alwaysDisplayValue={true}
        onChange={(opt) => {
          if (!ObjectUtils.isEmpty(ArrayUtils.isArray(opt) ? opt[0] : opt)) {
            setEditMode(false);
          }
          onChange(opt);
        }}
        filterBy={(option, state) => {
          if (state.text.length === 0) {
            return true;
          }

          if (
            option.userId.toLowerCase().indexOf(state.text.toLowerCase()) >
              -1 ||
            option.userNm.toLowerCase().indexOf(state.text.toLowerCase()) > -1
          ) {
            return true;
          }

          return false;
        }}
        placeholder={"사용자 명 검색"}
        textRender={textRender ? textRender : defaultTextRender}
        value={value}
      />
    );
  } else {
    return (
      <div
        style={{ width: "100%", height: "100%" }}
        onDoubleClick={(e) => setEditMode(!editMode)}
      >
        {value ? value : <>&nbsp;</>}
      </div>
    );
  }
};

export default Object.assign(DynamicSelect, {
  User,
});
