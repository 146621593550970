import Form from "components/common/form/Form";
import { Button, Col, InputGroup, Row } from "react-bootstrap";
import { MdSearch } from "react-icons/md";
import TrdUpdateViewer from "./TrdUpdateViewer";
import { useEffect, useState } from "react";
import { StringUtils } from "@alpha/com.bizentro.daaf.front.framework";
import TrdHistoryViewer from "./TrdHistoryViewer";
import TrdService from "services/TrdService";

const TrdHistoryDetailInfo = ({ trdMstHistory, prevTrdMst, ...props }) => {
  const [viewMode, setViewMode] = useState("update");
  const [trdHistoryInfo, setTrdHistoryInfo] = useState();
  const [prevTrdHistoryInfo, setPrevTrdHistoryInfo] = useState(null);

  const onClcickViewMode = (mode) => {
    setViewMode(mode);
  };

  useEffect(() => {
    getTrdHistoryInfo();
  }, [trdMstHistory]);

  const getTrdHistoryInfo = async () => {
    if (trdMstHistory?.trdHistoryId) {
      const result = await TrdService.getTrdMstHistoryDetailInfo({
        trdHistoryId: trdMstHistory.trdHistoryId,
      });
      setTrdHistoryInfo(result.data);
    }

    if (prevTrdMst?.trdHistoryId) {
      const prevResult = await TrdService.getTrdMstHistoryDetailInfo({
        trdHistoryId: prevTrdMst.trdHistoryId,
      });
      setPrevTrdHistoryInfo(prevResult.data);
    }
  };
  return (
    <div style={{ height: "calc(100% - 50px) " }}>
      <Row className="mb-3">
        <Col xs={3}>
          <InputGroup>
            <Form.DInput
              size={"sm"}
              placeholder={"검색어 입력  e.g. 테이블 명, 필드명 "}
            />
            <Button variant="outline-secondary" size="sm">
              <MdSearch />
            </Button>
          </InputGroup>
        </Col>
        <Col xs={3}>
          <InputGroup>
            <Button onClick={(e) => onClcickViewMode("update")}>
              업데이트 내용 보기
            </Button>
            <Button
              onClick={(e) => onClcickViewMode("all")}
              variant="outline-success"
            >
              전체 보기
            </Button>
          </InputGroup>
        </Col>
      </Row>
      {StringUtils.equalsIgnoreCase(viewMode, "update") ? (
        <TrdUpdateViewer
          trdHistoryInfo={trdHistoryInfo}
          prevTrd={prevTrdHistoryInfo}
        />
      ) : (
        <TrdHistoryViewer trdHistoryInfo={trdHistoryInfo} />
      )}
    </div>
  );
};

export default TrdHistoryDetailInfo;
