import envReducer from "./AppEnvReducer";
import TrdReducer from "./TrdReducer";
import DataDictionaryReducer from "./DataDictionaryReducer";
import MainReducer from "./MainReducer";
import CommandReducer from "./CommandReducer";

const AppReducer = {
  env: envReducer,
  trd: TrdReducer,
  dictionary: DataDictionaryReducer,
  main: MainReducer,
  command: CommandReducer,
};

export default AppReducer;
