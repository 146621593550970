import { Button } from "react-bootstrap";
import Grid from "components/grid/Grid";
import Tab from "../../components/common/tab/Tab";
import Title from "components/common/Title";

/**
 * @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 * 2024-04-24
 * APP 환경 설정은 Builder에서 진행하기 때문에
 * 해당 페이지는 삭제 예정입니다.
 * @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 * @returns
 *
 */
function SettingMain() {
  // useEffect(() => {
  //   TestService.sendTest(() => {});
  // }, []);

  const columns = [
    {
      field: "envName",
      name: "환경 명",
    },
    {
      field: "envUrl",
      name: "접속 URL",
    },
    {
      field: "envType",
      name: "환경 유형",
    },
    {
      field: "accessToken",
      name: "접근 토큰",
    },
    {
      field: "tenantId",
      name: "Tenant 아이디",
    },
    {
      field: "coCd",
      name: "회사 코드",
    },
    {
      field: "insetDt",
      name: "등록일",
    },
  ];

  const DATA = [
    {
      envName: "과제관리 개발",
      envUrl: "localhost:8090",
      envType: "개발",
      accessToken: "********************",
      tenantId: "*",
      coCd: "*",
      insetDt: "2024-04-05",
    },
    {
      envName: "",
      envUrl: "",
      envType: "",
      accessToken: "********************",
      tenantId: "*",
      coCd: "*",
      insetDt: "2024-04-05",
    },
    {
      envName: "",
      envUrl: "",
      envType: "",
      accessToken: "********************",
      tenantId: "*",
      coCd: "*",
      insetDt: "2024-04-05",
    },
    {
      envName: "",
      envUrl: "",
      envType: "",
      accessToken: "********************",
      tenantId: "*",
      coCd: "*",
      insetDt: "2024-04-05",
    },
  ];

  return (
    <div className={`dd-manage-wrapper `}>
      <div className="contents-body">
        <Title
          breadcrumb={
            <>
              <div>Home</div>/<div>환경 설정</div>
            </>
          }
        >
          개발 환경 설정
        </Title>
        <div className="setting-app">
          <h3 className="dd-title">Application 선택</h3>
          <div className="title-desc-wrapper">
            <span className="title-desc">
              개발 환경을 설정할 Application을 선택합니다.
            </span>
            <select className="app-select">
              <option>연구소 과제 관리 시스템</option>
            </select>
          </div>
        </div>

        <hr />

        <Tab
          header={
            <>
              <Tab.Header>개발 환경 등록</Tab.Header>
            </>
          }
          body={
            <Tab.Body>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "5px",
                  paddingRight: "20px",
                }}
              >
                <Button size="sm">추가</Button>
              </div>

              <Grid columns={columns} rows={[{}]} />
            </Tab.Body>
          }
        />
      </div>
    </div>
  );
}

export default SettingMain;
