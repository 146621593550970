import { LuMousePointerClick } from "react-icons/lu";
import React from "react";

const CommandButton = ({
  icon,
  onClick,
  disabled,
  dataTooltipId,
  ...props
}) => {
  return (
    <button
      data-tooltip-id={dataTooltipId}
      onClick={onClick ? onClick : () => {}}
      className="command-button"
      disabled={disabled}
      {...props}
    >
      {icon ? icon : <LuMousePointerClick />}
    </button>
  );
};

export default CommandButton;
