import {
  ArrayUtils,
  CommonUtils,
  ObjectUtils,
  StringUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import Message from "@alpha/com.bizentro.daaf.front.framework/dist/component/Message";
import DomainAutoSelectBox from "components/common/DomainAutoSelectBox";
import Grid from "components/grid/Grid";
import produce from "immer";
import WijmoGrid from "page/domain/WijmoGridTest1";
import LoadingPage from "page/LoadingPage";
import { useEffect, useRef, useState } from "react";
import { Button, Col, FormControl, Row } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import ElementService from "services/ElementService";
import useAppEnv from "utils/hook/useAppEnv";

/**
 * elementCd : Element Code params
 * elementNm : Element Name params
 * columns : Element List Grid에 넣을 columns 속성
 * listData : Element List Grid에 Set 할 List
 * onRowClick : 그리드 Row onClick 이벤트 함수
 * onSelectNewElement : 신규등록 버튼 사용 유무
 * searchElementList : 검색 버튼에 사용될 onClick 함수( Element List 검색 함수 )
 */
const ElementListComponent = ({
  dispatchElementParams,
  elementParams,
  columns,
  listData,
  setIsNewElement,
  onSelectNewElement,
  onRowDoubleClick,
  selectedElement,
  setSelectedElement,
  elementType,
  ...props
}) => {
  const [elementList, setElementList] = useState([]);
  const [filterdList, setFilterdList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [params, setParams] = useState({
    elementSearch: null,
    domain: null,
  });

  const selectElement = useRef(selectedElement);

  const { app, env } = useAppEnv();

  useEffect(() => {
    if (listData && !ArrayUtils.isEmpty(listData)) {
      setFilterdList(listData);
    } else if (ArrayUtils.isEmpty(filterdList)) {
      getElementList();
    }
  }, [listData]);

  useEffect(() => {
    if (setSelectedElement) {
      if (!StringUtils.isEmpty(selectedElement?.elementId)) {
        setSelectedElement(
          filterdList.find((e) => e.elementId === selectedElement.elementId)
        );
      } else {
        // setSelectedElement(filterdList[0]);
      }
    }
  }, [filterdList]);

  /**
   * Get Element List
   */
  const getElementList = async (params) => {
    let listData = [];
    if (StringUtils.equalsIgnoreCase(elementType, "element")) {
      listData = await ElementService.getElementContainsDomainList({
        disableLoad: true,
        appEnvId: env.appEnvId,
        ...elementParams,
        ...params,
      });
    } else {
      listData = await ElementService.getElementList({
        disableLoad: true,
        appEnvId: env.appEnvId,
        elementType,
        ...elementParams,
        ...params,
      });
    }

    if (listData.isError) {
      Message.alert(Message.alert("조회에 실패했습니다.", "warning"));
      setIsLoading(false);
      props.setIndexLoading && props.setIndexLoading(false);
    } else {
      setFilterdList(listData.data);
      setIsLoading(false);
      props.setIndexLoading && props.setIndexLoading(false);
    }
  };

  /**
   * 엘리먼트 리스트 필터링
   * @param {*} List
   * @returns
   */
  const filterElementList = (List) => {
    setIsLoading(false);
    return List;
  };

  /**
   * 버튼으로 검색시 filter
   */
  const getRowData = () => {
    setIsLoading(true);
    getElementList(params).then((res) => {
      dispatchElementParams && dispatchElementParams(params);
    });
  };

  /**
   * Parameters 변경
   */
  const onChangeParams = (e) => {
    setParams(
      produce(params, (draft) => {
        draft[e.target.id] = e.target.value;
      })
    );
  };

  // column 정보가 없는 경우 기본적으로 세팅될 Columns Data
  const columnData = columns || [
    { field: "elementCd", headerName: "Element Code", style: { width: "20%" } },
    { field: "elementNm", headerName: "Element 명", style: { width: "40%" } },
    { field: "domainCd", headerName: "Domain", style: { width: "20%" } },
    { field: "domainNm", headerName: "Domain 명", style: { width: "20%" } },
  ];

  return (
    <div>
      {isLoading ? <LoadingPage /> : ""}
      <Row className="mb-3">
        <Col xs={10}>
          <Row>
            <Col xs={3}>
              <FormControl
                id="elementSearch"
                size="sm"
                placeholder="Element 검색"
                value={
                  params.elementSearch || elementParams?.elementSearch || ""
                }
                onChange={(e) => {
                  onChangeParams(e);
                  dispatchElementParams &&
                    dispatchElementParams({
                      ...elementParams,
                      elementSearch: e.target.value,
                    });
                }}
                onKeyUp={(e) => e.key === "Enter" && getRowData()}
              />
            </Col>
            <Col xs={3}>
              <DomainAutoSelectBox
                placeholder="Domain 선택"
                value={params.domain || elementParams?.domain}
                getLabel={(data) => `${data.domainNm} (${data.domainCd})`}
                onChange={(data) => {
                  setParams({
                    ...params,
                    domain: data,
                  });
                  dispatchElementParams &&
                    dispatchElementParams({ ...elementParams, domain: data });
                }}
              />
            </Col>
            <Col xs={4} style={{ display: "flex" }}>
              <Button
                className="dd-manage-button"
                style={{ height: "100%", marginRight: "10px" }}
                size="sm"
                variant="outline-primary"
                onClick={getRowData}
              >
                <FaSearch />
              </Button>
              <Button
                className="dd-manage-button"
                style={{ height: "100%" }}
                size="sm"
                onClick={(e) => {
                  // ElementService.dummyElement({});
                  dispatchElementParams &&
                    dispatchElementParams({
                      elementSearch: null,
                      domain: null,
                    });
                  setParams({ elementSearch: null, domain: null });
                }}
              >
                검색 초기화
              </Button>
            </Col>
          </Row>
        </Col>
        <Col xs={2}>
          {onSelectNewElement && (
            <div className="form-button-wrapper">
              <Button variant="success" size="sm" onClick={onSelectNewElement}>
                신규 등록
              </Button>
            </div>
          )}
        </Col>
      </Row>

      {/* <Grid
        gridBoxStyle={{ maxHeight: "70vh", border: "1px solid #c4c4c4" }}
        numbering={true}
        columns={columnData}
        rows={filterdList}
        onRowClick={(e, row) => {
          setSelectedElement && setSelectedElement(row);
          setIsNewElement && setIsNewElement(false);
        }}
        onRowDoubleClick={(e, row) => {
          onRowDoubleClick && onRowDoubleClick(row);
        }}
        getId={(data) => data.elementId}
        selected={selectedElement || filterdList[0]}
        stickyHeader={true}
        isLoading={isLoading}
      /> */}
      <WijmoGrid
        style={{ maxHeight: "70vh" }}
        headerFilter={true}
        headersVisibility="Column"
        onRowClick={(e) => {
          setSelectedElement && setSelectedElement(e);
          setIsNewElement && setIsNewElement(false);
        }}
        columns={columns || columnData}
        rows={filterdList}
        onRowDoubleClick={onRowDoubleClick}
      />
    </div>
  );
};

export default ElementListComponent;
