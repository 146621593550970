import { RiLoader4Fill } from "react-icons/ri";

const LoadingPage = () => {
  return (
    <div className="loading-wrapper">
      <div className="progress">
        <RiLoader4Fill />
      </div>

      {/* <span>데이터를 호출 중입니다.</span> */}
    </div>
  );
};

export default LoadingPage;
