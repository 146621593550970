import { Api, BaseService } from "@alpha/com.bizentro.daaf.front.framework";

class MultiLangService extends BaseService {
  static api = new BaseService("/multiLang");

  static getMultiLangList = (data) => {
    return this.api.sendPost("/getMultiLangList", data);
  };
  static saveMultiLang = (data) => {
    return this.api.sendPost("/saveMultiLang", data);
  };
  static saveMultiLangList = (data) => {
    return this.api.sendPost("/saveMultiLangList", data);
  };

  static getMultiLang = (data, successCallback, errorCallback) => {
    return Api.post(
      this.apiPrefix + "/getMultiLang",
      data,
      successCallback,
      errorCallback
    );
  };

  static deleteMultiLang = (data, successCallback, errorCallback) => {
    return Api.post(
      this.apiPrefix + "/deleteMultiLang",
      data,
      successCallback,
      errorCallback
    );
  };
}

export default MultiLangService;
