import { AppPath } from "app/Enums";
import { Button } from "react-bootstrap";
import { TbLogout } from "react-icons/tb";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const navi = useNavigate();

  const onLogout = () => {
    sessionStorage.clear();
    navi(AppPath.Login);
  };
  return (
    <div className="header-component">
      <Button
        className="dd-manage-button"
        style={{ border: "none", fontSize: "1.5rem", height: "80%" }}
        size="lg"
        variant="outline-secondary"
        onClick={() => {
          onLogout();
        }}
      >
        <TbLogout />
      </Button>
    </div>
  );
};

export default Logout;
