import { UserUtils } from "@alpha/com.bizentro.daaf.front.framework";
import avatar from "../../../images/avartar.png";

const HeaderUser = () => {
  return (
    <>
      <div className="header-avatar">
        <div className="name-wrapper">
          <span className="name">{UserUtils.getNm()}</span>
          <span className="auth">관리자</span>
        </div>
        <img src={avatar} alt="사용자 이미지" />
      </div>
    </>
  );
};
export default HeaderUser;
