import {
  ArrayUtils,
  DaafEnums,
  DaafMessage,
  DaafPopup,
  LocalStorageService,
  ObjectUtils,
  SessionUtils,
  StringUtils,
  UserUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import { AppContext } from "app/AppProvider";
import { AppPath, Enums } from "app/Enums";
import CommandButton from "components/common/command/CommandButton";
import CommandButtonGroup from "components/common/command/CommandButtonGroup";
import CommandLine from "components/common/command/CommandLine";
import Form from "components/common/form/Form";
import NewTrdPopup from "components/popup/trd/NewTrdPopup";
import TrdQueryPopup from "components/popup/trd/TrdQueryPopup";
import TrdReverseEngineeringPopup from "components/popup/trd/TrdReverseEngineeringPopup";
import { TrdContext } from "page/trd/editor/TrdBuilder";
import TrdReduxHelper from "page/trd/editor/render/TrdReduxHelper";
import TrdRenderUtils from "page/trd/editor/render/TrdRenderUtils";
import { useCallback, useContext } from "react";
import { AiOutlineConsoleSql } from "react-icons/ai";
import { FaCompress, FaExpand, FaRegSave } from "react-icons/fa";
import { IoArrowRedo, IoArrowUndo } from "react-icons/io5";
import {
  MdEdit,
  MdOutlineDisplaySettings,
  MdOutlineNightlightRound,
  MdOutlineWbSunny,
} from "react-icons/md";
import { VscNewFile } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useNodes, useReactFlow, useStoreApi } from "reactflow";
import DataStudioService from "services/DataStudioService";
import ElementService from "services/ElementService";
import TrdService from "services/TrdService";
import { redo, undo } from "store/actions/CommandAction";
import { setFullScreen } from "store/actions/MainAction";
import {
  patchTrd,
  reverseEngineeringSetting,
  selectTrd,
  setReverseEngineering,
  updateTrd,
} from "store/actions/TrdAction";
import {
  confirmMessage,
  JSONTargetStringify,
  stopEvent,
} from "utils/CommonUtils";
import TrdUtil from "utils/TrdUtils";
import useAppEnv from "utils/hook/useAppEnv";

/**
 * Trd에 쓰이는 커맨드 라인
 */
const TrdCommandLine = ({ ...props }) => {
  const { theme, setTheme, openNew, renderTooltip } = useContext(TrdContext);
  const { connectState, setConnectState } = useContext(AppContext);
  const trd = useSelector((state) => state.trd);
  const { isFullScreen } = useSelector((state) => state.main);
  const { undoIndex, prev, latest, undoable, redoable } = useSelector(
    (state) => state.command
  );
  const nodes = useNodes();
  const { env } = useAppEnv();
  const dispatch = useDispatch();
  const { setCenter } = useReactFlow();
  const store = useStoreApi();
  const navigate = useNavigate();
  const location = useLocation();
  const isEditorListUrl = location.pathname === AppPath.TRD.Editor_List.url;

  /**
   * 테마 바꾸는 로직
   */
  const onChangeTheme = () => {
    let changed = null;
    if (StringUtils.equalsIgnoreCase(theme, "light")) {
      changed = "dark";
    } else {
      changed = "light";
    }
    setTheme(changed);
    LocalStorageService.set(Enums.LocalStorageName.EDITOR_THEME, {
      userId: UserUtils.getId(),
      theme: changed,
    });
  };

  /**
   * Trd 정보 수정
   * @param {*} e
   */
  const openEdit = (e) => {
    stopEvent(e);
    const callback = (erdData) => {
      TrdReduxHelper.editTrd(dispatch, { ...erdData }, trd);
    };
    DaafPopup.open(<NewTrdPopup callback={callback} trd={trd} />, {
      style: { content: { width: "550px" } },
    });
  };

  /**
   * 영역 탭 선택하는 함수
   */
  const onSelectArea = useCallback((e, data) => {
    dispatch(selectTrd(data));
  }, []);

  /**
   * 영역 탭 그리는 함수
   */
  const renderAreaTab = () => {
    const { activate } = trd;
    if (ArrayUtils.isEmpty(trd.areaList)) {
      return <></>;
    } else {
      return trd.areaList.map((area) => {
        return (
          <div
            className={`area-tab ${
              StringUtils.equalsIgnoreType(area.areaId, activate.areaId)
                ? "selected"
                : ""
            }`}
            key={area.areaId}
            onClick={(e) => onSelectArea(e, area)}
          >
            {area.areaNm}
          </div>
        );
      });
    }
  };

  /**
   * keys안에 들어간 항목은 number가 아닌 경우 obj에서 삭제함
   * @param {*} data
   * @param {*} keys
   */
  const filterKeyData = (data, keys = []) => {
    let obj = null;
    if (data === null || data === undefined) {
      return obj;
    } else {
      if (ArrayUtils.isArray(data)) {
        obj = [...data];
      } else if (ObjectUtils.isObject(data)) {
        obj = { ...data };
      }
      for (const key in obj) {
        if (StringUtils.includes(key, keys)) {
          if (typeof obj[key] === "string") {
            delete obj[key];
          }
        } else if (ObjectUtils.isObject(obj[key])) {
          obj[key] = filterKeyData(obj[key], keys);
        }
      }
    }

    return obj;
  };

  /**
   * Trd 저장
   */
  const onSave = async () => {
    if (StringUtils.isEmpty(trd.info.trdUid)) {
      openNew();
      return DaafMessage.alert("TRD를 생성해 주세요", "warning");
    }
    const {
      info,
      areaList: _areaList,
      tableList: _tableList,
      initReverseEngineering,
    } = trd;
    const trdInfo = { ...info, areaList: _areaList, tableList: _tableList };

    const saveLogic = async () => {
      //JSON 데이터 들은 stringify 진행
      if (JSON.stringify(trdInfo) === JSON.stringify(trd.info)) {
        return DaafMessage.alert("수정된 정보가 없습니다.", "warning");
      }
      const saveData = generateSaveData();
      if (!saveData) return;
      if (initReverseEngineering) {
        //리버스 엔지니어링으로 최초 로딩하는 경우 쿼리 중복을 위해서 Y로 체크하여 보냄
        saveData.appApplyQuery = "Y";
        saveData.appApplyYn = "Y";
      }
      const result = await TrdService.save(saveData);
      if (result.isError) {
        DaafMessage.alert(
          "저장 중 오류가 발생 했습니다.",
          DaafEnums.MessageType.ERROR
        );
      } else {
        DaafMessage.alert("저장 되었습니다.", DaafEnums.MessageType.SUCCESS);
        dispatch(setReverseEngineering(false));

        dispatch(
          patchTrd({
            ...result.data,
          })
        );
      }
    };

    if (ArrayUtils.isEmpty(_tableList)) {
      DaafMessage.confirm(
        "테이블이 생성되지 않았습니다. 진행하시겠습니까?",
        saveLogic
      );
    } else {
      saveLogic();
    }
  };

  /**
   * 선택된 테이블 포커스하는 기능
   * @param {*} data
   */
  const onSelectTableFocus = (data) => {
    const { nodeInternals } = store.getState(); // 캔버스 위에 그려진 노드 정보 전체 호출
    const nodes = Array.from(nodeInternals).map(([, node]) => node);

    if (nodes.length > 0) {
      const targetNode = nodes.find((node) => node.id === data.id);
      let x = targetNode.position.x + targetNode.width / 2;
      let y = targetNode.position.y + targetNode.height * 0.2;
      //전체 영역일때는 영역 보정 들어가야함
      if (trd.activate.sectorType === "A") {
        let areaNode = nodes.find(
          (node) =>
            node.id === TrdRenderUtils.getAreaNodeId(targetNode.data.trdArea)
        );
        x += areaNode.position.x;
        y += areaNode.position.y;
      }
      const zoom = 0.8;

      setCenter(x, y, { zoom, duration: 1000 });
    }
  };

  /**
   * 풀 화면
   * @param {*} e
   */
  const onClickTrdFullScreen = (e) => {
    stopEvent(e);
    dispatch(setFullScreen());
  };

  /**
   * undo
   */
  const onClickUndo = () => {
    const prevTrd = { ...prev.at(undoIndex) };
    dispatch(updateTrd(prevTrd));
    dispatch(undo());
  };

  /**
   * redo
   */
  const onClickRedo = () => {
    const nextTrd = { ...latest.at(undoIndex) };
    dispatch(updateTrd(nextTrd));
    dispatch(redo());
  };

  /**
   * 쿼리 생성
   */
  const onGenerateQuery = async () => {
    if (trd.initReverseEngineering) {
      return DaafMessage.alert(
        "테이블 호출로 불러온 경우 최초 저장 후 사용 가능합니다.",
        "info"
      );
    }
    const popupCallback = async (queryList) => {
      let saveData = generateSaveData();
      if (!saveData) return;
      saveData.appApplyYn = "Y";
      saveData.queryList = filterKeyData(queryList, ["trdId"]);
      const result = await TrdService.save(saveData);
      if (result.isError) {
        DaafMessage.alert(
          "저장 중 오류가 발생 했습니다.",
          DaafEnums.MessageType.ERROR
        );
      } else {
        DaafMessage.alert("저장 되었습니다.", DaafEnums.MessageType.SUCCESS);
        dispatch(patchTrd(result.data));
      }
    };

    DaafPopup.open(<TrdQueryPopup trd={trd} callback={popupCallback} />, {
      style: { content: { width: "800px" } },
    });
  };

  /**
   * TrdMst 저장 데이터 생성 함수
   */
  const generateSaveData = () => {
    try {
      const { info, areaList: _areaList, tableList: _tableList } = trd;
      const trdInfo = { ...info, areaList: _areaList, tableList: _tableList };

      const areaList = _areaList.map((area) => {
        let obj = { ...area };
        obj.userId = UserUtils.getId();
        obj.appEnvId = env.appEnvId;
        //areaViewPort , position stringify
        obj = filterKeyData(obj, ["areaId", "tableMstId", "fieldId", "trdId"]);

        obj = JSONTargetStringify(obj, "areaViewport");
        obj = JSONTargetStringify(obj, "areaMemo");
        obj = JSONTargetStringify(obj, "position");
        return obj;
      });
      //JSON 데이터 들은 stringify 진행
      const tableList = [];
      _tableList.forEach((table) => {
        //중복된 테이블 검색
        const dup = tableList.find((pTable) =>
          StringUtils.equalsIgnoreCase(
            pTable.tablePhysicalNm,
            table.tablePhysicalNm
          )
        );
        if (dup) {
          throw new Error(`${table.tablePhysicalNm} 테이블이 중복되었습니다.`);
        }
        //테이블 내 중복된 필드 검색
        table.trdTableField.forEach((field) => {
          const dupLength = table.trdTableField.filter((f) => {
            const fNm = f.element ? f.element.elementCd : f.physicalNm;
            if (field.element) {
              return StringUtils.equalsIgnoreCase(fNm, field.element.elementCd);
            } else {
              return StringUtils.equalsIgnoreCase(fNm, field.physicalNm);
            }
          });

          if (dupLength > 1) {
            throw new Error(
              `${table.tablePhysicalNm} 테이블의 ${field.physicalNm} 컬럼이 중복되었습니다.`
            );
          }
        });

        let obj = { ...table };
        obj.userId = UserUtils.getId();
        obj.appEnvId = env.appEnvId;
        obj = filterKeyData(obj, ["areaId", "tableMstId", "fieldId", "trdId"]);
        obj = JSONTargetStringify(obj, "areaMemo");
        obj = JSONTargetStringify(obj, "position");
        obj = JSONTargetStringify(obj, "areaViewport");
        tableList.push(obj);
      });

      /**
       * 새로만든 TRD의 경우 UUID가 들어가 있어서 삭제
       */
      if (typeof trdInfo.trdId === "string") {
        delete trdInfo.trdId;
      }

      return {
        ...trdInfo,
        appEnvId: env.appEnvId,
        areaList,
        tableList,
        appApplyYn: "N",
      };
    } catch (error) {
      DaafMessage.alert(error, "warning");
      return false;
    }
  };

  /**
   * 리버스 엔지니어링 실행
   * 대상 서버에서 테이블 호출
   * @param {*} e
   */
  const onClickReverseEngineering = async (e) => {
    stopEvent(e);
    if (StringUtils.isEmpty(trd.info.trdUid)) {
      openNew();
      return DaafMessage.alert("TRD를 생성해 주세요", "warning");
    }

    const connection = SessionUtils.get("connection");
    if (ObjectUtils.isEmpty(connection)) {
      return DaafMessage.alert("서버 연결이 필요합니다.", "warning");
    }

    // 연결 parameter
    const body = {
      ...connection,
      developerId: UserUtils.getId(),
      searchModule: JSON.stringify({
        in: ["*"],
        notIn: [""],
      }),
    };
    // 테이블 정보만 가진 목록 호출
    const serverTableList = await DataStudioService.getServerTableListOnly(
      body
    );

    const apiErrorMethod = (apiResult) => {
      if (apiResult.isError) {
        if (StringUtils.equalsIgnoreCase(apiResult.errType, "system")) {
          //연결 오류
          SessionUtils.set({ connection: null });
          setConnectState({});
        }
        return DaafMessage.alert(apiResult.message, "error");
      }
    };
    //연결 오류시 리턴
    apiErrorMethod(serverTableList);

    //매핑할 엘리먼트 목록 호출
    const ElementResult = await ElementService.getElementListContainsDomainObj({
      elementType: "element",
    });
    const elementList = ElementResult.data;

    /**
     * 팝업 콜백
     * @param {*} tableList
     */
    const callback = async (tableList) => {
      const tableNameList = tableList.map((t) => t.tableName);
      //선택된 테이블 목록 중 1개라도 기존 테이블 목록에 있는 것이면
      // 덮어 쓸 거라고 판단
      if (
        !ArrayUtils.isEmpty(trd.tableList) &&
        trd.tableList.some(
          (t) =>
            StringUtils.includesIgnoreCase(t.tablePhysicalNm, tableNameList) &&
            t.useYn !== "N"
        )
      ) {
        const confirmResult = await confirmMessage(
          "겹치는 테이블이 있습니다. 기존 테이블을 덮어 쓰시겠습니까?"
        );
        if (!confirmResult) return;
      }

      //선택된 테이블목록으로 각 테이블 상세 정보 호출
      const selectedTableListResult =
        await DataStudioService.getServerTableList({
          ...body,
          tableList: JSON.stringify(tableNameList),
        });

      apiErrorMethod(selectedTableListResult);

      const trdInfo = TrdUtil.convertTableData({
        trd,
        serverTableList: selectedTableListResult.data,
        elementList: elementList,
        appEnvId: env.appEnvId,
      });

      if (trdInfo.tableList.length > 50) {
        DaafMessage.alert(
          "호출된 테이블이 50개를 넘어가는 경우 테이블 목록으로 표시를 전환합니다.",
          "success"
        );
        navigate(AppPath.TRD.Editor_List.url);
      }

      dispatch(reverseEngineeringSetting(trdInfo));

      DaafPopup.close();
    };

    DaafPopup.open(
      <TrdReverseEngineeringPopup
        callback={callback}
        tableList={serverTableList.data}
      />,
      {
        style: { content: { width: "650px" } },
      }
    );

    return false;
  };

  return (
    <>
      <CommandLine {...props}>
        <div className="trd-title"> TRD</div>
        {!isEditorListUrl && (
          <>
            <CommandButton
              onClick={onChangeTheme}
              dataTooltipId={"change-theme"}
              icon={
                StringUtils.equalsIgnoreCase(theme, "light") ? (
                  <MdOutlineNightlightRound />
                ) : (
                  <MdOutlineWbSunny />
                )
              }
            />
            <CommandButton
              dataTooltipId={"undo"}
              icon={<IoArrowUndo />}
              onClick={onClickUndo}
              disabled={!undoable}
            />
            <CommandButton
              dataTooltipId={"redo"}
              icon={<IoArrowRedo />}
              onClick={onClickRedo}
              disabled={!redoable}
            />
          </>
        )}

        <CommandButtonGroup>
          <CommandButton
            dataTooltipId={"new-trd"}
            icon={<VscNewFile />}
            onClick={openNew}
          />
          <CommandButton
            dataTooltipId={"edit-trd"}
            disabled={trd.info.trdId ? false : true}
            icon={<MdEdit />}
            onClick={openEdit}
          />
          <CommandButton
            dataTooltipId={"save-trd"}
            icon={<FaRegSave />}
            onClick={onSave}
          />
          <CommandButton
            dataTooltipId={"gen-query"}
            icon={<AiOutlineConsoleSql />}
            onClick={onGenerateQuery}
          />

          <CommandButton
            dataTooltipId={"load-db"}
            icon={<MdOutlineDisplaySettings />}
            onClick={onClickReverseEngineering}
          />
        </CommandButtonGroup>
        <CommandButtonGroup>
          <CommandButton
            dataTooltipId={isFullScreen ? "minimize-screen" : "maximize-screen"}
            icon={isFullScreen ? <FaCompress /> : <FaExpand />}
            onClick={onClickTrdFullScreen}
          />
        </CommandButtonGroup>
        <CommandButtonGroup
          style={{ display: "flex", height: "100%", alignItems: "center" }}
        >
          {renderAreaTab()}
        </CommandButtonGroup>

        <CommandLine.Right>
          <CommandButtonGroup>
            <Form.ComboBox
              options={nodes.filter(
                (node) => node.type === Enums.ErdType.TABLE
              )}
              getOptionLabel={(obj) => obj.data.tablePhysicalNm}
              getOptionValue={(obj) => obj.data.tableMstId}
              onChange={onSelectTableFocus}
            />
          </CommandButtonGroup>

          <div className="trd-breadcumb">{trd.info.trdUid}</div>
          <div className="trd-breadcumb">{trd.info.trdNm}</div>
        </CommandLine.Right>
      </CommandLine>
      {renderTooltip({
        tooltipId: "change-theme",
        text: "테마 변경",
        place: "bottom",
      })}
      {renderTooltip({ tooltipId: "undo", text: "되돌리기", place: "bottom" })}
      {renderTooltip({ tooltipId: "redo", text: "다시하기", place: "bottom" })}
      {renderTooltip({ tooltipId: "new-trd", text: "새 TRD", place: "bottom" })}
      {renderTooltip({
        tooltipId: "edit-trd",
        text: "TRD 수정",
        place: "bottom",
      })}
      {renderTooltip({
        tooltipId: "save-trd",
        text: "TRD 저장",
        place: "bottom",
      })}
      {renderTooltip({
        tooltipId: "gen-query",
        text: "쿼리 생성",
        place: "bottom",
      })}
      {renderTooltip({
        tooltipId: "load-db",
        text: "테이블 불러오기",
        place: "bottom",
      })}
      {renderTooltip({
        tooltipId: "maximize-screen",
        text: "화면 최대화",
        place: "bottom",
      })}
      {renderTooltip({
        tooltipId: "minimize-screen",
        text: "화면 최소화",
        place: "bottom",
      })}
    </>
  );
};

export default TrdCommandLine;
