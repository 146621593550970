import {
  ArrayUtils,
  CodeService,
  ObjectUtils,
  StringUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import React, { useEffect, useState } from "react";
import { InputGroup, Form as RForm } from "react-bootstrap";
import ReactSelect from "react-select";
import { ComboDefaultStyle } from "./ComboStyle";

/**
 * Data Dictionary를 등록할때 사용하는 form 컴포넌트 입니다.
 * @param {*} param0
 * @returns
 */
const Form = ({ className, ...props }) => {
  return <div className={` ${className}`}>{props.children}</div>;
};

const Label = ({ className, ...props }) => {
  return (
    <RForm.Label className={` ${className}`}>{props.children}</RForm.Label>
  );
};

/**
 * @props feedback = Object | boolean : true 일때 feedback On,  text : feedback String
 */
/**
 *
 * @param {Object} props
 * @param {Function} props.onChange
 * @param {Function} props.onBlur
 * @param {String} props.value
 * @param {Object} props.feedback
 * @param {Boolean} props.feedback.boolean
 * @param {String} props.feedback.text
 * @returns
 */
const Input = ({ onChange, onBlur, feedback, Dref, value, ...props }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <RForm.Control
        as="input"
        onChange={onChange}
        onBlur={onBlur}
        style={{ height: "100%" }}
        size="sm"
        value={value || ""}
        ref={Dref}
        {...props}
      />
      {!ObjectUtils.isEmpty(feedback) && feedback.boolean && (
        <span
          style={{
            left: 5,
            display: "block",
            background: "darkgray",
            padding: "2px 5px",
            borderRadius: "4px",
            color: "whitesmoke",
            marginTop: ".5rem",
          }}
        >
          {feedback.text}
        </span>
      )}
    </div>
  );
};

const DInput = ({ onChange, onBlur, feedback, ...props }) => {
  return (
    <RForm.Control
      onChange={onChange}
      onBlur={onBlur}
      feedback={feedback}
      {...props}
    />
  );
};

/**
 * TextArea
 * @param {{style:React.CSSProperties, rows : number}} param0
 * @returns
 */
const Textarea = ({ rows, style, ...props }) => {
  return (
    <RForm.Control
      as="textarea"
      rows={5}
      style={{
        width: "100%",
        padding: "5px",
        height: "50px",
        margin: "2px",
        ...style,
      }}
      {...props}
    />
  );
};

const Select = ({ className, style, onChange, defaultValue, ...props }) => {
  return (
    <RForm.Select
      className={`${className}`}
      value={defaultValue}
      onChange={onChange}
      {...props}
    >
      {props.children}
    </RForm.Select>
  );
};

const CheckBox = ({ ...props }) => {
  return <input type="checkbox" {...props} />;
};

/**
 * 스위치
 * @param {*} param0
 * @returns
 */
const Switch = ({
  checked,
  size = "sm",
  onstyle,
  offstyle,
  offlabel,
  onlabel,
  width,
  onChange,
  ...props
}) => {
  return (
    <BootstrapSwitchButton
      checked={checked}
      size={size}
      onstyle={onstyle}
      offstyle={offstyle}
      offlabel={offlabel}
      onlabel={onlabel}
      width={width}
      onChange={onChange}
      {...props}
    />
  );
};

const ToggleButtonOnOff = () => {
  const [isOff, setIsOff] = useState(true);
  return <button onClick={() => setIsOff(!isOff)}></button>;
};

const ComboBox = ({
  options,
  value,
  onChange,
  autoFocus,
  placeholder,
  className,
  classNamePrefix,
  isDisabled,
  isMulti,
  isSearchable,
  name,
  isClearable,
  isLoading,
  getOptionLabel,
  getOptionValue,
  noOptionsMessage,
  ...props
}) => {
  return (
    <ReactSelect
      className={`daaf-codecombo ${className}`}
      classNamePrefix={classNamePrefix}
      autoFocus={autoFocus}
      isDisabled={isDisabled}
      isMulti={isMulti}
      isSearchable={isSearchable}
      isClearable={isClearable}
      isLoading={isLoading}
      name={name}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      noOptionsMessage={noOptionsMessage}
      value={value}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      styles={ComboDefaultStyle}
      {...props}
    />
  );
};

const Tooltip = ({ text = "", placement = "top", ...props }) => {
  const [isMouseOver, setIsMouseOver] = useState(false);

  const placePosition = () => {
    const placeStyle = {
      bottom: "calc(100% + 5px)",
      left: "-25%",
    };
    switch (placement) {
      case "top":
        placeStyle.bottom = "calc(100% + 5px)";
        placeStyle.left = "-25%";
        break;
      case "left":
        placeStyle.bottom = "0";
        placeStyle.right = "calc(100% + 5px)";
        delete placeStyle.left;
        break;
      case "right":
        placeStyle.bottom = "0";
        placeStyle.left = "calc(100% + 5px) ";
        break;
      case "bottom":
        placeStyle.top = "calc(100% + 5px)";
        placeStyle.left = "-25%";
        delete placeStyle.bottom;
        break;

      default:
        break;
    }

    return placeStyle;
  };

  return (
    <div
      style={{ position: "relative", display: "inline" }}
      onMouseOver={(e) => setIsMouseOver(true)}
      onMouseOut={(e) => setIsMouseOver(false)}
    >
      {!StringUtils.isEmpty(text) && isMouseOver && (
        <span
          style={{
            position: "absolute",
            padding: "3px 5px",
            background: "#5d5d5d",
            color: "whitesmoke",
            fontSize: "0.875rem",
            borderRadius: "5px",
            minWidth: "150%",
            zIndex: 9999,
            ...placePosition(),
          }}
        >
          {text}
        </span>
      )}

      {props.children}
    </div>
  );
};

/**
 * Z_Code data를 운용하는 콤보
 * @param {*} param0
 * @returns
 */
const CodeComboBox = ({
  value,
  onChange: _onChange,
  autoFocus,
  placeholder,
  className,
  classNamePrefix,
  isDisabled,
  isMulti,
  isSearchable,
  name,
  isClearable,
  isLoading,
  mstCd,
  disabled = false,
  ...props
}) => {
  const [options, setOptions] = useState();
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    getCodeCombo();
  }, []);

  useEffect(() => {
    //값이 오브젝트로 넘어오지 않고 number 또는 string으로 넘어오는 경우는 목록에서 찾아서 오브젝트로 값을 세팅한다.
    if (!ArrayUtils.isEmpty(options) && typeof value !== "object") {
      const sValue = options.find((o) => o.id === value);
      if (sValue) setSelectedOption(sValue);
    } else {
      setSelectedOption(value);
    }
  }, [options]);

  const getCodeCombo = () => {
    CodeService.getCodeCombo({ codeMstCd: mstCd }, (res) => {
      setOptions(res.data);
    });
  };

  const onChange = (data) => {
    setSelectedOption(data);
    if (_onChange) {
      _onChange(data);
    }
  };

  return (
    <ReactSelect
      className={`daaf-codecombo ${className}`}
      classNamePrefix={classNamePrefix}
      placeholder={placeholder}
      noOptionsMessage={() => "데이터가 없습니다."}
      value={selectedOption}
      getOptionLabel={(obj) => obj.text}
      getOptionValue={(obj) => obj.id}
      loadingMessage={"코드 목록을 호출 중입니다."}
      onChange={onChange}
      options={options}
      styles={{ ...ComboDefaultStyle }}
      isDisabled={disabled}
    />
  );
};

/**
 *
 * @param {import("react-bootstrap/esm/helpers").BsPrefixRefForwardingComponent} props
 * @returns
 */
const Group = (props) => {
  return (
    <RForm.Group className={`mb-3 ${props.className}`} {...props}>
      {props.children}
    </RForm.Group>
  );
};

export default Object.assign(Form, {
  Label,
  Input,
  Textarea,
  Select,
  ToggleButtonOnOff,
  Switch,
  CheckBox,
  DInput,
  Tooltip,
  Group,
  ComboBox,
  CodeComboBox,
  InputGroup,
});
