import React from "react";
import { useState } from "react";
import { StringUtils } from "@alpha/com.bizentro.daaf.front.framework";
import { useRef } from "react";
import { useEffect } from "react";
import Form from "components/common/form/Form";

/**
 * 더블클릭하면 편집모드 되는 컴포넌트
 * @param {{value : any,onBlur:Function, style : React.CSSProperties,id:string,name:string, initialMode:boolean}} param0
 * @returns
 */
const DynamicInput = ({
  value,
  onBlur: _onBlur,
  style,
  id,
  name,
  initialMode = false,
  ...props
}) => {
  const [inputMode, setInputMode] = useState(initialMode);
  const [inputValue, setInputValue] = useState(value);
  const inputRef = useRef();

  /**
   * 편집모드가 바뀔때 input element에 auto focus
   */
  useEffect(() => {
    if (inputMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputMode]);

  /**
   * 편집 모드 변경
   */
  const onDbClick = () => {
    setInputMode(!inputMode);
    setInputValue(value);
  };

  /**
   * 블러 이벤트
   * 포커스 탈출할때 실행
   * @param {*} event
   */
  const onBlur = (event) => {
    setInputMode(false);
    setInputValue("");

    if (_onBlur) {
      _onBlur(event);
    }
  };

  const onKeyDown = (e) => {
    if (StringUtils.equalsIgnoreCase(e.key, "enter")) {
      if (onBlur) onBlur({ target: { id: id, value: inputValue } });
    }
  };

  return (
    <div style={{ ...style, width: "100%" }}>
      {inputMode ? (
        <Form.Input
          id={id}
          name={name}
          onBlur={onBlur}
          // defaultValue={value}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={onKeyDown}
          Dref={inputRef}
        />
      ) : (
        <div style={{ ...style, width: "100%" }} onDoubleClick={onDbClick}>
          {value}&nbsp;
        </div>
      )}
    </div>
  );
};

export default DynamicInput;
