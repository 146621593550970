import produce from "immer";
import ACTION_TYPE from "store/actionType";

const initialState = {
  domain: {
    params: { domainSearch: null, dataType: null },
    domainList: [],
  },
  element: {
    params: { elementSearch: null, domain: null },
    elementList: [],
  },
};

export default function DataDictionaryReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPE.Domain.params:
      return produce(state, (draft) => {
        draft.domain.params = action.payload;
      });
    case ACTION_TYPE.Element.params:
      return produce(state, (draft) => {
        draft.element.params = action.payload;
      });
    default:
      return state;
  }
}
