/*!
 *   Combo style
 *   Author: donghoon.lee
 *   Date: 2024-04
 */

export const ComboDefaultStyle = {
  container: (provided) => ({
    ...provided,
    display: "inline-block",
    minHeight: "1px",
    textAlign: "left",
    width: "100%",
  }),
  //input 을 감싸는 스타일
  control: (provided) => ({
    ...provided,
    border: "1px solid lightgray",
    minHeight: "1px",
    height: "28px",
  }),
  //valueContainer 를 감싸는 container 스타일
  input: (provided) => ({
    ...provided,
    fontSize: "0.93rem",
    minHeight: "1px",
  }),
  //셀렉트 박스 오른쪽 화살표의 스타일
  dropdownIndicator: (provided) => ({
    ...provided,
    minHeight: "1px",
    paddingTop: "0",
    paddingBottom: "0",
    color: "#757575",
  }),
  //셀렉트 박스 오른쪽의 화살표 인디케이터 사이의 세로바 스타일
  indicatorSeparator: (provided) => ({
    ...provided,
    minHeight: "1px",
    height: "12px",
  }),
  //isClearble true일 경우 나타나는 X 자 표시 style
  clearIndicator: (provided) => ({
    ...provided,
    minHeight: "1px",
    paddingTop: "0px",
    paddingBottom: "0px",
  }),
  //value 값이 담기는 input 스타일
  valueContainer: (provided) => ({
    ...provided,
    minHeight: "1px",
    height: "24px",
    paddingTop: "0",
    paddingBottom: "0",
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: "0.93rem",
    minHeight: "1px",
    paddingBottom: "2px",
  }),
  indicatorContainer: (provided) => ({
    ...provided,
    paddingTop: "0px",
    paddingBottom: "0px",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      zIndex: 999,
    };
  },
};
