import {
  Api,
  DaafModal,
  DaafModalTemplate,
  ObjectUtils,
  WijmoGrid,
} from "@alpha/com.bizentro.daaf.front.framework";
import { ProgramType } from "@alpha/com.bizentro.daaf.front.framework/dist/enums/enum";
import Form from "components/common/form/Form";
import { useEffect, useRef, useState } from "react";
import { FormControl } from "react-bootstrap";

const PopupProgramPopup = ({ app, env, onCloseEvent, ...props }) => {
  const [programList, setProgramList] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState({});

  const searchInput = useRef();

  useEffect(() => {
    getPopupProgramList();
  }, []);

  /**
   * 팝업 프로그램 조회
   */
  const getPopupProgramList = () => {
    const body = {
      appId: app.appId,
      programType: ProgramType.POPUP_PROGRAM,
      moduleCd: "R2COMMON",
      tenantId: env.tenantId,
      coCd: env.coCd,
    };

    Api.post("http://localhost:8080/program/programList", body, (res) => {
      setProgramList(res.data);
      setFilterData(res.data);
    });
  };

  /**
   * PopupProgram List 검색
   */
  const filtering = () => {
    const filter = programList.filter((data) => {
      return (
        data.programId
          .toLowerCase()
          .replaceAll(" ", "")
          .includes(
            searchInput.current.value.toLowerCase().replaceAll(" ", "")
          ) ||
        data.programNm
          .toLowerCase()
          .replaceAll(" ", "")
          .includes(searchInput.current.value.replaceAll(" ", ""))
      );
    });
    setFilterData(filter);
  };

  /**
   * 팝업창 종료시
   */
  const closePopup = () => {
    if (!ObjectUtils.isEmpty(selectedRowData)) {
      onCloseEvent(selectedRowData);
    }
  };

  return (
    <DaafModal>
      <DaafModal.Header title="Popup 선택" />
      <DaafModal.Body>
        <DaafModalTemplate>
          <Form
            className="justify-content-center mb-4"
            style={{ width: "50%" }}
          >
            <Form.Label>{"Program 검색"}</Form.Label>
            <FormControl
              ref={searchInput}
              placeholder="검색어 입력"
              onChange={filtering}
            />
          </Form>
          <div
            style={{
              width: "100%",
              height: "50vh",
              overflow: "auto",
            }}
          >
            <WijmoGrid
              style={{ maxHeight: "50vh" }}
              onRowClick={(e) => {
                setSelectedRowData(e);
              }}
              onRowDoubleClick={(e) => {
                onCloseEvent(e);
              }}
              columns={[
                {
                  field: "programId",
                  name: "프로그램 ID",
                },
                {
                  field: "programNm",
                  name: "프로그램 명",
                },
                {
                  field: "programType",
                  name: "프로그램 타입",
                },
                {
                  field: "moduleCd",
                  name: "모듈 코드",
                },
              ]}
              rows={filterData}
            />
          </div>
        </DaafModalTemplate>
      </DaafModal.Body>
      <DaafModal.Footer>
        <DaafModal.Footer.Button onClick={closePopup}>
          &nbsp;확인
        </DaafModal.Footer.Button>
      </DaafModal.Footer>
    </DaafModal>
  );
};

export default PopupProgramPopup;
