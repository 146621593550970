import {
  ArrayUtils,
  DaafMessage,
  ObjectUtils,
  StringUtils,
} from "@alpha/com.bizentro.daaf.front.framework";

export const stopEvent = (e) => {
  if (e) {
    e.stopPropagation();
    e.preventDefault();
  }
};

/**
 * JSON Data 내에서 지정한 key를 String으로 바꿈
 * @param {*} jsonData
 * @param {*} key
 */
export const JSONTargetStringify = (data, key) => {
  let obj = null;
  if (data === null || data === undefined) return obj;
  if (ArrayUtils.isArray(data)) {
    obj = [...data];
  } else if (ObjectUtils.isObject(data)) {
    obj = { ...data };
  } else {
    obj = data;
  }
  for (const dataKey in obj) {
    if (StringUtils.equalsIgnoreCase(dataKey, key)) {
      if (typeof obj[dataKey] !== "string")
        obj[dataKey] = JSON.stringify(obj[dataKey]);
    } else if (
      ArrayUtils.isArray(obj[dataKey]) ||
      ObjectUtils.isObject(obj[dataKey])
    ) {
      obj[dataKey] = JSONTargetStringify(obj[dataKey], key);
    }
  }
  return obj;
};

/**
 * JSON Data 내에서 지정한 key를 parsing
 * @param {*} jsonData
 * @param {*} key
 */
export const JSONTargetParse = (objectData, key) => {
  let obj = null;
  if (objectData === null || objectData === undefined) return obj;
  if (ArrayUtils.isArray(objectData)) {
    obj = objectData.map((childData) => {
      return JSONTargetParse(childData, key);
    });
  } else if (ObjectUtils.isObject(objectData)) {
    obj = { ...objectData };
    for (const dataKey in obj) {
      if (StringUtils.equalsIgnoreCase(dataKey, key)) {
        if (typeof obj[dataKey] === "string") {
          obj[dataKey] = JSON.parse(obj[dataKey]);
        }
      } else if (
        ArrayUtils.isArray(obj[dataKey]) ||
        ObjectUtils.isObject(obj[dataKey])
      ) {
        obj[dataKey] = JSONTargetParse(obj[dataKey], key);
      }
    }
  } else {
    obj = objectData;
  }

  return obj;
};

export const changeFullScreen = (e) => {
  if (e) stopEvent(e);
  // 현재 문서의 body 요소를 선택합니다.
  let elem = document.body;

  // 현재 문서가 전체 화면 모드인지 확인합니다.
  if (
    // W3C 표준: 전체 화면 모드에서 표시된 요소가 없으면 전체 화면 모드가 아님을 의미합니다.
    (document.fullScreenElement !== undefined &&
      document.fullScreenElement === null) ||
    // Internet Explorer: 전체 화면 모드에서 표시된 요소가 없으면 전체 화면 모드가 아님을 의미합니다.
    (document.msFullscreenElement !== undefined &&
      document.msFullscreenElement === null) ||
    // Firefox: 전체 화면 모드가 아닌 상태를 의미합니다.
    (document.mozFullScreen !== undefined && !document.mozFullScreen) ||
    // WebKit 기반 브라우저 (예: Chrome, Safari): 전체 화면 모드가 아닌 상태를 의미합니다.
    (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)
  ) {
    // 전체 화면 모드가 아닌 경우, 전체 화면 모드로 전환합니다.

    // W3C 표준 메서드: 전체 화면 모드로 전환합니다.
    if (elem.requestFullScreen) {
      elem.requestFullScreen();
    }
    // Firefox 메서드: 전체 화면 모드로 전환합니다.
    else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    }
    // WebKit 기반 브라우저 메서드: 전체 화면 모드로 전환합니다.
    // 키보드 입력을 허용하도록 설정합니다.
    else if (elem.webkitRequestFullScreen) {
      elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
    }
    // Internet Explorer 메서드: 전체 화면 모드로 전환합니다.
    else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
    return true;
  } else {
    // 현재 전체 화면 모드인 경우, 전체 화면 모드를 해제합니다.

    // W3C 표준 메서드: 전체 화면 모드를 해제합니다.
    if (document.cancelFullScreen) {
      document.cancelFullScreen();
    }
    // Firefox 메서드: 전체 화면 모드를 해제합니다.
    else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    }
    // WebKit 기반 브라우저 메서드: 전체 화면 모드를 해제합니다.
    else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
    }
    // Internet Explorer 메서드: 전체 화면 모드를 해제합니다.
    else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
    return false;
  }
};

export const confirmMessage = (text) => {
  return new Promise((resolve, reject) => {
    DaafMessage.confirm(
      text,
      () => {
        resolve(true);
      },
      () => {
        resolve(false);
      }
    );
  });
};

/**
 * App에서 ConfigCd 값을 가져오는 로직
 * App 필수
 * @param {*} app
 * @param {*} configCd
 * @returns
 */
export const getAppConfig = (app, configCd) => {
  if (!app) return null;
  if (!configCd) return app.config;
  const config = app.config.find((config) =>
    StringUtils.equalsIgnoreCase(config.configCd, configCd)
  );
  if (config) {
    return config.configValue;
  } else {
    return null;
  }
};
