import {
  ConnectionService,
  DaafEnums,
  DaafMessage,
  DaafPopup,
  ObjectUtils,
  SessionUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import { AppContext } from "app/AppProvider";
import ConnectPopup from "components/popup/common/ConnectPopup";
import ConnectionListPopup from "components/popup/common/ConnectionListPopup";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  MdOutlineSignalWifiStatusbar4Bar,
  MdSignalWifiStatusbarConnectedNoInternet,
} from "react-icons/md";
import { stopEvent } from "utils/CommonUtils";
import useAppEnv from "utils/hook/useAppEnv";

const ConnectionControl = () => {
  const { connectState, setConnectState } = useContext(AppContext);

  useEffect(() => {
    const myConnection = SessionUtils.get("connection");
    if (myConnection) {
      setConnectState(myConnection);
    }
  }, []);

  /**
   * 연결 팝업 호출 이벤트
   * @param {*} connectInfo
   * @param {*} connectionList
   */
  const onOpenConnectPopup = (connectInfo, connectionList) => {
    /**
     * 연결 이벤트
     * @param {*} data
     */
    const callbackFnc = async (data, cb) => {
      ConnectionService.connect(
        data,
        (res) => {
          const { connectionInfo, ...otherInfo } = res.data;
          const userConnection = {
            ...connectionInfo,
            ...otherInfo,
            userId: data.systemUserId,
          };
          let userInfo = SessionUtils.get(null);
          let targetObj = {
            ...userInfo,
            connection: userConnection,
          };
          SessionUtils.set(targetObj);
          setConnectState(userConnection);
          DaafMessage.alert(
            "연결 및 저장 되었습니다.",
            DaafEnums.MessageType.SUCCESS
          );
          DaafPopup.close();
        },
        (error) => {
          DaafMessage.alert(error.message, "error");
          cb();
        }
      );
    };
    /**
     * 연결 해제
     * @param {*} data
     */
    const disconnection = (data) => {
      SessionUtils.set({ connection: null });
      setConnectState(null);
    };

    /**
     * 연결 정보 삭제
     */
    const deleteConnectionFnc = () => {};

    /**
     * 연결 테스트
     * @param {*} data
     * @param {*} cb
     */
    const ConnectTest = (data, cb) => {
      ConnectionService.test(
        data,
        (res) => {
          if (res.data) {
            DaafMessage.alert("연결 성공", "success");
          } else {
            DaafMessage.alert("연결 성공", "error");
          }
          cb();
        },
        cb
      );
    };

    DaafPopup.open(
      <ConnectPopup
        title={connectInfo.connectionNm}
        callbackFnc={callbackFnc}
        connection={connectInfo}
        disconnection={disconnection}
        deleteConnectionFnc={deleteConnectionFnc}
        connectionList={connectionList}
        connectTest={ConnectTest}
      />,
      {
        style: { content: { width: "600px" } },
      }
    );
  };

  /**
   * 연결 목록 팝업 호출하는 이벤트
   */
  const onOpenConnectionList = () => {
    ConnectionService.getAllConnectionList({}, (res) => {
      const connectionList = [...res.data];

      const callback = (connectInfo) => {
        DaafPopup.close();
        onOpenConnectPopup(connectInfo, connectionList);
      };

      DaafPopup.open(
        <ConnectionListPopup
          connectionList={connectionList}
          callback={callback}
        />,
        {
          style: { content: { width: "600px" } },
        }
      );
    });
  };

  const onClickConnection = (e) => {
    stopEvent(e);
    if (ObjectUtils.isEmpty(connectState)) {
      onOpenConnectionList();
    } else {
      ConnectionService.getAllConnectionList({}, (res) => {
        onOpenConnectPopup(connectState, res.data);
      });
    }
  };

  return (
    <div
      className={`connection-wrapper ${
        ObjectUtils.isEmpty(connectState) ? "unconnect" : "connected"
      }`}
      onClick={onClickConnection}
    >
      <span className="connect-icon">
        {ObjectUtils.isEmpty(connectState) ? (
          <MdSignalWifiStatusbarConnectedNoInternet />
        ) : (
          <MdOutlineSignalWifiStatusbar4Bar />
        )}
      </span>

      <div>
        {ObjectUtils.isEmpty(connectState)
          ? "연결 필요"
          : `${connectState.connectionNm} 연결됨`}
      </div>
    </div>
  );
};

export default ConnectionControl;
