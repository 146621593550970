import { BaseService } from "@alpha/com.bizentro.daaf.front.framework";

class DomainService extends BaseService {
  static api = new BaseService("/domain");

  /**
   * 도메인 조회
   * @returns
   */
  static getDomain = (data) => {
    return this.api.sendPost("/getDomain", data);
  };

  /**
   * 도메인 전체 조회
   * @param {*} data
   * @param {*} successCallback
   * @param {*} errorCallback
   * @returns
   */
  static getDomainList = (data) => {
    return this.api.sendPost("/getDomainList", data);
  };

  /**
   * 도메인 저장
   * @param {*} data
   * @returns
   */
  static saveDomain = (data) => {
    return this.api.sendPost("/saveDomain", data);
  };

  /**
   * 도메인 삭제
   * @param data domainId
   * @returns
   */
  static deleteDomain = (data) => {
    return this.api.sendPost("/deleteDomain", data);
  };
}

export default DomainService;
