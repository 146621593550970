import {
  AuthProvider,
  AuthRoute,
} from "@alpha/com.bizentro.daaf.front.framework";
import MainWrapper from "components/MainWrapper";
import NotFound from "page/NotFound";
import Domain from "page/domain";
import Element from "page/element";
import EnvChoice from "page/envChoice";
import DataStudio from "page/studio";
import TrdEditor from "page/trd/editor";
import TrdHistoryMain from "page/trd/history";
import TrdHistoryDetail from "page/trd/history/TrdHistoryDetail";
import TrdList from "page/trd/list";
import Login from "page/user/Login";
import { Navigate, Route, Routes } from "react-router-dom";
import SettingMain from "../page/setting/SettingMain";
import SettingUser from "../page/setting/SettingUser";
import { AppPath } from "./Enums";
import UnissoLogin from "page/UnissoLogin";

function AppRouter() {
  return (
    <AuthProvider>
      <Routes>
        <Route
          path={AppPath.Root}
          element={<Navigate to={AppPath.Domain.Edit.url} replace />}
        />
        <Route path={AppPath.Login} element={<Login />} />
        <Route path="/unite/studio/checkSSO" element={<UnissoLogin />} />
        <Route
          path={AppPath.Main}
          element={<AuthRoute authFailReturnType="login" />}
        >
          <Route path={AppPath.Env.Main.path} element={<EnvChoice />} />
          <Route element={<MainWrapper />}>
            <Route path={AppPath.Domain.Main.path}>
              <Route path={AppPath.Domain.Edit.path} element={<Domain />} />
            </Route>
            <Route path={AppPath.DataElement.Main.path}>
              <Route
                path={AppPath.DataElement.Edit.path}
                element={<Element />}
              />
            </Route>

            <Route path={AppPath.Setting.Main.path}>
              <Route
                path={AppPath.Setting.UserSet.path}
                element={<SettingUser />}
              />
              <Route
                path={AppPath.Setting.EnvSet.path}
                element={<SettingMain />}
              />
            </Route>
            <Route path={AppPath.TRD.Main.path}>
              <Route path={AppPath.TRD.List.path} element={<TrdList />} />
              <Route path={AppPath.TRD.Editor.path} element={<TrdEditor />} />
              <Route
                path={AppPath.TRD.Editor_List.path}
                element={<TrdEditor />}
              />
              <Route
                path={AppPath.TRD.History.path}
                element={<TrdHistoryMain />}
              />
              <Route
                path={AppPath.TRD.History_Detail.path}
                element={<TrdHistoryDetail />}
              />
            </Route>
            <Route path={AppPath.Studio.Main.path} element={<DataStudio />} />
          </Route>
        </Route>
        <Route path={AppPath.NotFound} element={<NotFound />} />
      </Routes>
    </AuthProvider>
  );
}

export default AppRouter;
