import {
  CommonUtils,
  StringUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import Template from "components/common/Template";
import Grid from "components/grid/Grid";
import TrdHistoryDetailInfo from "components/trd/TrdHistoryDetailInfo";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TrdService from "services/TrdService";

const TrdHistoryDetail = () => {
  const [trdMstHistoryList, setTrdMstHistoryList] = useState([]);
  const params = useParams();
  const [selectedTrd, setSelectedTrd] = useState(null);
  const [prevTrd, setPrevTrd] = useState(null);
  const [detailShow, setDetailShow] = useState(false);

  const columns = [
    {
      field: "trdNm",
      headerName: "TRD 명",
    },
    {
      field: "appApplyYn",
      headerName: "앱 반영 여부",
      renderCell: (data) => {
        return StringUtils.equalsIgnoreCase(data.appApplyYn, "Y")
          ? "예"
          : "아니오";
      },
    },
    {
      field: "trdUid",
      headerName: "TRD U-ID",
    },
    {
      field: "trdAreaType",
      headerName: "영역 유형",
      renderCell: (data) => {
        return StringUtils.equalsIgnoreCase(data.trdAreaType, "M")
          ? "다중 영역"
          : "단일 영역";
      },
    },
    {
      field: "insertUserId",
      headerName: "수정자",
    },
    {
      field: "insertDt",
      headerName: "수정일",
      renderCell: (rowData) => {
        return CommonUtils.getDate(rowData.insertDt, "datetime");
      },
    },
  ];

  const detailColumn = [
    {
      field: "trdNm",
      headerName: "TRD 이름",
    },
    {
      field: "appApplyYn",
      headerName: "앱 반영 여부",
      renderCell: (data) => {
        return StringUtils.equalsIgnoreCase(data.appApplyYn, "Y")
          ? "예"
          : "아니오";
      },
    },

    {
      field: "insertDt",
      headerName: "수정일",
      renderCell: (rowData) => {
        return CommonUtils.getDate(rowData.insertDt, "datetime");
      },
    },
  ];

  useEffect(() => {
    getTrdMstHistoryList();
  }, []);

  /**
   * TRD MST 이력 목록 호출
   */
  const getTrdMstHistoryList = async () => {
    const result = await TrdService.getTrdMstHistoryList({
      trdHistoryMstId: params.trdHistoryMstId,
    });
    setTrdMstHistoryList(result.data);
  };

  /**
   * 그리드 클릭 이벤트 TRD 상세
   * @param {*} e
   * @param {*} data
   * @param {*} index
   */
  const onDetailTrd = (e, data, index) => {
    if (data.trdHistoryId === selectedTrd?.trdHistoryId) {
      setSelectedTrd(null);
      setPrevTrd(null);
      setDetailShow(false);
    } else {
      setSelectedTrd(data);
      if (index < trdMstHistoryList.length - 1) {
        setPrevTrd(trdMstHistoryList[index + 1]);
      }

      setDetailShow(true);
    }
  };

  return (
    <div
      className={`dd-manage-wrapper ${detailShow ? "detail" : "list"} `}
      style={{
        gridTemplateColumns: detailShow ? "500px auto" : "1fr 0",
      }}
    >
      <Template
        title={"TRD 이력 목록"}
        breadcrumb={
          <>
            <div>TRD </div>/<div>이력 목록</div>/
            <div>{params.trdHistoryMstId}</div>
          </>
        }
      >
        <Grid
          rows={trdMstHistoryList}
          columns={detailShow ? detailColumn : columns}
          numbering={true}
          onRowClick={onDetailTrd}
          getId={(data) => data.trdHistoryId}
          selected={selectedTrd}
        />
      </Template>
      <Template
        hidden={!detailShow}
        title={selectedTrd?.trdNm + " 테이블 정보"}
        breadcrumb={<></>}
      >
        <TrdHistoryDetailInfo
          trdMstHistory={selectedTrd}
          prevTrdMst={prevTrd}
        />
      </Template>
    </div>
  );
};

export default TrdHistoryDetail;
