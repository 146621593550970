import {
  ArrayUtils,
  CodeService,
  DaafModal,
  ObjectUtils,
  StringUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import Message from "@alpha/com.bizentro.daaf.front.framework/dist/component/Message";
import { Enums } from "app/Enums";
import ElementContents from "page/element/ElementContents";
import { useEffect, useState } from "react";
import DomainService from "services/DomainService";
import ElementService from "services/ElementService";
import useAppEnv from "utils/hook/useAppEnv";

const ElementSettingPopup = ({ title, fieldData, callback, ...props }) => {
  const [element, setElement] = useState({
    elementId: null,
    elementCd: null,
    elementNm: null,
    domain: {},
    shortCd: null,
    description: null,
    entryType: null,
    entryRefTable: null,
    entryRefWhere: null,
    entryDisType: null,
    popupProgramId: null,
    popupSize: null,
    parameterId: null,
    possibleEntryList: [],
  });
  const [newDomain, setNewDomain] = useState({
    domainCd: null,
    domainNm: null,
    dataType: null,
    dataLength: null,
    entryDisType: "I",
    isRefresh: false,
  });
  const [comboData, setComboData] = useState([]);

  const { app, env } = useAppEnv();

  useEffect(() => {
    getComboList();
  }, []);

  /**
   *  Domain Definition의 Data Type 콤보 값을 가져오는 함수
   */
  const getComboList = () => {
    const data = {
      dataType: [],
      caseSensitive: [],
      formType: [],
      // roundingPolicy: [],
      negativeType: [],
      entryDisType: [],
      popupSizeCombo: [],
    };
    // Data Type
    CodeService.getCodeCombo({ codeMstCd: "D0001" }, (res) => {
      data.dataType = res.data;
    });
    // 대소문자
    CodeService.getCodeCombo({ codeMstCd: "D0002" }, (res) => {
      data.caseSensitive = res.data;
    });
    // 숫자유형
    CodeService.getCodeCombo({ codeMstCd: "Z0029" }, (res) => {
      data.formType = res.data;
    });
    // negativeTypeCombo 음수표현방법
    CodeService.getCodeCombo({ codeMstCd: "D0003" }, (res) => {
      data.negativeType = res.data;
    });
    // popupSizeData
    CodeService.getCodeCombo({ codeMstCd: "Z0021" }, (res) => {
      data.popupSizeCombo = res.data;
    });
    // // Rounding Policy 반올림 유형
    // CodeService.getCodeCombo({ codeMstCd: "Z0047" }, (res) => {
    //   data.roundingPolicy = res.data;
    // });
    setComboData(data);
  };

  const saveElement = (domain) => {
    ElementService.saveElement({
      ...element,
      domain: domain ? domain : element.domain || {},
      appEnvId: env.appEnvId,
    }).then((result) => {
      if (!result.isError) {
        callback(result.data);
      } else {
        Message.alert("Element가 저장되지 않았습니다.", "error");
      }
    });
  };

  /**
   * 팝업 닫을때 저장로직
   */
  const onClickConfirm = async () => {
    !StringUtils.isEmpty(element.elementCd) &&
      !StringUtils.isEmpty(element.elementNm) &&
      (await ElementService.getElement({
        //Element 중복 확인
        ...element,
        appEnvId: env.appEnvId,
      }).then((res) => {
        if (!ObjectUtils.isEmpty(res.data)) {
          Message.alert("중복된 Element입니다.", "error");
          return false;
        } else {
          if (callback) {
            if (
              !StringUtils.isEmpty(newDomain.domainCd) ||
              !StringUtils.isEmpty(newDomain.dataType) ||
              !StringUtils.isEmpty(newDomain.domainNm) ||
              !StringUtils.isEmpty(newDomain.dataLength)
            ) {
              Message.confirm(
                "신규 도메인이 저장되지 않았습니다. 도메인을 적용하여 Element를 적용하시겠습니까?",
                async (ok) => {
                  if (
                    StringUtils.isEmpty(newDomain.domainCd) ||
                    StringUtils.isEmpty(newDomain.domainNm) ||
                    StringUtils.isEmpty(newDomain.dataType) ||
                    ((StringUtils.equalsIgnoreCase(
                      Enums.getDataCase(newDomain.dataType),
                      Enums.CaseType.String
                    ) ||
                      StringUtils.equalsIgnoreCase(
                        Enums.getDataCase(newDomain.dataType),
                        Enums.CaseType.Decimal
                      )) &&
                      (ObjectUtils.isEmpty(newDomain.dataLength) ||
                        ObjectUtils.isEmpty(newDomain.decimals) ||
                        ObjectUtils.isEmpty(newDomain.formType)))
                  ) {
                    // 도메인cd,nm,datatype가 비어있거나 / datatype에 datalength가 필수지만 datlength가 비어있는경우
                    Message.alert("필수값을 입력해주세요.", "error");
                    return false;
                  } else {
                    DomainService.getDomain({
                      //Domain 중복 확인
                      ...newDomain,
                      appEnvId: env.appEnvId,
                    }).then((res) => {
                      if (!ObjectUtils.isEmpty(res.data)) {
                        Message.alert("중복된 Domain입니다.", "error");
                        return false;
                      } else {
                        // domain dataType이 문자형일때
                        DomainService.saveDomain({
                          //신규 도메인 저장
                          ...newDomain,
                          appEnvId: env.appEnvId,
                        }).then((res) => {
                          if (res.isError) {
                            Message.alert(
                              "도메인 저장에 실패하였습니다.",
                              "error"
                            );
                            return false;
                          } else {
                            saveElement(res.data);
                          }
                        });
                      }
                    });
                  }
                }
              );
            } else {
              saveElement();
            }
          }
        }
      }));
  };

  return (
    <DaafModal>
      <DaafModal.Header title={"Element 생성"}></DaafModal.Header>
      <DaafModal.Body style={{ background: "whitesmoke" }}>
        <ElementContents
          selectedElement={element}
          setPopupElemnt={setElement}
          fieldData={fieldData}
          isNewElement={true}
          comboData={comboData}
          popupSizeComboList={comboData.popupSizeCombo}
          isPopup={true}
          popupDomain={newDomain}
          setPopupDomain={setNewDomain}
        />
      </DaafModal.Body>
      <DaafModal.Footer>
        <DaafModal.Footer.Button onClick={onClickConfirm}>
          Element 신규 저장
        </DaafModal.Footer.Button>
      </DaafModal.Footer>
    </DaafModal>
  );
};

export default ElementSettingPopup;
