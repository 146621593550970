import {
  combineReducers,
  compose,
  legacy_createStore as createStore,
} from "@reduxjs/toolkit";
import AppReducer from "../store/reducers"; // 애플리케이션에서 사용할 리듀서를 가져옵니다.

// 스토어를 생성합니다.
// createStore 함수를 사용하여 스토어를 생성합니다.
// 첫 번째 인자로는 리듀서(AppReducer)를 전달합니다. 이 리듀서는 애플리케이션 상태를 관리합니다.
// 두 번째 인자는 Redux DevTools 확장 기능을 위한 부분입니다.
// 이 부분은 개발 환경에서만 사용되며, 프로덕션 환경에서는 제거되어야 합니다.
// const AppStore = createStore(
//   combineReducers({ ...AppReducer }),
//   window.__REDUX_DEVTOOLS_EXTENSION__()
// );
const AppStore = createStore(
  combineReducers({ ...AppReducer }),
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
    : compose
);

export default AppStore;
