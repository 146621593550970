import {
  AuthContext,
  DaafLogin,
  DaafPopup,
} from "@alpha/com.bizentro.daaf.front.framework";
import { useNavigate } from "react-router-dom";
import { AppPath } from "../../app/Enums";
import UserRegisterPopup from "components/popup/common/UserRegisterPopup";

class LoginWithoutNavi extends DaafLogin {
  loginSuccessCallback = (userInfoRes) => {
    this.props.navigate(AppPath.Env.Main.url);
  };

  static contextType = AuthContext;

  getUserRegisterButtonText = () => {
    return "등록 요청";
  };

  /**
   * @override
   * 등록요청 클릭
   */
  onClickUserRegister = () => {
    DaafPopup.open(<UserRegisterPopup joinRequest={true} />, {
      style: { content: { width: "30%" } },
    });
  };
}

const Login = () => {
  const navigate = useNavigate();

  return <LoginWithoutNavi navigate={navigate} isIdSaveAct={true} />;
};

export default Login;
