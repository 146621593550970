import {
  ArrayUtils,
  DaafEnums,
  DaafMessage,
  DaafModal,
  DaafModalTemplate,
  DaafPopup,
  StringUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import produce from "immer";
import { useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { MdDelete } from "react-icons/md";

/**
 * 신규 ERD 등록 팝업
 * @param {*} param0
 * @returns
 */
const NewTrdPopup = ({ ...props }) => {
  const [trdInfo, setTrdInfo] = useState({
    trdId: StringUtils.getUuid(),
    trdUid: "",
    trdNm: "",
    trdDesc: "",
    areaList: [],
    trdAreaType: "S",
    trdDevType: "D",
    useYn: "Y",
  });
  const [deletedArea, setDeletedArea] = useState([]);

  const getNewAreaDto = (index) => {
    return {
      areaId: `_${index}${StringUtils.getUuid()}`,
      areaNm: "",
      areaAffixType: "none",
      areaCriteria: "",
      sectorType: "N",
      areaViewport: {
        x: 0,
        y: 0,
        zoom: 0.75,
      },
    };
  };

  useEffect(() => {
    if (props.trd) {
      const info = produce(trdInfo, (draft) => {
        draft.trdId = props.trd.info.trdId;
        draft.trdUid = props.trd.info.trdUid;
        draft.trdNm = props.trd.info.trdNm;
        draft.trdDesc = props.trd.info.trdDesc;
        draft.trdAreaType = props.trd.info.trdAreaType;
        draft.dbType = props.trd.info.dbType;
        draft.areaList = props.trd.areaList;
      });
      setTrdInfo(info);
    }
  }, []);

  /**
   * 기본 항목 체인지 이벤트
   * @param {*} e
   */
  const onChangeValue = (e) => {
    setTrdInfo(
      produce(trdInfo, (draft) => {
        if (e.target.type === "checkbox") {
          draft[e.target.id] = e.target.checked;
        } else draft[e.target.id] = e.target.value;
      })
    );
  };

  /**
   * 확인 버튼 클릭 이벤트
   * @returns
   */
  const onClickConfirm = () => {
    const trdData = { ...trdInfo };

    const warnMsg = (text) =>
      DaafMessage.alert(text, DaafEnums.MessageType.WARN);
    if (StringUtils.isEmpty(trdData.trdUid)) {
      return warnMsg("TRD ID를 입력해주세요.");
    }
    if (StringUtils.isEmpty(trdData.trdNm)) {
      return warnMsg("TRD 명을 입력해주세요.");
    }

    if (StringUtils.equalsIgnoreCase(trdData.trdAreaType, "S")) {
      if (ArrayUtils.isEmpty(trdData.areaList)) {
        trdData.areaList = [
          {
            areaId: `_0${StringUtils.getUuid()}`,
            areaNm: trdData.trdNm,
            areaAffixType: "none",
            areaCriteria: "",
            sectorType: "C",
            areaViewport: { x: 0, y: 0, zoom: 0.75 },
          },
        ];
      }
    } else if (StringUtils.equalsIgnoreCase(trdData.trdAreaType, "M")) {
      if (ArrayUtils.isEmpty(trdData.areaList))
        return warnMsg("영역을 추가해주세요.");
      for (const ca of trdData.areaList) {
        if (StringUtils.isEmpty(ca.areaNm)) {
          return warnMsg("영역 명이 비어 있는 항목이 있습니다.");
        }
        if (StringUtils.isEmpty(ca.areaAffixType)) {
          return warnMsg("영역 분류방식이 비어있는 항목이 있습니다.");
        }

        //영역명 중복 방지
        const areaDupCheck = trdData.areaList.filter(
          (a) => a.areaNm === ca.areaNm
        );
        if (areaDupCheck.length > 1) {
          return warnMsg("중복된 영역 명이 있습니다.");
        }
      }

      /**
       * 전체 영역 추가
       */
      if (trdInfo.trdAreaType === "M") {
        if (!trdData.areaList.find((a) => a.sectorType === "A")) {
          trdData.areaList = [
            {
              areaId: `_0${StringUtils.getUuid()}`,
              areaNm: "전체 보기",
              areaAffixType: "none",
              areaCriteria: "",
              sectorType: "A",
              areaViewport: { x: 0, y: 0, zoom: 0.75 },
            },
            ...trdData.areaList,
          ];
        }
        if (!trdData.areaList.find((a) => a.sectorType === "C")) {
          trdData.areaList.push({
            areaId: `_${trdData.areaList.length + 1}${StringUtils.getUuid()}`,
            areaNm: "미 분류",
            areaAffixType: "none",
            areaCriteria: "",
            sectorType: "C",
            areaViewport: { x: 0, y: 0, zoom: 0.75 },
          });
        }
      }
    }

    if (props.callback) {
      props.callback(trdData);
      DaafPopup.close();
    }
  };

  /**
   * 카테고리 목록 체인지 이벤트
   * @param {*} e
   * @param {*} index
   */
  const onChangeCategoty = (e, index) => {
    setTrdInfo(
      produce(trdInfo, (draft) => {
        draft.areaList[index][e.target.id] = e.target.value;
        if (e.target.id === "areaAffixType" && e.target.value === "none") {
          draft.areaList[index].areaCriteria = "";
        }
      })
    );
  };

  /**
   * 영역 추가
   * @param {*} e
   */
  const addArea = (e) => {
    const index = trdInfo.areaList.length;
    setTrdInfo(
      produce(trdInfo, (draft) => {
        draft.areaList.push(getNewAreaDto(index));
      })
    );
  };

  /**
   * 영역 삭제
   */
  const onClickAreaDelete = (e, index) => {
    setTrdInfo(
      produce(trdInfo, (draft) => {
        draft.areaList[index].useYn = "N";
      })
    );
  };

  return (
    <DaafModal>
      <DaafModal.Header title="신규 TRD 생성" />
      <DaafModal.Body>
        <DaafModalTemplate>
          <Form.Group>
            <Form.Label className="required ">TRD ID</Form.Label>
            <Form.Control
              className="mb-3"
              id={"trdUid"}
              value={trdInfo.trdUid}
              onChange={onChangeValue}
            />
            <Form.Label className="required ">TRD 명</Form.Label>
            <Form.Control
              className="mb-3"
              id={"trdNm"}
              value={trdInfo.trdNm}
              onChange={onChangeValue}
            />
            {/* <Form.Label>테이블 영역 유형</Form.Label>
            <Form.Select
              className="mb-3"
              id="trdAreaType"
              value={trdInfo.trdAreaType}
              onChange={onChangeValue}
            >
              <option value="S">Single 영역</option>
              <option value="M">Multi 영역</option>
            </Form.Select> */}
            {trdInfo.trdAreaType === "M" && (
              <>
                <Form.Label>TABLE 영역 목록</Form.Label>
                <div className="erd-table-column-grid">
                  <div
                    className="mb-1"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button size="sm" onClick={addArea}>
                      영역 추가
                    </Button>
                  </div>
                </div>
                <Table>
                  <thead>
                    <tr>
                      <th>영역 명</th>
                      <th>분류 유형</th>
                      <th>분류 기준</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {trdInfo.areaList.map((area, index) => {
                      if (
                        StringUtils.includes(area.sectorType, ["A", "C"]) ||
                        area.useYn === "N"
                      )
                        return <></>;
                      else
                        return (
                          <tr key={index} className="mb-1">
                            <td className="cell" xs={4}>
                              <Form.Control
                                id="areaNm"
                                placeholder="영역 명 입력"
                                value={area.areaNm}
                                onChange={(e) => onChangeCategoty(e, index)}
                              />
                            </td>
                            <td className="cell" xs={3}>
                              <Form.Select
                                id="areaAffixType"
                                value={area.areaAffixType}
                                onChange={(e) => onChangeCategoty(e, index)}
                              >
                                <option value={"none"}>선택</option>
                                <option value={"none"}>없음</option>
                                <option value={"BE"}>prefix</option>
                                <option value={"ED"}>suffix</option>
                                <option value={"CT"}>포함</option>
                              </Form.Select>
                            </td>
                            <td className="cell" xs={3}>
                              <Form.Control
                                id="areaCriteria"
                                onChange={(e) => onChangeCategoty(e, index)}
                                value={area.areaCriteria}
                                placeholder="분류 기준 입력"
                                disabled={area.areaAffixType === "none"}
                              />
                            </td>
                            <td className="cell center" xs={2}>
                              <Button
                                size="sm"
                                variant="outline-danger"
                                onClick={(e) => onClickAreaDelete(e, index)}
                              >
                                <MdDelete />
                              </Button>
                            </td>
                          </tr>
                        );
                    })}
                  </tbody>
                </Table>
              </>
            )}
            <Form.Label>TRD 설명</Form.Label>
            <Form.Control
              className="mb-3"
              as="textarea"
              id={"trdDesc"}
              value={trdInfo.trdDesc}
              onChange={onChangeValue}
              rows={5}
            />
          </Form.Group>
        </DaafModalTemplate>
      </DaafModal.Body>
      <DaafModal.Footer>
        <DaafModal.Footer.Button onClick={onClickConfirm}>
          확인
        </DaafModal.Footer.Button>
      </DaafModal.Footer>
    </DaafModal>
  );
};

export default NewTrdPopup;
