import ACTION_TYPE from "../actionType";

/**
 * Domain List 호출 파라미터 Set
 *
 * @param {*} params
 * @returns
 */
export const setDomainParams = (params) => {
  return {
    type: ACTION_TYPE.Domain.params,
    payload: params,
  };
};

/**
 * Element List 호출 파라미터 Set
 * @param {*} params
 * @returns
 */
export const setElementParams = (params) => {
  return {
    type: ACTION_TYPE.Element.params,
    payload: params,
  };
};
