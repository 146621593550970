import EnvChoicePopup from "./EnvChoicePopup";

const EnvChoice = () => {
  return (
    <div className="env-choice-wrapper">
      <EnvChoicePopup />
    </div>
  );
};

export default EnvChoice;
