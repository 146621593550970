import { DaafModal, DaafPopup } from "@alpha/com.bizentro.daaf.front.framework";
import UModalTemplate from "@alpha/com.bizentro.daaf.front.framework/dist/component/modal/UModalTemplate";
import Grid from "components/grid/Grid";
import React from "react";
import { Table } from "react-bootstrap";

/**
 * 이력에서 필드 목록 확인 하는 팝업
 * @returns
 */
function TableFieldDetailPopup({ table, ...props }) {
  const columns = [
    {
      field: "element",
      headerName: "Element",
      renderCell: (data) => {
        return data.element.elementNm;
      },
    },
    {
      field: "pkYn",
      headerName: "필수 값 여부",
    },
    {
      field: "notNullYn",
      headerName: "Not Null 여부",
    },
  ];

  return (
    <DaafModal>
      <DaafModal.Header title={`${table.tablePhysicalNm} 테이블 컬럼 상세`} />
      <DaafModal.Body>
        <UModalTemplate>
          <Grid columns={columns} rows={table.trdTableField} />
        </UModalTemplate>
      </DaafModal.Body>
      <DaafModal.Footer>
        {/* <DaafModal.Footer.Button>확인</DaafModal.Footer.Button> */}
      </DaafModal.Footer>
    </DaafModal>
  );
}

export default TableFieldDetailPopup;
