import { memo } from "react";

/**
 * 사이드 메뉴를 작게 했을때, 메뉴 아이콘 및 컨텍스트 메뉴
 * @param {*} param0
 * @returns
 */
const ContentsMenuMini = memo(
  ({ Icon, text, subMenus = [], onClickNavMenu, selectedMenu, ...props }) => {
    const onClickMenu = () => {
      if (props.onClick) {
        props.onClick(props.id);
      } else {
        onClickNavMenu({
          id: props.id,
          Icon,
          text,
          subMenus,
        });
      }
    };

    return (
      <>
        <div
          className={`contents-header mini ${
            props.isSelected ? "selected" : ""
          }`}
          onClick={onClickMenu}
        >
          <div className="icon">
            <Icon />
          </div>
          <div className="contents-name">{text}</div>
        </div>
      </>
    );
  }
);

export default ContentsMenuMini;
