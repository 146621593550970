import {
  ArrayUtils,
  DaafPopup,
  StringUtils,
  UserUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import Message from "@alpha/com.bizentro.daaf.front.framework/dist/component/Message";
import { MessageType } from "@alpha/com.bizentro.daaf.front.framework/dist/enums/enum";
import { CaseType, Enums, MultiLanguage } from "app/Enums";
import Title from "components/common/Title";
import Form from "components/common/form/Form";
import FormWrapper from "components/common/form/FormWrapper";
import PossibleEntryComponent from "components/datadictionary/PossibleEntryComponent";
import DDMultiLangPopup from "components/popup/MultiLangPopup";
import produce from "immer";
import { useEffect, useRef, useState } from "react";
import { Button, Col, FormControl, InputGroup, Row } from "react-bootstrap";
import { FaHistory } from "react-icons/fa";
import { IoLanguageOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import DomainService from "services/DomainService";
import MultiLangService from "services/MultiLangService";
import { getAppConfig } from "utils/CommonUtils";
import useAppEnv from "utils/hook/useAppEnv";

/**
 * title : 신규 or 편집에 따라 title 명 변경
 * selectHistory : historyYn on/off
 * selectedDomain : Domain List 에서 선택된 Domain 객체 Data
 * isNewDomain : 신규인지 편집인지 판별하는 props
 * updateDomainList : Domain 신규 및 편집시 list data를 변경하기 위한 메소드
 */
const DomainContents = ({
  title,
  selectHistory,
  selectedDomain,
  isNewDomain,
  updateDomainList,
  setDomainContentsYn,
  comboData,
  ...props
}) => {
  const [domain, setDomain] = useState(selectedDomain); // Domain contents value

  const domainParmas = useSelector((state) => state.dictionary.domain.params);
  const [entryCaseFlag, setEntryCaseFlag] = useState(false);
  const { app, env } = useAppEnv();

  const domainCdRef = useRef();
  const domainNmRef = useRef();

  /**
   * 최초 Domain 값을 세팅 및 변경 감지
   */
  useEffect(() => {
    let tempEle = selectedDomain ? selectedDomain : isNewDomain;
    setDomain(tempEle);
    if (
      !StringUtils.equalsIgnoreCase(selectedDomain?.domainId, domain?.domainId)
    )
      setEntryCaseFlag(true);
  }, [selectedDomain, isNewDomain]);

  /**
   * Data Type에 따라 현재 요소를 disabled 시키기 위한 함수
   * @param {String} thisCase CaseType.String | CaseType.Number | CaseType.Decimal| "timeCase"
   * @returns Boolean
   */
  const isCase = (thisCase) => {
    const currentDataCase = Enums.getDataCase(domain.dataType);
    // 현재 data type이 파라미터 케이스와 같은 경우 true
    if (currentDataCase === thisCase) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * 데이터 타입에 따라 사용되지 않는 parameter 를 null로 변환하는 함수
   * @param {String} dataType Domain의 DataType
   */
  const disabledOtherParams = (dataType, saveDomainData) => {
    // Case 별로 Null로 만들어야할 data들
    const setNull = {
      [Enums.CaseType.String]: {
        formType: null, // integer, decimalcase에서 사용
        negativeType: null, // integer,decimalcase에서 사용
        outputConversion: null, // integer, decimalcase에서 사용
        decimals: null, // decimalcase에서 사용
        // roundingPolicy: null, // decimalcase에서 사용
      },
      [Enums.CaseType.Number]: {
        dataLength: null, // stringcase에서 사용
        caseSensitive: null, // stringcase에서 사용
        decimals: null, // decimalcase에서 사용
        outputConversion: null,
      },
      [Enums.CaseType.Decimal]: {
        caseSensitive: null, // stringcase에서 사용
        outputConversion: null,
      },
      [Enums.CaseType.Date]: {
        dataLength: null, // stringcase에서 사용
        caseSensitive: null, // stringcase에서 사용
        formType: null, // integer, decimalcase에서 사용
        negativeType: null, // integer,decimalcase에서 사용
        outputConversion: null, // integer, decimalcase에서 사용
        decimals: null, // decimalcase에서 사용
        // roundingPolicy: null, // decimalcase에서 사용
      },
      [Enums.CaseType.Object]: {
        dataLength: null, // stringcase에서 사용
        caseSensitive: null, // stringcase에서 사용
        formType: null, // integer, decimalcase에서 사용
        negativeType: null, // integer,decimalcase에서 사용
        outputConversion: null, // integer, decimalcase에서 사용
        decimals: null, // decimalcase에서 사용
      },
    };
    // 현재 Domain.DataType이 어떤 형식인지 찾는 함수
    const currentDataType = Enums.getDataCase(dataType);
    // 현재 형식에 사용되지 않는 Data null처리
    Object.keys(setNull).forEach((key) => {
      if (key === currentDataType) {
        setDomain({
          ...saveDomainData,
          dataType: dataType,
          ...setNull[key],
        });
        return;
      }
    });
  };

  /**
   * domain state의 값을 변경하는 함수
   */
  const onChangeDomain = (id, value, index) => {
    const newDomain = produce(domain, (draft) => {
      draft[id] = value;
    });
    setDomain(newDomain);
  };

  /**
   * domain 값을 변경하기위한 onChange 함수
   * @param {*} e
   * @param {*} data
   */
  const onChangeData = (e, data) => {
    const id = e.target.id;
    const value = e.target.value || null;
    const index = e.target.selectedIndexindex;
    onChangeDomain(id, value, index);
  };

  /**
   * 도메인 저장
   */
  const saveDomain = async () => {
    // Valid Check
    if (
      StringUtils.isEmpty(domain.domainCd) ||
      StringUtils.isEmpty(domain.domainNm) ||
      StringUtils.isEmpty(domain.dataType)
    ) {
      Message.alert("필수값을 입력해주세요", MessageType.ERROR);
      return;
    }

    // String Case인데 dataLength가 비어있는 경우
    if (isCase(CaseType.String) && StringUtils.isEmpty(domain.dataLength)) {
      Message.alert("길이를 입력해주세요", MessageType.ERROR);
      return;
    }
    // Integer or Decimal Case인데 negativeType이 비어있는 경우
    if (
      (isCase(CaseType.Number) || isCase(CaseType.Decimal)) &&
      StringUtils.isEmpty(domain.negativeType)
    ) {
      Message.alert("음수 표현 방식을 선택해주세요", MessageType.ERROR);
      return;
    }
    if (StringUtils.isEmpty(domain.entryDisType) && dropdownCondition) {
      Message.alert("값 표현방식을 선택해주세요", MessageType.ERROR);
      return;
    }
    if (!ArrayUtils.isEmpty(domain.possibleEntryList)) {
      const newPossibleEntry = domain.possibleEntryList.filter(
        (p, index) => !StringUtils.isEmpty(p.entryValue)
      );
      setDomain({ ...domain, possibleEntryList: newPossibleEntry });
    }
    const result = await DomainService.saveDomain({
      ...domain,
      appEnvId: env.appEnvId,
      tableName: MultiLanguage.TableName.DOMAIN,
    });

    if (result.isError) {
      Message.alert("저장에 실패했습니다", MessageType.ERROR);
    } else {
      Message.alert("저장되었습니다", MessageType.SUCCESS);
      saveMultiLang(result.data.domainId);
      updateDomainList && updateDomainList(domainParmas);
      // 도메인 신규 생성 저장 성공시 데이터 리셋
      if (isNewDomain === true) {
        props.domainContentsClear();
      }
    }
  };

  /**
   * Domain이 저장될때 현재 언어코드로 다국어를 저장시키는 함수
   * @param {Number} domainId
   */
  const saveMultiLang = (domainId) => {
    MultiLangService.getMultiLangList({
      tableKeyValue: domainId,
      tableNm: MultiLanguage.TableName.DOMAIN,
    })
      .then((result) => {
        const data = {
          appEnvId: env.appEnvId,
          tableNm: MultiLanguage.TableName.DOMAIN,
          tableKeyValue: domainId,
          langCd: UserUtils.getLanguage().toLowerCase(),
          columnNm: null,
          langText: null,
        };
        const multiLangData = [
          {
            ...data,
            columnNm: MultiLanguage.ColnumName.DOMAIN.fullNm,
            langText: domain.domainNm,
          },
          {
            ...data,
            columnNm: MultiLanguage.ColnumName.DOMAIN.description,
            langText: domain.description,
          },
        ];
        return multiLangData.map((data) => {
          const existingData = result.data.find(
            (m) => m.columnNm === data.columnNm && m.langCd === data.langCd
          );

          return (data = { ...data, ...existingData, langText: data.langText });
        });
      })
      .then((res) => {
        MultiLangService.saveMultiLangList({ multiLangList: res });
      });
  };

  /**
   * 도메인 삭제
   */
  const deleteDomain = async () => {
    const result = await DomainService.deleteDomain(domain);
    if (result.isError) {
      Message.alert("삭제에 실패했습니다", MessageType.ERROR);
    } else {
      Message.alert("삭제되었습니다", MessageType.SUCCESS);
      updateDomainList(domainParmas);
      props.domainContentsClear();
      setDomainContentsYn(false);
    }
  };

  /**
   * Possible Entry 오브젝트 추가 이벤트
   */
  const onAddPossibleEntry = () => {
    const prevPossibleEntry = [...domain.possibleEntryList];
    const nObj = {
      appEnvId: env.appEnvId, // 추후 변경
      domainId: domain.domainId ? domain.domainId : "",
      entryValue: "",
      entryText: "",
      langCd: UserUtils.getLanguage(),
      sortSeq: ArrayUtils.isEmpty(domain.possibleEntryList)
        ? 1
        : domain.possibleEntryList.length + 1,
    };

    setDomain({
      ...domain,
      possibleEntryList: [...prevPossibleEntry, nObj],
    });
  };

  // Possible Entry Dropdown Hidden/required 조건
  const dropdownCondition =
    !ArrayUtils.isEmpty(domain.possibleEntryList) ||
    !StringUtils.isEmpty(domain.entryRefTable) ||
    !StringUtils.isEmpty(domain.entryRefWhere);

  /**
   * 다국어 팝업
   */
  const multiLangPopup = () => {
    DaafPopup.open(
      <DDMultiLangPopup
        title={"Domain"}
        tableNm={MultiLanguage.TableName.DOMAIN}
        keyValue={domain.domainId}
        columnNm={MultiLanguage.ColnumName.DOMAIN}
        localData={{
          langCd: UserUtils.getLanguage(),
          fullNm: domain.domainNm,
          description: domain.description,
        }}
      />,
      {
        style: { content: { width: "100vh" } },
      }
    );
  };

  return (
    <div className={`contents-body dd-manage-contents show `}>
      <Title
        selectHistory={selectHistory}
        breadcrumb={
          <div className="form-button-wrapper">
            <Button size="sm" onClick={saveDomain}>
              저장
            </Button>
            {!isNewDomain && (
              <Button variant="danger" size={"sm"} onClick={deleteDomain}>
                삭제
              </Button>
            )}
          </div>
        }
      >
        Domain {isNewDomain ? "신규 등록" : ""}
        {!isNewDomain && (
          <button className="dd-title-history" onClick={selectHistory}>
            <FaHistory />
          </button>
        )}
      </Title>
      {/* Property */}
      <FormWrapper title="Property">
        <Form className="form-body">
          <Row>
            <Col className="mb-3" xs={6}>
              <Form.Label className={"required"}>{"Domain Code"}</Form.Label>
              <FormControl
                ref={domainCdRef}
                size="sm"
                id="domainCd"
                isInvalid={!domain.domainCd}
                placeholder="Domain 입력"
                value={domain.domainCd || ""}
                onChange={(e) =>
                  setDomain({ ...domain, domainCd: e.target.value })
                }
              />
              <FormControl.Feedback type="invalid">
                {"Domain은 필수값입니다"}
              </FormControl.Feedback>
            </Col>
            <Col className="mb-3" xs={6}>
              <Form.Label className={" required"}>{"Domain 명"}</Form.Label>
              <InputGroup>
                <FormControl
                  ref={domainNmRef}
                  size="sm"
                  id="domainNm"
                  isInvalid={!domain.domainNm}
                  placeholder="Domain 명 입력"
                  value={domain.domainNm || ""}
                  onChange={(e) =>
                    setDomain({ ...domain, domainNm: e.target.value })
                  }
                />
                {!isNewDomain && (
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    onClick={() => {
                      multiLangPopup();
                    }}
                  >
                    <IoLanguageOutline fontSize={"15px"} />
                  </Button>
                )}
              </InputGroup>
              <FormControl.Feedback
                type="invalid"
                style={{ display: !domain.domainNm ? "block" : "none" }}
              >
                {"Domain은 필수값입니다"}
              </FormControl.Feedback>
            </Col>
            <Col className="mb-3" xs={6}>
              <Form.Label>{"Description"}</Form.Label>
              <FormControl
                as={"textarea"}
                size="sm"
                id="description"
                value={domain.description || ""}
                onChange={(e) => onChangeData(e)}
              />
            </Col>
          </Row>
        </Form>
      </FormWrapper>
      {/* Definition */}
      <FormWrapper title="Domain Definition">
        <Form className="form-body">
          <Row>
            <Col xs={6} className="mb-3">
              <Form.Label className={"required"}>{"Data Type"}</Form.Label>
              <Form.Select
                size="sm"
                id="dataType"
                isInvalid={!domain.dataType}
                value={domain.dataType || ""}
                onChange={(e) => {
                  const type = e.target.value;
                  let newDomain = { ...domain, dataType: type };
                  if (
                    StringUtils.includes(Enums.getDataCase(type), [
                      Enums.CaseType.Number,
                      Enums.CaseType.Decimal,
                    ]) &&
                    StringUtils.isEmpty(domain.negativeType)
                  ) {
                    newDomain = {
                      ...domain,
                      negativeType: "M",
                    };
                  }
                  disabledOtherParams(type, newDomain);
                }}
              >
                <option label="선택" />;
                {comboData.dataType?.map((data, index) => {
                  return (
                    <option key={index} value={data.id} label={data.text} />
                  );
                })}
              </Form.Select>
              <FormControl.Feedback type="invalid">
                {"Data Type은 필수값입니다."}
              </FormControl.Feedback>
            </Col>
            <Col xs={6}>
              <Form.Label className={" required"}>{"값 표현방식"}</Form.Label>
              <Form.Select
                size="sm"
                id="entryDisType"
                isInvalid={!domain.entryDisType}
                value={domain.entryDisType || ""}
                onChange={(e) => {
                  onChangeData(e);
                }}
              >
                {comboData.entryDisType?.map((data, index) => {
                  return (
                    <option key={index} value={data.id} label={data.text} />
                  );
                })}
              </Form.Select>
            </Col>
            {(isCase(CaseType.String) || isCase(CaseType.Decimal)) && (
              <>
                <Col xs={6} className="mb-3">
                  <Form.Label>{"Data 길이"}</Form.Label>
                  <Form.DInput
                    size="sm"
                    style={{ textAlign: "start" }}
                    id="dataLength"
                    isInvalid={
                      (isCase(CaseType.String) || isCase(CaseType.Decimal)) &&
                      !domain.dataLength
                    }
                    type="number"
                    min={1}
                    placeholder="길이"
                    value={domain.dataLength || ""}
                    onChange={(e) => onChangeData(e)}
                    disabled={
                      !(isCase(CaseType.String) || isCase(CaseType.Decimal))
                    }
                  />
                  <FormControl.Feedback type="invalid">
                    {`${domain.dataType.toUpperCase()}" : Data 길이는 필수값입니다."`}
                  </FormControl.Feedback>
                </Col>
                {isCase(CaseType.String) && (
                  <>
                    <Col xs={6} className=" mb-3">
                      <Form.Label>{"대소문자 구분"}</Form.Label>
                      <InputGroup>
                        <Form.Select
                          size="sm"
                          id="caseSensitive"
                          value={domain.caseSensitive || ""}
                          onChange={(e) => onChangeData(e)}
                          disabled={!isCase(CaseType.String)}
                        >
                          {comboData.caseSensitive?.map((data, index) => {
                            return (
                              <option
                                key={index}
                                value={data.id}
                                label={data.text}
                              />
                            );
                          })}
                        </Form.Select>
                      </InputGroup>
                    </Col>
                    <Col xs={6} className=" mb-3">
                      <Form.Label>{"유니코드 사용 여부"}</Form.Label>
                      <InputGroup>
                        <Form.Select
                          size="sm"
                          id="unicodeYn"
                          value={domain.unicodeYn || "Y"}
                          onChange={(e) => onChangeData(e)}
                          disabled={!isCase(CaseType.String)}
                        >
                          <option value="Y">예</option>
                          <option value="N">아니오</option>
                        </Form.Select>
                      </InputGroup>
                    </Col>
                  </>
                )}
              </>
            )}
            {(isCase(CaseType.Number) || isCase(CaseType.Decimal)) && (
              <>
                <Col xs={6} className="mb-3">
                  <Form.Label>{"숫자 유형"}</Form.Label>
                  <InputGroup>
                    <Form.Select
                      size="sm"
                      id="formType"
                      value={domain.formType || ""}
                      onChange={(e) => onChangeData(e)}
                      disabled={
                        !(isCase(CaseType.Number) || isCase(CaseType.Decimal))
                      }
                    >
                      <option value={null} label="일반"></option>
                      {comboData.formType?.map((data, index) => {
                        return (
                          <option
                            key={index}
                            value={data.id}
                            label={data.text}
                          />
                        );
                      })}
                    </Form.Select>
                  </InputGroup>
                </Col>
                <Col xs={6} className="mb-3">
                  <Form.Label>{"소수 자리수"}</Form.Label>
                  <InputGroup>
                    <Form.Select
                      size="sm"
                      id="decimals"
                      value={domain.decimals || ""}
                      onChange={(e) => {
                        setDomain({
                          ...domain,
                          decimals: e.target.value,
                        });
                      }}
                      disabled={!isCase(CaseType.Decimal)}
                    >
                      <option value={""} label="선택"></option>
                      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((num, index) => {
                        return <option key={index} value={num} label={num} />;
                      })}
                    </Form.Select>
                  </InputGroup>
                </Col>
                {/* 의사결정 과정에서 반올림 유형을 사용하지 않는것으로 결정하여 주석처리
                  {<Col xs={6} className="mb-3">
                  <Form.Label>{"반올림 유형"}</Form.Label>
                  <Form.Select
                    size="sm"
                    id="roundingPolicy"
                    value={domain.roundingPolicy || ""}
                    onChange={(e) => onChangeData(e)}
                    disabled={
                      !(
                        isCase(CaseType.Decimal) &&
                        !StringUtils.isEmpty(domain.decimals)
                      )
                    }
                  >
                    {comboData.roundingPolicy?.map((data, index) => {
                      return (
                        <option key={index} value={data.id} label={data.text} />
                      );
                    })}
                  </Form.Select>
                </Col>} */}
                <Col xs={6} className="mb-3">
                  <Form.Label>{"음수 표현 방법"}</Form.Label>
                  <InputGroup>
                    <Form.Select
                      size="sm"
                      id="negativeType"
                      value={domain.negativeType || ""}
                      onChange={(e) => onChangeData(e)}
                      disabled={
                        !(isCase(CaseType.Number) || isCase(CaseType.Decimal))
                      }
                    >
                      {comboData.negativeType?.map((data, index) => {
                        return (
                          <option
                            key={index}
                            value={data.id}
                            label={data.text}
                          />
                        );
                      })}
                    </Form.Select>
                  </InputGroup>
                </Col>
              </>
            )}
            {/* {isCase(CaseType.String) && (
              <Col xs={6} className="mb-3">
                <Form.Label>{"Output Conversion"}</Form.Label>
                <Form.Select size="sm" disabled={!isCase(CaseType.String)}>
                  <option>repeat</option>
                  <option>-</option>
                  <option>wrap</option>
                </Form.Select>
              </Col>
            )} */}
          </Row>
        </Form>
      </FormWrapper>
      {/* 값 범위 */}
      <FormWrapper title="Data Mapping">
        <PossibleEntryComponent
          domain={domain}
          env={env}
          setDomain={setDomain}
          onChangeData={onChangeData}
          onChangeDomain={onChangeDomain}
          entryCaseFlag={entryCaseFlag}
          setEntryCaseFlag={setEntryCaseFlag}
        />
      </FormWrapper>
    </div>
  );
};

export default DomainContents;
