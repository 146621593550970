import { BaseService } from "@alpha/com.bizentro.daaf.front.framework";

class ElementHistoryService extends BaseService {
  static api = new BaseService("/elementHistory");

  /**
   * Element 이력 조회
   * @returns
   */
  static getElementHistoryList = (data) => {
    return this.api.sendPost("/getElementHistoryList", data);
  };
}

export default ElementHistoryService;
