import ACTION_TYPE from "../actionType";

const initialState = {
  app: {},
  env: {},
};

export default function envReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPE.AppEnv.set:
      return action.payload;

    default:
      return state;
  }
}
