import {
  ArrayUtils,
  ObjectUtils,
  StringUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import { Enums } from "app/Enums";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MarkerType } from "reactflow";
import TrdRenderUtils from "./TrdRenderUtils";
import TrdService from "services/TrdService";

const useTrdRender = (theme) => {
  const [Nodes, setNodes] = useState([]);
  const [Edge, setEdge] = useState([]);
  const trd = useSelector((state) => state.trd);
  const { areaList, memo, activate, tableList } = trd;

  useEffect(() => {
    renderNode();
  }, [areaList, tableList, memo, activate, theme]);

  /**
   * 엣지 렌더링
   */
  const renderTableEdge = (targetNodeTableId, soureNodeTableId, relation) => {
    let edge = {
      // id: `edge-from-${from.entity.physEntityNm}-to-${entity.physEntityNm}`,
      id: StringUtils.getUuid(),
      target: targetNodeTableId,
      source: soureNodeTableId,
      type: "floating",
      animated: relation.relationType === "I" ? true : false,
      data: {
        relation: relation,
      },
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 8,
        height: 8,
        color: theme === "light" ? "dodgerblue" : "#60b9c4",
      },
      style: {
        strokeWidth: 4,
        stroke: theme === "light" ? "dodgerblue" : "#60b9c4",
      },
    };
    return edge;
  };

  const setRelationList = (relationList, nodes, edges) => {
    if (ArrayUtils.isArray(relationList) && relationList.length > 0) {
      for (const relation of relationList) {
        if (relation?.useYn === "N") continue;
        const targetNodeId = TrdRenderUtils.getNodeId(relation.targetTable);
        const targetNode = nodes.find((node) =>
          StringUtils.equalsIgnoreType(node.id, targetNodeId)
        );

        if (targetNode) {
          //다른 영역에 관계를 가진 경우는 타겟 노드가 검색되지 않을수도 있기 때문에 있을때만 엣지를 추가하도록 한다.
          const edge = renderTableEdge(
            targetNodeId,
            TrdRenderUtils.getNodeId(relation.sourceTable),
            relation
          );
          edges.push(edge);
        }
      }
    }
  };

  /**
   * 노드 & 엣지 렌더링
   */
  const renderNode = () => {
    let nodes = []; //랜더링되는 노드
    let edges = []; //랜더링 되는 연결선

    if (!ObjectUtils.isEmpty(activate)) {
      //메모 설정
      const { areaMemo: memoList = [] } = TrdRenderUtils.getArea(
        trd,
        activate.areaId
      );
      if (memoList) {
        for (const memo of memoList) {
          const node = {
            //노드 정보
            id: memo.compId,
            style: memo.style,
            type: Enums.ErdType.MEMO, //ErdTypes에서 찾을것
            position: memo.position,
            data: {
              memo,
            },
          };
          nodes.push(node);
        }
      }

      let areaTableList = [];
      if (activate.sectorType === "A") {
        areaTableList = trd.tableList;
      } else {
        areaTableList = trd.tableList.filter((table) =>
          StringUtils.equalsIgnoreType(table.trdArea.areaId, activate.areaId)
        );
      }

      if (ArrayUtils.isEmpty(areaTableList)) {
        return setNodes(nodes);
      }
      //영역당 최소, 최대 포지션(크기)
      //영역 크기 환산을 위한 최소단위 테이블
      const standardTable = areaTableList.find(
        (table) => table.trdArea.sectorType !== "A"
      );

      let areaMinPosition = { x: 0, y: 0 };
      let areaMaxPosition = { x: 0, y: 0 };
      if (standardTable) {
        areaMinPosition.x = standardTable.position.x;
        areaMinPosition.y = standardTable.position.y;
        areaMaxPosition.x = standardTable.position.x;
        areaMaxPosition.y = standardTable.position.y;
      }

      areaTableList.forEach((table) => {
        if (table.position.x < areaMinPosition.x) {
          areaMinPosition.x = table.position.x;
        }

        if (table.position.y < areaMinPosition.y) {
          areaMinPosition.y = table.position.y;
        }

        if (table.position.x + 500 > areaMaxPosition.x) {
          areaMaxPosition.x = table.position.x + 500;
        }
        const tableHeight = table.trdTableField.length * 26.5 + 60;
        if (table.position.y + tableHeight > areaMaxPosition.y) {
          areaMaxPosition.y = table.position.y + tableHeight;
        }
      });

      let relationList = [];
      const nomalAreaList = areaList.filter(
        (a) => StringUtils.includes(a.sectorType, ["N", "C"]) && a.useYn !== "N"
      );
      /**
       * 테이블 노드 그리는 과정
       * 1. 테이블 루프
       * 2. 각 테이블 노드 설정
       *  2-1. 포지션 관계 없음
       *  2-2. 각 포지션은 영역 내에서도 상대위치로 움직이기 때문
       * 3. 각 테이블에 영역이 부여되어 있고, 이 영역이 존재할때
       * 4. 테이블은 각 영역에 귀속 시킨다.
       * 5. 영역이 없는 경우는 자동으로 전체 영역에서 굴러다니도록 한다.
       */
      for (let table of areaTableList) {
        if (table.useYn === "N") continue;

        const node = {
          id: TrdRenderUtils.getNodeId(table),
          type: Enums.ErdType.TABLE,
          position: table.position,
          data: {
            ...table,
          },
          style: {
            zIndex: 1,
          },
        };
        if (
          activate.sectorType === "A" &&
          nomalAreaList.find((a) => a.areaId === table.trdArea?.areaId)
        ) {
          /** 전체 보기인 경우  */
          node.position = {
            x: node.position.x - areaMinPosition.x + 50,
            y: node.position.y - areaMinPosition.y + 50,
          };
          node.isSelectable = true;

          node.parentNode = TrdRenderUtils.getAreaNodeId(table.trdArea);
          node.extent = "parent";
        }

        nodes.push(node);

        const { relation: relations } = table;
        relationList = relationList.concat(relations);
      }

      /**
       * 전체 영역인 경우에는 각 영역을 렌더링 하고,
       * 영역내 테이블에 위치를 보정한다.
       */
      if (activate.sectorType === "A") {
        //area 최대 사이즈 보정치
        const maxSizeAccurate = 200;
        const maxAreaSize = {
          x: 0,
          y: 0,
        };
        /**
         * 에어리어 포지션
         */
        let parentPosition = {
          x: 0,
          y: 0,
        };
        // 멀티 에어리어 일때 3개씩 1개 행으로 한다.
        // 이후 2행으로 넘어갈때의 index 값
        let yIndex = 0;
        /**
         *3.  Area Template 정의
         * 사이즈는 가로세로 중 큰걸 기준으로 해서 정사각형 모양이 나오도록 한다.
         * 3개씩 가로 정렬하고 3개가 넘어가면 밑으로 내려서 그리드 형태다 나오도록 한다.
         */
        for (const [index, area] of nomalAreaList.entries()) {
          //- 최대 영역 사이즈는 X 최대값 + 150, Y 최대값 + 150 으로 한다.

          //영역 사이즈
          let areaSize = {
            x: Math.abs(areaMaxPosition.x - areaMinPosition.x),
            y: Math.abs(areaMaxPosition.y - areaMinPosition.y),
          };
          if (maxAreaSize.x < areaSize.x) {
            maxAreaSize.x = areaSize.x;
          }
          if (maxAreaSize.y < areaSize.y) {
            maxAreaSize.y = areaSize.y;
          }
          parentPosition.x =
            (index % 3) * (maxAreaSize.x + maxSizeAccurate) + (index % 3) * 100;
          parentPosition.y = maxAreaSize.y + maxSizeAccurate;
          let areaWidth = 0;
          if (maxAreaSize.x > maxAreaSize.y) {
            areaWidth = maxAreaSize.x;
          } else if (maxAreaSize.x < maxAreaSize.y) {
            areaWidth = maxAreaSize.y;
          } else {
            areaWidth = maxAreaSize.x;
          }
          const { table, memo, ...otherData } = area;
          let yPosition = 0;
          if (index % 3 === 0) {
            yPosition = yIndex * (areaWidth + (yIndex !== 0 ? 100 : 0));
            yIndex++;
          }

          const areaNode = {
            id: TrdRenderUtils.getAreaNodeId(area),
            className: "erd-area-template",
            type: Enums.ErdType.AREA_TEMPLATE,
            position: {
              x: parentPosition.x,
              y: yPosition,
              // y: 0,
            },
            style: {
              width: areaWidth + maxSizeAccurate,
              height: areaWidth + maxSizeAccurate,
              zIndex: 0,
            },
            data: {
              ...otherData,
            },
          };
          nodes.push(areaNode);
        }
      }
      setRelationList(relationList, nodes, edges);
      setNodes(nodes);
      setEdge(edges);
    } else {
      setNodes([]);
      setEdge([]);
    }
  };

  return [Nodes, Edge];
};

export default useTrdRender;
