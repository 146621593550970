import { BaseService } from "@alpha/com.bizentro.daaf.front.framework";

class DomainHistoryService extends BaseService {
  static api = new BaseService("/domainHistory");

  /**
   * 도메인 이력 조회
   * @param {*} data
   * @param {*} successCallback
   * @param {*} errorCallback
   * @returns
   */
  static getDomainHistoryList = (data) => {
    return this.api.sendPost("/getDomainHistoryList", data);
  };
}

export default DomainHistoryService;
