import {
  DaafPopup,
  StringUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import React from "react";
import TrdQueryViewerPopup from "./TrdQueryViewerPopup";
import Form from "components/common/form/Form";
import produce from "immer";
import { Button } from "react-bootstrap";
import TrdQueryFailInfoPopup from "./TrdQueryFailInfoPopup";
import Grid from "components/grid/Grid";
/**
 * Query 상태 Grid 컴포넌트
 *
 * @param {Object} props - 컴포넌트의 속성
 * @param {Array} props.queryList - 쿼리 목록 데이터
 * @param {Function} props.setQueryList - 쿼리 목록을 업데이트하는 함수
 * @param {boolean} props.editable - 쿼리 목록이 수정 가능한지 여부
 * @param {boolean} props.allStateViewer - 쿼리수행의 모든 상태를 그리드에 그릴지 여부 - 이력에서 사용 default false
 * @returns {ReactElement} QueryGrid 컴포넌트
 */
const TrdQueryStateGrid = ({
  queryList,
  setQueryList,
  editable,
  allStateViewer = false,
  ...props
}) => {
  /**
   * 쿼리 상세 팝업
   * @param {*} query
   */
  const onViewQuery = (query, index) => {
    const callback = (confirmedQuery) => {
      const newQuery = [...queryList];
      newQuery[index].query = confirmedQuery;
      if (setQueryList) setQueryList(newQuery);
      DaafPopup.close();
    };
    DaafPopup.open(
      <TrdQueryViewerPopup
        query={query}
        callback={callback}
        editable={editable}
      />,
      {
        style: { content: { width: "650px" } },
      }
    );
  };

  const columns = editable
    ? [
        {
          field: "queryExecuteYn",
          headerName: (
            <div style={{ display: "flex", alignItems: "center" }}>
              {editable && (
                <Form.CheckBox
                  checked={queryList.every((q) => q.queryExecuteYn === "Y")}
                  onChange={(e) => {
                    if (setQueryList)
                      setQueryList(
                        produce(queryList, (draft) => {
                          for (const q of draft) {
                            q.queryExecuteYn = e.target.checked ? "Y" : "N";
                          }
                        })
                      );
                  }}
                />
              )}
              &nbsp;실행 여부
            </div>
          ),
          renderCell: (data, index) => {
            return (
              <Form.CheckBox
                checked={data.queryExecuteYn === "Y" ? true : false}
                onChange={(e) => {
                  setQueryList(
                    produce(queryList, (draft) => {
                      draft[index].queryExecuteYn = e.target.checked
                        ? "Y"
                        : "N";
                    })
                  );
                }}
              />
            );
          },
        },
      ]
    : [];

  columns.push({
    field: "queryComment",
    headerName: "변경사항",
  });
  columns.push({
    field: "queryDetail",
    headerName: "쿼리 상세",
    renderCell: (data, index) => {
      return (
        <Button
          variant="outline-primary"
          onClick={(e) => onViewQuery(data.queryContents, index)}
          size="sm"
        >
          상세보기
        </Button>
      );
    },
  });
  columns.push({
    field: "querySuccessYn",
    headerName: "실행결과",
    renderCell: (data, index) => {
      if (StringUtils.isEmpty(data.querySuccessYn)) {
        return "실행 대기중";
      } else {
        if (data.querySuccessYn === "Y") {
          return (
            <Button variant="outline-primary" size="sm">
              완료
            </Button>
          );
        } else if (data.querySuccessYn === "R") {
          return (
            <Button variant="outline-success" size="sm">
              롤백
            </Button>
          );
        } else {
          return (
            <Button
              variant="outline-danger"
              size="sm"
              onClick={(e) =>
                DaafPopup.open(
                  <TrdQueryFailInfoPopup info={data.queryErrorMessage} />,
                  { style: { content: { width: "750px" } } }
                )
              }
            >
              실패
            </Button>
          );
        }
      }
    },
  });

  if (allStateViewer) {
    columns.push({
      field: "errorCheckedYn",
      headerName: "확인 여부",
      renderCell: (data, index) => {
        if (StringUtils.equalsIgnoreCase(data.querySuccessYn, "Y"))
          return (
            <Button size="sm" variant="primary">
              완료
            </Button>
          );
        else if (StringUtils.equalsIgnoreCase(data.errorCheckedYn, "Y")) {
          return (
            <Button size="sm" variant="success">
              확인
            </Button>
          );
        } else {
          return (
            <Button size="sm" variant="outline-danger">
              미확인
            </Button>
          );
        }
      },
    });
    columns.push({
      field: "errorCheckedUserId",
      headerName: "최종 확인자",
    });
  }

  return (
    <Grid
      className="mb-3"
      columns={columns}
      rows={queryList}
      rowDraggable
      onDragDropChange={(data) => {
        setQueryList(data);
      }}
    />
  );
};

export default TrdQueryStateGrid;
