import {
  ArrayUtils,
  CodeService,
} from "@alpha/com.bizentro.daaf.front.framework";
import Message from "@alpha/com.bizentro.daaf.front.framework/dist/component/Message";
import LoadingPage from "page/LoadingPage";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DomainService from "services/DomainService";
import useAppEnv from "utils/hook/useAppEnv";
import DomainContents from "./DomainContents";
import DomainList from "./DomainList";
import DomainHistoryContents from "./history/DomainHistoryContents";

const Domain = () => {
  const [domainHistoryYn, setDomainHistoryYn] = useState(false); //도메인 이력 on/off
  const [selectedDomain, setSelectedDomain] = useState({
    domainId: null,
    domainCd: null,
    domainNm: null,
    dataType: "char",
    dataLength: null,
    formType: null,
    decimals: null,
    // roundingPolicy: null,
    negativeType: null,
    outputConversion: null,
    caseSensitive: null,
    entryType: null,
    entryRefTable: null,
    entryRefWhere: null,
    entryRefKey: null,
    entryRefValue: null,
    entryDisType: "I", // 기본값 I
    possibleEntryList: [],
  });
  const [isNewDomain, setIsNewDomain] = useState(false);
  const [domainList, setDomainList] = useState([]);
  const [domainContentsTitle, setDomainContentsTitle] = useState("등록");

  const [comboDataList, setComboDataList] = useState({});

  const [isLoading, setIsLoading] = useState(true);

  const domainParams = useSelector((state) => state.dictionary.domain.params);
  const { app, env } = useAppEnv();

  useEffect(() => {
    getComboList().then(() => {
      getDomainList(domainParams);
    });
  }, []);

  const onHistory = () => {
    setDomainHistoryYn(!domainHistoryYn);
  };

  /**
   * 도메인 페이지 진입시 Domain List 가져오기
   */
  const getDomainList = async (params) => {
    setIsLoading(true);
    params = { ...params, appEnvId: env.appEnvId };
    const result = await DomainService.getDomainList(params);
    if (result.isError) {
      Message.alert("조회에 실패했습니다.", "warning");
    } else {
      if (ArrayUtils.isEmpty(result.data)) {
        //조회된 데이터가 없어서 selected 도메인이 없는경우 신규도메인 저장이 되도록
        onSelectNewDomain();
      }
      setDomainList(result.data);
      setIsLoading(false);
    }
  };

  /**
   *  Domain Definition의 Data Type 콤보 값을 가져오는 함수
   */
  const getComboList = async () => {
    const data = {
      dataType: [],
      caseSensitive: [],
      formType: [],
      // roundingPolicy: [],
      negativeType: [],
      entryDisType: [],
    };
    // Data Type
    CodeService.getCodeCombo({ codeMstCd: "D0001" }, (res) => {
      data.dataType = res.data;
    });
    // 대소문자
    CodeService.getCodeCombo({ codeMstCd: "D0002" }, (res) => {
      data.caseSensitive = res.data;
    });
    // 숫자유형
    CodeService.getCodeCombo({ codeMstCd: "Z0029" }, (res) => {
      data.formType = res.data;
    });
    // negativeTypeCombo 음수표현방법
    CodeService.getCodeCombo({ codeMstCd: "D0003" }, (res) => {
      data.negativeType = res.data;
    });
    // 값범위 표현방법
    CodeService.getCodeCombo({ codeMstCd: "D0007" }, (res) => {
      data.entryDisType = res.data;
    });

    // // Rounding Policy 반올림 유형 : 의사결정 과정에서 사용하지 않는것으로 결정되어 우선 주석처리
    // CodeService.getCodeCombo({ codeMstCd: "Z0047" }, (res) => {
    //   data.roundingPolicy = res.data;
    // });

    setComboDataList(data);
  };

  const onSelectNewDomain = () => {
    // 도메인컨텐츠가 활성화중 신규등록 클릭시 도메인 컨텐츠 리셋
    resetDomainData();
    setIsNewDomain(true);
    setDomainContentsTitle("신규 등록");
  };

  /**
   * 도메인 신규 생성시 데이터 리셋
   */
  const resetDomainData = () => {
    setSelectedDomain({
      domainId: null,
      appEnvId: env.appEnvId,
      domainCd: null,
      domainNm: null,
      dataType: "char",
      dataLength: null,
      formType: null,
      decimals: null,
      // roundingPolicy: null,
      negativeType: null,
      outputConversion: null,
      caseSensitive: null,
      entryType: null,
      entryRefTable: null,
      entryRefWhere: null,
      entryRefKey: null,
      entryRefValue: null,
      entryDisType: "I", // 기본값 I
      possibleEntryList: [],
    });
  };

  const domainContentsControl = (tf) => {
    setDomainHistoryYn(tf);
  };

  const getSelectedDomain = (rows) => {
    DomainService.getDomain({
      appEnvId: env.appEnvId,
      domainCd: rows?.domainCd,
    }).then((res) => {
      setSelectedDomain(res.data);
    });
  };

  return (
    <div className={`dd-manage-wrapper detail`}>
      {isLoading ? <LoadingPage /> : ""}
      <DomainList
        onSelectNewDomain={onSelectNewDomain}
        domainList={domainList}
        selectedDomain={selectedDomain}
        setSelectedDomain={getSelectedDomain}
        setIsNewDomain={setIsNewDomain}
        getDomainList={getDomainList}
        dataTypeList={comboDataList.dataType}
      />
      <DomainHistoryContents
        domainHistoryYn={domainHistoryYn}
        selectedDomain={selectedDomain}
        comboData={comboDataList}
      />
      <DomainContents
        title={domainContentsTitle}
        selectHistory={onHistory}
        selectedDomain={selectedDomain}
        isNewDomain={isNewDomain}
        updateDomainList={getDomainList}
        domainContentsClear={resetDomainData}
        setDomainContentsYn={domainContentsControl}
        comboData={comboDataList}
      />
    </div>
  );
};

export default Domain;
