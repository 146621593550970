import {
  ArrayUtils,
  DaafMessage,
  DaafModal,
  DaafModalTemplate,
  ObjectUtils,
  StringUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import Form from "components/common/form/Form";
import Grid from "components/grid/Grid";
import { useRef, useState } from "react";
import { Button, Col, InputGroup, Row } from "react-bootstrap";
import { MdSearch } from "react-icons/md";
import TrdService from "services/TrdService";
import { stopEvent } from "utils/CommonUtils";

const TrdReverseEngineeringPopup = ({
  tableList: serverTableList,
  callback,
  ...props
}) => {
  const [tableList, setTableList] = useState(serverTableList);
  const [searchTerm, setSearchTerm] = useState([]);
  const tableListRef = useRef(serverTableList);

  const [selectedTable, setSelectedTable] = useState({});

  const columns = [
    {
      field: "tableName",
      headerName: "테이블 명",
      style: {
        maxWidth: "150px",
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
      renderCell: (data, index) => {
        if (selectedTable[data.tableName]) {
          return <span style={{ fontWeight: "bold" }}>{data.tableName}</span>;
        } else {
          return data.tableName;
        }
      },
    },
    {
      field: "plus",
      headerName: "추가",
      renderCell: (data, index) => {
        return (
          <span
            style={{ fontWeight: "bold", fontSize: "18px", color: "limegreen" }}
          >
            +
          </span>
        );
      },
    },
  ];

  const selectedGridColumns = [
    {
      field: "tableName",
      headerName: "선택된 테이블 명",
      style: {
        maxWidth: "150px",
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      field: "minus",
      headerName: "삭제",
      renderCell: (data, index) => {
        return (
          <span
            style={{ fontWeight: "bold", fontSize: "18px", color: "tomato" }}
          >
            -
          </span>
        );
      },
    },
  ];

  const selectTable = async (data) => {
    const tableDupCheck = await TrdService.getTrdTable({
      tablePhysicalNm: data.tableName,
      disableLoad: true,
    });

    if (!ObjectUtils.isEmpty(tableDupCheck.data)) {
      return DaafMessage.alert(
        `${tableDupCheck.data.trdMst.trdNm} TRD에 저장된 테이블입니다.`,
        "info"
      );
    }
    const newSt = { ...selectedTable };
    if (newSt[data.tableName]) {
      delete newSt[data.tableName];
    } else {
      newSt[data.tableName] = true;
    }

    setSelectedTable(newSt);
  };

  /**
   * 검색어 입력 이벤트
   * @param {*} e
   */
  const onChangeSearchTerm = (e) => {
    setSearchTerm(e.target.value);
    if (StringUtils.isEmpty(e.target.value)) {
      setTableList(tableListRef.current);
    } else {
      const filtered = tableListRef.current.filter(
        (t) =>
          String(t.tableName)
            .toLocaleLowerCase()
            .indexOf(String(e.target.value).toLowerCase()) > -1
      );
      setTableList(filtered);
    }
  };

  /**
   * 전체 추가
   */
  const onClickAddAll = () => {
    const newSt = {};
    tableListRef.current.forEach((t) => {
      newSt[t.tableName] = true;
    });
    setSelectedTable(newSt);
  };

  const onClickCallback = () => {
    if (callback) {
      const checkedList = tableListRef.current.filter(
        (t) => selectedTable[t.tableName]
      );
      if (ArrayUtils.isEmpty(checkedList)) {
        DaafMessage.alert("테이블을 선택해주세요.", "success");
      } else {
        callback(checkedList);
      }
    }
  };

  return (
    <DaafModal>
      <DaafModal.Header title="테이블 호출" />
      <DaafModal.Body>
        <DaafModalTemplate>
          <Row className="mb-3">
            <Col xs={2}>
              <Form.Label>검색어 필터</Form.Label>
            </Col>
            <Col xs={10}>
              <InputGroup style={{ alignItems: "center" }}>
                <Form.DInput
                  placeholder="테이블 명 입력"
                  value={searchTerm}
                  onChange={onChangeSearchTerm}
                />
                <Button>
                  <MdSearch />
                </Button>
              </InputGroup>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={4}>
              <Form.Label>호출된 테이블 목록</Form.Label>
            </Col>
            <Col xs={2}>
              <Button size={"sm"} onClick={onClickAddAll}>
                전체 추가
              </Button>
            </Col>
            <Col xs={6}>
              <Form.Label>추가될 테이블 목록</Form.Label>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={6}>
              <Grid
                columns={columns}
                rows={tableList}
                gridBoxStyle={{ maxHeight: "600px" }}
                stickyHeader
                onRowClick={(e, data, index) => {
                  stopEvent(e);
                  selectTable(data, index);
                }}
              />
            </Col>
            <Col xs={6}>
              <Grid
                columns={selectedGridColumns}
                rows={tableListRef.current.filter(
                  (t) => selectedTable[t.tableName]
                )}
                gridBoxStyle={{ maxHeight: "600px" }}
                stickyHeader
                onRowClick={(e, data, index) => {
                  stopEvent(e);
                  selectTable(data, index);
                }}
              />
            </Col>
          </Row>
        </DaafModalTemplate>
      </DaafModal.Body>
      <DaafModal.Footer>
        <DaafModal.Footer.Button onClick={onClickCallback}>
          확인
        </DaafModal.Footer.Button>
      </DaafModal.Footer>
    </DaafModal>
  );
};

export default TrdReverseEngineeringPopup;
