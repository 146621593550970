import {
  AppService,
  ArrayUtils,
  DaafEnums,
  DaafMessage,
  LocalStorageService,
  ObjectUtils,
  SessionUtils,
  StringUtils,
  UserUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import { AppPath, Enums, LocalStorageName } from "app/Enums";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAppEnv } from "../../store/actions/AppEnvAction";
import { stopEvent } from "utils/CommonUtils";
function EnvChoicePopup() {
  const ConnectedEnvHistory = LocalStorageService.get(
    LocalStorageName.AppEnvChoiceHistory
  );

  const [appList, setAppList] = useState([]);
  const [envList, setEnvList] = useState([]);

  const [selectedApp, setSelectedApp] = useState({});
  const [selectedEnv, setSelectedEnv] = useState({});

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getAppList();
  }, []);

  /**
   * 취소 버튼 이벤트
   * 선택시 로그인 화면으로 이동
   * @param {*} e
   */
  const onClickCancel = (e) => {
    stopEvent(e);
    DaafMessage.confirm(
      "선택하지 않으면 컨텐츠를 이용할 수 없습니다. 로그인 화면으로 이동합니다.",
      () => {
        SessionUtils.deleteItem();
        navigate(AppPath.Login);
      }
    );
  };

  /**
   * App 목록 호출
   */
  const getAppList = () => {
    AppService.getAppList().then((res) => {
      setAppList(res.data);
      if (!ArrayUtils.isEmpty(res.data)) setSelectedApp(res.data[0]);
    });
  };

  /**
   * APP 환경 목록 호출
   * @param {*} appId
   */
  const getAppEnvList = (appId) => {
    AppService.getAppEnvList({ appId }, (res) => {
      setEnvList(res.data);
      if (!ArrayUtils.isEmpty(res.data)) {
        setSelectedEnv(res.data[0]);
      }
    });
  };

  /**
   * APP 선택
   * @param {*} e
   */
  const onChangeAppSelect = (e) => {
    setSelectedApp(
      appList.find((app) =>
        StringUtils.equalsIgnoreType(app.appId, e.target.value)
      )
    );
    getAppEnvList(e.target.value);
  };

  /**
   * 작업환경 선택
   */
  const onClickConfirm = () => {
    if (ObjectUtils.isEmpty(selectedApp))
      return DaafMessage.alert(
        "Application이 선택되지 않았습니다.",
        DaafEnums.MessageType.ERROR
      );
    if (ObjectUtils.isEmpty(selectedEnv))
      return DaafMessage.alert(
        "작업환경이 선택되지 않았습니다.",
        DaafEnums.MessageType.ERROR
      );

    let hisUserId = "";
    let hisList = [];
    if (ConnectedEnvHistory) {
      hisUserId = ConnectedEnvHistory.userId;
      hisList = ConnectedEnvHistory.list;
    }

    // 기존에 접속한 사람과 다른 사람이면 목록 초기화
    if (!StringUtils.equalsIgnoreCase(hisUserId, UserUtils.getId())) {
      hisList = [];
    }

    const body = {
      app: selectedApp,
      env: selectedEnv,
    };
    //기존에 접속한 흔적있으면 해당 목록 삭제후에 추가
    const isExist = hisList.findIndex(
      (his) =>
        his.app.appId === selectedApp.appId &&
        his.env.appEnvId === selectedEnv.appEnvId
    );
    if (isExist > -1) {
      hisList.splice(isExist, 1);
    } else if (hisList.length > 4) {
      hisList.splice(0, 1);
    }

    hisList.push(body);

    LocalStorageService.set(LocalStorageName.AppEnvChoiceHistory, {
      userId: UserUtils.getId(),
      list: hisList,
    });
    SessionUtils.set(body, LocalStorageName.ENV_STORAGE);
    setEnv(body);
  };

  const setEnv = (body) => {
    //redux State에 적용
    dispatch(setAppEnv(body));
    //navigate
    navigate(AppPath.Domain.Edit.url);
  };

  /**
   * 이전 기록 접속할 경우 최신 데이터를 불러오기 위함
   * @param {*} body
   * @returns
   */
  const quickSetEnv = async (body) => {
    const app = body.app;
    const env = body.env;
    const envList = await AppService.getAppEnvList(app);
    const newApp = await AppService.getApp(app);
    const newEnv = envList.data.find((e) => e.appEnvId === env.appEnvId);

    if (!newApp || !newEnv) {
      return DaafMessage.alert(
        "App 또는 환경정보를 찾을 수 없습니다.",
        "error"
      );
    }
    const newBody = {
      app: newApp.data,
      env: newEnv,
    };

    SessionUtils.set(newBody, Enums.LocalStorageName.ENV_STORAGE);
    setEnv(newBody);
  };

  return (
    <div className="env-choice-popup">
      <div className="env-choice-header">작업 환경 선택</div>
      <div className="env-choice-body">
        <Row>
          <Col xs={6}>
            <Form.Label className="required">Application 선택</Form.Label>
          </Col>
          <Col xs={6}>
            <Form.Label className="required">작업 환경</Form.Label>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Form.Select
              size="sm"
              value={selectedApp?.appId}
              onChange={onChangeAppSelect}
            >
              {appList.map((app) => {
                return (
                  <option key={app.appId} value={app.appId}>
                    {app.appNm}
                  </option>
                );
              })}
            </Form.Select>
          </Col>
          <Col xs={6}>
            <Form.Select
              size="sm"
              disabled={ObjectUtils.isEmpty(selectedApp)}
              value={selectedEnv?.appEnvId}
              onChange={(e) => {
                setSelectedEnv(
                  envList.find((env) =>
                    StringUtils.equalsIgnoreType(env.appEnvId, e.target.value)
                  )
                );
              }}
            >
              {ObjectUtils.isEmpty(selectedApp) ? (
                <option>APP을 선택해주세요</option>
              ) : ArrayUtils.isEmpty(envList) ? (
                <option>접근가능한 작업환경이 없습니다.</option>
              ) : (
                <>
                  <option value={""}>선택</option>
                  {envList.map((env) => {
                    return (
                      <option key={env.appEnvId} value={env.appEnvId}>
                        {env.envName}
                      </option>
                    );
                  })}
                </>
              )}
            </Form.Select>
          </Col>
        </Row>

        <Row className="recent-env-wrapper">
          <Col xs={8}>
            <Form.Label>최근 작업한 환경</Form.Label>
            <div className="recent-list-box">
              {StringUtils.equalsIgnoreCase(
                ConnectedEnvHistory?.userId,
                UserUtils.getId()
              )
                ? ConnectedEnvHistory?.list.reverse().map((his, index) => {
                    return (
                      <span
                        className="recent-env-content"
                        key={his.app.appId + his.env.appEnvId}
                        onClick={() =>
                          quickSetEnv({ app: his.app, env: his.env })
                        }
                      >
                        {index + 1}. {his.app.appNm} - {his.env.envName}
                      </span>
                    );
                  })
                : "최근 작업한 목록이 없습니다."}
            </div>
          </Col>
        </Row>
      </div>
      <div className="env-choice-footer">
        <Button size="sm" onClick={onClickConfirm}>
          확인
        </Button>
        <Button size="sm" variant="secondary" onClick={onClickCancel}>
          취소
        </Button>
      </div>
    </div>
  );
}

export default EnvChoicePopup;
