import {
  ObjectUtils,
  StringUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import Form from "components/common/form/Form";
import FormWrapper from "components/common/form/FormWrapper";
import HistoryForm from "components/datadictionary/HistoryForm";
import { Col, Row } from "react-bootstrap";

/**
 * Domain / Element History의 [이전, 최근] 비교 컴포넌트
 *
 * @props {Object, required} labelData prevData | newestData 를 매핑할 label = { labelKey1 : labelName1, labelKey2 : labelName2, ...}
 * @props {Object, required} prevData 이전 Form에 들어갈 데이터
 * @props {Object, required} newestData 최근 Form에 들어갈 데이터
 */
const HistoryCompare = ({ labelData, prevData, newestData, ...props }) => {
  return (
    <Row className="history-row">
      <Col>
        <FormWrapper
          title={prevData.updtDt ? "이전 (" + newestData.updtDt + ")" : "이전"}
          style={{ minHeight: "40vh", marginTop: "0px", marginBottom: "0px" }}
        >
          <Form className="form-body">
            {prevData && (
              <Row style={{ alignItems: "center" }}>
                {Object.entries(labelData).map(([key, value], index) => {
                  return (
                    (prevData[key] || newestData[key]) && (
                      <HistoryForm
                        key={index}
                        label={value}
                        text={prevData[key] || ""}
                      />
                    )
                  );
                })}
              </Row>
            )}
          </Form>
        </FormWrapper>
      </Col>
      <Col>
        <FormWrapper
          title={
            newestData.updtDt ? "최근 (" + newestData.updtDt + ")" : "최근"
          }
          style={{ minHeight: "40vh", marginTop: "0px", marginBottom: "0px" }}
        >
          <Form className="form-body">
            {newestData && (
              <Row style={{ alignItems: "center" }}>
                {Object.entries(labelData).map(([key, value], index) => {
                  return (
                    (prevData[key] || newestData[key]) && (
                      <HistoryForm
                        key={index}
                        different={
                          !ObjectUtils.isEmpty(prevData) &&
                          !StringUtils.equalsIgnoreType(
                            prevData[key],
                            newestData[key]
                          )
                        }
                        label={value}
                        text={newestData[key] || ""}
                      />
                    )
                  );
                })}
              </Row>
            )}
          </Form>
        </FormWrapper>
      </Col>
    </Row>
  );
};

export default HistoryCompare;
