import { StringUtils } from "@alpha/com.bizentro.daaf.front.framework";

/**
 * 각 페이지 URL
 * path - AppRoute에서 쓰이는
 */
export const AppPath = {
  Root: "/",
  NotFound: "/*",
  Main: "/",
  Login: "/login",
  Env: {
    Main: {
      path: "env",
      url: "/env",
    },
  },
  Domain: {
    Main: {
      path: "domain",
      url: "/domain",
    },
    Edit: {
      path: "edit",
      url: "/domain/edit",
    },
  },
  DataElement: {
    Main: {
      path: "dataElement",
      url: "/dataElement",
    },
    Edit: {
      path: "edit",
      url: "/dataElement/edit",
    },
  },
  TRD: {
    Main: {
      path: "trd",
      url: "/trd",
    },
    Editor: {
      path: "editor",
      url: "/trd/editor",
    },
    List: {
      path: "list",
      url: "/trd/list",
    },
    History: {
      path: "history",
      url: "/trd/history",
    },
    History_Detail: {
      path: "history/detail/:trdHistoryMstId",
      url: "/trd/history/detail/",
    },
    Editor_List: {
      path: "editor-list",
      url: "/trd/editor-list",
    },
  },
  Studio: {
    Main: {
      path: "studio",
      url: "/studio",
    },
  },
  Setting: {
    Main: {
      path: "setting",
      url: "/setting",
    },
    EnvSet: {
      path: "env",
      url: "/setting/env",
    },
    UserSet: {
      path: "user",
      url: "/setting/user",
    },
  },
};

export const LocalStorageName = {
  AppEnvChoiceHistory: "app_env_choice_history",
  EDITOR_THEME: "FlowEditorTheme",
  ENV_STORAGE: "Env Storage",
};

export const MultiLanguage = {
  TableName: {
    DOMAIN: "d_domain",
    ELEMENT: "d_element",
    TABLE: "d_table_mst",
  },
  ColnumName: {
    DOMAIN: {
      fullNm: "domain_nm",
      shortNm: "domain_short_nm",
      description: "description",
    },
    ELEMENT: {
      fullNm: "element_nm",
      shortNm: "element_short_nm",
      description: "description",
    },
    TABLE: {
      fullNm: "table_logical_nm",
      shortNm: "table_logical_nm",
      description: "description",
    },
  },
};

export const ErdType = {
  MEMO: "memo",
  TABLE: "table",
  CONNECTOR: "connector",
  AREA_TEMPLATE: "areaTemplate",
};

export const CaseType = {
  String: "string",
  Number: "integer",
  Decimal: "decimal",
  Date: "date",
  Object: "object",
};

const definitionCase = {
  [CaseType.String]: ["char", "nChar", "varChar"],
  [CaseType.Number]: ["integer", "smallInt", "bigInt"],
  [CaseType.Decimal]: ["real", "float", "double", "decimal"],
  [CaseType.Date]: ["date", "time", "timeStamp"],
  [CaseType.Object]: ["json", "longText"],
};

/**
 * Case 유형 리턴 함수
 * @param {*} dataType
 * @returns
 */
const getDataCase = (dataType) => {
  let currentDataCase;
  Object.keys(definitionCase).forEach((key) => {
    definitionCase[key].forEach((element) => {
      if (StringUtils.equalsIgnoreCase(dataType, element)) {
        currentDataCase = key;
        return;
      }
    });
  });
  return currentDataCase;
};

const TrdKeyName = {
  trdUid: "TRD UID",
  trdNm: "TRD 명",
  trdDevType: "TRD 개발 유형",
  trdConfig: "TRD 설정",
  trdAreaType: "TRD 영역 유형",
  trdDesc: "설명",
  useYn: "사용 여부",
  appApplyYn: "App 적용 여부",
  appApplyQuery: "App 적용 쿼리",
  updtUserId: "수정자",

  tableMstId: "테이블 ID",
  tableLogicalNm: "논리 명",
  tablePhysicalNm: "물리 명",
  tableDesc: "테이블 설명",
  tableDeliveryClass: "테이블 작용 범위",
  tableViewMaint: "테이블 관리 권한",
  position: "테이블 위치",
  tableConfig: "테이블 설정",
  trdTableField: "테이블 컬럼",
  relation: "테이블 관계",
  trdArea: "테이블 영역",

  pkYn: "PK 여부",
  notNullYn: "Not Null 여부",
  autoIncrementYn: "자동 증가여부",
  uniqueYn: "유니크 여부",
  fieldDefaultValue: "기본값",
  extra: "Extra",
  sortSeq: "필드 순서",
};
/**
 * Trd Table생성시 사용하는 Who columns
 */
const TrdWhoColumns = [
  "insertUserId",
  "updtDt",
  "updtUserId",
  "insertDt",
  "userId",
  "appEnvId",
];

/**
 * Table 생성시 Who Columns에 사용되는 Element의 Domain Code 목록
 */
const WhoColumnsElementCodeList = [
  "INSRT_USER_ID", //UNIERP 방식
  "INSRT_DT",
  "INSERT_USER_ID",
  "INSERT_DT",
  "UPDT_USER_ID",
  "UPDT_DT",
];

const DefaultElementCodeList = [
  "PGM_ID",
  "SP_NM",
  "IP_ADDR",
  "CLIENT_ID",
  "EXT1_CD",
  "EXT2_CD",
  "EXT3_CD",
  "EXT4_CD",
  "EXT5_CD",
  "EXT6_CD",
  "EXT7_CD",
  "EXT8_CD",
  "EXT9_CD",
  "EXT10_CD",
  "EXT1_TEXT",
  "EXT2_TEXT",
  "EXT3_TEXT",
  "EXT4_TEXT",
  "EXT5_TEXT",
  "EXT6_TEXT",
  "EXT7_TEXT",
  "EXT8_TEXT",
  "EXT9_TEXT",
  "EXT10_TEXT",
  "EXT1_NUM",
  "EXT2_NUM",
  "EXT3_NUM",
  "EXT4_NUM",
  "EXT5_NUM",
  "EXT6_NUM",
  "EXT7_NUM",
  "EXT8_NUM",
  "EXT9_NUM",
  "EXT10_NUM",
  "EXT1_DT",
  "EXT2_DT",
  "EXT3_DT",
  "EXT4_DT",
  "EXT5_DT",
  "EXT6_DT",
  "EXT7_DT",
  "EXT8_DT",
  "EXT9_DT",
  "EXT10_DT",
];

/**
 * Table 생성시 Who Columns에 사용되는 Element의 Domain Code 목록
 */
const TenantColumnsElementCodeList = ["TENANT_ID", "CO_CD"];

const ElementType = {
  TEXT: "text",
  ELEMENT: "element",
  DOMAIN_ELEMENT: "domainElement",
};

/**
 * 쿼리 생성시 사용되는 쿼리 키
 */
const QueryKey = {
  TABLE: {
    CREATE: "CREATE_TABLE",
    DROP: "DROP_TABLE",
  },
  COLUMN: {
    ADD: "ADD_COLUMN",
    DROP: "DROP_COLUMN",
    ALTER: "ALTER_COLUMN",
    PK: "ALTER_PK",
    DROP_PK: "DROP_PK",
    UNIQUE: "ALTER_UNIQUE",
  },
  RELATION: {
    ADD: "ADD_RELATION",
    ALTER: "ALTER_RELATION",
    DROP: "DROP_RELATION",
  },
};

export const Enums = {
  ErdType,
  LocalStorageName,
  getDataCase,
  CaseType,
  TrdKeyName,
  TrdWhoColumns,
  TenantColumnsElementCodeList,
  WhoColumnsElementCodeList,
  ElementType,
  DefaultElementCodeList,
  QueryKey,
  MultiLanguage,
};
