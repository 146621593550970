import {
  ArrayUtils,
  DaafEnums,
  DaafMessage,
  DaafModal,
  DaafModalTemplate,
  DaafPopup,
  ObjectUtils,
  SessionUtils,
  StringUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import Message from "@alpha/com.bizentro.daaf.front.framework/dist/component/Message";
import { Enums, MultiLanguage } from "app/Enums";
import Form from "components/common/form/Form";
import Grid from "components/grid/Grid";
import produce from "immer";
import { debounce } from "lodash";
import TrdRenderUtils from "page/trd/editor/render/TrdRenderUtils";
import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, FormControl, Row, Tab, Tabs } from "react-bootstrap";
import { MdDelete, MdEdit, MdErrorOutline } from "react-icons/md";
import { RiBook2Line } from "react-icons/ri";
import DomainService from "services/DomainService";
import ElementService from "services/ElementService";
import TrdService from "services/TrdService";
import ElementSelectPopup from "../element/ElementSelectPopup";
import ElementSettingPopup from "./ElementSettingPopup";
import TableRelationListPopup from "./TableRelationListPopup";
import { IoLanguageOutline } from "react-icons/io5";
import SimplePopup from "../SimplePopup";
import DDMultiLangPopup from "../MultiLangPopup";
import MultiLangService from "services/MultiLangService";

const TableRegisterPopup = ({
  tableInfo: _tableInfo,
  trd,
  area,
  applyElement,
  ...props
}) => {
  const [tableInfo, _setTableInfo] = useState({
    tableMstId: StringUtils.getUuid(),
    type: Enums.ErdType.TABLE,
    relation: [],
    tableLogicalNm: "",
    tablePhysicalNm: "",
    tableDesc: "",
    tableDeliveryClass: "A",
    tableViewMaint: "A",
    trdTableField: [],
    position: {},
  });
  const [etcProps, setEtcProps] = useState();
  const [selectedArea, setSelectedArea] = useState(area);
  const [tableUsedTrd, setTableUsedTrd] = useState();
  //import Table이면 수정하지 못하도록 함
  const isImportTable = _tableInfo.importTableId ? true : false;

  //Add Column 시 Call by Refer 이슈로 Ref에 따로 필드 목록 정보를 담음
  const fieldListRef = useRef([]);
  const fieldDefaultState = {
    pkYn: "N",
    notNullYn: "N",
    autoIncrementYn: "N",
    uniqueYn: "N",
    fieldDefaultValue: "",
    extra: "",
  };

  /**
   * Table 정보 set 하는 함수
   * ref랑 같이 적용
   * @param {*} data
   */
  const setTableInfo = (data) => {
    _setTableInfo(data);
    fieldListRef.current = data.trdTableField;
  };

  //컬럼 정보
  const columns = [
    {
      field: "fieldPhysicalNm",
      headerName: "물리 명",
      renderCell: (row, index) => {
        let isElementExist = true;
        if (
          !row.element ||
          StringUtils.equalsIgnoreCase(row.element.elementType, "text")
        )
          isElementExist = false;
        return (
          <>
            {!isElementExist && (
              <span className="warning-text">
                <MdErrorOutline />
              </span>
            )}

            {row.element?.elementCd || row.physicalNm}
          </>
        );
      },
    },
    {
      field: "fieldLogicalNm",
      headerName: "논리 명",
      renderCell: (row, index) => {
        return row.element?.elementNm || row.logicalNm;
      },
    },
    {
      field: "pkYn",
      headerName: "키값 여부",
      style: { textAlign: "center", width: "80px" },
      renderCell: (row, index) => {
        return (
          <Form.CheckBox
            checked={row.pkYn === "Y" ? true : false}
            disabled={isImportTable}
            onChange={(e) => {
              onChangeFieldData(
                { target: { id: "pkYn", value: e.target.checked ? "Y" : "N" } },
                index
              );
            }}
          />
        );
      },
    },
    {
      field: "notNullYn",
      headerName: "Not Null",
      style: { textAlign: "center", width: "80px" },
      renderCell: (row, index) => {
        return (
          <Form.CheckBox
            disabled={
              isImportTable ||
              row.autoIncrementYn === "Y" ||
              row.pkYn === "Y" ||
              row.uniqueYn === "Y"
            }
            checked={row.notNullYn === "Y" ? true : false}
            onChange={(e) => {
              onChangeFieldData(
                {
                  target: {
                    id: "notNullYn",
                    value: e.target.checked ? "Y" : "N",
                  },
                },
                index
              );
            }}
          />
        );
      },
    },
    {
      field: "autoIncrementYn",
      headerName: "자동 증가 여부",
      style: { textAlign: "center", width: "120px" },
      renderCell: (row, index) => {
        return (
          <Form.CheckBox
            disabled={
              !isAutoIncrementYnField(row.element?.domain?.dataType) ||
              isImportTable
            }
            checked={row.autoIncrementYn === "Y" ? true : false}
            onChange={(e) => {
              onChangeFieldData(
                {
                  target: {
                    id: "autoIncrementYn",
                    value: e.target.checked ? "Y" : "N",
                  },
                },
                index
              );
            }}
          />
        );
      },
    },
    {
      field: "uniqueYn",
      headerName: "유니크 키",

      style: { textAlign: "center", width: "80px" },
      renderCell: (row, index) => {
        return (
          <Form.CheckBox
            checked={row.uniqueYn === "Y" ? true : false}
            disabled={isImportTable}
            onChange={(e) => {
              onChangeFieldData(
                {
                  target: {
                    id: "uniqueYn",
                    value: e.target.checked ? "Y" : "N",
                  },
                },
                index
              );
            }}
          />
        );
      },
    },
    {
      field: "fieldDefaultValue",
      headerName: "기본 값",
      style: { width: "150px" },
      renderCell: (row, index) => {
        return (
          <Form.Input
            id={"fieldDefaultValue"}
            onChange={(e) => onChangeFieldData(e, index)}
            value={row.fieldDefaultValue}
            disabled={isImportTable}
          />
        );
      },
    },
    {
      field: "extra",
      headerName: "Extra",
      style: { width: "150px" },
      renderCell: (row, index) => {
        return (
          <Form.Input
            id={"extra"}
            onChange={(e) => onChangeFieldData(e, index)}
            value={row.extra}
            disabled={isImportTable}
          />
        );
      },
    },

    {
      field: "remark",
      headerName: `관리`,
      headerStyle: { textAlign: "center" },
      renderCell: (row, index) => {
        if (isImportTable) return <></>;
        let isElementExist = true;
        if (
          !row.element ||
          StringUtils.equalsIgnoreCase(
            row.element.elementType,
            Enums.ElementType.TEXT
          )
        )
          isElementExist = false;
        return (
          <div style={{ display: "flex", gap: "5px", paddingLeft: "25%" }}>
            <Button
              variant="outline-primary"
              onClick={(e) => onClickElementChange(row, index)}
              size="sm"
            >
              <MdEdit />
            </Button>

            <Button
              variant="outline-danger"
              onClick={(e) => onDeleteField(index)}
              size="sm"
            >
              <MdDelete />
            </Button>
            {!isElementExist && (
              <Button
                variant="success"
                onClick={(e) => elementSettingPopup(row, index)}
                size="sm"
              >
                <RiBook2Line />
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (_tableInfo) {
      setTableInfo(
        produce(tableInfo, (draft) => {
          for (const k in _tableInfo) {
            draft[k] = _tableInfo[k];
          }
        })
      );
    }
  }, []);

  /**
   * property Value change 이벤트
   * @param {*} e
   */
  const onChangeData = (e) => {
    setTableInfo(
      produce(tableInfo, (draft) => {
        draft[e.target.id] = e.target.value;
      })
    );
  };

  const tableNmDupCheck = useCallback(
    debounce(async (tableNm) => {
      //다른 TRD에 등록되어있는지 확인

      const tableDupCheck = await TrdService.getTrdTable({
        tablePhysicalNm: tableNm,
        disableLoad: true,
      });
      if (
        !ObjectUtils.isEmpty(tableDupCheck.data) &&
        tableDupCheck.data.trdMst.trdId !== trd.info.trdId
      ) {
        setTableUsedTrd(tableDupCheck.data.trdMst);
      } else {
        setTableUsedTrd(null);
      }
    }, 400),
    []
  );

  const onChangeTableNm = (e) => {
    onChangeData(e);
    if (!StringUtils.isEmpty(e.target.value)) {
      tableNmDupCheck(e.target.value);
    }
  };

  const addColumnCallback = useCallback(
    (element) => {
      const dupField = fieldListRef.current.find(
        (f) => f.element?.elementCd === element.elementCd
      );
      if (dupField) {
        return DaafMessage.alert(
          "이미 존재하는 컬럼입니다.",
          DaafEnums.MessageType.WARN
        );
      } else {
        ElementService.getElement({
          elementCd: element.elementCd,
          disableLoad: true,
        }).then(
          (res) => {
            let fieldDefaultValue = "";
            //Data Type이 timestamp 인경우 fieldDefaultValue -> CURRENT_TIMESTAMP
            //Data Type이 date 인경우 fieldDefaultValue -> CURRENT_DATE
            if (
              StringUtils.equalsIgnoreCase(
                res.data.domain.dataType,
                "timestamp"
              )
            ) {
              fieldDefaultValue = "CURRENT_TIMESTAMP";
            } else if (
              StringUtils.equalsIgnoreCase(res.data.domain.dataType, "date")
            ) {
              //mysql 5.7 이상에서만 가능
              //fieldDefaultValue = "CURRENT_DATE";
            }
            fieldListRef.current = [
              ...fieldListRef.current,
              {
                element: res.data,
                pkYn: "N",
                notNullYn: "N",
                uniqueYn: "N",
                autoIncrementYn: "N",
                fieldDefaultValue: fieldDefaultValue,
                refTableId: null,
                refFieldId: null,
                sortSeq: fieldListRef.current.length,
              },
            ];

            setTableInfo(
              produce(tableInfo, (draft) => {
                draft.trdTableField = fieldListRef.current;
              })
            );
          },
          (err) => {
            DaafMessage.alert(err.message, "warning");
          }
        );
      }
    },
    [tableInfo]
  );

  /**
   * 칼럼 추가
   */
  const onAddColumn = () => {
    DaafPopup.open(<ElementSelectPopup callback={addColumnCallback} />, {
      style: { content: { width: "750px" } },
    });
  };

  /**
   * 컬럼 데이터 온체인지 이벤트
   * @param {*} e
   * @param {*} index
   */
  const onChangeFieldData = (e, index) => {
    setTableInfo(
      produce(tableInfo, (draft) => {
        draft.trdTableField[index][e.target.id] = e.target.value;
        //자동 증가 여부가 확인 되면, 키값, notnull 은 자동 Y
        if (StringUtils.equalsIgnoreCase(e.target.value, "Y")) {
          if (StringUtils.equalsIgnoreCase("autoIncrementYn", e.target.id)) {
            draft.trdTableField[index]["pkYn"] = "Y";
            draft.trdTableField[index]["notNullYn"] = "Y";
            //auto incre가 Y 일때 다른 PK 선택 취소 후 UK로 옮김
            draft.trdTableField.forEach((field, fieldIndex) => {
              if (fieldIndex !== index && field.pkYn === "Y") {
                field.pkYn = "N";
                field.uniqueYn = "Y";
              }
            });
          }

          //pk가 Y 면 Not null은 자동 Y
          if (
            StringUtils.equalsIgnoreCase("pkYn", e.target.id) ||
            StringUtils.equalsIgnoreCase("uniqueYn", e.target.id)
          ) {
            draft.trdTableField[index]["notNullYn"] = "Y";
          }
        } else {
          if (StringUtils.equalsIgnoreCase("autoIncrementYn", e.target.id)) {
            DaafMessage.alert(
              "자동 증가 컬럼 해제시 적용될 쿼리에서 auto increment 컬럼 수정 부분은 최우선으로 실행 하세요",
              "info"
            );
          }
        }
      })
    );
  };

  /**
   * 컬럼 삭제
   * @param {Number} index
   */
  const onDeleteField = (index) => {
    setTableInfo(
      produce(tableInfo, (draft) => {
        draft.trdTableField.splice(index, 1);
      })
    );
  };

  /**
   * 확인 버튼 이벤트
   */
  const onClickConfirm = async () => {
    const saveData = {
      relation: [],
      position: _tableInfo.position,
      ...tableInfo,
      trdArea: selectedArea,
      useYn: "Y",
    };

    if (
      trd.tableList.find(
        (t) =>
          t.tablePhysicalNm === tableInfo.tablePhysicalNm &&
          t.tablePhysicalNm !== _tableInfo.tablePhysicalNm
      )
    ) {
      return DaafMessage.alert(
        "이미 등록된 테이블 명입니다.",
        DaafEnums.MessageType.WARN
      );
    }

    if (StringUtils.isEmpty(saveData.tablePhysicalNm)) {
      return DaafMessage.alert(
        "테이블 물리명을 입력해주세요",
        DaafEnums.MessageType.WARN
      );
    }
    if (StringUtils.isEmpty(saveData.tableDeliveryClass)) {
      return DaafMessage.alert(
        "테이블 운용범위를 선택해주세요",
        DaafEnums.MessageType.WARN
      );
    }
    if (StringUtils.isEmpty(saveData.tableViewMaint)) {
      return DaafMessage.alert(
        "테이블 관리 권한을 선택해주세요",
        DaafEnums.MessageType.WARN
      );
    }

    for (const [index, field] of saveData.trdTableField.entries()) {
      const rowNumber = index + 1;

      if (StringUtils.isEmpty(field.pkYn)) {
        return DaafMessage.alert(
          `${rowNumber}번 칼럼의 키값 여부를 선택해주세요.`,
          DaafEnums.MessageType.WARN
        );
      }
      if (StringUtils.isEmpty(field.notNullYn)) {
        return DaafMessage.alert(
          `${rowNumber}번 칼럼의 Not Null 여부를 선택해주세요.`,
          DaafEnums.MessageType.WARN
        );
      }
    }
    if (Number.isInteger(tableInfo.tableMstId)) {
      if (
        !StringUtils.equalsIgnoreCase(
          tableInfo.tableLogicalNm,
          _tableInfo.tableLogicalNm
        )
      ) {
        //논리 명이 있는 경우 당 언어 코드 다국어 업데이트
        const saveResult = await MultiLangService.saveMultiLang({
          tableNm: Enums.MultiLanguage.TableName.TABLE,
          columnNm: Enums.MultiLanguage.ColnumName.TABLE.fullNm,
          langText: tableInfo.tableLogicalNm,
          tableKeyValue: tableInfo.tableMstId,
        });
        if (saveResult.isError) {
          return DaafMessage.alert("Table logical n ame save Error");
        }
      }
    }

    if (props.callback) {
      //2번째 파라미터는 targetTabke changeNode가 적용됨
      props.callback(saveData, etcProps);
    }
  };

  /**
   * 참조 설정
   * @param {*} e
   */
  const onSetJoin = (e) => {
    const listCallback = (relations) => {
      const changedNodes = [];
      const nonIdentifyingRel = relations.filter((r) => r.relationType === "I");

      setTableInfo(
        produce(tableInfo, (draft) => {
          draft.relation = relations;
        })
      );

      for (const table of trd.tableList) {
        let targetTable = null;
        const targetTableRel = nonIdentifyingRel.filter(
          (nr) => nr.props?.targetTable.tableMstId === table.tableMstId
        );
        if (!ArrayUtils.isEmpty(targetTableRel)) {
          for (const rel of targetTableRel) {
            const {
              props: { keyColumns },
            } = rel;
            targetTable = TrdRenderUtils.setIdentifyingKeyColumn(
              keyColumns,
              table
            );
          }
          changedNodes.push(targetTable);
        }
      }
      setEtcProps(changedNodes);

      DaafPopup.close();
    };
    DaafPopup.open(
      <TableRelationListPopup
        trd={trd}
        table={tableInfo}
        callback={listCallback}
      />,
      {
        style: { content: { width: "550px" } },
      }
    );
  };

  /**
   * 참조필드 온체인지 이벤트
   * @param {*} e
   * @param {*} data
   */
  const onChangeFieldFormat = (e, data) => {
    const targetFieldIndex = tableInfo.trdTableField.findIndex(
      (f) => f.element.elementCd === data.element.elementCd
    );
    if (targetFieldIndex > -1) {
      setTableInfo(
        produce(tableInfo, (draft) => {
          draft.trdTableField[targetFieldIndex][e.target.id] = Number(
            e.target.value
          );
        })
      );
    }
  };

  /**
   * Element 정보 바꾸는 함수
   * @param {*} data
   * @param {*} index
   */
  const onClickElementChange = (data, index) => {
    const callback = async (ele) => {
      const elementResult = await ElementService.getElement(ele);
      const element = elementResult.data;
      setTableInfo(
        produce(tableInfo, (draft) => {
          draft.trdTableField[index].element = element;
          if (!isAutoIncrementYnField(element.domain?.dataType)) {
            draft.trdTableField[index].autoIncrementYn = "N";
          }
        })
      );
      DaafPopup.close();
    };
    DaafPopup.open(<ElementSelectPopup callback={callback} />, {
      style: { content: { width: "750px" } },
    });
  };

  const elementSettingPopup = (data, index) => {
    DomainService.getDomain({
      appEnvId: SessionUtils.get(null, Enums.LocalStorageName.ENV_STORAGE).env
        .appEnvId,
      domainCd: data.physicalNm,
    }).then((res) => {
      data = { ...data, domain: res.isError ? {} : res.data };
      const callback = (element) => {
        setTableInfo(
          produce(tableInfo, (draft) => {
            draft.trdTableField[index].element = element;
            if (
              !isAutoIncrementYnField(
                !ObjectUtils.isEmpty(element.domain) && element.domain.dataType
              )
            ) {
              draft.trdTableField[index].autoIncrementYn = "N";
            }
          })
        );

        DaafMessage.confirm(
          "다른 테이블의 같은 이름을 가진 컬럼에도 해당 Element를 적용하시겠습니까?",
          () => {
            if (applyElement) {
              applyElement(element);
            }
          }
        );

        DaafPopup.close();
      };
      DaafPopup.open(
        <ElementSettingPopup fieldData={data} callback={callback} />,
        {
          style: { content: { width: "40%" } },
        }
      );
    });
  };

  const isAutoIncrementYnField = (type) => {
    return StringUtils.includes(type, ["integer", "smallInt", "bigInt", "int"]);
  };

  /**
   * 테넌트 및 회사코드 넣는 버튼 이벤트
   * @returns
   */
  const onAddTenantCoCdField = async () => {
    const result = await ElementService.getElementListByElementNm({
      elementCds: Enums.TenantColumnsElementCodeList,
    });
    if (ArrayUtils.isEmpty(result.data))
      return DaafMessage.alert(
        "테넌트 또는 회사코드의 Element가 사전에 등록되지 않았습니다.",
        "warning"
      );
    else {
      const tenantElement = result.data.find((e) =>
        StringUtils.equalsIgnoreCase(
          e.elementCd,
          Enums.TenantColumnsElementCodeList[0]
        )
      );
      const cocdElement = result.data.find((e) =>
        StringUtils.equalsIgnoreCase(
          e.elementCd,
          Enums.TenantColumnsElementCodeList[1]
        )
      );
      if (!tenantElement) {
        return DaafMessage.alert(
          "테넌트 ID (TENANT_ID) Element가 존재하지 않습니다."
        );
      }
      if (!cocdElement) {
        return DaafMessage.alert(
          "회사 코드 (CO_CD) Element가 존재하지 않습니다."
        );
      }
      const tenantField = {
        fieldId: StringUtils.getUuid(),
        pkYn: "N",
        notNullYn: "N",
        autoIncrementYn: "N",
        uniqueYn: "N",
        defaultValue: "",
        extra: "",
        element: tenantElement,
        index: 0,
      };
      const coCdField = {
        fieldId: StringUtils.getUuid(),
        pkYn: "N",
        notNullYn: "N",
        autoIncrementYn: "N",
        uniqueYn: "N",
        defaultValue: "",
        extra: "",
        element: cocdElement,
        index: 0,
      };
      setTableInfo(
        produce(tableInfo, (draft) => {
          const newFieldList = [tenantField, coCdField, ...draft.trdTableField];
          draft.trdTableField = newFieldList.map((f, index) => {
            const obj = { ...f };
            obj.sortSeq = index + 1;
            return obj;
          });
        })
      );
    }
  };

  const onAddWhoColumn = async () => {
    const result = await ElementService.getElementListByElementNm({
      elementCds: Enums.WhoColumnsElementCodeList,
    });
    if (ArrayUtils.isEmpty(result.data))
      return DaafMessage.alert(
        Enums.WhoColumnsElementCodeList.join(",") +
          " 해당 코드의 Element가 사전에 등록되지 않았습니다.",
        "warning"
      );
    else {
      const fieldState = {
        pkYn: "N",
        notNullYn: "N",
        autoIncrementYn: "N",
        uniqueYn: "N",
        fieldDefaultValue: "",
        extra: "",
      };
      const insertUserElement = result.data.find((e) =>
        StringUtils.equalsIgnoreCase(e.elementCd, "INSRT_USER_ID")
      );
      const insertDtElement = result.data.find((e) =>
        StringUtils.equalsIgnoreCase(e.elementCd, "INSRT_DT")
      );
      const updateUserElement = result.data.find((e) =>
        StringUtils.equalsIgnoreCase(e.elementCd, "UPDT_USER_ID")
      );
      const updateDtElement = result.data.find((e) =>
        StringUtils.equalsIgnoreCase(e.elementCd, "UPDT_DT")
      );
      if (!insertUserElement) {
        return DaafMessage.alert(
          "입력자(INSRT_USER_ID) Element가 존재하지 않습니다."
        );
      }
      if (!insertDtElement) {
        return DaafMessage.alert(
          "입력일(INSRT_DT) Element가 존재하지 않습니다."
        );
      }
      if (!updateUserElement) {
        return DaafMessage.alert(
          "수정자(UPDT_USER_ID) Element가 존재하지 않습니다."
        );
      }
      if (!updateDtElement) {
        return DaafMessage.alert(
          "수정일(UPDT_DT) Element가 존재하지 않습니다."
        );
      }
      const insertUserId = {
        ...fieldState,
        element: insertUserElement,
        fieldId: StringUtils.getUuid(),
      };
      const insertDt = {
        ...fieldState,
        element: insertDtElement,
        fieldId: StringUtils.getUuid(),
        fieldDefaultValue: "CURRENT_TIMESTAMP",
      };
      const updtUserId = {
        ...fieldState,
        element: updateUserElement,
        fieldId: StringUtils.getUuid(),
      };
      const updtDt = {
        ...fieldState,
        element: updateDtElement,
        fieldId: StringUtils.getUuid(),
        fieldDefaultValue: "CURRENT_TIMESTAMP",
      };
      setTableInfo(
        produce(tableInfo, (draft) => {
          const newFieldList = [
            ...draft.trdTableField,
            insertUserId,
            insertDt,
            updtUserId,
            updtDt,
          ];
          draft.trdTableField = newFieldList.map((f, index) => {
            const obj = { ...f };
            obj.sortSeq = index + 1;
            return obj;
          });
        })
      );
    }
  };

  const onAddDefaultColumn = async () => {
    const result = await ElementService.getElementListByElementNm({
      elementCds: Enums.DefaultElementCodeList,
    });
    if (ArrayUtils.isEmpty(result.data)) {
      return DaafMessage.alert(
        Enums.WhoColumnsElementCodeList.join(",") +
          " 해당 코드의 Element가 사전에 등록되지 않았습니다.",
        "warning"
      );
    }
    const fieldList = [];
    const elementList = result.data;
    const unAddedElements = [];

    Enums.DefaultElementCodeList.forEach((elementCd) => {
      const element = elementList.find(
        (element) => element.elementCd === elementCd
      );
      if (!element) unAddedElements.push(elementCd);
      else {
        fieldList.push({
          ...fieldDefaultState,
          element: element,
          fieldId: StringUtils.getUuid(),
        });
      }
    });

    if (!ArrayUtils.isEmpty(unAddedElements)) {
      unAddedElements.forEach((elementCd) => {
        Message.alert(elementCd + " Element가 등록되지 않았습니다.", "warning");
      });
    }

    setTableInfo(
      produce(tableInfo, (draft) => {
        const newFieldList = [...draft.trdTableField, ...fieldList];
        draft.trdTableField = newFieldList.map((f, index) => {
          const obj = { ...f };
          obj.sortSeq = index + 1;
          return obj;
        });
      })
    );
  };

  const onClickMultiLangPopup = (e) => {
    if (!Number.isInteger(tableInfo.tableMstId)) {
      return DaafMessage.alert(
        "Please save the table first to set up multiple languages.",
        "info"
      );
    }
    DaafPopup.open(
      <DDMultiLangPopup
        tableNm={MultiLanguage.TableName.TABLE}
        columnNm={MultiLanguage.ColnumName.TABLE}
        keyValue={tableInfo.tableMstId}
        title={tableInfo.tableLogicalNm + " Multi Language Setting"}
      />,
      {
        style: { content: { width: "700px" } },
      }
    );
  };

  return (
    <DaafModal>
      <DaafModal.Header
        title={`${_tableInfo?.tablePhysicalNm || ""} 테이블 정보 입력 ${
          isImportTable ? "( Import )" : ""
        }`}
      />
      <DaafModal.Body>
        <DaafModalTemplate>
          {/** 한번 테이블이 등록된 상태에서 영역 변경 가능, 신규 테이블 생성 일때는 나오지 않음 */}
          {tableInfo.trdArea && (
            <Form.Group>
              <Form.Label className="required">영역 선택</Form.Label>
              <Form.Select
                defaultValue={selectedArea.areaId}
                onChange={(e) => {
                  const { tableList, ...area } = trd.areaList.find((a) =>
                    StringUtils.equalsIgnoreType(a.areaId, e.target.value)
                  );

                  setSelectedArea(area);
                }}
              >
                {trd.areaList.map((area, index) => {
                  return (
                    <option value={area.areaId} key={area.areaId}>
                      {area.areaNm}
                      {area.sectorType === "A" ? ` ( 전체 )` : ""}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          )}

          <Form.Group>
            <Form.Label className="required">테이블 명 (물리)</Form.Label>
            <FormControl
              id={"tablePhysicalNm"}
              value={tableInfo.tablePhysicalNm}
              onChange={onChangeTableNm}
              isInvalid={!ObjectUtils.isEmpty(tableUsedTrd)}
              disabled={isImportTable}
            />
            <FormControl.Feedback type="invalid">
              {tableUsedTrd?.trdNm} TRD 에서 정의 된 테이블 입니다.
            </FormControl.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>테이블 명 (논리)</Form.Label>
            <Form.InputGroup>
              <Form.DInput
                id={"tableLogicalNm"}
                value={tableInfo.tableLogicalNm}
                onChange={onChangeData}
                disabled={isImportTable}
              />
              <Button
                size="sm"
                onClick={onClickMultiLangPopup}
                variant="outline-secondary"
              >
                <IoLanguageOutline />
              </Button>
            </Form.InputGroup>
          </Form.Group>
          <Form.Group>
            <Form.Label>테이블 설명</Form.Label>
            <Form.Textarea
              id={"tableDesc"}
              style={{ width: "100%" }}
              onChange={onChangeData}
              disabled={isImportTable}
              value={tableInfo.tableDesc}
            />
          </Form.Group>
          <Form.Group>
            <Row>
              <Col>
                <Form.Label className={"required"}>테이블 운용 범위</Form.Label>
                <Form.CodeComboBox
                  className="daaf-codecombo"
                  mstCd="D0004"
                  value={tableInfo.tableDeliveryClass}
                  onChange={(data) => {
                    onChangeData({
                      target: {
                        id: "tableDeliveryClass",
                        value: data.id,
                      },
                    });
                  }}
                  disabled={isImportTable}
                />
              </Col>
              <Col>
                <Form.Label className={"required"}>테이블 관리 권한</Form.Label>
                <Form.CodeComboBox
                  mstCd="D0005"
                  value={tableInfo.tableViewMaint}
                  onChange={(data) => {
                    onChangeData({
                      target: {
                        id: "tableViewMaint",
                        value: data.id,
                      },
                    });
                  }}
                  disabled={isImportTable}
                />
              </Col>
            </Row>
          </Form.Group>
          <Form.Group>
            <Tabs className="mt-3 mb-3" defaultActiveKey={"list"}>
              <Tab eventKey={"list"} title="필드 설정">
                <Row>
                  <Col xs={3}></Col>
                  <Col xs={9}>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "5px",
                      }}
                      className="mb-1"
                    >
                      {!isImportTable && (
                        <>
                          <Button onClick={onAddColumn} variant="success">
                            Add Column
                          </Button>
                          <Button
                            onClick={onAddTenantCoCdField}
                            variant="outline-primary"
                          >
                            Add Tenant / Co CD Column
                          </Button>
                          <Button
                            onClick={onAddDefaultColumn}
                            variant="outline-primary"
                          >
                            Add Common Column
                          </Button>
                          <Button
                            onClick={onAddWhoColumn}
                            variant="outline-primary"
                          >
                            Add Who Column
                          </Button>
                        </>
                      )}

                      <Button onClick={onSetJoin} variant="outline-secondary">
                        참조 설정
                      </Button>
                    </div>
                  </Col>
                </Row>
                <Grid
                  columns={columns}
                  rows={tableInfo.trdTableField}
                  numbering={true}
                  rowDraggable={true}
                  gridBoxStyle={{ maxHeight: "700px", overflow: "auto" }}
                  stickyHeader
                  onDragDropChange={(fieldList, index) => {
                    setTableInfo(
                      produce(tableInfo, (draft) => {
                        draft.trdTableField = fieldList.map((f, index) => {
                          const obj = { ...f };
                          obj.sortSeq = index + 1;
                          return obj;
                        });
                      })
                    );
                  }}
                />
              </Tab>
              <Tab eventKey={"config"} title="숫자 포맷 설정">
                {!ArrayUtils.isEmpty(tableInfo.trdTableField) && (
                  <Grid
                    columns={[
                      {
                        headerName: "필드",
                        field: "Field",
                        renderCell: (data) => {
                          return data.element?.elementCd;
                        },
                      },
                      {
                        headerName: "필드 명",
                        field: "Fieldname",
                        renderCell: (data) => {
                          return data.element?.elementNm;
                        },
                      },
                      {
                        headerName: "Domain",
                        renderCell: (data) => {
                          return data.element?.domain?.domainNm;
                        },
                      },
                      {
                        headerName: "참조 테이블",
                        renderCell: (data, index) => {
                          return (
                            <Form.Select
                              id="refTableId"
                              defaultValue={
                                data.refTableId || tableInfo.tableMstId
                              }
                              disabled={isImportTable}
                              onChange={(e) => onChangeFieldFormat(e, data)}
                            >
                              {trd.tableList.map((table) => {
                                return (
                                  <option
                                    key={table.tableMstId}
                                    value={table.tableMstId}
                                  >
                                    {table.tablePhysicalNm}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          );
                        },
                      },
                      {
                        headerName: "참조 필드",
                        style: {
                          width: "200px",
                        },
                        renderCell: (data) => {
                          const table = trd.tableList.find(
                            (table) => table.tableMstId === data.refTableId
                          );
                          const optionList = table
                            ? table.trdTableField
                            : tableInfo.trdTableField;
                          return (
                            <Form.Select
                              value={data.refFieldId}
                              onChange={(e) => onChangeFieldFormat(e, data)}
                              id={"refFieldId"}
                              disabled={isImportTable}
                            >
                              {optionList.map((f) => (
                                <option value={f.fieldId} key={f.fieldId}>
                                  {f.element?.elementNm}
                                </option>
                              ))}
                            </Form.Select>
                          );
                        },
                      },
                    ]}
                    rows={tableInfo.trdTableField.filter((f) =>
                      StringUtils.includesIgnoreCase(
                        f.element?.domain?.dataType,
                        ["DECIMAL", "DOUBLE", "FLOAT", "INTEGER", "SMALLINT"]
                      )
                    )}
                  />
                )}
              </Tab>
            </Tabs>
          </Form.Group>

          {/* <Grid columns={columns} rows={data.trdTableField} /> */}
        </DaafModalTemplate>
      </DaafModal.Body>
      <DaafModal.Footer>
        {!ObjectUtils.isEmpty(tableUsedTrd) ? (
          <DaafModal.Footer.Button variant="danger">
            저장 불가
          </DaafModal.Footer.Button>
        ) : (
          <DaafModal.Footer.Button
            onClick={onClickConfirm}
            disabled={!ObjectUtils.isEmpty(tableUsedTrd)}
          >
            확인
          </DaafModal.Footer.Button>
        )}
      </DaafModal.Footer>
    </DaafModal>
  );
};

export default TableRegisterPopup;
