import {
  ConnectionService,
  DaafEnums,
  DaafMessage,
  DaafModal,
  DaafModalTemplate,
  DaafPopup,
  SessionUtils,
  StringUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import DForm from "components/common/form/Form";
import { useEffect, useRef, useState } from "react";
import { Button, Col, InputGroup, Row, Form } from "react-bootstrap";
import { AiOutlineQuestionCircle } from "react-icons/ai";

function ConnectPopup(props) {
  const {
    title,
    callbackFnc,
    connection,
    disconnection,
    deleteConnectionFnc,
    workspace,
    connectionList,
  } = props;

  const [connectionId, setConnectionId] = useState("");
  const [connectionNm, setConnectionNm] = useState("");
  const [protocol, setProtocol] = useState("HTTP");
  const [host, setHost] = useState("");
  const [connectionType, setConnectionType] = useState("proxy");
  const [runtimeProtocol, setRuntimeProtocol] = useState("HTTP");
  const [runtimeHost, setRuntimeHost] = useState("");
  const [systemUserId, setSystemUserId] = useState("");
  const [systemUserPw, setSystemUserPw] = useState("");
  const [tenantId, setTenantId] = useState("*");
  const [coCd, setCoCd] = useState("*");
  const [isConnecting, setIsConnecting] = useState(false);
  const [connected, setConnected] = useState(SessionUtils.get("connection"));

  const connectionNmRef = useRef();
  const systemUserPwRef = useRef();

  useEffect(() => {
    if (connection) {
      setConnectionId(connection.connectionId);
      setConnectionNm(connection.connectionNm);
      setProtocol(connection.protocol);
      setHost(connection.host);
      setRuntimeProtocol(connection.runtimeProtocol);
      setRuntimeHost(connection.runtimeHost);
      setSystemUserId(connection.userId);
      setTenantId(connection.tenantId);
      setCoCd(connection.coCd);
      setConnectionType(connection.connectionType || "proxy");
      systemUserPwRef.current.focus();
    } else {
      connectionNmRef.current.focus();
    }
  }, []);

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      onSaveConnect();
    }
  };

  const validationCheck = () => {
    if (StringUtils.isEmpty(tenantId) || StringUtils.isEmpty(coCd)) {
      DaafMessage.alert(
        "테넌트 ID와 회사코드를 입력해주세요.",
        DaafEnums.MessageType.WARN
      );
      return false;
    }
    if (StringUtils.isEmpty(protocol)) {
      DaafMessage.alert("스키마를 입력 해주세요", DaafEnums.MessageType.WARN);
      return false;
    }
    if (StringUtils.isEmpty(host)) {
      DaafMessage.alert(
        "배포 서버 호스트를 입력해주세요",
        DaafEnums.MessageType.WARN
      );
      return false;
    }
    if (StringUtils.isEmpty(connectionType)) {
      DaafMessage.alert(
        "배포 서버 연결 유형을 선택해주세요.",
        DaafEnums.MessageType.WARN
      );
      return false;
    }
    if (StringUtils.isEmpty(systemUserId)) {
      DaafMessage.alert(
        "접속 유저 아이디를 입력해주세요",
        DaafEnums.MessageType.WARN
      );
      return false;
    }
    if (StringUtils.isEmpty(systemUserPw)) {
      DaafMessage.alert(
        "접속 유저 비밀번호를 입력해주세요",
        DaafEnums.MessageType.WARN
      );
      return false;
    }
    return {
      tenantId,
      coCd,
      protocol,
      host,
      runtimeProtocol,
      connectionType,
      runtimeHost,
      systemUserId,
      systemUserPw,
      connectionId: connectionId,
    };
  };

  const onTestConnect = () => {
    //테스트만 실행
    const body = validationCheck();
    let _testMethod = () => {};
    if (body) {
      setIsConnecting(true);
      if (body.connectionType === "proxy") {
        _testMethod = ConnectionService.test;
      } else if (body.connectionType === "direct") {
        _testMethod = ConnectionService.directConnectTest;
      }
    }
    if (props.connectTest) {
      props.connectTest(body, () => setIsConnecting(false));
    }
    return false;
    _testMethod(
      body,
      (res) => {
        // const { flag, text } = ConnectionService.reponseValidator(
        //   res,
        //   workspace.tenantMstId
        // );
        // if (flag) DaafMessage.alert(text, DaafEnums.MessageType.SUCCESS);
        // else DaafMessage.alert(text, DaafEnums.MessageType.ERROR);
        // setIsConnecting(false);
      },
      (err) => {
        console.log(err);
        setIsConnecting(false);
      }
    );
  };

  const onSaveConnect = () => {
    //연결 및 DB 저장
    if (StringUtils.isEmpty(connectionNm)) {
      DaafMessage.alert("연결 명을 설정해주세요", DaafEnums.MessageType.WARN);
      return false;
    }
    const body = validationCheck();

    if (body) {
      setIsConnecting(true);
      body.connectionNm = connectionNm;
      callbackFnc(body, () => {
        setIsConnecting(false);
      });
    }
  };
  const onDisConnect = (e) => {
    disconnection(e);
    DaafPopup.close();
  };
  const onDeleteConnection = (e) => {
    DaafMessage.confirm("해당 연결정보를 전부 삭제하시겠습니까?", () => {
      deleteConnectionFnc(e, connection);
    });
  };

  const onChangeConnection = (e) => {
    const selectedConnection = connectionList.find((c) =>
      StringUtils.equalsIgnoreType(c.connectionId, e.target.value)
    );

    setConnectionId(selectedConnection.connectionId);
    setConnectionNm(selectedConnection.connectionNm);
    setProtocol(selectedConnection.protocol);
    setHost(selectedConnection.host);
    setSystemUserId(selectedConnection.userId);
    setTenantId(selectedConnection.tenantId);
    setCoCd(selectedConnection.coCd);
    setRuntimeProtocol(selectedConnection.runtimeProtocol);
    setRuntimeHost(selectedConnection.runtimeHost);
    setConnectionType(selectedConnection.connectionType || "proxy");

    systemUserPwRef.current.focus();
  };

  return (
    <DaafModal>
      <DaafModal.Header title={title} />
      <DaafModal.Body>
        <Form>
          <DaafModalTemplate>
            {connectionList && connectionList.length > 0 && (
              <Form.Group className="mb-3">
                <Form.Label>연결 목록</Form.Label>
                <Form.Select value={connectionId} onChange={onChangeConnection}>
                  <option value="">선택</option>
                  {connectionList.map((_connection) => {
                    return (
                      <option
                        value={_connection.connectionId}
                        key={_connection.connectionId}
                      >
                        {_connection.connectionNm}
                        {""}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        HOST : &nbsp;
                        {_connection.host}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            )}

            <Form.Group className="mb-3">
              <Form.Label className="required">연결명</Form.Label>
              <input
                type="text"
                id="connectionNm"
                name="connectionNm"
                className="form-control"
                onChange={(e) => setConnectionNm(e.target.value)}
                value={connectionNm}
                ref={connectionNmRef}
                onKeyDown={onKeyDown}
              />
            </Form.Group>
            <Row>
              <Col xs={3}>
                <Form.Label className="required">배포 서버 </Form.Label>
              </Col>
              <Col xs={9} />
            </Row>
            <Row className="mb-3">
              <Col xs={2}>
                <Form.Select
                  value={StringUtils.defaultString(protocol, "HTTP")}
                  onChange={(e) => setProtocol(e.target.value)}
                >
                  <option value={"HTTP"}>HTTP</option>
                  <option value={"HTTPS"}>HTTPS</option>
                </Form.Select>
              </Col>
              <Col xs={6}>
                <Form.Control
                  type="text"
                  id="host"
                  name="host"
                  className="form-control"
                  onChange={(e) => setHost(e.target.value)}
                  value={host}
                  onKeyDown={onKeyDown}
                  size={"sm"}
                />
              </Col>
              <Col xs={4}>
                <InputGroup>
                  <Form.Select
                    value={StringUtils.defaultString(connectionType, "proxy")}
                    onChange={(e) => {
                      setConnectionType(e.target.value);
                    }}
                  >
                    <option value={"proxy"}>DAAF Proxy 배포</option>
                    <option value={"direct"}>직접 연결 배포</option>
                  </Form.Select>

                  {/* <Button
                    size={"sm"}
                    variant="outline-secondary"
                    onClick={(e) => {
                      DaafPopup.open(<ConnectTypeDescPopup />, {
                        style: { content: { width: "550px" } },
                      });
                    }}
                  >
                    <AiOutlineQuestionCircle />
                  </Button> */}
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={3}>
                <Form.Label>Runtime 서버</Form.Label>
              </Col>
              <Col xs={9}></Col>
            </Row>
            <Row className="mb-3">
              <Col xs={2}>
                <Form.Select
                  value={StringUtils.defaultString(runtimeProtocol, "HTTP")}
                  onChange={(e) => setRuntimeProtocol(e.target.value)}
                >
                  <option value={"HTTP"}>HTTP</option>
                  <option value={"HTTPS"}>HTTPS</option>
                </Form.Select>
              </Col>
              <Col xs={10}>
                <InputGroup>
                  <Form.Control
                    type="text"
                    id="host"
                    name="host"
                    className="form-control"
                    onChange={(e) => setRuntimeHost(e.target.value)}
                    value={runtimeHost}
                    onKeyDown={onKeyDown}
                    size="sm"
                  />
                  <DForm.Tooltip
                    title={
                      <>
                        Workflow Debugging 기능을 사용하기 위해서는 HTTPS
                        Runtime URL을 입력 하셔야 합니다.
                      </>
                    }
                    placement="top"
                  >
                    <Button size={"sm"} variant="outline-secondary">
                      <AiOutlineQuestionCircle />
                    </Button>
                  </DForm.Tooltip>
                </InputGroup>
              </Col>
            </Row>
            <Form.Group className="mb-3">
              <Form.Label className="required">접속 유저 ID</Form.Label>
              <input
                type="text"
                id="systemUserId"
                name="systemUserId"
                className="form-control"
                onChange={(e) => setSystemUserId(e.target.value)}
                value={systemUserId}
                onKeyDown={onKeyDown}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="required">접속 유저 비밀번호</Form.Label>
              <input
                type="password"
                id="systemUserPw"
                name="systemUserPw"
                className="form-control"
                onChange={(e) => setSystemUserPw(e.target.value)}
                value={systemUserPw}
                ref={systemUserPwRef}
                onKeyDown={onKeyDown}
              />
            </Form.Group>
          </DaafModalTemplate>
        </Form>
      </DaafModal.Body>
      <DaafModal.Footer
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <DaafModal.Footer.ProgressButton
          variant="secondary"
          onClick={onTestConnect}
          doing={isConnecting}
          doingText={"연결 중"}
          side="left"
        >
          연결 테스트
        </DaafModal.Footer.ProgressButton>
        {connection ? (
          connected ? (
            disconnection ? (
              <DaafModal.Footer.Button
                variant="danger"
                side="left"
                onClick={onDisConnect}
              >
                연결 종료
              </DaafModal.Footer.Button>
            ) : (
              <></>
            )
          ) : deleteConnectionFnc ? (
            <DaafModal.Footer.ProgressButton
              variant="danger"
              side="left"
              onClick={onDeleteConnection}
            >
              연결 삭제
            </DaafModal.Footer.ProgressButton>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}

        <DaafModal.Footer.ProgressButton
          onClick={onSaveConnect}
          doing={isConnecting}
          doingText={"연결 중"}
          side={"right"}
          // disabled={connected && User.getConnection("token")}
        >
          저장 {"&"} 연결
        </DaafModal.Footer.ProgressButton>
      </DaafModal.Footer>
    </DaafModal>
  );
}

export default ConnectPopup;
