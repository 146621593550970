import { DaafModal } from "@alpha/com.bizentro.daaf.front.framework";
import UModalTemplate from "@alpha/com.bizentro.daaf.front.framework/dist/component/modal/UModalTemplate";
import Grid from "components/grid/Grid";

function ConnectionListPopup({ connectionList, callback, ...props }) {
  const columns = [
    {
      field: "connectionNm",
      headerName: "연결명",
    },
    {
      field: "protocol",
      headerName: "프로토콜",
    },
    {
      field: "host",
      headerName: "호스트",
    },
    {
      field: "connectionType",
      headerName: "연결 유형",
    },
  ];

  return (
    <DaafModal>
      <DaafModal.Header title="연결 목록" />
      <DaafModal.Body>
        <UModalTemplate>
          <Grid
            rows={connectionList}
            columns={columns}
            onRowClick={(e, data, index) => {
              if (callback) callback(data);
            }}
          />
        </UModalTemplate>
      </DaafModal.Body>
      <DaafModal.Footer>
        <DaafModal.Footer.Button>확인</DaafModal.Footer.Button>
      </DaafModal.Footer>
    </DaafModal>
  );
}

export default ConnectionListPopup;
