import { CommonUtils } from "@alpha/com.bizentro.daaf.front.framework";
import Message from "@alpha/com.bizentro.daaf.front.framework/dist/component/Message";
import { AppPath } from "app/Enums";
import Template from "components/common/Template";
import Grid from "components/grid/Grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TrdService from "services/TrdService";
import useAppEnv from "utils/hook/useAppEnv";

const TrdHistoryMain = () => {
  const [trdHistoryList, setTrdHistoryList] = useState([]);
  const { env } = useAppEnv();
  const navigate = useNavigate();

  useEffect(() => {
    getTrdHistoryList();
  }, []);

  const columns = [
    {
      field: "trdNm",
      headerName: "TRD 명",
    },
    {
      field: "updtUserId",
      headerName: "최종 수정자",
    },
    {
      field: "updtDt",
      headerName: "최종 수정일",
      renderCell: (rowdata) => {
        return CommonUtils.getDate(rowdata.updtDt);
      },
    },
  ];

  /**
   * 이력 마스터 목록 가져오기
   * @returns
   */
  const getTrdHistoryList = async () => {
    const result = await TrdService.getTrdHistoryMstList({
      appEnvId: env.appEnvId,
    });
    if (result.isError)
      return Message.alert("호출 중 오류가 발생했습니다.", "error");
    setTrdHistoryList(result.data);
  };

  const onRowClick = (e, data, index) => {
    navigate(AppPath.TRD.History_Detail.url + data.trdHistoryMstId);
  };

  return (
    <Template
      title={"TRD 이력 목록"}
      breadcrumb={
        <>
          <div>TRD</div>/<div>목록</div>
        </>
      }
    >
      <Grid rows={trdHistoryList} columns={columns} onRowClick={onRowClick} />
    </Template>
  );
};

export default TrdHistoryMain;
