import {
  ArrayUtils,
  CommonUtils,
  ObjectUtils,
  StringUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import Message from "@alpha/com.bizentro.daaf.front.framework/dist/component/Message";
import { MessageType } from "@alpha/com.bizentro.daaf.front.framework/dist/enums/enum";
import Title from "components/common/Title";
import FormWrapper from "components/common/form/FormWrapper";
import Grid from "components/grid/Grid";
import HistoryCompare from "components/grid/HistoryCompare";
import { useEffect, useRef, useState } from "react";
import { Row } from "react-bootstrap";
import DomainHistoryService from "services/DomainHistoryService";

const DomainHistoryContents = ({
  selectedDomain,
  domainHistoryYn,
  comboData = {},
  ...props
}) => {
  const [domainHistoryList, setDomainHistoryList] = useState([]);
  const [prevData, setPrevData] = useState({});
  const [newestData, setNewestData] = useState({});
  const lastData = useRef({});

  useEffect(() => {
    if (domainHistoryYn) {
      getHistoryList();
      setPrevData({});
      setNewestData({});
      lastData.current = {};
    }
  }, [domainHistoryYn]);

  /**
   * Domain 이력 리스트 호출 함수
   */
  const getHistoryList = async () => {
    const result = await DomainHistoryService.getDomainHistoryList(
      selectedDomain
    );
    if (result.isError) {
      Message.alert("이력 불러오기를 실패했습니다.", MessageType.ERROR);
      setDomainHistoryList([]);
    } else {
      setDomainHistoryList(result.data);
    }
  };

  /**
   * Code mst 값으로 저장되어있는 데이터를 dtl 데이터로 치환하는 함수
   *
   * @param {Object} row
   * @return {Object} row
   */
  const codeToText = (row) => {
    const newRow = { ...row };
    for (const [key, value] of Object.entries(newRow)) {
      if (comboData[key] && !StringUtils.isEmpty(value)) {
        newRow[key] = comboData[key].find((item) => item.id === value).text;
      }
      if (key === "updtDt") {
        newRow[key] = CommonUtils.getDate(newRow[key], "datetime");
      }
    }
    return newRow;
  };

  /**
   * row click시 가장 최근 클릭한 두 row를 비교하는 함수 | id값이 큰 값이 최근 값
   *
   * @param {Object} row
   */
  const rowClickEvent = (row) => {
    if (lastData.current.domainHistoryId === row.domainHistoryId) {
      return;
    }
    if (ObjectUtils.isEmpty(newestData)) {
      setNewestData(codeToText(row));
    } else if (ObjectUtils.isEmpty(prevData)) {
      if (row.domainHistoryId < newestData.domainHistoryId) {
        setPrevData(codeToText(row));
      } else {
        setPrevData(newestData);
        setNewestData(codeToText(row));
      }
    } else {
      // 둘 다 있는 경우
      if (row.domainHistoryId < lastData.current.domainHistoryId) {
        setPrevData(codeToText(row));
        setNewestData(codeToText(lastData.current));
      } else {
        setPrevData(codeToText(lastData.current));
        setNewestData(codeToText(row));
      }
    }

    lastData.current = row;
  };

  /**
   * HistoryCompare Component에 들어갈 props
   */
  const labelData = {
    domainCd: "Domain",
    domainNm: "Domain 명",
    dataType: "Data Type",
    dataLength: "길이",
    formType: "숫자 유형",
    decimals: "소수점 자리수",
    roundingPolicy: "반올림 유형",
    negativeType: "음수 표현 방법",
    outputConversion: "인풋 마스크",
    caseSensitive: "대소문자 표현",
    entryType: "값 범위 유형",
    entryRefTable: "참조 Table",
    entryRefWhere: "Table 검색 조건",
    entryDisType: "값 표현 방법",
    userNm: "수정자",
    updtDt: "수정일",
  };

  const domainHistoryColumns = [
    // {
    //   field: "selectBox",
    //   headerName: "선택",
    // },
    {
      field: "domainCd",
      headerName: "Domain",
    },
    {
      field: "domainNm",
      headerName: "Domain 명",
    },
    {
      field: "dataType",
      headerName: "Data Type",
      renderCell: (data, index) => {
        return (
          <div className="dd-manage-label-cell">
            {data.dataType.toUpperCase()}
          </div>
        );
      },
    },
    {
      field: "updtUserId",
      headerName: "수정자",
      renderCell: (data, index) => {
        return <div className="dd-manage-label-cell">{data.updtUserId}</div>;
      },
    },
    {
      field: "updtDt",
      headerName: "수정일",
      renderCell: (data, index) => {
        return (
          <div className="dd-manage-label-cell">
            {CommonUtils.getDate(data.updtDt, "datetime")}
          </div>
        );
      },
    },
  ];

  return (
    <div
      className={`contents-body dd-manage-history ${
        domainHistoryYn ? "show" : ""
      }`}
    >
      <Title>{"Domain 이력 열람"}</Title>
      <HistoryCompare
        labelData={labelData}
        prevData={prevData}
        newestData={newestData}
      />
      <Row>
        <div className="history-grid-div">
          <FormWrapper style={{ padding: "0px", margin: "0px" }}>
            <Grid
              style={{ marginBottom: "0px" }}
              columns={domainHistoryColumns}
              rows={domainHistoryList}
              onRowClick={(e, row, index) => rowClickEvent(row)}
            />
          </FormWrapper>
        </div>
      </Row>
    </div>
  );
};

export default DomainHistoryContents;
