import {
  ObjectUtils,
  SessionUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import { Enums } from "app/Enums";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useAppEnv = () => {
  const data = SessionUtils.get(null, Enums.LocalStorageName.ENV_STORAGE);

  const [app, setApp] = useState(data.app);
  const [env, setEnv] = useState(data.env);

  useEffect(() => {
    if (ObjectUtils.isEmpty(app) || ObjectUtils.isEmpty(env)) {
      if (data.app) {
        setApp(data.app);
      }
      if (data.env) {
        setEnv(data.env);
      }
    }
  }, []);

  return { app, env };
};

export default useAppEnv;
