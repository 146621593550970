import { ObjectUtils } from "@alpha/com.bizentro.daaf.front.framework";
import { memo } from "react";
import { IoDocumentOutline } from "react-icons/io5";

const ContextMenuForMini = memo(({ menu, ...props }) => {
  return (
    <div
      className={`mini-context-menu ${
        ObjectUtils.isEmpty(menu) ? "hidden" : "show"
      }`}
    >
      <div className="context-menu-header">
        <span className="icon">
          {menu.Icon ? <menu.Icon /> : <IoDocumentOutline />}{" "}
        </span>
        <span className="text">{menu.text}</span>
      </div>
      <hr />
      <div className="sub-menu-list">
        <ul>
          {menu?.subMenus?.map((smenu) => {
            return (
              <li
                key={smenu.text}
                onClick={() => {
                  props.menuInitial();
                  if (smenu.onClick) {
                    smenu.onClick();
                  }
                }}
              >
                {smenu.text}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
});

export default ContextMenuForMini;
