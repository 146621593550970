import {
  CommonUtils,
  ObjectUtils,
  StringUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import Message from "@alpha/com.bizentro.daaf.front.framework/dist/component/Message";
import { MessageType } from "@alpha/com.bizentro.daaf.front.framework/dist/enums/enum";
import Title from "components/common/Title";
import FormWrapper from "components/common/form/FormWrapper";
import Grid from "components/grid/Grid";
import HistoryCompare from "components/grid/HistoryCompare";
import { useEffect, useRef, useState } from "react";
import { Row } from "react-bootstrap";
import ElementHistoryService from "services/ElementHistoryService";
import useAppEnv from "utils/hook/useAppEnv";

const ElementHistoryContents = ({
  elementHistoryYn,
  selectedElement,
  popupSizeComboList,
  ...props
}) => {
  const [elementHistoryList, setElementHisotryList] = useState([]);
  const [prevData, setPrevData] = useState({});
  const [newestData, setNewestData] = useState({});
  const lastData = useRef({});
  const { app, env } = useAppEnv();
  useEffect(() => {
    if (elementHistoryYn) {
      getHistoryList();
      setPrevData({});
      setNewestData({});
      lastData.current = {};
    }
  }, [elementHistoryYn]);

  /**
   * Element 이력 리스트를 호출하는 함수
   */
  const getHistoryList = async () => {
    const result = await ElementHistoryService.getElementHistoryList({
      ...selectedElement,
      appEnvId: env.appEnvId,
    });
    if (result.isError) {
      Message.alert("이력 불러오기를 실패했습니다.", MessageType.ERROR);
      setElementHisotryList([]);
    } else {
      setElementHisotryList(result.data);
    }
  };

  /**
   * 들어온 row Data의 Domain 정보 데이터를 정제해서 반환
   *
   * @param {Object} row
   * @return {Object} newRow
   */
  const rowDataCleansing = (row) => {
    const newRow = { ...row };
    for (const [key, value] of Object.entries(newRow)) {
      if (newRow.domain && key === "domain") {
        newRow.domainCd = newRow[key].domainCd;
        newRow.domainNm = newRow[key].domainNm;
      }
      if (
        popupSizeComboList &&
        key === "popupSize" &&
        !StringUtils.isEmpty(value)
      ) {
        newRow.popupSize = popupSizeComboList.find(
          (item) => item.id === value
        ).text;
      }
      if (key === "updtDt") {
        newRow[key] = CommonUtils.getDate(newRow[key], "datetime");
      }
    }
    return newRow;
  };

  /**
   * row click시 가장 최근 클릭한 두 row를 비교하는 함수 | id값이 큰 값이 최근 값
   *
   * @param {Object} row
   */
  const rowClickEvent = (row) => {
    if (lastData.current.elementHistoryId === row.elementHistoryId) {
      return;
    }
    if (ObjectUtils.isEmpty(newestData)) {
      setNewestData(rowDataCleansing(row));
    } else if (ObjectUtils.isEmpty(prevData)) {
      // 최근만 있는 경우
      if (row.elementHistoryId < newestData.elementHistoryId) {
        // row가 newest보다 이전인 경우
        setPrevData(rowDataCleansing(row));
      } else {
        // row가 newest보다 최근인 경우
        setPrevData(newestData);
        setNewestData(rowDataCleansing(row));
      }
    } else {
      // 둘 다 있는 경우
      if (row.elementHistoryId < lastData.current.elementHistoryId) {
        setPrevData(rowDataCleansing(row));
        setNewestData(rowDataCleansing(lastData.current));
      } else {
        setPrevData(rowDataCleansing(lastData.current));
        setNewestData(rowDataCleansing(row));
      }
    }

    lastData.current = row;
  };

  /**
   * HistoryCompare Component에 들어갈 props
   */
  const labelData = {
    elementCd: "Element",
    elementNm: "Element 명",
    domainCd: "Domain",
    domainNm: "Domain 명",
    shortCd: "약어",
    description: "설명",
    decimals: "소수점 자리수",
    popupProgramId: "Popup Program Id",
    popupSize: "Popup Size",
    parameterId: "Parameter Id",
    userNm: "수정자",
    updtDt: "수정일",
  };

  /**
   * 이력 리스트 그리드 설정 컬럼
   */
  const elementHistoryColumns = [
    // {
    //   field: "selectBox",
    //   headerName: "선택",
    // },
    {
      field: "elementCd",
      headerName: "Element Code",
    },
    {
      field: "elementNm",
      headerName: "Element 명",
    },
    {
      field: "domain",
      headerName: "Domain 명",
      renderCell: (data, index) => {
        return (
          <div className="dd-manage-label-cell">{data.domain?.domainNm}</div>
        );
      },
    },
    {
      field: "updtUserId",
      headerName: "수정자",
      renderCell: (data, index) => {
        return <div className="dd-manage-label-cell">{data.updtUserId}</div>;
      },
    },
    {
      field: "updtDt",
      headerName: "수정일",
      renderCell: (data, index) => {
        return (
          <div className="dd-manage-label-cell">
            {CommonUtils.getDate(data.updtDt, "datetime")}
          </div>
        );
      },
    },
  ];

  return (
    <div
      className={`contents-body dd-manage-history ${
        elementHistoryYn ? "show" : ""
      }`}
    >
      <Title>{"Element 이력 열람"}</Title>
      <HistoryCompare
        labelData={labelData}
        prevData={prevData}
        newestData={newestData}
      />
      <Row>
        <div className="history-grid-div">
          <FormWrapper style={{ padding: "0px", margin: "0px" }}>
            <Grid
              style={{ marginBottom: "0px" }}
              columns={elementHistoryColumns}
              rows={elementHistoryList}
              onRowClick={(e, row, index) => rowClickEvent(row)}
            />
          </FormWrapper>
        </div>
      </Row>
    </div>
  );
};

export default ElementHistoryContents;
