import {
  ArrayUtils,
  CodeService,
  ObjectUtils,
  UserUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import Message from "@alpha/com.bizentro.daaf.front.framework/dist/component/Message";
import LoadingPage from "page/LoadingPage";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ElementService from "services/ElementService";
import { setElementParams } from "store/actions/DataDictionaryAction";
import useAppEnv from "utils/hook/useAppEnv";
import ElementContents from "./ElementContents";
import ElementList from "./ElementList";
import ElementHistoryContents from "./history/ElementHistoryContents";

const Element = () => {
  const [elementHistoryYn, setElementHistoryYn] = useState(false); // 엘리먼트 이력 on/off
  const [selectedElement, setSelectedElement] = useState({
    elementId: null,
    appEnvId: null,
    elementCd: null,
    elementNm: null,
    domain: {},
    domainId: null,
    shortCd: null,
    description: null,
    entryType: null,
    entryRefTable: null,
    entryRefWhere: null,
    entryRefKey: null,
    entryRefValue: null,
    entryDisType: null,
    possibleEntryList: [],
    popupProgramId: null,
    popupSize: null,
    parameterId: null,
    langCd: UserUtils.getLanguage(),
  });
  const [isNewElement, setIsNewElement] = useState(true);
  const [elementList, setElementList] = useState([]);
  const [elementContentsTitle, setElementContentsTitle] = useState("");
  const [comboData, setComboData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();

  const popupSizeComboList = useRef([]);

  const elementParmas = useSelector((state) => state.dictionary.element.params);

  const { app, env } = useAppEnv();

  useEffect(() => {
    // 최초 로드시 모든 list update
    if (ArrayUtils.isEmpty(popupSizeComboList.current)) {
      getPopupSizeComboList();
      getComboList();
    }
  }, []);

  /**
   *  Domain Definition의 Data Type 콤보 값을 가져오는 함수
   */
  const getComboList = () => {
    const data = {
      dataType: [],
      caseSensitive: [],
      formType: [],
      // roundingPolicy: [],
      negativeType: [],
      entryDisType: [],
    };
    // Data Type
    CodeService.getCodeCombo({ codeMstCd: "D0001" }, (res) => {
      data.dataType = res.data;
    });
    // 대소문자
    CodeService.getCodeCombo({ codeMstCd: "D0002" }, (res) => {
      data.caseSensitive = res.data;
    });
    // 숫자유형
    CodeService.getCodeCombo({ codeMstCd: "Z0029" }, (res) => {
      data.formType = res.data;
    });
    // negativeTypeCombo 음수표현방법
    CodeService.getCodeCombo({ codeMstCd: "D0003" }, (res) => {
      data.negativeType = res.data;
    });
    // // Rounding Policy 반올림 유형
    // CodeService.getCodeCombo({ codeMstCd: "Z0047" }, (res) => {
    //   data.roundingPolicy = res.data;
    // });
    setComboData(data);
  };

  /**
   * History on/off
   */
  const onHistory = () => {
    setElementHistoryYn(!elementHistoryYn);
  };

  /**
   * Element 페이지에서 Element List 가져오기
   */
  const getElementList = async (params) => {
    params = { ...params, appEnvId: env.appEnvId };
    setIsLoading(true);
    if (!ObjectUtils.isEmpty(params)) {
      dispatch(setElementParams(params));
    }
    const listData = await ElementService.getElementList(params);
    if (listData.isError) {
      Message.alert("조회에 실패했습니다.", "error");
      setIsLoading(false);
    } else {
      setElementList(listData.data);
      setIsLoading(false);
    }
  };

  const getPopupSizeComboList = () => {
    CodeService.getCodeCombo({ codeMstCd: "Z0021" }, (res) => {
      popupSizeComboList.current = res.data;
    });
  };

  // Element 컨텐츠가 활성화 중 신규등록시 Element 컨텐츠 리셋
  const onSelectNewElement = () => {
    resetElementData();
    setIsNewElement(true);
    setElementContentsTitle("신규 등록");
  };

  /**
   * Element 신규 생성시 데이터 리셋
   */
  const resetElementData = () => {
    setSelectedElement({
      elementId: null,
      appEnvId: env.appEnvId, // 추후 변경
      elementCd: null,
      elementNm: null,
      domain: {},
      domainId: null,
      shortCd: null,
      description: null,
      entryType: null,
      entryRefTable: null,
      entryRefWhere: null,
      entryRefKey: null,
      entryRefValue: null,
      entryDisType: null,
      possibleEntryList: [],
      popupProgramId: null,
      popupSize: null,
      parameterId: null,
      langCd: UserUtils.getLanguage(),
    });
  };

  const elementContentsControl = (tf) => {
    setElementHistoryYn(tf);
  };

  const getSelectedElement = (rows) => {
    ElementService.getElement({
      appEnvId: env.appEnvId,
      elementCd: rows?.elementCd,
    }).then((res) => {
      setSelectedElement(res.data);
    });
  };

  return (
    <div className={`dd-manage-wrapper detail`}>
      {isLoading ? <LoadingPage /> : ""}
      <ElementList
        onSelectNewElement={onSelectNewElement}
        elementList={elementList}
        selectedElement={selectedElement}
        setSelectedElement={getSelectedElement}
        setIsNewElement={setIsNewElement}
        getElementList={getElementList}
        setIndexLoading={setIsLoading}
      />
      <ElementHistoryContents
        elementHistoryYn={elementHistoryYn}
        selectedElement={selectedElement}
        popupSizeComboList={popupSizeComboList.current}
      />
      <ElementContents
        title={elementContentsTitle}
        selectHistory={onHistory}
        selectedElement={selectedElement}
        isNewElement={isNewElement}
        updateElementList={getElementList}
        elementContentsClear={resetElementData}
        setElementContentsYn={elementContentsControl}
        popupSizeComboList={popupSizeComboList.current}
        elementParmas={elementParmas}
        comboData={comboData}
      />
    </div>
  );
};

export default Element;
