import {
  ArrayUtils,
  CodeService,
  DaafMessage,
  DaafPopup,
  ObjectUtils,
  StringUtils,
  UserUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import Message from "@alpha/com.bizentro.daaf.front.framework/dist/component/Message";
import { MessageType } from "@alpha/com.bizentro.daaf.front.framework/dist/enums/enum";
import { Enums, MultiLanguage } from "app/Enums";
import DomainAutoSelectBox from "components/common/DomainAutoSelectBox";
import Title from "components/common/Title";
import Form from "components/common/form/Form";
import FormWrapper from "components/common/form/FormWrapper";
import Grid from "components/grid/Grid";
import DDMultiLangPopup from "components/popup/MultiLangPopup";
import PopupProgramPopup from "components/popup/element/PopupProgramPopup";
import DynamicInput from "components/setting/DynamicInput";
import produce from "immer";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Collapse,
  FormCheck,
  FormControl,
  FormLabel,
  FormSelect,
  InputGroup,
  Row,
} from "react-bootstrap";
import { FaHistory, FaSearch } from "react-icons/fa";
import { IoLanguageOutline } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import DomainService from "services/DomainService";
import ElementService from "services/ElementService";
import MultiLangService from "services/MultiLangService";
import TrdService from "services/TrdService";
import useAppEnv from "utils/hook/useAppEnv";

const emptyDomain = {
  domainCd: null,
  domainNm: null,
  dataType: null,
  dataLength: null,
  entryDisType: "I",
  isRefresh: false,
};

/**
 * required Props : selectedElement
 *
 * title : 신규 or 편집에 따라 title 명 변경
 * selectHistory : historyYn on/off
 * selectedElement : Element List 에서 선택된 Element 객체 Data
 * isSelectedElement : ElementContents를 화면에 보여줄지 말지 정하는 props
 * isNewElement : 신규인지 편집인지 판별하는 props
 * updateElementList : Element 신규 및 편집시 list data를 변경하기 위한 메소드
 * props.isPopup : Popup화면에서 비활성화 되는 Content - title, updateElementList, selectHistory
 */
const ElementContents = ({
  title,
  selectHistory,
  selectedElement,
  isNewElement,
  updateElementList,
  setElementContentsYn,
  comboData,
  ...props
}) => {
  const [element, setElement] = useState(selectedElement);
  const [onDomainInfo, setOnDomainInfo] = useState(false);
  const [onSaveDomain, setOnSaveDomain] = useState(false);
  const [newDomain, setNewDomain] = useState(emptyDomain);
  const [radioValue, setRadioValue] = useState("defaultValue");

  const { app, env } = useAppEnv();

  const tableContainerRef = useRef([]);

  useEffect(() => {
    if (selectedElement) {
      setElement(selectedElement);
      ObjectUtils.isEmpty(props.fieldData) && setNewDomain(emptyDomain); // List에서 선택하는 경우에만 newDomain 초기화
    }
    isNewElement && setOnDomainInfo(false);
  }, [selectedElement, isNewElement]);

  useEffect(() => {
    const cpElement = {
      ...element,
      elementCd: props.fieldData?.physicalNm,
      elementNm: props.fieldData?.logicalNm,
      domain: props.fieldData?.domain || {},
    };
    setElement({ ...cpElement });
    props.setPopupElemnt && props.setPopupElemnt({ ...cpElement });
  }, [props.fieldData]);

  const dataMapping = [
    { id: "defaultValue", text: "고정 값" },
    { id: "findTable", text: "테이블 참조" },
    { id: "popupMapping", text: "팝업 매핑" },
  ];

  /**
   * Element state의 값을 변경하는 함수
   */
  const onChangeElement = (id, value, index) => {
    const newElement = produce(element, (draft) => {
      draft[id] = value;
    });
    setElement(newElement);

    if (props.setPopupElemnt) {
      props.setPopupElemnt(newElement);
    }
  };

  /**
   * Element 값을 변경하기위한 onChange 함수
   * @param {*} e
   * @param {*} data
   */
  const onChangeData = (e, data) => {
    const id = e.target.id;
    const value = e.target.value;
    const index = e.target.selectedIndexindex;
    onChangeElement(id, value, index);
  };

  /**
   * 신규 도메인 Data Type Setting 함수
   */
  const newDomainSetDataType = (value) => {
    if (Enums.getDataCase(value) === Enums.CaseType.String) {
      setNewDomain({
        ...newDomain,
        dataType: value,
        caseSensitive: "D",
      });
      props.setPopupDomain &&
        props.setPopupDomain({
          ...props.popupDomain,
          dataType: value,
          caseSensitive: "D",
        });
    } else if (
      Enums.getDataCase(value) === Enums.CaseType.Number ||
      Enums.getDataCase(value) === Enums.CaseType.Decimal
    ) {
      setNewDomain({
        ...newDomain,
        dataType: value,
        negativeType: "M",
      });
      props.setPopupDomain &&
        props.setPopupDomain({
          ...props.popupDomain,
          dataType: value,
          negativeType: "M",
        });
    } else {
      setNewDomain({
        ...newDomain,
        dataType: value,
      });
      props.setPopupDomain &&
        props.setPopupDomain({
          ...props.popupDomain,
          dataType: value,
        });
    }
  };

  /**
   * 엘리먼트 저장
   */
  const saveElement = () => {
    // Valid Check
    if (
      StringUtils.isEmpty(element.elementCd) ||
      StringUtils.isEmpty(element.elementNm)
    ) {
      Message.alert("필수값을 입력해주세요.", MessageType.ERROR);
      return;
    }

    // 신규도메인을 작성과 함께 저장하려는 경우 save 로직
    if (
      !StringUtils.isEmpty(newDomain.domainCd) ||
      !StringUtils.isEmpty(newDomain.domainNm) ||
      !StringUtils.isEmpty(newDomain.dataType) ||
      !StringUtils.isEmpty(newDomain.dataLength)
    ) {
      Message.confirm(
        "작성중인 Domain이 존재합니다. Domain을 저장 및 현재 Element에 적용하시겠습니까?",
        (ok) => {
          if (
            StringUtils.isEmpty(newDomain.domainCd) ||
            StringUtils.isEmpty(newDomain.domainNm) ||
            StringUtils.isEmpty(newDomain.dataType) ||
            ((StringUtils.equalsIgnoreCase(
              Enums.getDataCase(newDomain.dataType),
              Enums.CaseType.String
            ) ||
              StringUtils.equalsIgnoreCase(
                Enums.getDataCase(newDomain.dataType),
                Enums.CaseType.Decimal
              )) &&
              (ObjectUtils.isEmpty(newDomain.dataLength) ||
                ObjectUtils.isEmpty(newDomain.decimals) ||
                ObjectUtils.isEmpty(newDomain.formType)))
          ) {
            // 도메인cd,nm,datatype가 비어있거나 / decimalCase의 필수값인 datlength/decimals/formType가 비어있는경우
            Message.alert("필수값을 입력해주세요.", "error");
            return false;
          } else {
            DomainService.saveDomain({ ...newDomain, appEnvId: env.appEnvId })
              .then((res) => {
                if (!res.isError) {
                  setElement({ ...element, domain: res.data });
                  return res.data;
                } else {
                  Message.alert(
                    "Domain저장에 실패했습니다.",
                    MessageType.ERROR
                  );
                  return false;
                }
              })
              .then((res) => {
                if (res) {
                  elementSave({ ...element, domain: res });
                } else {
                  Message.alert(
                    "Element저장에 실패했습니다.",
                    MessageType.ERROR
                  );
                }
              });
          }
        },
        (no) => {
          return;
        }
      );
    } else {
      elementSave();
    }
  };

  /**
   * Element 저장
   * @param {*} elementParameter
   */
  const elementSave = async (elementParameter) => {
    const newElement = elementParameter || element;

    //중복 체크
    const dupCheck = await ElementService.getElement({
      ...newElement,
      appEnvId: env.appEnvId,
    });
    if (!ObjectUtils.isEmpty(dupCheck.data)) {
      if (
        !newElement.elementId ||
        newElement.elementId !== dupCheck.data.elementId
      ) {
        return DaafMessage.alert("이미 등록된 Element 입니다.", "warning");
      }
    }

    const result = await ElementService.saveElement({
      ...newElement,
      appEnvId: env.appEnvId,
    });

    if (result.isError) {
      Message.alert("저장에 실패했습니다.", MessageType.ERROR);
    } else {
      saveMultiLang(result.data.elementId);
      Message.alert("저장되었습니다.", MessageType.SUCCESS);
      !props.isPopup && updateElementList(props.elementParmas);
      // 도메인 신규 생성 저장 성공시 데이터 리셋
      if (isNewElement === true && props.elementContentsClear) {
        props.elementContentsClear();
      }
    }
  };

  /**
   * Element가 저장될때 현재 언어코드로 다국어를 저장시키는 함수
   * @param {Number} elementId
   */
  const saveMultiLang = (elementId) => {
    MultiLangService.getMultiLangList({
      tableKeyValue: elementId,
      tableNm: MultiLanguage.TableName.ELEMENT,
    })
      .then((result) => {
        const data = {
          appEnvId: env.appEnvId,
          tableNm: MultiLanguage.TableName.ELEMENT,
          tableKeyValue: elementId,
          langCd: UserUtils.getLanguage().toLowerCase(),
          columnNm: null,
          langText: null,
        };
        const multiLangData = [
          {
            ...data,
            columnNm: MultiLanguage.ColnumName.ELEMENT.fullNm,
            langText: element.elementNm,
          },
          {
            ...data,
            columnNm: MultiLanguage.ColnumName.ELEMENT.shortNm,
            langText: element.elementShortNm,
          },
          {
            ...data,
            columnNm: MultiLanguage.ColnumName.ELEMENT.description,
            langText: element.description,
          },
        ];
        return multiLangData.map((data) => {
          const existingData = result.data.find(
            (m) => m.columnNm === data.columnNm && m.langCd === data.langCd
          );
          return (data = { ...data, ...existingData, langText: data.langText });
        });
      })
      .then((res) => {
        MultiLangService.saveMultiLangList({ multiLangList: res });
      });
  };

  /**
   * 엘리먼트 삭제
   */
  const deleteElement = async () => {
    // trd Table Field에서 element를 참조하고 있는 경우 삭제 불가
    await TrdService.getTableFieldHasElement({ element: element }).then(
      (res) => {
        if (!ArrayUtils.isEmpty(res.data)) {
          Message.alert(
            "Element 를 참조중인 데이터가 존재하여 삭제할 수 없습니다. ",
            MessageType.ERROR
          );
          return;
        } else {
          const result = ElementService.deleteElement({
            ...element,
            tableName: MultiLanguage.TableName.ELEMENT,
          });
          if (result.isError) {
            Message.alert("삭제에 실패했습니다.", MessageType.ERROR);
          } else {
            Message.alert("삭제되었습니다.", MessageType.SUCCESS);
            setElementContentsYn(false);
            props.elementContentsClear && props.elementContentsClear();
            updateElementList(props.elementParmas);
          }
        }
      }
    );
  };

  /**
   * Combo code 값을 Text로 변경해주는 함수
   * @param {*} value
   * @returns
   */
  const translateCodeToText = (value, comboData) => {
    const result = comboData?.find((data) => data.id === value);
    return result?.text;
  };

  /**
   * 다국어 팝업
   */
  const multiLangPopup = () => {
    DaafPopup.open(
      <DDMultiLangPopup
        title={"Element"}
        tableNm={MultiLanguage.TableName.ELEMENT}
        keyValue={element.elementId}
        localData={{
          langCd: UserUtils.getLanguage(),
          fullNm: element.elementNm,
          shortNm: element.elementShortNm,
          description: element.description,
        }}
        columnNm={MultiLanguage.ColnumName.ELEMENT}
      />,
      {
        style: { content: { width: "100vh" } },
      }
    );
  };

  /**
   * 팝업프로그램 선택 팝업 Open
   */
  const onPopupProgramSelectPopup = () => {
    const doubleClickEvent = (data) => {
      const newElement = {
        ...element,
        popupProgramId: data.programId,
      };
      setElement(newElement);
      DaafPopup.close();
    };

    // 팝업 옵션
    const options = {
      keyDownEvent: false,
      style: {
        content: {
          width: "35%", //popup의 크기를 50% (default 60%)
        },
      },
    };

    DaafPopup.open(
      <PopupProgramPopup app={app} env={env} onCloseEvent={doubleClickEvent} />,
      options
    );
  };

  const saveNewDomain = async () => {
    await DomainService.saveDomain({
      ...newDomain,
      appEnvId: env.appEnvId,
    })
      .then((res) => {
        if (res.isError) {
          Message.alert("Domain 저장에 실패하였습니다.", "error");
          return false;
        } else {
          Message.alert("Domain 저장에 성공했습니다.", "success");
          return res.data;
        }
      })
      .then((res) => {
        if (res) {
          setElement({ ...element, domain: res });
          setNewDomain({
            ...emptyDomain,
            isRefresh: !newDomain.isRefresh,
          });
          setOnSaveDomain(false);
          props.setPopupElemnt({ ...element, domain: res });
          props.setPopupDomain(emptyDomain);
        }
      });
  };

  /**
   * Possible Entry Grid Column
   */
  const possibleEntryColumn = [
    {
      field: "sortSeq",
      headerName: "",
      style: {
        width: "1vh",
        height: "41px",
      },
      renderCell: (data, index) => {
        if (data.addButton) {
          return;
        } else {
          return (
            <div
              className="dd-manage-label-cell"
              style={{
                fontWeight: "bold",
                justifyContent: "center",
              }}
            >
              {index + 1}
            </div>
          );
        }
      },
    },
    {
      field: "entryValue",
      headerName: "Fix.Val",
      style: {
        width: "5vh",
        height: "41px",
      },
      renderCell: (data, index) => {
        if (data.addButton) {
          return data.addEntry;
        } else {
          return (
            <DynamicInput
              style={{
                display: "flex",
                alignItems: "center",
                height: "100%",
              }}
              value={data.entryValue || ""}
              id="entryValue"
              onBlur={(e) => {
                onBlurPossibleEntry(e, data, index);
              }}
            />
          );
        }
      },
    },
    {
      field: "entryText",
      headerName: "Short Desc",
      style: { width: "15vh", height: "41px" },
      renderCell: (data, index) => {
        if (data.addButton) {
          return <></>;
        } else {
          return (
            <DynamicInput
              style={{
                display: "flex",
                alignItems: "center",
                height: "100%",
              }}
              value={data.entryText}
              id="entryText"
              onBlur={(e) => {
                onBlurPossibleEntry(e, data, index);
              }}
            />
          );
        }
      },
    },
    {
      field: "deleteBtn",
      headerName: "삭제",
      style: { height: "41px", width: "3vh", alignItems: "center" },
      renderCell: (data, index) => {
        if (data.addButton) {
          return <div></div>;
        } else {
          return (
            <Button
              variant="outline-danger"
              size={"sm"}
              onClick={(e) => {
                onRemovePossibleEntry(data, index);
              }}
            >
              <MdDelete />
            </Button>
          );
        }
      },
    },
  ];

  /**
   * Possible Entry 오브젝트 추가 이벤트
   */
  const onAddPossibleEntry = () => {
    const prevPossibleEntry = [...element.possibleEntryList];
    const nObj = {
      appEnvId: env.appEnvId,
      elementId: element.elementId ? element.elementId : "",
      entryValue: "",
      entryText: "",
      langCd: UserUtils.getLanguage(),
      sortSeq: ArrayUtils.isEmpty(element.possibleEntryList)
        ? 1
        : element.possibleEntryList.length + 1,
    };

    setElement({
      ...element,
      possibleEntryList: [...prevPossibleEntry, nObj],
    });
  };

  /**
   * Possible Entry onBlur 이벤트
   * @param {*} e
   * @param {*} data
   */
  const onBlurPossibleEntry = (e, data, index) => {
    data[e.target.id] = e.target.value || null;
    data["sortSeq"] = index + 1;
    if (!StringUtils.isEmpty(e.target.value)) {
      const prevPossibleEntry = [...element.possibleEntryList];
      setElement({
        ...element,
        possibleEntryList: [...prevPossibleEntry],
      });
    }
  };

  /**
   * Possible Entry 오브젝트 삭제 이벤트
   */
  const onRemovePossibleEntry = (data, index) => {
    const newList = [...element.possibleEntryList];
    newList.splice(index, 1);
    setElement({ ...element, possibleEntryList: [...newList] });
  };

  return (
    <div
      className={`${
        !props.isPopup ? "contents-body dd-manage-contents show" : ""
      }`}
    >
      {!props.isPopup && (
        <Title
          selectHistory={selectHistory}
          breadcrumb={
            <div className="form-button-wrapper">
              <Button size="sm" onClick={saveElement}>
                저장
              </Button>
              {!isNewElement && (
                <Button variant="danger" size={"sm"} onClick={deleteElement}>
                  삭제
                </Button>
              )}
            </div>
          }
        >
          Element {isNewElement ? "신규 등록" : ""}
          {!isNewElement && (
            <button className="dd-title-history" onClick={selectHistory}>
              <FaHistory />
            </button>
          )}
        </Title>
      )}
      <FormWrapper title="Property">
        <Form className="form-body">
          <Row>
            <Col xs={6} className="mb-3">
              <Form.Label className={" required"}>Element Code</Form.Label>
              <Form.DInput
                id="elementCd"
                size="sm"
                // isInvalid={!element.elementCd}
                placeholder="Element 입력"
                value={element.elementCd || ""}
                onChange={(e) => onChangeData(e)}
              />
              {/* <FormControl.Feedback type="invalid">
                {"Element는 필수값입니다."}
              </FormControl.Feedback> */}
            </Col>
            <Col xs={6} className="mb-3">
              <Form.Label className={" required"}>Element 명</Form.Label>
              <InputGroup>
                <Form.DInput
                  id="elementNm"
                  size="sm"
                  // isInvalid={!element.elementNm}
                  placeholder="Element명 입력"
                  value={element.elementNm || ""}
                  onChange={(e) => onChangeData(e)}
                />
                {!isNewElement && (
                  <Button
                    className="dd-manage-button"
                    variant="outline-secondary"
                    size="sm"
                    onClick={() => {
                      multiLangPopup();
                    }}
                  >
                    <IoLanguageOutline />
                  </Button>
                )}
              </InputGroup>
              {/* <FormControl.Feedback
                type="invalid"
                style={{ display: !element.elementNm ? "block" : "none" }}
              >
                {"Element명은 필수값입니다."}
              </FormControl.Feedback> */}
            </Col>
            <Col xs={6} className="mb-3">
              <Form.Label>약어</Form.Label>
              <FormControl
                size="sm"
                id="elementShortNm"
                value={element.elementShortNm || ""}
                onChange={(e) => onChangeData(e)}
              />
            </Col>
            <Col xs={6} className="mb-3">
              <Form.Label>설명</Form.Label>
              <FormControl
                as={"textarea"}
                id="description"
                value={element.description || ""}
                onChange={(e) => onChangeData(e)}
              />
            </Col>
          </Row>
        </Form>
      </FormWrapper>
      <FormWrapper title="Data Type" style={{ marginBottom: "5px" }}>
        <Form className="form-body">
          <Row className="mb-3">
            <Col xs={12}>
              <Form.Label>Domain</Form.Label>
              <Row>
                <Col xs={6}>
                  <DomainAutoSelectBox
                    placeholder="선택"
                    value={
                      ObjectUtils.isEmpty(element.domain)
                        ? null
                        : element.domain
                    }
                    getLabel={(data) => `${data.domainNm} (${data.domainCd})`}
                    reset={newDomain.isRefresh}
                    onChange={(data) => {
                      setElement({
                        ...element,
                        domain: data,
                      });
                      props.setPopupElemnt &&
                        props.setPopupElemnt({
                          ...element,
                          domain: data,
                        });
                      setNewDomain(emptyDomain);
                      setOnSaveDomain(false);
                      props.setPopupDomain && props.setPopupDomain(emptyDomain);
                    }}
                  />
                </Col>
                <Col xs={6}>
                  <div
                    style={{ justifyContent: "flex-start" }}
                    className="form-button-wrapper"
                  >
                    {element?.domain && (
                      <Button
                        className="dd-manage-button"
                        variant="outline-secondary"
                        size="sm"
                        onClick={() => {
                          setOnDomainInfo(!onDomainInfo);
                          setOnSaveDomain(false);
                        }}
                      >
                        {"상세정보"}
                      </Button>
                    )}
                    <Button
                      size="sm"
                      variant="success"
                      onClick={(e) => {
                        setOnSaveDomain(!onSaveDomain);
                        setOnDomainInfo(false);
                      }}
                    >
                      {"도메인 등록"}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* New Domain */}
          {element.domain && (
            <Collapse in={onDomainInfo}>
              <Row>
                <Col className="mb-2" xs={3}>
                  <FormLabel>Domain Code</FormLabel>
                  <FormControl
                    size="sm"
                    readOnly={true}
                    value={element.domain.domainCd || ""}
                  />
                </Col>
                <Col xs={3}>
                  <Form.Label>Domain 명</Form.Label>
                  <FormControl
                    size="sm"
                    readOnly={true}
                    value={element.domain.domainNm || ""}
                  />
                </Col>
                {element.domain.dataType && (
                  <Col className="mb-3" xs={3}>
                    <Form.Label>Data Type</Form.Label>
                    <FormControl
                      size="sm"
                      readOnly={true}
                      value={element.domain.dataType.toUpperCase() || ""}
                    />
                  </Col>
                )}
                {element.domain.dataLength && (
                  <Col xs={3}>
                    <Form.Label>Data 길이</Form.Label>
                    <FormControl
                      size="sm"
                      readOnly={true}
                      value={element.domain.dataLength || ""}
                    />
                  </Col>
                )}
                {element.domain?.formType && (
                  <Col xs={3}>
                    <Form.Label>숫자 유형</Form.Label>
                    <FormControl
                      size="sm"
                      readOnly={true}
                      value={translateCodeToText(
                        element.domain.formType,
                        comboData.formType
                      )}
                    />
                  </Col>
                )}
                {element.domain?.caseSensitive && (
                  <Col xs={3}>
                    <Form.Label>대소문자 표현방식</Form.Label>
                    <FormControl
                      size="sm"
                      readOnly={true}
                      value={translateCodeToText(
                        element.domain.caseSensitive,
                        comboData.caseSensitive
                      )}
                    />
                  </Col>
                )}
                {element.domain.decimals && (
                  <Col className="mb-3" xs={3}>
                    <Form.Label>소수점 자리수</Form.Label>
                    <FormControl
                      size="sm"
                      readOnly={true}
                      value={element.domain.decimals || ""}
                    />
                  </Col>
                )}
                {/* {element.domain?.roundingPolicy && (
                  <Col xs={3}>
                    <Form.Label>반올림 유형</Form.Label>
                    <FormControl
                      size="sm"
                      readOnly={true}
                      value={translateCodeToText(
                        element.domain.roundingPolicy,
                        comboData.roundingPolicy
                      )}
                    />
                  </Col>
                )} */}
                {element.domain?.negativeType && (
                  <Col xs={3}>
                    <Form.Label>음수 표현 방법</Form.Label>
                    <FormControl
                      size="sm"
                      readOnly={true}
                      value={translateCodeToText(
                        element.domain.negativeType,
                        comboData.negativeType
                      )}
                    />
                  </Col>
                )}
              </Row>
            </Collapse>
          )}
        </Form>
      </FormWrapper>
      <Collapse in={onSaveDomain}>
        <div>
          <FormWrapper
            title={"신규 도메인"}
            button={
              <Button
                size="sm"
                onClick={(e) => {
                  setOnDomainInfo(false);
                  saveNewDomain();
                }}
              >
                {"저장"}
              </Button>
            }
            style={{ marginTop: "0", marginBottom: "0" }}
          >
            <Form className="form-body">
              {/* New Domain */}
              <Row>
                <Col className="mb-2" xs={3}>
                  <FormLabel className="required">Domain Code</FormLabel>
                  <FormControl
                    size="sm"
                    id="domainCd"
                    value={newDomain.domainCd || ""}
                    onChange={(e) => {
                      setNewDomain({ ...newDomain, domainCd: e.target.value });
                      props.setPopupDomain &&
                        props.setPopupDomain({
                          ...props.popupDomain,
                          domainCd: e.target.value,
                        });
                    }}
                  />
                </Col>
                <Col xs={3}>
                  <Form.Label className="required">Domain 명</Form.Label>
                  <FormControl
                    size="sm"
                    id="domainNm"
                    value={newDomain.domainNm || ""}
                    onChange={(e) => {
                      setNewDomain({ ...newDomain, domainNm: e.target.value });
                      props.setPopupDomain &&
                        props.setPopupDomain({
                          ...props.popupDomain,
                          domainNm: e.target.value,
                        });
                    }}
                  />
                </Col>
                <Col className="mb-3" xs={3}>
                  <Form.Label className="required">Data Type</Form.Label>
                  {!ArrayUtils.isEmpty(comboData?.dataType) && (
                    <FormSelect
                      size="sm"
                      id="dataType"
                      value={newDomain.dataType || ""}
                      onChange={(e) => newDomainSetDataType(e.target.value)}
                    >
                      <option label="선택" />;
                      {comboData?.dataType?.map((data, index) => {
                        return (
                          <option
                            key={index}
                            value={data.id}
                            label={`${data.text} (${data.id})`}
                          />
                        );
                      })}
                    </FormSelect>
                  )}
                </Col>
                {(StringUtils.equalsIgnoreCase(
                  Enums.getDataCase(newDomain.dataType),
                  Enums.CaseType.String
                ) ||
                  StringUtils.equalsIgnoreCase(
                    Enums.getDataCase(newDomain.dataType),
                    Enums.CaseType.Decimal
                  )) && (
                  <>
                    <Col xs={3}>
                      <Form.Label className="required">Data 길이</Form.Label>
                      <FormControl
                        size="sm"
                        id="domainLength"
                        type="number"
                        value={newDomain.dataLength || ""}
                        onChange={(e) => {
                          setNewDomain({
                            ...newDomain,
                            dataLength: e.target.value,
                          });
                          props.setPopupDomain &&
                            props.setPopupDomain({
                              ...props.popupDomain,
                              dataLength: e.target.value,
                            });
                        }}
                      />
                    </Col>
                    {(StringUtils.equalsIgnoreCase(
                      Enums.getDataCase(newDomain.dataType),
                      Enums.CaseType.Decimal
                    ) ||
                      StringUtils.equalsIgnoreCase(
                        Enums.getDataCase(newDomain.dataType),
                        Enums.CaseType.Number
                      )) && (
                      <>
                        <Col xs={3}>
                          <Form.Label className="required">
                            소수점 자리수
                          </Form.Label>
                          <FormSelect
                            size="sm"
                            id="decimals"
                            value={newDomain.decimals || ""}
                            onChange={(e) => {
                              setNewDomain({
                                ...newDomain,
                                decimals: e.target.value,
                              });
                              props.setPopupDomain &&
                                props.setPopupDomain({
                                  ...props.popupDomain,
                                  decimals: e.target.value,
                                });
                            }}
                          >
                            <option value={""} label="선택"></option>
                            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(
                              (num, index) => {
                                return (
                                  <option key={index} value={num} label={num} />
                                );
                              }
                            )}
                          </FormSelect>
                        </Col>
                        <Col xs={3}>
                          <Form.Label className="required">숫자유형</Form.Label>
                          <Form.Select
                            size="sm"
                            id="formType"
                            value={newDomain.formType || ""}
                            onChange={(e) => {
                              setNewDomain({
                                ...newDomain,
                                formType: e.target.value,
                              });
                              props.setPopupDomain &&
                                props.setPopupDomain({
                                  ...props.popupDomain,
                                  formType: e.target.value,
                                });
                            }}
                          >
                            {comboData.formType.map((data, index) => {
                              return (
                                <option
                                  key={index}
                                  value={data.id}
                                  label={data.text}
                                ></option>
                              );
                            })}
                          </Form.Select>
                        </Col>
                      </>
                    )}
                  </>
                )}
              </Row>
            </Form>
          </FormWrapper>
        </div>
      </Collapse>
      <FormWrapper title={"Data Mapping"}>
        <Form className="form-body">
          <Col className="col-6 mb-3">
            <div className="dd-manage-radio">
              {dataMapping.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <label className="radio-label" htmlFor={item.id}>
                      {item.text}
                    </label>
                    <FormCheck
                      style={{ marginRight: "15px" }}
                      inline
                      type="radio"
                      id={item.id}
                      checked={StringUtils.equalsIgnoreCase(
                        radioValue,
                        item.id
                      )}
                      onChange={(e) => setRadioValue(e.target.id)}
                    />
                  </React.Fragment>
                );
              })}
            </div>
          </Col>
          {StringUtils.equalsIgnoreCase(radioValue, dataMapping[0].id) && (
            <Row>
              <div
                style={{ maxHeight: "25vh", overflowY: "auto" }}
                ref={tableContainerRef}
              >
                <Grid
                  id="possibleEntryGrid"
                  style={{ borderCollapse: "separate", borderSpacing: "0" }}
                  bodyMaxHeight={"300px"}
                  columns={possibleEntryColumn}
                  rows={
                    element?.possibleEntryList && [
                      ...element.possibleEntryList,
                      {
                        addButton: true,
                        addEntry: (
                          <div
                            onClick={onAddPossibleEntry}
                            style={{
                              padding: "10px",
                              color: "dodgerblue",
                              fontWeight: "bold",
                              cursor: "pointer",
                            }}
                          >
                            + Entry 추가
                          </div>
                        ),
                      },
                    ]
                  }
                />
              </div>
            </Row>
          )}
          {StringUtils.equalsIgnoreCase(radioValue, dataMapping[1].id) && (
            <Row>
              <Col xs={6} className="mb-3">
                <Form.Label>{"Table"}</Form.Label>
                <Form.DInput
                  size="sm"
                  id="entryRefTable"
                  placeholder="참조 테이블 명 입력"
                  value={element.entryRefTable || ""}
                  onChange={(e) => onChangeData(e)}
                />
              </Col>
              <Col xs={6}>
                <Form.Label>{"Where"}</Form.Label>
                <Form.DInput
                  size="sm"
                  id="entryRefWhere"
                  placeholder="조건 입력"
                  value={element.entryRefWhere || ""}
                  onChange={(e) => onChangeData(e)}
                />
              </Col>
              <Col xs={6}>
                <Form.Label>{"Key Column"}</Form.Label>
                <Form.DInput
                  size="sm"
                  id="entryRefKey"
                  placeholder="콤보에 적용될 Key Column"
                  value={element?.entryRefKey || ""}
                  onChange={(e) => onChangeData(e)}
                />
              </Col>
              <Col xs={6}>
                <Form.Label>{"Text Column"}</Form.Label>
                <Form.DInput
                  size="sm"
                  id="entryRefValue"
                  placeholder="콤보에 적용될 Text Column"
                  value={element?.entryRefValue || ""}
                  onChange={(e) => onChangeData(e)}
                />
              </Col>
            </Row>
          )}
          {StringUtils.equalsIgnoreCase(radioValue, dataMapping[2].id) && (
            <Row>
              <Col xs={6} className="mb-3">
                <Form.Label>Popup PGM ID</Form.Label>
                <InputGroup>
                  <FormControl
                    id="popupProgramId"
                    size="sm"
                    value={element.popupProgramId || ""}
                    onChange={(e) => onChangeData(e)}
                  />
                  <Button
                    className="dd-manage-button"
                    variant="outline-secondary"
                    size="sm"
                    onClick={() => {
                      onPopupProgramSelectPopup();
                    }}
                  >
                    <FaSearch />
                  </Button>
                </InputGroup>
              </Col>
              <Col xs={6}>
                <Form.Label>Popup Size</Form.Label>
                <Form.Select
                  size="sm"
                  id="popupSize"
                  value={element.popupSize || ""}
                  onChange={(e) => {
                    onChangeData(e);
                  }}
                >
                  <option label="선택" />;
                  {props?.popupSizeComboList?.map((data, index) => {
                    return (
                      <option key={index} value={data.id} label={data.text} />
                    );
                  })}
                </Form.Select>
              </Col>
              <Col xs={6}>
                <Form.Label>Parameters ID</Form.Label>
                <FormControl
                  id="parametersId"
                  size="sm"
                  value={element.parametersId || ""}
                  onChange={(e) => onChangeData(e)}
                />
              </Col>
            </Row>
          )}
        </Form>
      </FormWrapper>
    </div>
  );
};

export default ElementContents;
