import {
  DaafModal,
  DaafModalTemplate,
  DaafPopup,
} from "@alpha/com.bizentro.daaf.front.framework";

/**
 * 간단한 팝업창을 만들기 위함 팝업 Wrapper
 * @param {Object} props
 * @param {String} props.title
 * @param {Function} props.onClickConfirm
 * @returns
 */
const SimplePopup = (props) => {
  return (
    <DaafModal>
      <DaafModal.Header title={props.title} />
      <DaafModal.Body>
        <DaafModalTemplate>{props.children}</DaafModalTemplate>
      </DaafModal.Body>
      <DaafModal.Footer>
        <DaafModal.Footer.Button
          onClick={props.onClickConfirm || DaafPopup.close}
        >
          확인
        </DaafModal.Footer.Button>
      </DaafModal.Footer>
    </DaafModal>
  );
};

export default SimplePopup;
