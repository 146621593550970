import { FormLabel } from "react-bootstrap";
import useAppEnv from "utils/hook/useAppEnv";

const WorkspaceControl = () => {
  const { app, env } = useAppEnv();

  return (
    <div className="header-component" style={{ marginLeft: "15px" }}>
      <FormLabel className="mb-0" style={{ fontSize: "1.2rem" }}>
        {app.appNm + " - " + env.envName}
      </FormLabel>
    </div>
  );
};

export default WorkspaceControl;
