import GroupUserSetting from "./GroupUserSetting";
import AdminSetting from "./AdminSetting";
import { useState } from "react";
import { createContext } from "react";
import { UserService } from "@alpha/com.bizentro.daaf.front.framework";
import { useEffect } from "react";
import { Form } from "react-bootstrap";

export const AppSettingContext = createContext({
  isLoading: false,
  setIsLoading: () => {},
  appUserList: [],
  setAppUserList: () => {},
});

const AppGroupSetting = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [appUserList, setAppUserList] = useState([]);

  useEffect(() => {
    getAppUserList();
  }, []);

  /**
   * APP 내 사용자 호출
   * @return setAppUserList
   */
  const getAppUserList = () => {
    // 사용자 목록은 App 그룹설정 및 관리자 설정에서도 사용하기 때문에 context로 설정
    UserService.getUserList({ exceptSuper: "N" }, (res) => {
      setAppUserList(res.data);
    });
  };

  const contextValue = {
    isLoading,
    setIsLoading,
    appUserList,
    setAppUserList,
  };

  return (
    <AppSettingContext.Provider value={contextValue}>
      <GroupUserSetting />
      <AdminSetting />
    </AppSettingContext.Provider>
  );
};

export default AppGroupSetting;
