/**
 * App 전체를 감싸는 컴포넌트
 * @returns
 */

import { useSelector } from "react-redux";
import MainHeader from "./main/header";

const { AppContext } = require("app/AppProvider");
const { useContext, useEffect } = require("react");
const { default: Navigation } = require("./main/nav");
const { Outlet } = require("react-router-dom");

const MainWrapper = () => {
  const { menu, navMini, setMenu, setNavMini } = useContext(AppContext);
  const { isFullScreen } = useSelector((state) => state.main);

  return (
    <div className={`template ${navMini ? "mini" : ""}`}>
      {!isFullScreen ? (
        <>
          <Navigation
            onClickMenu={setMenu}
            mini={navMini}
            setNavMini={setNavMini}
          />

          <MainHeader />
        </>
      ) : (
        <></>
      )}

      <div className={`body-wrapper ${isFullScreen ? "full" : ""}`}>
        <div className="contents-wrapper">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default MainWrapper;
