import {
  ArrayUtils,
  CommonUtils,
  DaafPopup,
  ObjectUtils,
  StringUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import { Enums } from "app/Enums";
import TableFieldDetailPopup from "components/popup/trd/TableFieldDetailPopup";
import TableRelationDetailPopup from "components/popup/trd/TableRelationDetailPopup";
import TrdQueryStateGrid from "components/popup/trd/TrdQueryStateGrid";
import { useCallback, useEffect, useState } from "react";
import { Accordion, AccordionBody, Button, Table } from "react-bootstrap";
import { FaEdit, FaPlus } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { stopEvent } from "utils/CommonUtils";
import TrdUtil from "utils/TrdUtils";

/**
 * 로그 비교해서 달라진것만 보여주는 컴포넌트
 * @param {*} param0
 * @returns
 */
const TrdUpdateViewer = ({ trdHistoryInfo, prevTrd, viewMode, ...props }) => {
  const [changedTrdData, setChangedTrdData] = useState([]);
  const [changedTable, setChangedTable] = useState([]);
  const [addedTable, setAddedTable] = useState([]);
  const [deletedTable, setDeletedTable] = useState([]);
  const [changedAreaData, setChangedAreaData] = useState([]);

  useEffect(() => {
    if (trdHistoryInfo && prevTrd) init();
  }, [trdHistoryInfo, prevTrd]);

  const init = () => {
    const { changedTrd, changedTableList, deletedTable, addedTable } =
      TrdUtil.getChangedData(trdHistoryInfo, prevTrd);
    setAddedTable(addedTable);
    setChangedTable(changedTableList);
    setDeletedTable(deletedTable);
    setChangedTrdData(changedTrd);
  };

  const isTableDataChanged = (obj = {}) => {
    const o = { ...obj };
    delete o.trdTableField;
    delete o.relation;
    delete o.tableNm;
    if (ObjectUtils.isEmpty(o)) return false;
    else return true;
  };

  /**
   * 변경된 테이블 렌더링
   * @returns
   */
  const renderChangedTable = () => {
    return (
      <>
        {!ArrayUtils.isEmpty(changedTable) && (
          <Accordion
            defaultActiveKey={"0"}
            className="mb-3 changed-table"
            alwaysOpen
          >
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <FaEdit /> 변경된 테이블
              </Accordion.Header>
              <Accordion.Body>
                <Accordion alwaysOpen>
                  {changedTable.map((table, index) => {
                    return (
                      <Accordion.Item eventKey={index} key={table.tableNm}>
                        <Accordion.Header>{table.tableNm}</Accordion.Header>
                        <Accordion.Body>
                          {isTableDataChanged(table) && (
                            <Table className="">
                              <thead>
                                <tr>
                                  <th></th>
                                  <th className="contents-header">변경 전</th>
                                  <th></th>
                                  <th className="contents-header">변경 후</th>
                                </tr>
                              </thead>
                              <tbody>
                                {Object.keys(table).map((fieldKey) => {
                                  if (
                                    StringUtils.includes(fieldKey, [
                                      "trdTableField",
                                      "tableNm",
                                      "fieldList",
                                    ])
                                  )
                                    return null;
                                  if (
                                    typeof table[fieldKey].prevData !==
                                      "object" &&
                                    typeof table[fieldKey].currentData
                                  ) {
                                    return (
                                      <tr key={fieldKey}>
                                        <th>{Enums.TrdKeyName[fieldKey]}</th>
                                        <th>{table[fieldKey].prevData}</th>
                                        <th className="change-arrow">▶</th>
                                        <th>{table[fieldKey].currentData}</th>
                                      </tr>
                                    );
                                  }
                                })}
                              </tbody>
                            </Table>
                          )}

                          {table.trdTableField && (
                            <Accordion>
                              <Accordion.Item>
                                <Accordion.Header>
                                  {table.tableNm} 필드 변경 사항
                                </Accordion.Header>
                                <Accordion.Body>
                                  {!ArrayUtils.isEmpty(
                                    table.trdTableField.addedField
                                  ) && (
                                    <>
                                      <div
                                        className="mb-1"
                                        style={{
                                          color: "dodgerblue",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        추가된 필드
                                      </div>
                                      <Table>
                                        <tbody>
                                          {table.trdTableField.addedField?.map(
                                            (df, index) => {
                                              return (
                                                <tr key={index}>
                                                  <td>
                                                    {df.element
                                                      ? df.element.elementCd
                                                      : df.physicalNm}{" "}
                                                    컬럼 추가 되었습니다.
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}
                                        </tbody>
                                      </Table>
                                    </>
                                  )}
                                  {!ArrayUtils.isEmpty(
                                    table.trdTableField.deletedField
                                  ) && (
                                    <>
                                      <div
                                        className="mb-1"
                                        style={{
                                          color: "tomato",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        삭제된 필드
                                      </div>
                                      <Table>
                                        <tbody>
                                          {table.trdTableField.deletedField?.map(
                                            (df, index) => {
                                              return (
                                                <tr key={index} xs={12}>
                                                  <td>
                                                    {df.element
                                                      ? df.element.elementCd
                                                      : df.physicalNm}{" "}
                                                    컬럼 삭제되었습니다.
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}
                                        </tbody>
                                      </Table>
                                    </>
                                  )}
                                  {!ArrayUtils.isEmpty(
                                    table.trdTableField.updatedField
                                  ) && (
                                    <>
                                      <div
                                        className="mb-1"
                                        style={{
                                          fontWeight: "bold",
                                        }}
                                      >
                                        상태가 변경된 필드
                                      </div>
                                      <Table>
                                        <tbody>
                                          {table.trdTableField.updatedField.map(
                                            (field, index) => {
                                              return Object.keys(
                                                field.changed
                                              ).map(
                                                (changedFieldKey, index) => {
                                                  return (
                                                    <tr key={index} xs={12}>
                                                      {index === 0 && (
                                                        <td
                                                          rowSpan={
                                                            Object.keys(
                                                              field.changed
                                                            ).length
                                                          }
                                                        >
                                                          {field.fieldNm} 컬럼
                                                        </td>
                                                      )}

                                                      <td>
                                                        {
                                                          Enums.TrdKeyName[
                                                            changedFieldKey
                                                          ]
                                                        }
                                                        {`  "${field.changed[changedFieldKey].prevData}" 에서 "${field.changed[changedFieldKey].currentData}" 으로 변경 되었습니다.`}
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                              );
                                            }
                                          )}
                                        </tbody>
                                      </Table>
                                    </>
                                  )}
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          )}
                          {table.relation && (
                            <Accordion>
                              <Accordion.Item>
                                <Accordion.Header>
                                  {table.tableNm} 관계 변경 사항
                                </Accordion.Header>
                                <Accordion.Body></Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}
      </>
    );
  };

  /**
   * 추가된 테이블,삭제된 테이블 컬럼 상세
   */
  const onClickFieldDetail = useCallback((event, table) => {
    stopEvent(event);

    DaafPopup.open(<TableFieldDetailPopup table={table} />, {
      style: { content: { width: "500px" } },
    });
  }, []);

  /**
   * 추가된 테이블,삭제된 테이블 컬럼 상세
   */
  const onClickRelationDetail = useCallback((event, table, isDelete) => {
    stopEvent(event);

    DaafPopup.open(<TableRelationDetailPopup table={table} />, {
      style: { content: { width: "500px" } },
    });
  }, []);

  /**
   * 엔티티 아코디언 렌더링
   * 추가된 테이블, 삭제된 테이블 공통
   * @param {*} className
   * @param {*} title
   * @param {*} Icon
   * @param {*} entity
   * @returns
   */
  const renderEntity = (className, title, Icon, entity) => {
    const isDelete = StringUtils.equalsIgnoreCase(className, "deleted-table");
    return (
      <Accordion className={className} alwaysOpen>
        <Accordion.Item>
          <Accordion.Header>
            <Icon /> {title}
          </Accordion.Header>
          <AccordionBody>
            {entity.map((table) => {
              return (
                <Accordion key={table.tablePhysicalNm} alwaysOpen>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>{table.tablePhysicalNm}</Accordion.Header>
                    <Accordion.Body>
                      <Table>
                        <thead>
                          <tr>
                            <th />
                            <th>{table.tablePhysicalNm}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(table).map((tableKey) => {
                            if (
                              StringUtils.includes(tableKey, [
                                ...Enums.TrdWhoColumns,
                                "trdMst",
                                "tableMstHistoryId",
                              ])
                            )
                              return <></>;
                            let value = table[tableKey];
                            if (
                              StringUtils.equalsIgnoreCase(
                                tableKey,
                                "trdTableField"
                              )
                            ) {
                              value = (
                                <Button
                                  size="sm"
                                  onClick={(e) => onClickFieldDetail(e, table)}
                                >
                                  테이플 컬럼 보기
                                </Button>
                              );
                            } else if (
                              StringUtils.equalsIgnoreCase(tableKey, "trdArea")
                            ) {
                              value = table[tableKey].areaNm;
                            } else if (
                              StringUtils.equalsIgnoreCase(tableKey, "relation")
                            ) {
                              if (ArrayUtils.isEmpty(table[tableKey])) {
                                return <></>;
                              }

                              value = (
                                <Button
                                  size="sm"
                                  variant="success"
                                  onClick={(e) =>
                                    onClickRelationDetail(e, table, isDelete)
                                  }
                                >
                                  테이플 관계 보기
                                </Button>
                              );
                            }
                            return (
                              <tr key={tableKey}>
                                <td style={{ verticalAlign: "middle" }}>
                                  {Enums.TrdKeyName[tableKey]}
                                </td>
                                <td>{value}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              );
            })}
          </AccordionBody>
        </Accordion.Item>
      </Accordion>
    );
  };

  return (
    <div className="trd-history-wrapper">
      <Table className="main-table">
        <thead>
          <tr>
            <th className="key-header" />
            <th className="contents-header">
              {prevTrd?.trdNm}{" "}
              {CommonUtils.getDate(prevTrd?.insertDt, "datetime")}
            </th>
            <th />
            <th className="contents-header">
              {trdHistoryInfo?.trdNm}{" "}
              {CommonUtils.getDate(trdHistoryInfo?.insertDt, "datetime")}
            </th>
          </tr>
        </thead>
        <tbody>
          {changedTrdData.map((trdData) => {
            return (
              <tr key={trdData.key}>
                <td> {Enums.TrdKeyName[trdData.key]}</td>
                <td>{trdData.prevData}</td>
                <th className="change-arrow">▶</th>
                <td>{trdData.currentData}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {!ArrayUtils.isEmpty(trdHistoryInfo?.queryList) && (
        <TrdQueryStateGrid
          editable={false}
          queryList={trdHistoryInfo.queryList}
          allStateViewer={true}
        />
      )}
      {renderChangedTable()}
      {!ArrayUtils.isEmpty(addedTable) &&
        renderEntity("added-table", "추가된 테이블", FaPlus, addedTable)}
      {!ArrayUtils.isEmpty(deletedTable) &&
        renderEntity("deleted-table", "삭제된 테이블", MdDelete, deletedTable)}
    </div>
  );
};

export default TrdUpdateViewer;
