import {
  ArrayUtils,
  DaafLogo,
  DaafMessage,
  StringUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import { useState } from "react";
import { FaBook, FaUserCog } from "react-icons/fa";
import { FaDatabase } from "react-icons/fa6";
import { ImTable2 } from "react-icons/im";
import { IoIosHelpCircleOutline, IoMdSettings } from "react-icons/io";
import MenuDivider from "./MenuDivider";
import ContentsMenu from "./ContentsMenu";
import ContextMenuForMini from "./ContextMenuForMini";
import { useNavigate } from "react-router-dom";
import { AppPath } from "../../../app/Enums";
import { RiBook2Line } from "react-icons/ri";
import { IoDocumentOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { trdInit } from "store/actions/TrdAction";

const Navigation = ({ onClickMenu: setMenu, mini, setNavMini, ...props }) => {
  const [selectedMenu, setSelectedMenu] = useState({});
  const { tableList, areaList, info } = useSelector((state) => state.trd);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  /**
   * 컨텍스트 메뉴 선택
   * 미니 모드에서는 사이드바에서 선택된 메뉴를 뜻함
   */
  const onClickNavMenu = (menu) => {
    if (menu.text === selectedMenu.text) {
      setSelectedMenu({});
    } else {
      setSelectedMenu(menu);
    }
  };

  const onCreateNewTrd = (e) => {
    if (!StringUtils.isEmpty(info.trdUid) || !ArrayUtils.isEmpty(tableList)) {
      return DaafMessage.confirm(
        "진행 중인 TRD 작업이 있습니다. 진행하시겠습니까?",
        () => {
          dispatch(trdInit());
          navigate(AppPath.TRD.Editor.url);
        }
      );
    } else {
      navigate(AppPath.TRD.Editor.url);
    }
  };

  return (
    <div className={`left-nav ${mini ? "mini" : ""}`}>
      <div className="main-logo">
        <img src={DaafLogo} alt="DAAF LOGO" />
        <div className={`title-wrapper ${mini ? "mini" : ""}`}>
          <span className="title semi">DAAF</span>
          <span className="title"> DATA MANAGEMENT</span>
        </div>
      </div>
      <div className={`nav-area ${mini ? "mini" : ""}`}>
        <div className="menu-contents">
          <MenuDivider
            text={"Management"}
            mini={mini}
            onClickArrow={(e) => setNavMini(!mini)}
          />

          <ContentsMenu
            id={"dataDictionary"}
            mini={mini}
            onClickNavMenu={onClickNavMenu}
            selectedMenu={selectedMenu}
            Icon={IoDocumentOutline}
            text={"Domain"}
            onClick={(id) => {
              setSelectedMenu({
                id,
              });
              navigate(AppPath.Domain.Edit.url);
            }}
          />
          <ContentsMenu
            id={"dataElement"}
            mini={mini}
            onClickNavMenu={onClickNavMenu}
            selectedMenu={selectedMenu}
            Icon={RiBook2Line}
            text={"Element"}
            onClick={(id) => {
              setSelectedMenu({
                id,
              });
              navigate(AppPath.DataElement.Edit.url);
            }}
          />

          <ContentsMenu
            mini={mini}
            onClickNavMenu={onClickNavMenu}
            selectedMenu={selectedMenu}
            Icon={ImTable2}
            text={"TRD"}
            subMenus={[
              {
                onClick: onCreateNewTrd,
                text: "신규 TRD 생성",
              },
              {
                onClick: () => {
                  navigate(AppPath.TRD.Editor.url);
                },
                text: "TRD 편집",
              },
              {
                onClick: () => {
                  navigate(AppPath.TRD.List.url);
                },
                text: "TRD 목록",
              },
              {
                onClick: () => {
                  navigate(AppPath.TRD.History.url);
                },
                text: "TRD 이력",
              },
            ]}
          />
          <ContentsMenu
            id={"dataStudio"}
            mini={mini}
            onClickNavMenu={() => {
              setSelectedMenu({
                id: "dataStudio",
              });
              navigate(AppPath.Studio.Main.url);
            }}
            selectedMenu={selectedMenu}
            Icon={FaDatabase}
            text={"Data Studio"}
          />
        </div>

        <div className="setting-contents">
          <ContentsMenu
            mini={mini}
            onClickNavMenu={onClickNavMenu}
            selectedMenu={selectedMenu}
            Icon={IoMdSettings}
            text={"Setting"}
            id={"Setting"}
            onClick={() => {
              setSelectedMenu({
                id: "Setting",
              });
              navigate(AppPath.Setting.UserSet.url);
            }}
          />
        </div>
      </div>
      {mini && !ArrayUtils.isEmpty(selectedMenu.subMenus) && (
        <ContextMenuForMini
          menu={selectedMenu}
          menuInitial={() => setSelectedMenu({})}
        />
      )}
    </div>
  );
};

export default Navigation;
