const CommandLine = ({ ...props }) => {
  return (
    <div className="command-line" style={props.style}>
      {props.children}
    </div>
  );
};

const Right = ({ ...props }) => {
  return (
    <div className="right-side" {...props}>
      {props.children}
    </div>
  );
};

export default Object.assign(CommandLine, {
  Right,
});
