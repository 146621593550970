import {
  CommonUtils,
  ObjectUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import { Enums } from "app/Enums";
import { TreeItem } from "components/common/TableTree";
import Grid from "components/grid/Grid";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FaDatabase, FaRegCalendarAlt, FaTable } from "react-icons/fa";
import { TbNumbers } from "react-icons/tb";
import { TiSortAlphabeticallyOutline } from "react-icons/ti";

/**
 * 해당 TRD MST History의 상세 정보를 보여주는 컴포넌트
 * @param {*} param0
 * @returns
 */
const TrdHistoryViewer = ({ trdHistoryInfo, ...props }) => {
  const [tableInfo, setTableInfo] = useState(null);

  useEffect(() => {}, []);

  const onClickTreeItem = (item) => {
    setTableInfo(item);
  };

  const columns = [
    {
      field: "Element",
      headerName: "Element Code",
      renderCell: (data) => {
        return data.element.elementCd;
      },
    },
    {
      field: "fieldId",
      headerName: "필드명",
      renderCell: (data) => {
        return data.element.elementNm;
      },
    },
    {
      field: "pkYn",
      headerName: "PK 여부",
    },
    {
      field: "defaultValue",
      headerName: "기본값",
    },

    {
      field: "updtUserId",
      headerName: "최종 수정자",
    },
    {
      field: "updtDt",
      headerName: "최종 수정일",
      renderCell: (data) => {
        return CommonUtils.getDate(data.updtDt, "datetime");
      },
    },
  ];
  return (
    <>
      <Row style={{ height: "calc(100% - 70px)" }}>
        <Col
          xs={3}
          style={{
            height: "100%",
            overflow: "auto",
            borderRight: "1px solid lightgray",
          }}
        >
          {!ObjectUtils.isEmpty(trdHistoryInfo) && (
            <TreeItem
              name={trdHistoryInfo.trdNm}
              level={0}
              Icon={FaDatabase}
              child={trdHistoryInfo.tableList.map((table, index) => (
                <TreeItem
                  key={table.tableMstHistoryId}
                  name={table.tablePhysicalNm}
                  item={table}
                  level={1}
                  Icon={FaTable}
                  onClick={onClickTreeItem}
                  child={table.trdTableField.map((field) => {
                    const dataType = field.element.domain.dataType;
                    let Icon = null;
                    switch (Enums.getDataCase(dataType)) {
                      case Enums.CaseType.String:
                        Icon = TiSortAlphabeticallyOutline;
                        break;
                      case Enums.CaseType.Number:
                      case Enums.CaseType.Decimal:
                        Icon = TbNumbers;
                        break;
                      case Enums.CaseType.Date:
                        Icon = FaRegCalendarAlt;
                        break;
                      default:
                        break;
                    }

                    return (
                      <TreeItem
                        key={field.fieldHistoryId}
                        name={field.element.elementNm}
                        Icon={Icon}
                        level={2}
                      />
                    );
                  })}
                />
              ))}
            />
          )}
        </Col>
        <Col
          xs={9}
          style={{
            height: "100%",
            paddingLeft: "15px",
          }}
        >
          <Grid columns={columns} rows={tableInfo?.trdTableField} />
        </Col>
      </Row>
    </>
  );
};

export default TrdHistoryViewer;
