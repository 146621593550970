import {
  ArrayUtils,
  DaafEnums,
  DaafMessage,
  StringUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import Grid from "components/grid/Grid";
import { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import SettingService from "services/SettingService";
import useAppEnv from "utils/hook/useAppEnv";
import { AppSettingContext } from ".";
import DynamicInput from "../DynamicInput";
import DynamicSelect from "../DynamicSelect";

function AdminSetting() {
  const [adminList, setAdminList] = useState([]);
  const { appUserList } = useContext(AppSettingContext);
  const { app, env } = useAppEnv();

  useEffect(() => {
    getManagerList();
  }, []);

  /**
   * 관리자 목록 호출
   */
  const getManagerList = () => {
    SettingService.getAdminList({
      appEnvId: env.appEnvId,
    }).then((res) => {
      setAdminList(res.data);
    });
  };

  /**
   * 관리자 추가 오브젝트 생성
   */
  const onAddAdmin = () => {
    setAdminList([
      ...adminList,
      {
        adminId: "",
        adminName: "",
        adminEmail: "",
        groupType: "admin",
      },
    ]);
  };

  /**
   *
   */
  const onDeleteAdmin = (e, data, index) => {
    SettingService.deleteAdmin({ ...data }).then((res) => {
      const list = [...adminList];
      list.splice(index, 1);
      setAdminList(list);
      DaafMessage.alert("삭제 되었습니다.", DaafEnums.MessageType.SUCCESS);
    });
  };

  /**
   * 매니저 등록 또는 수정시 onBlur 이벤트
   * @param {*} e
   * @param {*} data
   * @param {Number} index
   */
  const onBlurAdminSet = (e, data, index) => {
    if (!e.target.value) return false;
    const body = {
      ...data,
      [e.target.id]: e.target.value,
      appEnvId: env.appEnvId,
    };
    if (
      StringUtils.equalsIgnoreCase(e.target.id, "appGroupUserId") &&
      StringUtils.isEmpty(e.target.value)
    ) {
      const list = [...adminList];
      list.splice(index, 1);
      setAdminList(list);
      return false;
    }
    // return console.log(body);
    SettingService.saveAppAdmin(body).then((res) => {
      getManagerList();
    });
  };

  const managerGroupColumns = [
    {
      field: "adminId",
      name: "관리자 아이디",
      style: {
        width: "200px",
        paddingLeft: "15px",
      },
      renderCell: (data, index) => {
        if (data.addButton) {
          return <div>{data.groupName}</div>;
        }
        return (
          <DynamicSelect.User
            value={data.userId}
            onChange={(opt) => {
              onBlurAdminSet(
                {
                  target: {
                    id: "appGroupUserId",
                    value: ArrayUtils.isArray(opt) ? opt[0].userId : opt.userId,
                  },
                },
                data,
                index
              );
            }}
            initialLoad={data.userId ? false : true}
          />
        );
      },
    },
    {
      field: "adminName",
      name: "관리자 명",
      style: {
        width: "150px",
      },
      renderCell: (data, index) => {
        return <>{data.userNm}</>;
      },
    },
    {
      field: "adminMain",
      name: "이메일",

      renderCell: (data, index) => {
        return <>{data.userEmail}</>;
      },
    },
    {
      field: "adminManager",
      name: "관리",
      style: {
        width: "150px",
        textAlign: "center",
      },
      renderCell: (data, index) => {
        if (!data.addButton) {
          return (
            <Button
              size="sm"
              variant="outline-danger"
              onClick={(e) => onDeleteAdmin(e, data, index)}
            >
              <MdDelete />
            </Button>
          );
        }
      },
    },
  ];

  return (
    <>
      <div className="setting-title">
        <div className="title">관리자 그룹 설정</div>
        <div className="add-button"></div>
      </div>
      <Grid
        columns={managerGroupColumns}
        rows={[
          ...adminList,
          {
            addButton: true,
            groupName: (
              <div
                onClick={onAddAdmin}
                style={{
                  padding: "10px",
                  color: "dodgerblue",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                {" "}
                + 관리자 추가
              </div>
            ),
          },
        ]}
      />
    </>
  );
}

export default AdminSetting;
