import React from "react";
import { ReactFlowProvider } from "reactflow";
import TrdBuilder from "./TrdBuilder";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearFullScreen } from "store/actions/MainAction";
import { initCommand } from "store/actions/CommandAction";

const TrdEditor = () => {
  const { isFullScreen } = useSelector((state) => state.main);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initCommand());
  }, []);

  useEffect(() => {
    return () => {
      //화면 바뀔때 풀스크린이면 풀스크린 해제
      if (isFullScreen) {
        dispatch(clearFullScreen());
      }
    };
  }, [isFullScreen]);

  return (
    <ReactFlowProvider>
      <TrdBuilder />
    </ReactFlowProvider>
  );
};

export default TrdEditor;
