const { FaAngleDoubleRight, FaAngleDoubleLeft } = require("react-icons/fa");

/**
 * 메뉴 구분선
 * @param {*} param0
 * @returns
 */
const MenuDivider = ({ text, onClickArrow, mini, ...props }) => {
  if (mini) {
    return (
      <div className={`divider mini`}>
        <div className="divider-line" />
        <div className="divider-text arrow" onClick={onClickArrow}>
          <FaAngleDoubleRight />
        </div>
        <div className="divider-line" />
      </div>
    );
  } else {
    return (
      <div className="divider">
        <div className="divider-line" />
        {text ? (
          <div className="divider-text">{text}</div>
        ) : (
          <div className="divider-line" />
        )}

        <div className="divider-line" />
        {onClickArrow && (
          <div className="divider-text arrow" onClick={onClickArrow}>
            <FaAngleDoubleLeft />
          </div>
        )}
      </div>
    );
  }
};

export default MenuDivider;
