import React from "react";
import Title from "./Title";

const Template = ({ title, breadcrumb, className, hidden, ...props }) => {
  return (
    <div
      className={`contents-body ${className} ${hidden ? "hidden" : ""}`}
      style={{ zIndex: 0 }}
    >
      <Title breadcrumb={breadcrumb}>{title}</Title>
      <div className="body-wrapper">{props.children}</div>
    </div>
  );
};

export default Template;
