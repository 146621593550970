import React from "react";
import {
  DaafModal,
  DaafModalTemplate,
  DaafMessage,
  UserService,
  UserUtils,
  StringUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import { useState } from "react";
import { useEffect } from "react";
import Form from "components/common/form/Form";
import Grid from "components/grid/Grid";
import { Button, InputGroup } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import SettingService from "services/SettingService";
import Message from "@alpha/com.bizentro.daaf.front.framework/dist/component/Message";

const AddGroupUserPopup = ({ groupId, appEnvId, ...props }) => {
  const [selectedUser, setSelectedUser] = useState("");
  const [appUserList, setAppUserList] = useState([]);

  const [groupUserList, setGroupUserList] = useState([]);

  useEffect(() => {
    getAppUserList();
    getUserList();
  }, []);

  /**
   * APP 내 사용자 호출
   */
  const getAppUserList = () => {
    UserService.getUserList({}, (res) => {
      setAppUserList(res.data);
    });
  };

  const getUserList = () => {
    SettingService.getAppGroupUserList({ groupId, appEnvId }).then((res) => {
      setGroupUserList(res.data);
    });
  };

  const addUserToAppGroup = () => {
    if (StringUtils.isEmpty(selectedUser))
      return Message.alert("사용자를 선택해주세요.", "warning");
    const body = {
      groupId,
      appEnvId,
      appGroupUserId: selectedUser,
      userId: UserUtils.getId(),
    };
    SettingService.addUserToAppGroup(body).then((res) => {
      const userInfo = appUserList.find((user) => user.userId === selectedUser);
      res.data.userMastRec = userInfo;
      setGroupUserList([...groupUserList, res.data]);
    });
  };

  const onClickDelete = (e, data) => {
    if (e) e.stopPropagation();

    DaafMessage.confirm("삭제 하시겠습니까?", async () => {
      SettingService.deleteAppGroupUser(data).then((res) => {
        const userList = [...groupUserList];
        const index = userList.findIndex(
          (user) => user.groupDtlId === data.groupDtlId
        );
        userList.splice(index, 1);
        setGroupUserList([...userList]);
      });
    });
  };

  const columns = [
    {
      field: "userNm",
      headerName: "사용자 명",
      renderCell: (data, index) => {
        return data.userNm;
      },
    },
    {
      field: "userDelete",
      headerName: "삭제",
      renderCell: (data, index) => {
        return (
          <Button
            size="sm"
            variant="outline-danger"
            onClick={(e) => onClickDelete(e, data)}
          >
            <MdDelete />
          </Button>
        );
      },
    },
  ];

  return (
    <DaafModal>
      <DaafModal.Header title={"사용자 추가"} />
      <DaafModal.Body>
        <DaafModalTemplate className="add-user-wrapper">
          <Form.Label className="required">사용자 검색</Form.Label>
          <InputGroup className="mb-3">
            <Form.Select
              style={{ width: "100%" }}
              onChange={(e) => {
                setSelectedUser(e.target.value);
              }}
              defaultValue={selectedUser}
            >
              <option>선택</option>
              {appUserList.map((user) => {
                return (
                  <option value={user.userId} key={user.userMastRecId}>
                    {user.userNm}
                  </option>
                );
              })}
            </Form.Select>
            <Button size="sm" onClick={addUserToAppGroup}>
              추가
            </Button>
          </InputGroup>

          <Form.Label>사용자 목록</Form.Label>
          <div className="user-list">
            <Grid columns={columns} rows={groupUserList} />
          </div>
        </DaafModalTemplate>
      </DaafModal.Body>
      <DaafModal.Footer>
        <DaafModal.Footer.Button>저장</DaafModal.Footer.Button>
      </DaafModal.Footer>
    </DaafModal>
  );
};

export default AddGroupUserPopup;
