import ACTION_TYPE from "../actionType";

export const trdInit = () => {
  return {
    type: ACTION_TYPE.TRD.INIT,
  };
};
/**
 * trd 업데이트
 * @param {*} trd
 * @returns
 */
export const updateTrd = (trd) => {
  return {
    type: ACTION_TYPE.TRD.UPDATE,
    payload: trd,
  };
};

/**
 * trd ViewPort 업데이트
 * @param {*} viewport
 * @returns
 */
export const updateTrdViewport = (viewport, areaId) => {
  return {
    type: ACTION_TYPE.TRD.UPDATE_VIEWPORT,
    payload: { viewport, areaId },
  };
};

/**
 * trd를 신규 생성할때
 * @param {*} payload
 * @returns
 */
export const createTrd = (payload) => {
  return {
    type: ACTION_TYPE.TRD.CREATE,
    payload: payload,
  };
};

/**
 * trd를 호출할때
 * (기존 데이터 완전 초기화 후 덮어쓰기)
 * @param {*} payload
 * @returns
 */
export const patchTrd = (payload) => {
  return {
    type: ACTION_TYPE.TRD.PATCH,
    payload: payload,
  };
};

/**
 * 빌더 좌측 위젯 목록에서 trd를 선택할때
 * @param {*} payload
 * @returns
 */
export const selectTrd = (payload) => {
  return {
    type: ACTION_TYPE.TRD.SELECT,
    payload: payload,
  };
};

/**
 * reverse Engineering 설정
 * @param {*} flag
 * @returns
 */
export const setReverseEngineering = (flag) => {
  return {
    type: ACTION_TYPE.TRD.REVERSE_ENGINEERING,
    payload: flag,
  };
};

/**
 * 리버스 엔지니어링 세팅
 * 리버스 엔지니어링 여부 + 서버에서 불러온 테이블 데이터
 * @param {*} payload
 * @returns
 */
export const reverseEngineeringSetting = (payload) => {
  return {
    type: ACTION_TYPE.TRD.REVERSE_ENGINEERING_SET,
    payload: payload,
  };
};
