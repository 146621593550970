import { CommonUtils } from "@alpha/com.bizentro.daaf.front.framework";
import Template from "components/common/Template";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ElementListComponent from "./ElementListComponent";
import { setElementParams } from "store/actions/DataDictionaryAction";

/**
 * onSelectNewElement : Element 컨텐츠 신규등록시 Element 컨텐츠 리셋시키는 메소드
 * elementList : Grid에 보여줄 Element List
 * selectedElement : Grid에서 선택한 Element
 * setSelectedElement : 선택한 Element를 set 해주는 setState
 *
 */
const ElementList = ({
  onSelectNewElement,
  elementList,
  domainList,
  selectedElement,
  setSelectedElement,
  setIsNewElement,
  getElementList,
  ...props
}) => {
  const elementParams = useSelector((state) => state.dictionary.element.params);

  const dispatch = useDispatch();

  const dispatchElementParams = (params) => {
    dispatch(setElementParams(params));
  };

  /**
   * Element List Grid Columns
   */
  const gridColumns = [
    { field: "elementCd", headerName: "Element Code", style: { width: "20%" } },
    {
      field: "elementNm",
      headerName: "Element 명",
      style: { maxWidth: "40%" },
    },
    { field: "domainCd", headerName: "Domain", style: { width: "15%" } },
    { field: "domainNm", headerName: "Domain 명", style: { width: "15%" } },
    { field: "elementShortNm", headerName: "약어", style: { width: "10%" } },
  ];

  return (
    <Template title={"Element 목록"}>
      <ElementListComponent
        selectedElement={selectedElement}
        elementParams={elementParams}
        dispatchElementParams={dispatchElementParams}
        columns={gridColumns}
        listData={elementList}
        searchElementList={getElementList}
        setIsNewElement={setIsNewElement}
        onSelectNewElement={onSelectNewElement}
        setSelectedElement={setSelectedElement}
        setIndexLoading={props.setIndexLoading}
      />
    </Template>
  );
};

export default ElementList;
