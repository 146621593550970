import {
  ArrayUtils,
  ObjectUtils,
  StringUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import produce from "immer";
import { Position } from "reactflow";

function getNodeIntersection(intersectionNode, targetNode) {
  // https://math.stackexchange.com/questions/1724792/an-algorithm-for-finding-the-intersection-point-between-a-center-of-vision-and-a
  const {
    width: intersectionNodeWidth,
    height: intersectionNodeHeight,
    positionAbsolute: intersectionNodePosition,
  } = intersectionNode;
  const targetPosition = targetNode.positionAbsolute;

  const w = intersectionNodeWidth / 2;
  const h = intersectionNodeHeight / 2;

  const x2 = intersectionNodePosition.x + w;
  const y2 = intersectionNodePosition.y + h;
  const x1 = targetPosition.x + w;
  const y1 = targetPosition.y + h;

  const xx1 = (x1 - x2) / (2 * w) - (y1 - y2) / (2 * h);
  const yy1 = (x1 - x2) / (2 * w) + (y1 - y2) / (2 * h);
  const a = 1 / (Math.abs(xx1) + Math.abs(yy1));
  const xx3 = a * xx1;
  const yy3 = a * yy1;
  const x = w * (xx3 + yy3) + x2;
  const y = h * (-xx3 + yy3) + y2;

  return { x, y };
}

// returns the position (top,right,bottom or right) passed node compared to the intersection point
function getEdgePosition(node, intersectionPoint) {
  const n = { ...node.positionAbsolute, ...node };
  const nx = Math.round(n.x);
  const ny = Math.round(n.y);
  const px = Math.round(intersectionPoint.x);
  const py = Math.round(intersectionPoint.y);

  if (px <= nx + 1) {
    return Position.Left;
  }
  if (px >= nx + n.width - 1) {
    return Position.Right;
  }
  if (py <= ny + 1) {
    return Position.Top;
  }
  if (py >= n.y + n.height - 1) {
    return Position.Bottom;
  }

  return Position.Top;
}

/**
 * ERD 렌더링에 필요한 함수들
 */
class TrdRenderUtils {
  static getConnectorPosition = (sourceTable, targetTable) => {
    // 소스엔티티 사이즈
    const sourceSize = {
      width: sourceTable.width || 500,
      height:
        sourceTable.height ||
        (sourceTable.data.propertyValue.columnList.length + 1) * 26.5 + 30 + 30,
    };

    const sourcePosition = sourceTable.position;

    //나아갈 방향의 사이즈
    const targetSize = {
      width: targetTable.width || 500,
      height:
        targetTable.height ||
        (targetTable.data.propertyValue.columnList.length + 1) * 26.5 + 30 + 30,
    };

    const targetPosition = targetTable.position;

    const connectorSize = {
      width: 83,
      height: 51,
    };

    const connectorPosition = {
      x: null,
      y: null,
    };

    connectorPosition.x =
      (sourcePosition.x +
        sourceSize.width / 2 +
        (targetPosition.x + targetSize.width / 2)) /
      2;
    connectorPosition.y =
      (sourcePosition.y +
        sourceSize.height / 2 +
        (targetPosition.y + targetSize.height / 2)) /
      2;
    connectorPosition.x -= connectorSize.width / 2;
    connectorPosition.y -= connectorSize.height / 2;

    return connectorPosition;
  };

  static getEdgeParams = (source, target) => {
    const sourceIntersectionPoint = getNodeIntersection(source, target);
    const targetIntersectionPoint = getNodeIntersection(target, source);

    const sourcePos = getEdgePosition(source, sourceIntersectionPoint);
    const targetPos = getEdgePosition(target, targetIntersectionPoint);

    return {
      sx: sourceIntersectionPoint.x,
      sy: sourceIntersectionPoint.y,
      tx: targetIntersectionPoint.x,
      ty: targetIntersectionPoint.y,
      sourcePos,
      targetPos,
    };
  };

  /**
   * 테이블 정보를 기준으로 node에 적용된 id를 가져온다.
   * @param {*} node
   */
  static getNodeId = (tableInfo) => {
    if (tableInfo && tableInfo.tableMstId) {
      if (typeof tableInfo.tableMstId === "number") {
        return `table_${tableInfo.tableMstId}`;
      } else {
        return tableInfo.tableMstId;
      }
    }
    return null;
  };

  /**
   * node 정보를 기준으로 TableMstId를 가져온다.
   * @param {*} nodeInfo
   */
  static getTableId = (nodeInfo) => {
    if (nodeInfo && nodeInfo.id) {
      const idSplit = String(nodeInfo.id).split("_");
      if (idSplit[0] && idSplit[0] === "table") {
        return idSplit[1];
      } else {
        return nodeInfo.id;
      }
    } else {
      return null;
    }
  };

  /**
   * node 정보를 기준으로 TableMstId를 가져온다.
   * @param {*} nodeInfo
   */
  static getAreaId = (nodeInfo) => {
    if (nodeInfo && nodeInfo.id) {
      const idSplit = String(nodeInfo.id).split("_");
      if (idSplit[0] && idSplit[0] === "area") {
        return idSplit[1];
      } else {
        return nodeInfo.id;
      }
    } else {
      return null;
    }
  };

  /**
   * 테이블 정보를 기준으로 node에 적용된 id를 가져온다.
   * @param {*} node
   */
  static getAreaNodeId = (areaInfo) => {
    if (areaInfo && areaInfo.areaId) {
      if (typeof areaInfo.areaId === "number") {
        return `area_${areaInfo.areaId}`;
      } else {
        return areaInfo.tableMstId;
      }
    }
    return null;
  };

  /**
   * TRD 테이블 정보 덮어쓰는 로직
   * JSON Utils의 overide는 DFS 방식이어서 relation 속에 잇는 Table을 검색하기 때문에
   * table 레벨에서의 검색만 하는 로직을 구성
   * @param {*} areas
   * @param {*} targetTableId
   * @returns
   */
  static override = (data, key, value) => {
    return produce(data, (draft) => {
      draft[key] = value;
    });
  };

  /**
   * 관계 삭제 로직
   * @param {*} trd
   * @param {*} targetTable
   * @returns
   */
  static deleteRelation = (trd, targetTable) => {
    // const {
    //   activate: { areaId },
    // } = trd;
    if (!targetTable) {
      return trd;
    }
    let newErd = null;
    if (ArrayUtils.isArray(targetTable)) {
      // 활동영역에서 테이블 삭제
      newErd = produce(trd, (draft) => {
        for (const area of draft.areaList) {
          const targetIndex = area.tableList.findIndex((t) =>
            targetTable.find((table) => table.tableMstId === t.tableMstId)
          );
          if (targetIndex > -1) {
            area.tableList.splice(targetIndex, 1);
          }

          for (const table of area.tableList) {
            const { relation } = table;
            if (relation) {
              const relIndex = relation.findIndex((r) =>
                targetTable.find(
                  (table) => table.tableMstId === r.targetTable.tableMstId
                )
              );
              if (relIndex > -1) {
                relation.splice(relIndex, 1);
              }
            }
          }
        }
      });
    } else if (ObjectUtils.isObject(targetTable)) {
      const { tableMstId: targetCompId } = targetTable;
      // 활동영역에서 테이블 삭제
      newErd = produce(trd, (draft) => {
        for (const area of draft.areaList) {
          const targetIndex = area.tableList.findIndex(
            (t) => t.tableMstId === targetCompId
          );
          if (targetIndex > -1) {
            area.tableList.splice(targetIndex, 1);
          }

          for (const table of area.tableList) {
            const { relation } = table;
            if (relation) {
              const relIndex = relation.findIndex(
                (r) => r.targetTable.tableMstId === targetCompId
              );
              if (relIndex > -1) {
                relation.splice(relIndex, 1);
              }
            }
          }
        }
      });
    }

    return newErd;
  };

  /**
   *
   */
  static addRelationToTable = (rel, targetTable) => {
    /**
     * 커넥터 정보 입력를 SourceNode에 입력
     */
    const newSourceNode = produce(targetTable, (draft) => {
      if (!targetTable.relation) draft.relation = [];
      draft.relation.push(rel);
    });

    return newSourceNode;
  };

  /**
   * Identifying 연결시 targetTable에 keyColumns을 적용하는 메서드
   * @param {*} keyColumns
   * @param {*} targetTable
   * @returns
   */
  static setIdentifyingKeyColumn = (keyColumns = [], targetTable) => {
    const newTable = produce(targetTable, (draft) => {
      keyColumns.forEach((field, index) => {
        let prevExistFieldIndex = targetTable.trdTableField.findIndex(
          (tf) => tf.element.elementCd === field.element.elementCd
        );
        if (prevExistFieldIndex > -1) {
          draft.trdTableField[prevExistFieldIndex].pkYn = "Y";
          draft.trdTableField[prevExistFieldIndex].notNullYn = "Y";
        } else {
          draft.trdTableField = [...draft.trdTableField, field];
        }
      });
    });

    return newTable;
  };

  /**
   * areaId로 area를 찾아주는 메서드
   * @param {*} trd
   * @param {*} areaId
   * @param {*} draft
   * @returns
   */
  static getArea = (trd, areaId, draft = false) => {
    const area = trd.areaList.find((a) => a.areaId === areaId);
    if (draft) return area;
    else return { ...area };
  };

  /**
   * tableMstId로 테이블을 찾아주는 메서드
   * @param {*} trd
   * @param {*} area
   */
  static getTable = (trd, tableMstId, draft = false) => {
    let returnTable = null;
    for (const area of trd.areaList) {
      for (const table of area.tableList) {
        if (StringUtils.equalsIgnoreType(table.tableMstId, tableMstId)) {
          if (draft) {
            returnTable = table;
          } else {
            returnTable = { ...table };
          }
        }
      }
    }
    return returnTable;
  };
}

export default TrdRenderUtils;
