import {
  ArrayUtils,
  DaafEnums,
  DaafMessage,
  DaafPopup,
  JsonUtils,
  ObjectUtils,
  StringUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import { AppPath, Enums } from "app/Enums";
import Title from "components/common/Title";
import Grid from "components/grid/Grid";
import TableRegisterPopup from "components/popup/trd/TableRegisterPopup";
import TrdCommandLine from "components/trd/TrdCommandLine";
import { useContext, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import { MdDelete, MdEdit, MdErrorOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { stopEvent } from "utils/CommonUtils";
import TrdUtil from "utils/TrdUtils";
import useAppEnv from "utils/hook/useAppEnv";
import { ErdPopupSize, TrdContext } from "../editor/TrdBuilder";
import TrdReduxHelper from "../editor/render/TrdReduxHelper";
import { BiImport } from "react-icons/bi";
import TrdBuilderBar from "../editor/components/TrdBuilderBar";
import { Tooltip } from "react-tooltip";

const Tablelist = () => {
  const [activatedTableList, setActivatedTableList] = useState([]);
  const { env } = useAppEnv();
  const navigate = useNavigate();

  const onClickTrdEditor = (e) => {
    stopEvent(e);
    navigate(AppPath.TRD.Editor.url);
  };
  const { onOpenImportTablePopup, renderTooltip } = useContext(TrdContext);
  const { activate, tableList, areaList } = useSelector((state) => state.trd);
  const trd = useSelector((state) => state.trd);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!ArrayUtils.isEmpty(tableList)) {
      setActivatedTableList(tableList.filter((table) => table.useYn !== "N"));
    }
  }, [tableList]);

  /**
   * Table 생성
   * @param {*} position
   * @param {*} nodeInfo
   * @param {*} area
   */
  const createTable = (position, nodeInfo, area) => {
    const callback = async (tableData, changeNodes = []) => {
      const prevNode = JsonUtils.findNode(
        areaList,
        "tablePhysicalNm",
        tableData.tablePhysicalNm
      );
      if (!ObjectUtils.isEmpty(prevNode)) {
        DaafMessage.alert(
          "이미 등록된 테이블 입니다.",
          DaafEnums.MessageType.WARN
        );
      } else {
        TrdReduxHelper.addTrdTable(
          dispatch,
          tableData,
          changeNodes,
          trd,
          area.areaId
        );
        DaafPopup.close();
      }
    };

    DaafPopup.open(
      <TableRegisterPopup
        trd={trd}
        area={area}
        callback={callback}
        tableInfo={{ position }}
      />,
      {
        style: { content: { width: ErdPopupSize.Register } },
      }
    );
  };

  /**
   * Table 수정
   * @param {*} e target row
   * @param {*} row table Data
   * @param {*} index row index
   */
  const tableInfo = (e, row, index) => {
    stopEvent(e);
    let tempTrd = { ...trd };
    if (tempTrd.initReverseEngineering) {
      return DaafMessage.alert(
        "테이블 호출로 불러온 경우 최초 저장 후 사용 가능합니다.",
        "info"
      );
    }

    const applyElement = (element) => {
      tempTrd = TrdUtil.applyElement(element, tempTrd, dispatch);
    };

    /**
     * 테이블 수정 콜백
     * @param {*} info
     * @param {*} changeNode
     * @returns
     */
    const tableEditCallback = (info, changeNode = []) => {
      const dupNode = JsonUtils.findNode(
        tempTrd.areaList,
        "tablePhysicalNm",
        info.tablePhysicalNm
      );
      if (dupNode.trdMstId !== info.trdMstId) {
        return DaafMessage.alert(
          "이미 등록된 테이블 입니다.",
          DaafEnums.MessageType.WARN
        );
      } else {
        //테이블 내용만 바뀌는 경우
        TrdReduxHelper.updateNodes(dispatch, [info, ...changeNode], tempTrd);
        DaafPopup.close();
      }
    };

    DaafPopup.open(
      <TableRegisterPopup
        area={row.trdArea}
        trd={tempTrd}
        tableInfo={row}
        callback={tableEditCallback}
        applyElement={applyElement}
      />,
      {
        style: { content: { width: ErdPopupSize.Register } },
      }
    );
  };

  const searchTable = (e) => {
    const list = tableList.filter((table) => table.useYn !== "N");
    if (!ArrayUtils.isEmpty(list)) {
      setActivatedTableList(
        list.filter(
          (item) =>
            item.tablePhysicalNm
              .toLowerCase()
              .split(" ")
              .join("")
              .includes(e.target.value.toLowerCase().split(" ").join("")) ||
            item.tableLogicalNm
              .toLowerCase()
              .split(" ")
              .join("")
              .includes(e.target.value.toLowerCase().split(" ").join(""))
        )
      );
    } else {
      setActivatedTableList(list);
    }
  };

  const columns = [
    {
      field: "tablePhysicalNm",
      headerName: "테이블 물리명",
      renderCell: (data, index) => {
        const isExistElementError = data.trdTableField.every(
          (f) =>
            !StringUtils.isEmpty(f.element?.elementId) ||
            StringUtils.equalsIgnoreCase(
              f.element?.elementType,
              Enums.ElementType.TEXT
            )
        );

        return (
          <>
            {data.tablePhysicalNm}
            &nbsp;&nbsp;&nbsp;
            {!isExistElementError && (
              <span className="warning-text" style={{ marginRight: "5px" }}>
                <MdErrorOutline /> Element 설정 필요
              </span>
            )}
          </>
        );
      },
    },
    {
      field: "tableLogicalNm",
      headerName: "테이블 논리명",
    },
    {
      field: "areaId",
      headerName: "영역명",
      renderCell: (data, index) => {
        return <div>{data.trdArea.areaNm}</div>;
      },
      style: { width: "200px" },
    },
    {
      field: "tableDeliveryClass",
      headerName: "테이블 운용 범위",
      style: { width: "200px" },
    },
    {
      field: "tableViewMaint",
      headerName: "테이블 관리 권한",
      style: { width: "200px" },
    },
    {
      field: "fieldManager",
      headerName: "수정",
      style: { width: "100px" },
      renderCell: (data, index) => {
        return (
          <Button
            size={"sm"}
            onClick={(e) => {
              tableInfo(e, data, index);
            }}
          >
            <MdEdit />
          </Button>
        );
      },
    },
    {
      filed: "deleteTable",
      headerName: `삭제`,
      style: { width: "100px" },
      renderCell: (data, index) => {
        return (
          <Button
            variant="outline-danger"
            size={"sm"}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              DaafMessage.confirm(
                `${data.tablePhysicalNm} 테이블을 삭제 하시겠습니까?`,
                () => {
                  TrdReduxHelper.deleteTable(dispatch, data, trd);
                }
              );
            }}
          >
            <MdDelete />
          </Button>
        );
      },
    },
  ];

  const onClickImportTable = () => {
    onOpenImportTablePopup();
  };

  return (
    <div className="trd-editor-wrapper" style={{ backgroundColor: "white" }}>
      <TrdCommandLine />
      <div className="builder-bar">
        <div
          className="editor-btn"
          data-tooltip-id="view-trd"
          onClick={onClickTrdEditor}
        >
          <FaEdit />
        </div>
        <div
          className="editor-btn"
          data-tooltip-id="table-import"
          onClick={onClickImportTable}
        >
          <BiImport />
        </div>
      </div>
      <div className="list-body" style={{ padding: "3px" }}>
        <Title>테이블 목록</Title>
        <div
          className="mb-3 mt-3"
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0 5px",
          }}
        >
          <div>
            <Form.Control
              size="sm"
              id="searchTable"
              placeholder="테이블 검색"
              onChange={searchTable}
            />
          </div>
          <div>
            <Button onClick={(e) => createTable({}, {}, trd.activate)}>
              테이블 생성
            </Button>
          </div>
        </div>
        {/* <Row>
          <Col className="mb-3 mt-3" xs={2}></Col>
          <Col
            className="mb-3 mt-3"
            style={{ display: "flex", justifyContent: "flex-end" }}
          ></Col>
        </Row> */}
        <Grid
          gridBoxStyle={{
            maxHeight: "80vh",
            paddingBottom: "30px",
          }}
          columns={columns}
          rows={activatedTableList}
          numbering={true}
          onRowClick={tableInfo}
          stickyHeader
        />
      </div>
      {renderTooltip({ text: "TRD 편집 화면", tooltipId: "view-trd" })}
      {renderTooltip({ tooltipId: "table-import", text: "Table 가져오기" })}
    </div>
  );
};

export default Tablelist;
