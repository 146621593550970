import {
  ArrayUtils,
  StringUtils,
  UserUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import Form from "components/common/form/Form";
import Grid from "components/grid/Grid";
import DynamicInput from "components/setting/DynamicInput";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { MdDelete } from "react-icons/md";

const PossibleEntryComponent = ({
  domain,
  setDomain = () => {},
  env,
  onChangeData,
  onChangeDomain,
  entryCaseFlag,
  setEntryCaseFlag,
  ...props
}) => {
  const [entryCase, setEntryCase] = useState(entryCaseFlag);

  const tableContainerRef = useRef();
  useEffect(() => {
    if (entryCase) {
      tableContainerRef.current.scrollTop =
        tableContainerRef.current?.scrollHeight;
    }
    setEntryCase(entryCaseFlag);
  }, [domain]);

  /**
   * Possible Entry Grid Column
   */
  const possibleEntryColumn = [
    {
      field: "sortSeq",
      headerName: "",
      style: {
        width: "1vh",
        height: "41px",
      },
      renderCell: (data, index) => {
        if (data.addButton) {
          return;
        } else {
          return (
            <div
              className="dd-manage-label-cell"
              style={{
                fontWeight: "bold",
                justifyContent: "center",
              }}
            >
              {index + 1}
            </div>
          );
        }
      },
    },
    {
      field: "entryValue",
      headerName: "Fix.Val",
      style: {
        width: "5vh",
        height: "41px",
      },
      renderCell: (data, index) => {
        if (data.addButton) {
          return data.addEntry;
        } else {
          return (
            <DynamicInput
              style={{
                display: "flex",
                alignItems: "center",
                height: "100%",
              }}
              value={data.entryValue || ""}
              id="entryValue"
              onBlur={(e) => {
                onBlurPossibleEntry(e, data, index);
              }}
            />
          );
        }
      },
    },
    {
      field: "entryText",
      headerName: "Short Desc",
      style: { width: "15vh", height: "41px" },
      renderCell: (data, index) => {
        if (data.addButton) {
          return <></>;
        } else {
          return (
            <DynamicInput
              style={{
                display: "flex",
                alignItems: "center",
                height: "100%",
              }}
              value={data.entryText}
              id="entryText"
              onBlur={(e) => {
                onBlurPossibleEntry(e, data, index);
              }}
            />
          );
        }
      },
    },
    {
      field: "deleteBtn",
      headerName: "삭제",
      style: { height: "41px", width: "3vh", alignItems: "center" },
      renderCell: (data, index) => {
        if (data.addButton) {
          return <div></div>;
        } else {
          return (
            <Button
              variant="outline-danger"
              size={"sm"}
              onClick={(e) => {
                onRemovePossibleEntry(data, index);
              }}
            >
              <MdDelete />
            </Button>
          );
        }
      },
    },
  ];

  /**
   * Possible Entry 오브젝트 추가 이벤트
   */
  const onAddPossibleEntry = () => {
    const prevPossibleEntry = [...domain.possibleEntryList];
    const nObj = {
      appEnvId: env.appEnvId,
      domainId: domain.domainId ? domain.domainId : "",
      entryValue: "",
      entryText: "",
      langCd: UserUtils.getLanguage(),
      sortSeq: ArrayUtils.isEmpty(domain.possibleEntryList)
        ? 1
        : domain.possibleEntryList.length + 1,
    };

    setDomain({
      ...domain,
      possibleEntryList: [...prevPossibleEntry, nObj],
    });
  };

  /**
   * Possible Entry onBlur 이벤트
   * @param {*} e
   * @param {*} data
   */
  const onBlurPossibleEntry = (e, data, index) => {
    data[e.target.id] = e.target.value || null;
    data["sortSeq"] = index + 1;
    if (!StringUtils.isEmpty(e.target.value)) {
      const prevPossibleEntry = [...domain.possibleEntryList];
      setDomain({
        ...domain,
        possibleEntryList: [...prevPossibleEntry],
      });
    }
  };

  /**
   * Possible Entry 오브젝트 삭제 이벤트
   */
  const onRemovePossibleEntry = (data, index) => {
    const newList = [...domain.possibleEntryList];
    newList.splice(index, 1);
    setDomain({ ...domain, possibleEntryList: [...newList] });
  };

  /**
   * Possible Entry 사용 방식 지정
   * @param {*} e
   */
  const clickRadio = (e) => {
    if (e.target.id === "defaultValue") {
      setEntryCase(true);
      setEntryCaseFlag(true);
      setDomain({ ...domain, entryType: "entry" });
    } else {
      setEntryCase(false);
      setEntryCaseFlag(false);
      setDomain({ ...domain, entryType: "table" });
    }
  };

  return (
    <Form className="form-body">
      <Col className="col-6 mb-3">
        <div className="dd-manage-radio">
          <label className="radio-label" htmlFor="defaultValue">
            고정 값
          </label>
          <Form.CheckBox
            style={{ marginRight: "15px" }}
            type="radio"
            id="defaultValue"
            checked={entryCase}
            onChange={clickRadio}
          />
          <label className="radio-label" htmlFor="searchTable">
            테이블참조
          </label>
          <Form.CheckBox
            type="radio"
            id="searchTable"
            checked={!entryCase}
            onChange={clickRadio}
          />
        </div>
      </Col>
      {entryCase ? (
        <Row>
          <div
            style={{ maxHeight: "25vh", overflowY: "auto" }}
            ref={tableContainerRef}
          >
            <Grid
              id="possibleEntryGrid"
              style={{ borderCollapse: "separate", borderSpacing: "0" }}
              bodyMaxHeight={"300px"}
              columns={possibleEntryColumn}
              rows={
                domain?.possibleEntryList && [
                  ...domain.possibleEntryList,
                  {
                    addButton: true,
                    addEntry: (
                      <div
                        onClick={onAddPossibleEntry}
                        style={{
                          padding: "10px",
                          color: "dodgerblue",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                      >
                        + Entry 추가
                      </div>
                    ),
                  },
                ]
              }
            />
          </div>
        </Row>
      ) : (
        <Row>
          <Col xs={6} className="mb-3">
            <Form.Label>{"Table"}</Form.Label>
            <Form.DInput
              size="sm"
              id="entryRefTable"
              placeholder="참조 테이블 명 입력"
              value={domain?.entryRefTable || ""}
              onChange={(e) => onChangeData(e)}
            />
          </Col>
          <Col xs={6}>
            <Form.Label>{"Where"}</Form.Label>
            <Form.DInput
              size="sm"
              id="entryRefWhere"
              placeholder="조건 입력"
              value={domain?.entryRefWhere || ""}
              onChange={(e) => onChangeData(e)}
            />
          </Col>
          <Col xs={6}>
            <Form.Label>{"Key Column"}</Form.Label>
            <Form.DInput
              size="sm"
              id="entryRefKey"
              placeholder="콤보에 적용될 Key Column"
              value={domain?.entryRefKey || ""}
              onChange={(e) => onChangeData(e)}
            />
          </Col>
          <Col xs={6}>
            <Form.Label>{"Text Column"}</Form.Label>
            <Form.DInput
              size="sm"
              id="entryRefValue"
              placeholder="콤보에 적용될 Text Column"
              value={domain?.entryRefValue || ""}
              onChange={(e) => onChangeData(e)}
            />
          </Col>
        </Row>
      )}
    </Form>
  );
};

export default PossibleEntryComponent;
