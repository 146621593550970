import {
  ObjectUtils,
  StringUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import { Enums } from "app/Enums";
import Template from "components/common/Template";
import { useEffect, useState } from "react";
import { Button, Col, FormControl, FormSelect, Row } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { setDomainParams } from "store/actions/DataDictionaryAction";
import WijmoGrid from "./WijmoGridTest1";
// import WijmoGrid from "@alpha/com.bizentro.daaf.front.framework";

/**
 * onSelectNewDomain : Domain 컨텐츠 신규등록시 Element 컨텐츠 리셋시키는 메소드
 * domainList : Grid에 보여줄 Domain List
 * selectedDomain : Grid에서 선택한 Domain
 * setSelectedDomain : 선택한 Domain를 set 해주는 setState
 *
 */
const DomainList = ({
  onSelectNewDomain,
  domainList,
  selectedDomain,
  setSelectedDomain,
  setIsNewDomain,
  getDomainList,
  dataTypeList,
  ...props
}) => {
  const [filterData, setFilterData] = useState(domainList);
  const [params, setParams] = useState({ domainSearch: null, dataType: null });

  const domainParams = useSelector((state) => state.dictionary.domain.params);
  const dispatch = useDispatch();

  useEffect(() => {
    setFilterData(filterDomainList(domainList || []));
  }, [domainList]);

  useEffect(() => {
    if (setSelectedDomain) {
      if (!StringUtils.isEmpty(selectedDomain?.domainId)) {
        setSelectedDomain(
          filterData.find((d) => d.domainId === selectedDomain.domainId)
        );
      } else {
        setSelectedDomain(filterData[0]);
      }
    }
  }, [filterData]);

  /**
   * 도메인 리스트 필터링
   */
  const filterDomainList = (list) => {
    const domainSearchData = list.filter((domain) => {
      // 도메인 리스트 column 세팅
      if (
        StringUtils.equalsIgnoreCase(
          Enums.getDataCase(domain.dataType),
          Enums.CaseType.String
        )
      )
        domain.dataTypeDetail =
          domain.dataType.toUpperCase() + "(" + domain.dataLength + ")";
      else if (
        StringUtils.equalsIgnoreCase(
          Enums.getDataCase(domain.dataType),
          Enums.CaseType.Decimal
        )
      )
        domain.dataTypeDetail =
          domain.dataType.toUpperCase() +
          "(" +
          domain.dataLength +
          "," +
          domain.decimals +
          ")";
      else {
        domain.dataTypeDetail = domain.dataType.toUpperCase();
      }

      return true;
    });
    return domainSearchData;
  };

  /**
   * 검색시 filtered Data
   */
  const getRowData = () => {
    getDomainList(params);
  };

  const onBlur = (e) => {
    const newParams = { ...params, domainSearch: e.target.value };
    setParams(newParams);
    dispatch(setDomainParams(newParams));
  };

  /**
   * Domain List Grid Columns
   */
  const domainColumns = [
    { field: "domainCd", headerName: "Domain Code" },
    { field: "domainNm", headerName: "Domain 명" },
    { field: "dataTypeDetail", headerName: "Data Type" },
    // { field: "dataLength", headerName: "Data Length", style: {} },
  ];

  return (
    <Template title={"Domain 목록"} breadcrumb={"도메인 목록"}>
      <div>
        <Row className="mb-3">
          <Col xs={10}>
            <Row>
              <Col xs={3}>
                <FormControl
                  id="domainSearch"
                  size="sm"
                  placeholder="Domain 검색"
                  value={
                    params.domainSearch || domainParams?.domainSearch || ""
                  }
                  onChange={(e) => onBlur(e)}
                  onKeyUp={(e) => e.key === "Enter" && getRowData()}
                />
              </Col>
              <Col xs={3}>
                {/* <Form.ComboBox
                  options={dataTypeList}
                  id="dataType"
                  placeholder="Data Type 선택"
                  value={params.dataType}
                  getOptionLabel={(obj) => obj["id"]}
                  getOptionValue={(obj) => obj["text"]}
                  onChange={(e) => {
                    let dataType = e.target.value;
                    if (dataType === "null") {
                      dataType = null;
                    }
                    dispatch(
                      setDomainParams({
                        ...domainParams,
                        dataType: dataType,
                      })
                    );
                    setParams({ ...params, dataType: e.target.value });
                  }}
                  {...props}
                /> */}
                <FormSelect
                  style={{ color: "gray" }}
                  size="sm"
                  id="dataType"
                  placeholder="Data Type 선택"
                  value={params.dataType || domainParams?.dataType || ""}
                  onChange={(e) => {
                    let dataType = e.target.value;
                    if (dataType === "null") {
                      dataType = null;
                    }
                    dispatch(
                      setDomainParams({
                        ...domainParams,
                        dataType: dataType,
                      })
                    );
                    setParams({ ...params, dataType: e.target.value });
                  }}
                >
                  <option value={"null"} label="Data Type 선택"></option>
                  {dataTypeList &&
                    dataTypeList.map((item, index) => {
                      return (
                        <option
                          key={index}
                          id={item.id}
                          value={item.id}
                          label={item.text}
                        ></option>
                      );
                    })}
                </FormSelect>
              </Col>
              <Col xs={4} style={{ display: "flex" }}>
                <Button
                  className="dd-manage-button"
                  style={{ height: "100%", marginRight: "10px" }}
                  size="sm"
                  variant="outline-primary"
                  onClick={getRowData}
                >
                  <FaSearch />
                </Button>
                <Button
                  className="dd-manage-button"
                  style={{ height: "100%" }}
                  size="sm"
                  onClick={(e) => {
                    dispatch(
                      setDomainParams({ domainSearch: null, dataType: null })
                    );
                    setParams({ domainSearch: null, dataType: null });
                  }}
                >
                  검색 초기화
                </Button>
              </Col>
            </Row>
          </Col>
          {/** 검색 **/}
          <Col xs={2}>
            <div className="form-button-wrapper">
              <Button variant="success" size="sm" onClick={onSelectNewDomain}>
                신규 등록
              </Button>
            </div>
          </Col>
        </Row>
        {/** 그리드 관련 버튼 **/}
      </div>
      {/* <hr /> */}
      {!ObjectUtils.isEmpty(domainColumns) && (
        // <Grid
        //   gridBoxStyle={{ maxHeight: "70vh", border: "1px solid #c4c4c4" }}
        //   numbering={true}
        //   onRowClick={(e, row) => {
        //     setSelectedDomain(row);
        //     setIsNewDomain(false);
        //   }}
        //   columns={domainColumns}
        //   rows={filterData}
        //   getId={(data) => data.domainId}
        //   selected={selectedDomain}
        //   stickyHeader={true}
        // />
        <div>
          <WijmoGrid
            style={{ maxHeight: "70vh" }}
            headerFilter={true}
            headersVisibility="Column"
            onRowClick={(e) => {
              setSelectedDomain(e);
              setIsNewDomain(false);
            }}
            columns={domainColumns}
            rows={filterData}
            // getId={(rowData) => {
            //   return rowData.domainId;
            // }}
            // selectedItem={selectedDomain || filterData[0]}
          />
        </div>
      )}
    </Template>
  );
};

export default DomainList;
