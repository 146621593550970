import { useState } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStore, getSmoothStepPath, EdgeLabelRenderer } from "reactflow";
import TrdRenderUtils from "./TrdRenderUtils";

function TrdRelationEdge({
  id,
  source,
  target,
  markerEnd,
  markerStart,
  style,
  data,
}) {
  const output = useSelector((state) => state.outputENT);
  const dispatch = useDispatch();
  const [isEditShow, setIsEditShow] = useState(false);

  const sourceNode = useStore(
    useCallback((store) => store.nodeInternals.get(source), [source])
  );
  const targetNode = useStore(
    useCallback((store) => store.nodeInternals.get(target), [target])
  );

  if (!sourceNode || !targetNode) {
    return null;
  }

  const { sx, sy, tx, ty, sourcePos, targetPos } = TrdRenderUtils.getEdgeParams(
    sourceNode,
    targetNode
  );

  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX: sx,
    sourceY: sy,
    sourcePosition: sourcePos,
    targetPosition: targetPos,
    targetX: tx,
    targetY: ty,
  });

  /**
   * 릴레이션 저장
   * @param {*} newRelation
   * @param {*} _entity
   */
  const onSaveRelation = (newRelation, _entity) => {};

  /**
   * 릴레이션 삭제
   * @param {*} relation
   * @param {*} _entity
   */
  const onRemoveRelation = (relation, _entity) => {};

  return (
    <>
      <path
        id={id}
        className="react-flow__edge-path"
        d={edgePath}
        markerStart={markerStart}
        markerEnd={markerEnd}
        style={style}
        // onMouseOver={(e) => {
        //   setIsEditShow(true);
        // }}
        // onMouseLeave={(e) => {
        //   setIsEditShow(false);
        // }}
      >
        {/* 마우스 오버로 노출시키기에는 한계가 있어서 해당 테이블에서 릴레이션 조절 할수 있도록 할 예정 */}
        {/* <EdgeLabelRenderer>
          <div
            style={{
              position: "absolute",
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              background: "white",
              borderRadius: 2,
              fontSize: `calc(25px / ${3})`,
              fontWeight: 700,
              cursor: "pointer",
              pointerEvents: "all",
              padding: "0 5px",
              display: isEditShow ? "block" : "none",
              width: "100px",
              height: "100px",
            }}
            // onClick={onDetailRelation}
            className="nodrag nopan"
          >
            연결
          </div>
        </EdgeLabelRenderer> */}
      </path>
    </>
  );
}

export default TrdRelationEdge;
