import { StringUtils } from "@alpha/com.bizentro.daaf.front.framework";
import { AppPath } from "app/Enums";
import { useContext } from "react";
import { BiImport } from "react-icons/bi";
import { CiMemoPad } from "react-icons/ci";
import { FaEdit, FaList, FaMousePointer, FaTable } from "react-icons/fa";
import { PiSelectionThin } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { stopEvent } from "utils/CommonUtils";
import { TrdContext } from "../TrdBuilder";
import { Tooltip } from "react-tooltip";

/**
 * TRD 빌더 왼쪽 사이드 바
 * 테이블 및 메모를 생성할 수 있도록 메뉴 선택지
 * @returns
 */
const TrdBuilderBar = ({ erdActivate = true, ...props }) => {
  const navigate = useNavigate();
  const trd = useSelector((state) => state.trd);
  const {
    activate: { sectorType },
  } = trd;

  const {
    actionContent,
    setActionContent,
    onOpenImportTablePopup,
    renderTooltip,
  } = useContext(TrdContext);

  const onClickBtn = (e, content) => {
    stopEvent(e);
    setActionContent(content);
  };

  const onClickTrdEditorList = (e) => {
    stopEvent(e);
    navigate(AppPath.TRD.Editor_List.url);
  };

  const onClickTrdEditor = (e) => {
    stopEvent(e);
    navigate(AppPath.TRD.Editor.url);
  };

  return (
    <>
      <div className="builder-bar">
        {erdActivate ? (
          <div
            data-tooltip-id="view-list"
            className="editor-btn"
            onClick={onClickTrdEditorList}
          >
            <FaList />
          </div>
        ) : (
          <div
            className="editor-btn"
            data-tooltip-id="view-trd"
            onClick={onClickTrdEditor}
          >
            <FaEdit />
          </div>
        )}
        {erdActivate && (
          <>
            <div className="editor-btn">
              <FaMousePointer />
            </div>
            <div className="editor-btn">
              <PiSelectionThin />
            </div>
          </>
        )}

        <div
          data-tooltip-id="table-import"
          className="editor-btn"
          onClick={onOpenImportTablePopup}
        >
          <BiImport />
        </div>

        {erdActivate && (
          <>
            {!StringUtils.equalsIgnoreCase(sectorType, "A") && (
              <div
                className={`editor-btn ${
                  actionContent === "table" ? "selected" : ""
                }`}
                onClick={(e) => onClickBtn(e, "table")}
                data-tooltip-id="table-add"
              >
                <FaTable />
              </div>
            )}
            <div
              className={`editor-btn ${
                actionContent === "memo" ? "selected" : ""
              }`}
              onClick={(e) => onClickBtn(e, "memo")}
              data-tooltip-id="memo-add"
            >
              <CiMemoPad />
            </div>
          </>
        )}
      </div>
      {/* Tooltip */}
      {renderTooltip({ tooltipId: "view-list", text: "테이블 목록 보기" })}
      {renderTooltip({ tooltipId: "view-trd", text: "Editor 보기" })}
      {renderTooltip({ tooltipId: "table-import", text: "Table 가져오기" })}
      {renderTooltip({ tooltipId: "table-add", text: "테이블 추가" })}
      {renderTooltip({ tooltipId: "memo-add", text: "메모 추가" })}
    </>
  );
};

export default TrdBuilderBar;
