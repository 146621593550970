import ConnectionControl from "components/common/header/ConnectionControl";
import HeaderUser from "components/common/header/HeaderUser";
import Logout from "components/common/header/Logout";
import WorkspaceControl from "components/common/header/WorkspaceControl";

function MainHeader() {
  return (
    <div className="main-header">
      <div className="header-left">
        <WorkspaceControl />
        <ConnectionControl />
      </div>

      <div className="header-center">
        <Logout />
        <HeaderUser />
      </div>
    </div>
  );
}

export default MainHeader;
