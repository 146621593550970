import Title from "components/common/Title";
import Tab from "components/common/tab/Tab";
import React from "react";
import { useState } from "react";
import { StringUtils } from "@alpha/com.bizentro.daaf.front.framework";
import AppGroupSetting from "components/setting/AppGroup";
import GroupAuth from "components/setting/GroupAuth";
import { useSelector } from "react-redux";
import useAppEnv from "utils/hook/useAppEnv";

/**
 * 사용자 환경설정을 만드는 곳
 * @returns
 */
const SettingUser = () => {
  const [selectedTab, setSelectedTab] = useState("group");
  const { app, env } = useAppEnv();

  const onClickTab = (e, tab) => {
    setSelectedTab(tab);
  };

  return (
    <div className={`dd-manage-wrapper `}>
      <div className="contents-body">
        <Title
          breadcrumb={
            <>
              <div>Home</div>/<div>환경 설정</div>/<div>사용자 환경 설정</div>
            </>
          }
        >
          사용자 설정
        </Title>
        <div className="setting-app">
          <h3 className="dd-title">{app.appNm} Application 그룹 설정</h3>
          <div className="title-desc-wrapper">
            <span className="title-desc">
              개발 환경을 설정할 Application을 선택합니다.
            </span>
          </div>
        </div>

        <hr />

        <Tab
          header={
            <>
              <Tab.Header
                selected={selectedTab === "group"}
                onClick={(e) => onClickTab(e, "group")}
              >
                App 그룹 설정
              </Tab.Header>
              <Tab.Header
                selected={selectedTab === "auth"}
                onClick={(e) => onClickTab(e, "auth")}
              >
                그룹별 권한 설정
              </Tab.Header>
            </>
          }
          body={
            <Tab.Body>
              {StringUtils.equalsIgnoreCase(selectedTab, "group") ? (
                <AppGroupSetting />
              ) : StringUtils.equalsIgnoreCase(selectedTab, "auth") ? (
                <GroupAuth app={app} env={env} />
              ) : (
                <></>
              )}
            </Tab.Body>
          }
        />
      </div>
    </div>
  );
};

export default SettingUser;
