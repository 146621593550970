import {
  ArrayUtils,
  StringUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import produce from "immer";
import TrdRenderUtils from "page/trd/editor/render/TrdRenderUtils";
import ACTION_TYPE from "../actionType";

const initialState = {
  initReverseEngineering: false, //리버스 엔지니어링으로 불러온 데이터인 경우 true : false
  info: {
    trdUid: "",
    trdNm: "",
    trdDesc: "",
    trdAreaType: "S",
    trdDevType: "D",
  },
  areaList: [],
  tableList: [],
  activate: {},
};

export default function TrdReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPE.TRD.INIT:
      return initialState;
    case ACTION_TYPE.TRD.UPDATE:
      return { ...state, ...action.payload };

    case ACTION_TYPE.TRD.CREATE:
      return produce(state, (draft) => {
        const { areaList, useYn, ...otherInfo } = action.payload;
        draft.info = {
          ...otherInfo,
        };
        draft.info.useYn = useYn || "Y";
        //영역 초기화
        draft.areaList = [];
        if (ArrayUtils.isArray(areaList)) {
          //첫번째 ERD를 기본 선택항목으로 지정
          draft.activate = areaList[0];
          //멀티 영역에서는 1번째 것을 선택함
          if (otherInfo.trdAreaType === "M") {
            draft.activate = areaList[1];
          }
          draft.areaList = areaList.map((area, index) => {
            const obj = { ...area };
            if (typeof obj.areaViewport === "string") {
              //DB에서 호출해 오는 경우 Stringify 되어 있기 때문에 파싱 해야함
              obj.areaViewport = JSON.parse(obj.areaViewport);
            }
            return obj;
          });
        }
        //테이블 초기화
        draft.tableList = [];
      });

    case ACTION_TYPE.TRD.PATCH:
      return produce(state, (draft) => {
        const { areaList, useYn, tableList, ...otherInfo } = action.payload;

        draft.info = {
          ...action.payload,
        };
        draft.info.useYn = useYn || "Y";
        // 영역 초기화
        draft.areaList = [];
        if (ArrayUtils.isArray(areaList)) {
          draft.areaList = areaList.map((area, index) => {
            //첫번째 ERD를 기본 선택항목으로 지정
            if (state.activate?.areaNm) {
              if (
                StringUtils.equalsIgnoreCase(state.activate.areaNm, area.areaNm)
              )
                draft.activate = area;
              else if (index === 0) {
                draft.activate = area;
              }
            } else if (index === 0) {
              draft.activate = area;
            }
            if (typeof area.areaViewport === "string") {
              //DB에서 호출해 오는 경우 Stringify 되어 있기 때문에 파싱 해야함
              area.areaViewport = JSON.parse(area.areaViewport);
            }
            if (typeof area.areaMemo === "string") {
              area.areaMemo = JSON.parse(area.areaMemo);
            }
            return area;
          });
        }
        if (ArrayUtils.isArray(tableList)) {
          // Position Parse
          draft.tableList = tableList.map((table) => {
            if (typeof table.position === "string") {
              table.position = JSON.parse(table.position);
            }
            return table;
          });
        }

        draft.info = {
          ...action.payload,
          tableList: draft.tableList,
          areaList: draft.areaList,
          ...otherInfo,
        };
      });
    case ACTION_TYPE.TRD.SELECT:
      return produce(state, (draft) => {
        draft.activate = action.payload;
      });
    case ACTION_TYPE.TRD.UPDATE_VIEWPORT:
      const { viewport, areaId } = action.payload;
      return produce(state, (draft) => {
        let area = TrdRenderUtils.getArea(draft, areaId, true);
        if (!area.areaViewport) area.areaViewport = {};
        area.areaViewport = viewport;
      });
    case ACTION_TYPE.TRD.REVERSE_ENGINEERING:
      return produce(state, (draft) => {
        draft.initReverseEngineering = action.payload;
      });
    case ACTION_TYPE.TRD.REVERSE_ENGINEERING_SET:
      return produce(state, (draft) => {
        draft.initReverseEngineering = true;
        const { tableList = [] } = action.payload;
        tableList.forEach((table, index) => {
          //기존에 존재하는 테이블이면 데이터 덮어쓰기
          //새로 들어가는 테이블이면 추가하기
          const existTable = draft.tableList.find((t) =>
            StringUtils.equalsIgnoreCase(
              t.tablePhysicalNm,
              table.tablePhysicalNm
            )
          );
          if (!existTable) {
            draft.tableList.push(table);
          } else {
            //제외 항목
            // tableMstId, position, 필드 아이디, relationId
            for (const tableKey in table) {
              if (
                !StringUtils.includesIgnoreCase(tableKey, [
                  "tableMstId",
                  "position",
                  "trdArea",
                ])
              ) {
                if (StringUtils.equalsIgnoreCase(tableKey, "trdTableField")) {
                  //필드 적용
                  const trdTableField = table[tableKey];
                  for (const field of trdTableField) {
                    const existField = existTable.trdTableField.find(
                      (f) =>
                        f.physicalNm === field.physicalNm ||
                        f.element?.elementCd === field.element?.elementCd
                    );
                    if (!existField) {
                      existTable.trdTableField.push(field);
                    } else {
                      for (const fieldKey in field) {
                        if (
                          !StringUtils.includesIgnoreCase(fieldKey, [
                            "fieldId",
                            "sortSeq",
                          ])
                        ) {
                          existField[fieldKey] = field[fieldKey];
                        }
                      }
                    }
                  }
                } else if (StringUtils.equalsIgnoreCase(tableKey, "relation")) {
                  //관계 적용
                  existTable[tableKey] = table[tableKey];
                } else {
                  existTable[tableKey] = table[tableKey];
                }
              }
            }
          }
        });
      });
    default:
      return state;
  }
}
