import {
  ArrayUtils,
  DaafMessage,
  StringUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import { Enums } from "app/Enums";
import produce from "immer";
import { stackRedo, stackUndo } from "store/actions/CommandAction";
import { updateTrd } from "store/actions/TrdAction";
import TrdRenderUtils from "./TrdRenderUtils";
import TrdService from "services/TrdService";

class TrdReduxHelper {
  /**
   * ERD를 통으로 업데이트
   * @param {*} dispatch
   * @param {*} erd
   */
  static _updateNode(dispatch, erd) {
    dispatch(updateTrd(erd));
    dispatch(stackRedo(erd));
  }

  /**
   * 새로운 Trd로 덮어씌우는 경우
   * table 영역 바뀌는 경우에 사용
   * @param {*} dispatch
   * @param {*} trd
   * @param {*} prevTrd
   */
  static patchTrd = (dispatch, trd, prevTrd) => {
    dispatch(stackUndo(prevTrd));
    this._updateNode(dispatch, trd);
  };

  /**
   * 메모 추가
   * @param {*} dispatch
   * @param {*} data
   * @param {*} prevErd
   */
  static addMemo(dispatch, data, prevErd) {
    dispatch(stackUndo(prevErd));
    const newErd = produce(prevErd, (draft) => {
      const area = TrdRenderUtils.getArea(draft, prevErd.activate.areaId, true);
      if (!area.areaMemo) area.areaMemo = [];
      area.areaMemo.push(data);
    });
    this._updateNode(dispatch, newErd);
  }

  /**
   * ERD에 테이블 추가하는 로직
   * @param {*} dispatch
   * @param {*} data
   * @param {*} prevErd
   * @param {*} areaId
   */
  static addTrdTable(dispatch, tableObject, changeNodes, prevErd, areaId) {
    if (prevErd.initReverseEngineering) {
      return DaafMessage.alert(
        "테이블 호출로 불러온 경우 최초 저장 후 사용 가능합니다.",
        "info"
      );
    }
    dispatch(stackUndo(prevErd));
    const newErd = produce(prevErd, (draft) => {
      if (ArrayUtils.isArray(tableObject)) {
        tableObject.forEach((table) => draft.tableList.push(table));
      } else {
        draft.tableList.push(tableObject);
      }

      if (changeNodes) {
        draft.tableList = draft.tableList.map((table) => {
          const node = changeNodes.find(
            (n) => n.tableMstId === table.tableMstId
          );
          if (node) {
            Object.keys(node).map((key) => {
              if (key !== "tableMstId" && node[key]) {
                table[key] = node[key];
              } else if (table[key] && !node[key]) {
                delete table[key];
              }
            });
          }
          return table;
        });
      }
    });

    this._updateNode(dispatch, newErd);
  }

  /**
   * 노드(테이블) 정보 업데이트
   * @param {Function} dispatch
   * @param {Array} dataList
   * @param {Object} prevErd
   */
  static updateNodes(dispatch, dataList, prevErd) {
    if (prevErd.initReverseEngineering) {
      return DaafMessage.alert(
        "테이블 호출로 불러온 경우 최초 저장 후 사용 가능합니다.",
        "info"
      );
    }
    dispatch(stackUndo(prevErd));
    const nodeIdList = dataList.map((n) => n.tableMstId);
    //메모는 id를 compId를 쓰기 때문에 별도로 체크
    const memoList = dataList.filter(
      (node) => node.type === Enums.ErdType.MEMO
    );
    const newData = produce(prevErd, (draft) => {
      //테이블 데이터 수정
      draft.tableList = draft.tableList.map((table) => {
        const node = dataList[nodeIdList.indexOf(table.tableMstId)];
        if (node) {
          Object.keys(node).map((key) => {
            if (key !== "tableMstId" && node[key]) {
              table[key] = node[key];
            } else if (table[key] && !node[key]) {
              delete table[key];
            }
          });
        }
        return table;
      });

      //메모의 경우 각 영역에 있기 때문에 수정
      draft.areaList = draft.areaList.map((area) => {
        if (area.areaMemo && ArrayUtils.isArray(area.areaMemo)) {
          //area 일때는 메모 업데이트
          area.areaMemo = area.areaMemo.map((memo) => {
            let willUpdateMemo = memoList.find((m) => m.compId === memo.compId);
            if (willUpdateMemo) {
              memo = { ...willUpdateMemo };
            }
            return memo;
          });
        }
        return area;
      });
    });
    this._updateNode(dispatch, newData);
  }

  /**
   * ERD에서 테이블 삭제
   * @param {*} dispatch
   * @param {*} targetTable
   * @param {*} prevErd
   */
  static deleteTable = (dispatch, targetTable, prevErd) => {
    dispatch(stackUndo(prevErd));
    const erd = { ...prevErd };
    const { tableMstId: targetCompId } = targetTable;
    // 활동영역에서 테이블 삭제
    const newErd = produce(erd, (draft) => {
      const index = draft.tableList.findIndex(
        (table) => table.tableMstId === targetCompId
      );
      //임시 아이디로 만든 테이블은 그냥 삭제
      if (typeof draft.tableList.tableMstId === "string") {
        draft.tableList.splice(index, 1);
      } else {
        draft.tableList[index].useYn = "N";
      }
    });
    this._updateNode(dispatch, newErd);
  };

  /**
   * Trd 정보 수정
   * Area 정보 삭제시 해당 Area에 관련한 Table 정보도 수정
   * @param {*} dispatch
   * @param {*} trd
   * @param {*} prevTrd
   */
  static editTrd = (dispatch, trd, prevTrd) => {
    dispatch(stackUndo(prevTrd));
    const { areaList, ...info } = trd;

    const newTrd = produce(prevTrd, (draft) => {
      draft.info = info;
      draft.areaList = areaList;
      const AArea = areaList.find((area) => area.sectorType === "A");
      //멀티플일때만 적용
      if (info.trdAreaType === "M" && AArea) {
        draft.tableList = draft.tableList.map((table) => {
          if (table.trdArea.areaId) {
            const isArea = areaList.find(
              (area) =>
                area.areaId === table.trdArea.areaId && area.useYn !== "N"
            );
            if (!isArea) {
              table.trdArea = AArea;
            }
          }
          return table;
        });
      }
    });
    this._updateNode(dispatch, newTrd);
  };

  /**
   * 메모내용 업데이트
   * @param {*} dispatch
   * @param {*} memo
   * @param {*} prevErd
   */
  static updateMemo = (dispatch, memo, prevErd) => {
    dispatch(stackUndo(prevErd));
    const newTrd = produce(prevErd, (draft) => {
      let area = TrdRenderUtils.getArea(draft, prevErd.activate.areaId, true);
      let memoIndex = area.areaMemo.findIndex((m) => m.compId === memo.compId);
      area.areaMemo[memoIndex] = memo;
    });

    this._updateNode(dispatch, newTrd);
  };

  /**
   * 메모 삭제
   * @param {*} dispatch
   * @param {*} memoId
   * @param {*} prevErd
   */
  static deleteMemo = (dispatch, memoId, prevErd) => {
    dispatch(stackUndo(prevErd));
    const newTrd = produce(prevErd, (draft) => {
      let area = TrdRenderUtils.getArea(draft, prevErd.activate.areaId, true);
      let memoIndex = area.areaMemo.findIndex((m) => m.compId === memoId);
      area.areaMemo.splice(memoIndex, 1);
    });

    this._updateNode(dispatch, newTrd);
  };

  /**
   * import 한 테이블의 정보를 갱신하는 함수
   * @param {*} dispatch
   * @param {*} table
   * @param {*} prevErd
   */
  static refreshImportTable = async (dispatch, table, prevErd) => {
    dispatch(stackUndo(prevErd));
    if (!table.importTableId) return false;
    const tableCp = JSON.parse(JSON.stringify(table));
    const tableResult = await TrdService.getTableInfo({
      tableMstId: table.importTableId,
    });
    if (tableResult.isError) {
      DaafMessage.alert("Error Occured in 'getTableInfo' Api", "error");
      return false;
    }
    const tableData = tableResult.data;
    if (!tableData) return false;
    tableData.trdTableField.forEach((newField, index) => {
      if (tableCp.trdTableField[index]) {
        tableCp.trdTableField[index] = {
          ...newField,
          fieldId: tableCp.trdTableField[index].fieldId,
        };
      } else {
        tableCp.trdTableField.push({
          ...newField,
          fieldId: StringUtils.getUuid(),
        });
      }
    });

    const newErd = produce(prevErd, (draft) => {
      const tableIndex = draft.tableList.findIndex(
        (table) => table.tableMstId === tableCp.tableMstId
      );
      if (tableIndex > -1) {
        draft.tableList[tableIndex] = tableCp;
      }
    });
    this._updateNode(dispatch, newErd);
  };
}

export default TrdReduxHelper;
