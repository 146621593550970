import produce from "immer";
import ACTION_TYPE from "store/actionType";

const initialState = {
  isFullScreen: false,
};

/**
 * App 전체에 해당하는 Reducer
 * @param {*} state
 * @param {*} action
 * @returns
 */
export default function MainReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPE.Menu.FullScreen.set:
      // 풀스크린 만드는 액션
      const isFull = !state.isFullScreen;

      return produce(state, (draft) => {
        draft.isFullScreen = isFull;
      });
    case ACTION_TYPE.Menu.FullScreen.close:
      return produce(state, (draft) => {
        draft.isFullScreen = false;
      });

    default:
      return state;
  }
}
