import {
  DaafModal,
  DaafModalTemplate,
} from "@alpha/com.bizentro.daaf.front.framework";
import React from "react";

/**
 * 쿼리 실행 오류 팝업
 * @param {Object} props
 * @param {String} props.info
 * @returns
 */
const TrdQueryFailInfoPopup = ({ info, ...props }) => {
  return (
    <DaafModal>
      <DaafModal.Header title="쿼리 실행 오류 내용" />
      <DaafModal.Body>
        <DaafModalTemplate>
          <code style={{ whiteSpace: "break-spaces" }}>{info}</code>
        </DaafModalTemplate>
      </DaafModal.Body>
      <DaafModal.Footer></DaafModal.Footer>
    </DaafModal>
  );
};

export default TrdQueryFailInfoPopup;
