import { ArrayUtils, ComboBox } from "@alpha/com.bizentro.daaf.front.framework";
import React, { useEffect, useState } from "react";
import DomainService from "services/DomainService";
import Form from "./form/Form";
import useAppEnv from "utils/hook/useAppEnv";

/**
 * @param label `required` : 콤보에 표시되는 이름 ["domainCd", "domainNm", "domainId"] 중 택1
 * @param value `required` : 콤보 값
 * @param onChange : 콤보 변경시 이벤트
 */
const DomainAutoSelectBox = ({
  getLabel,
  label,
  onChange,
  value,
  isInvalid,
  placeholder,
  reset,
  ...props
}) => {
  const [domainData, setDomainData] = useState([]);

  const { app, env } = useAppEnv();

  useEffect(() => {
    if (ArrayUtils.isEmpty(domainData) || reset) {
      getDomainList();
    }
  }, [reset]);

  const defaultValue = {
    domainId: null,
    domainCd: null,
    domainNm: "도메인 없음",
  };

  const getDomainList = async () => {
    const result = await DomainService.getDomainList({
      appEnvId: env.appEnvId,
    });
    if (!result.isError) {
      setDomainData([defaultValue, ...result.data]);
    }
  };

  return (
    <Form.ComboBox
      options={domainData}
      placeholder={placeholder}
      value={value}
      getOptionLabel={getLabel ? getLabel : (obj) => obj[label]}
      getOptionValue={(obj) => obj["domainId"]}
      onChange={onChange}
      {...props}
    />
  );
};

export default DomainAutoSelectBox;
