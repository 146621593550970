import {
  Api,
  DaafMessage,
  SessionUtils,
  StringUtils,
  UserUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import LoadingPage from "page/LoadingPage";
import { createContext, useState } from "react";
import { LocalStorageName } from "./Enums";

export const AppContext = createContext({
  menu: "Domain",
  setMenu: () => {},
  navMini: true,
  setNavMini: () => {},
  connectState: {},
  setConnectState: () => {},
});

const AppProvider = ({ ...props }) => {
  const [menu, setMenu] = useState("Domain");
  const [navMini, setNavMini] = useState(true);
  const [connectState, setConnectState] = useState({});
  const [isApiLoading, setIsApiLoading] = useState(false);

  Api.setReqInterceptor(
    (config) => {
      const sessionEnv = SessionUtils.get(null, LocalStorageName.ENV_STORAGE);
      const langCd = UserUtils.getLanguage();
      if (!config.data.appEnvId && sessionEnv) {
        config.data.appEnvId = sessionEnv.env.appEnvId;
      }

      if (langCd) {
        config.data.langCd = langCd;
      }

      //로딩 화면
      if (!config.data.disableLoad) {
        delete config.data.disableLoad;
        setIsApiLoading(true);
      }

      return config;
    },
    (error) => {
      setIsApiLoading(false);
      return error;
    }
  );

  Api.setResInterceptor((response) => {
    setIsApiLoading(false);
    const responseData = response.data;
    if (
      responseData.isError &&
      StringUtils.equalsIgnoreCase(responseData.errType, "system")
    ) {
      DaafMessage.alert(responseData.message, "error");
    }
    return response;
  });

  const contextValue = {
    menu,
    setMenu,
    navMini,
    setNavMini,
    isApiLoading,
    connectState,
    setConnectState,
  };

  return (
    <AppContext.Provider value={contextValue}>
      {isApiLoading && <LoadingPage />}
      {props.children}
    </AppContext.Provider>
  );
};

export default AppProvider;
