import {
  StringUtils,
  UserUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SettingService from "services/SettingService";

const AuthCategory = [
  {
    categoryName: "Domain",
    subCategory: [
      { categoryName: "편집", categoryKey: "DomainEdit", type: "edit" },
      { categoryName: "조회", categoryKey: "DomainSearch", type: "search" },
      { categoryName: "관리자", categoryKey: "DomainAdmin", type: "admin" },
    ],
  },
  {
    categoryName: "DataElement",
    subCategory: [
      { categoryName: "편집", categoryKey: "DataElementEdit", type: "edit" },
      {
        categoryName: "조회",
        categoryKey: "DataElementSearch",
        type: "search",
      },
      {
        categoryName: "관리자",
        categoryKey: "DataElementAdmin",
        type: "admin",
      },
    ],
  },
  {
    categoryName: "TRD",
    subCategory: [
      { categoryName: "편집", categoryKey: "TRDEdit", type: "edit" },
      { categoryName: "조회", categoryKey: "TRDSearch", type: "search" },
      { categoryName: "관리자", categoryKey: "TRDAdmin", type: "admin" },
    ],
  },
];

const GroupAuth = ({ app, env, ...props }) => {
  const [appGroupList, setAppGroupList] = useState([]);
  const [groupPermission, setGroupPermission] = useState([]);

  useEffect(() => {
    getAppGroupList().then(getGroupPermissionList);
  }, []);

  /**
   * APP Group 목록
   */
  const getAppGroupList = () => {
    return new Promise((resolve, reject) => {
      SettingService.getAppGroupList({ appEnvId: env.appEnvId }).then((res) => {
        setAppGroupList(res.data);
        resolve(res.data);
      });
    });
  };

  /**
   * 권한 체크박스 온체인지 이벤트
   */
  const onChangeAuth = (e) => {
    const {
      groupid: groupId,
      taskcode: taskCode,
      tasktype: taskType,
      grouppermissionid: groupPermissionId,
    } = e.target.dataset;

    const checkedValue = e.target.checked ? "Y" : "N";

    // 1. 변환된 데이터 API 호출 및 수정
    const body = {
      groupPermissionId,
      groupId,
      taskCode,
      taskType,
      taskPermissionYn: checkedValue,
      appEnvId: env.appEnvId,
      userId: UserUtils.getId(),
    };

    SettingService.editGroupPermission(body).then((res) => {
      // 2. State 수정
      const nGroupPermission = { ...groupPermission };
      if (!nGroupPermission[`_${groupId}`])
        nGroupPermission[`_${groupId}`] = {};
      if (!nGroupPermission[`_${groupId}`][taskCode])
        nGroupPermission[`_${groupId}`][taskCode] = {};
      if (!nGroupPermission[`_${groupId}`][taskCode][taskType])
        nGroupPermission[`_${groupId}`][taskCode][taskType] = {};

      nGroupPermission[`_${groupId}`][taskCode][taskType].taskPermissionYn =
        checkedValue;
      nGroupPermission[`_${groupId}`][taskCode][taskType].groupPermissionId =
        res.data.groupPermissionId;
      setGroupPermission(nGroupPermission);
    });
  };

  /**
   * 권한 목록 호출
   */
  const getGroupPermissionList = () => {
    SettingService.getGroupPermissionList({
      appEnvId: env.appEnvId,
    }).then((res) => {
      let permissionObject = {};

      /**
       * 오브젝트로 전환
       * 데이터가 많아질 경우 rendering 과정에서 불필요한 find 방지
       */
      for (const auth of res.data) {
        const {
          taskCode,
          taskType,
          taskPermissionYn,
          groupId,
          groupPermissionId,
        } = auth;
        if (!permissionObject[`_${groupId}`])
          permissionObject[`_${groupId}`] = {};
        if (!permissionObject[`_${groupId}`][taskCode])
          permissionObject[`_${groupId}`][taskCode] = {};
        if (!permissionObject[`_${groupId}`][taskCode][taskType])
          permissionObject[`_${groupId}`][taskCode][taskType] = {};
        permissionObject[`_${groupId}`][taskCode][taskType].taskPermissionYn =
          taskPermissionYn;
        permissionObject[`_${groupId}`][taskCode][taskType].groupPermissionId =
          groupPermissionId;
      }
      setGroupPermission(permissionObject);
    });
  };

  /**
   * 헤더 렌더링
   * @returns
   */
  const renderHeader = () => {
    let secondHeaderContents = [];

    const mainHeder = (
      <tr className="grid-row">
        <th rowSpan={2}>그룹 명</th>
        {AuthCategory.map((header) => {
          if (header.subCategory)
            secondHeaderContents = secondHeaderContents.concat(
              header.subCategory
            );
          return (
            <th key={header.categoryName} colSpan={header.subCategory.length}>
              {header.categoryName}
            </th>
          );
        })}
      </tr>
    );

    const subHeader = (
      <tr className="grid-row">
        {secondHeaderContents.map((header) => {
          return <th key={header.categoryKey}>{header.categoryName}</th>;
        })}
      </tr>
    );

    return (
      <>
        {mainHeder}
        {subHeader}
      </>
    );
  };

  return (
    <>
      <div className="setting-title">
        <div className="title">그룹 별 권한 설정</div>
      </div>
      <table className="grid">
        <thead className="grid-header">{renderHeader()}</thead>
        <tbody className="grid-body">
          {appGroupList.map((appGroup) => {
            return (
              <tr key={appGroup.groupId}>
                <td style={{ textAlign: "center" }}>{appGroup.groupName}</td>
                {AuthCategory.map((auth) => {
                  return auth.subCategory.map((sub) => {
                    let taskPermissionYn = false;
                    let groupId = appGroup.groupId;
                    let groupPermissionId = null;
                    let isAdmin = false;
                    let isDisabled = false;
                    if (
                      groupPermission[`_${groupId}`] &&
                      groupPermission[`_${groupId}`][auth.categoryName]
                    ) {
                      if (
                        groupPermission[`_${groupId}`][auth.categoryName][
                          "admin"
                        ]
                      ) {
                        isAdmin =
                          groupPermission[`_${groupId}`][auth.categoryName][
                            "admin"
                          ].taskPermissionYn === "Y"
                            ? true
                            : false;
                      }
                      if (
                        groupPermission[`_${groupId}`][auth.categoryName][
                          sub.type
                        ]
                      ) {
                        taskPermissionYn =
                          groupPermission[`_${groupId}`][auth.categoryName][
                            sub.type
                          ].taskPermissionYn;
                        groupPermissionId =
                          groupPermission[`_${groupId}`][auth.categoryName][
                            sub.type
                          ].groupPermissionId;
                      }
                    }

                    if (isAdmin) {
                      //권한 관리자면 무조건 Y 로 처리
                      // 조회, 편집은 체크 표시후 disabled
                      taskPermissionYn = "Y";
                      if (!StringUtils.equalsIgnoreCase(sub.type, "admin")) {
                        isDisabled = true;
                      }
                    }
                    return (
                      <td
                        key={sub.categoryName}
                        style={{ textAlign: "center" }}
                      >
                        <input
                          type="checkbox"
                          id={sub.categoryKey}
                          data-groupid={groupId}
                          data-groupPermissionId={groupPermissionId}
                          data-taskCode={auth.categoryName}
                          data-taskType={sub.type}
                          disabled={isDisabled}
                          checked={
                            isDisabled
                              ? true
                              : taskPermissionYn === "Y"
                              ? true
                              : false
                          }
                          onChange={onChangeAuth}
                        />
                      </td>
                    );
                  });
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default GroupAuth;
