import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import produce from "immer";
import {
  ArrayUtils,
  DaafMessage,
  DaafModal,
  DaafModalTemplate,
  DaafPopup,
} from "@alpha/com.bizentro.daaf.front.framework";
import TableRelationPopup from "./TableRelationPopup";
import TrdRenderUtils from "page/trd/editor/render/TrdRenderUtils";
import Grid from "components/grid/Grid";

function TableRelationListPopup({ table, trd, relationCallback, ...props }) {
  const [relation, setRelation] = useState([]);
  const [toRelation, setToRelation] = useState([]);

  useEffect(() => {
    setRelation(table.relation);
    getToRelation();
  }, []);

  /**
   * ERD에서 현재 테이블을 바라보는 관계를 가져옴
   */
  const getToRelation = () => {
    let toTableList = [];
    const thisTableNm = table.tablePhysicalNm;
    const areaList = trd.areaList.filter((a) => a.sectorType === "N");
    for (const area of areaList) {
      if (ArrayUtils.isEmpty(area.tableList)) return false;
      let fromTables = area.tableList.filter((t) =>
        t.relation.find((r) => r.target === thisTableNm)
      );
      const areaInfo = {
        name: area.areaNm,
        type: area.areaAffixType,
        alias: area.areaCriteria,
      };
      fromTables = fromTables.map((t) => {
        const obj = { ...t };
        obj.areaInfo = areaInfo;
        return obj;
      });
      toTableList = toTableList.concat(fromTables);
    }
    setToRelation(toTableList);
  };

  /**
   * 관계(FROM) 설정
   * @param {*} relation
   */
  const onClickRelationEdit = (type = "edit", r = [], index) => {
    const sourceTable = { ...table };

    const relCallback = ({
      relationNm,
      relationType,
      joinColumns,
      cascadeType,
      props,
    }) => {
      let rel = {
        relationNm,
        relationType,
        joinColumns,
        cascadeType,
        targetTable: props.targetTable,
        sourceTable: sourceTable,
        props,
      };
      const prevRel = relation.find((r) => r.relationNm === relationNm);
      const prevRelIndex = relation.findIndex(
        (r) => r.relationNm === relationNm
      );
      if (prevRel) {
        if (prevRel.useYn !== "N" && prevRelIndex !== index) {
          return DaafMessage.alert(
            "Relation Name has been registered already.",
            "warning"
          );
        } else if (prevRel.useYn === "N") {
          //기 지정된 관계가 삭제되고 동일한 이름으로 다시 등록되는 경우 기존 삭제된 항목을 업데이트 한다.
          rel = {
            ...prevRel,
            ...rel,
          };
        }
      }

      const relations = produce(relation, (draft) => {
        if (prevRelIndex === -1 && !index) {
          draft.push(rel);
        } else if (index) {
          draft[index] = {
            ...draft[index],
            ...rel,
          };
          //수정 된 것은 useYn -> "Y"로 바뀐다는 뜻
          delete draft[index].useYn;
        } else if (prevRelIndex) {
          draft[prevRelIndex] = {
            ...draft[prevRelIndex],
            ...rel,
          };
          delete draft[prevRelIndex].useYn;
        }
      });
      setRelation(relations);
      DaafPopup.close();
    };

    DaafPopup.open(
      <TableRelationPopup
        callback={relCallback}
        sourceTable={sourceTable}
        targetTable={r.targetTable}
        tableList={trd.tableList}
        areaList={trd.areaList}
        relation={r}
        refChangeable={type === "add" ? true : false}
      />,
      {
        style: { content: { width: "600px" } },
      }
    );
  };

  /**
   * 관계 삭제
   * @param {*} relation
   */
  const onClickRelationRemove = (relData, index) => {
    const list = produce(relation, (draft) => {
      const relIndex = draft.findIndex(
        (r) => r.relationNm === relData.relationNm
      );
      if (!draft[relIndex].relationId) {
        draft.splice(index, 1);
      } else {
        draft[relIndex].useYn = "N";
      }
    });
    setRelation(list);
  };

  /**
   * 확인 버튼
   */
  const onClickConfirm = () => {
    if (props.callback) props.callback(relation);
  };

  return (
    <DaafModal>
      <DaafModal.Header title={"테이블 관계 목록"} />
      <DaafModal.Body>
        <DaafModalTemplate className={"relation-list-popup"}>
          <Form.Group className="mb-3">
            <Form.Label>
              Table " {table.tablePhysicalNm} " 가 참조하는 테이블&nbsp;(FROM)
            </Form.Label>
            <Row>
              <Col className="relation-add-button-col mb-3">
                <Button
                  size="sm"
                  onClick={(e) => onClickRelationEdit("add", relation)}
                >
                  참조 추가
                </Button>
              </Col>
            </Row>

            <Row className="relation-list-wrapper">
              <Col xs={12}>
                <Grid
                  columns={[
                    {
                      field: "toTable",
                      headerName: "참조 대상 테이블 (TO)",
                      renderCell: (data) => {
                        return data.targetTable.tablePhysicalNm;
                      },
                    },
                    {
                      field: "area",
                      headerName: "관계 명",
                      renderCell: (data) => {
                        return data.relationNm;
                      },
                    },
                    {
                      field: "area",
                      headerName: "영역",
                      renderCell: (data) => {
                        return data.targetTable.trdArea.areaNm;
                      },
                    },

                    {
                      field: "config",
                      headerName: "설정",
                      style: {},
                      renderCell: (r, index) => {
                        return (
                          <div style={{ display: "flex", gap: "5px" }}>
                            <Button
                              size="sm"
                              variant="outline-success"
                              onClick={(e) =>
                                onClickRelationEdit("edit", r, index)
                              }
                            >
                              수정
                            </Button>
                            <Button
                              size="sm"
                              variant="outline-danger"
                              onClick={(e) => onClickRelationRemove(r, index)}
                            >
                              삭제
                            </Button>
                          </div>
                        );
                      },
                    },
                  ]}
                  rows={relation.filter((r) => r?.useYn !== "N")}
                />
              </Col>
            </Row>
          </Form.Group>
          {/* <Form.Group className="mb-3">
            <Form.Label>
              Table " {table.tablePhysicalNm} " 가 참조되는 테이블&nbsp;(TO)
            </Form.Label>

            <Row className="relation-list-wrapper">
              <Col></Col>
            </Row>
          </Form.Group> */}
        </DaafModalTemplate>
      </DaafModal.Body>
      <DaafModal.Footer>
        <DaafModal.Footer.Button onClick={onClickConfirm}>
          확인
        </DaafModal.Footer.Button>
      </DaafModal.Footer>
    </DaafModal>
  );
}

export default TableRelationListPopup;
