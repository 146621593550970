import React from "react";

function CommandButtonGroup({ ...props }) {
  return (
    <div className="command-button-group" {...props}>
      {props.children}
    </div>
  );
}

export default CommandButtonGroup;
