import ACTION_TYPE from "store/actionType";

export const setFullScreen = () => {
  return {
    type: ACTION_TYPE.Menu.FullScreen.set,
  };
};
export const clearFullScreen = () => {
  return {
    type: ACTION_TYPE.Menu.FullScreen.close,
  };
};
