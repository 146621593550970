import {
  DaafEnums,
  DaafMessage,
  DaafPopup,
  StringUtils,
  UserUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import Grid from "components/grid/Grid";
import AddGroupUserPopup from "components/popup/setting/AddGroupUserPopup";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import SettingService from "services/SettingService";
import useAppEnv from "utils/hook/useAppEnv";
import DynamicInput from "../DynamicInput";

const GroupUserSetting = () => {
  const [appGroupList, setAppGroupList] = useState([]);
  const { app, env } = useAppEnv();

  useEffect(() => {
    getAppGroupList();
  }, []);

  /**
   * App Group 목록 호출
   */
  const getAppGroupList = async () => {
    const result = await SettingService.getAppGroupList({
      appEnvId: env.appEnvId,
    });
    setAppGroupList(result.data);
  };

  /**
   * App Group 삭제
   */
  const onAppGroupDelete = async (e, data, index) => {
    const deleteFromRow = () => {
      const newList = [...appGroupList];
      newList.splice(index, 1);
      setAppGroupList(newList);
    };

    if (!data.groupId) {
      deleteFromRow();
      return false;
    }

    SettingService.deleteAppGroup(data).then((data) => {
      DaafMessage.alert("삭제 되었습니다.", DaafEnums.MessageType.SUCCESS);
      deleteFromRow();
    });
  };

  /**
   * 그룹 사용자 추가
   * @param {*} e
   * @param {*} groupData
   */
  const onAddUser = (e, groupData) => {
    DaafPopup.open(
      <AddGroupUserPopup appEnvId={"1"} groupId={groupData.groupId} />,
      {
        style: { content: { width: "450px" } },
      }
    );
  };

  /**
   * App 그룹 설정에서 앱
   * @param {*} e
   * @param {*} data
   * @param {Number} index
   */
  const onBlurAppGroup = (e, data, index) => {
    const body = {
      ...data,
      [e.target.id]: e.target.value,
      appEnvId: env.appEnvId,
      userId: UserUtils.getId(),
    };

    if (
      StringUtils.equalsIgnoreCase(e.target.id, "groupName") &&
      StringUtils.isEmpty(e.target.value)
    ) {
      return false;
    } else {
      body[e.target.id] = e.target.value || "";
    }

    SettingService.saveAppGroup(body, (res) => {
      getAppGroupList();
    });
  };

  const appGroupcolumns = [
    {
      field: "groupName",
      name: "그룹 명",
      style: {
        width: "200px",
        paddingLeft: "15px",
      },

      renderCell: (data, index) => {
        if (data.addButton) {
          return <div>{data.groupName}</div>;
        }
        return (
          <DynamicInput
            value={data.groupName}
            id="groupName"
            onBlur={(e) => onBlurAppGroup(e, data, index)}
            initialMode={!data.groupId ? true : false}
          />
        );
      },
    },

    {
      field: "groupDesc",
      name: "그룹 설명",

      renderCell: (data) => {
        if (data.addButton) return <></>;
        return (
          <DynamicInput
            style={{ width: "100%" }}
            id="groupDesc"
            value={data.groupDesc}
            onBlur={(e) => onBlurAppGroup(e, data)}
          />
        );
      },
    },
    {
      field: "userManager",
      name: "구성원",
      style: {
        textAlign: "center",
        width: "150px",
      },
      renderCell: (data) => {
        if (data.addButton || !data.groupId) return <div></div>;
        return <Button onClick={(e) => onAddUser(e, data)}>구성원 관리</Button>;
      },
    },
    {
      field: "groupDelete",
      name: "관리",
      style: {
        width: "150px",
        textAlign: "center",
      },
      renderCell: (data, index) => {
        if (data.addButton || !data.groupId) return <div></div>;

        return (
          <Button
            variant="outline-danger"
            size="sm"
            onClick={(e) => onAppGroupDelete(e, data, index)}
          >
            <MdDelete />
          </Button>
        );
      },
    },
  ];

  /**
   * 그룹 추가하는 로직
   */
  const onAddGroup = () => {
    setAppGroupList([
      ...appGroupList,
      {
        groupName: "",
        userCount: "",
        groupDesc: "",
        groupType: "DEV",
      },
    ]);
  };

  return (
    <>
      <div className="setting-title">
        <div className="title">App 그룹 설정</div>
        <div className="add-button"></div>
      </div>
      <Grid
        columns={appGroupcolumns}
        rows={[
          ...appGroupList,
          {
            addButton: true,
            groupName: (
              <div
                onClick={onAddGroup}
                style={{
                  padding: "10px",
                  color: "dodgerblue",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                {" "}
                + 사용자 그룹 추가
              </div>
            ),
          },
        ]}
      />
    </>
  );
};

export default GroupUserSetting;
