import { BaseService } from "@alpha/com.bizentro.daaf.front.framework";

class SettingService extends BaseService {
  static api = new BaseService("/setting");

  /**
   * App 그룹 저장
   * @param {*} data
   * @param {*} success
   * @param {*} err
   */
  static saveAppGroup = (data, success, err) => {
    this.api.sendPost("/saveAppGroup", data, success, err);
  };

  /**
   * App 사용자 목록 호출
   * @param {*} data
   * @param {*} success
   * @param {*} err
   * @returns
   */
  static getAppGroupList = (data, success, err) => {
    return this.api.sendPost("/getAppGroupList", data);
  };

  /**
   * 사용자 그룹 삭제
   */
  static deleteAppGroup = (data) => {
    return this.api.sendPost("/deleteAppGroup", data);
  };

  /**
   * 사용자 그룹 내 사용자 목록 호출
   */
  static getAppGroupUserList = (data) => {
    return this.api.sendPost("/getAppGroupUserList", data);
  };

  /**
   * 사용자 그룹에 사용자 등록
   * @param {*} data
   * @returns
   */
  static addUserToAppGroup = (data) => {
    return this.api.sendPost("/addUserToAppGroup", data);
  };

  /**
   * 사용자 그룹에 사용자 삭제
   * @param {*} data
   * @returns
   */
  static deleteAppGroupUser = (data) => {
    return this.api.sendPost("/deleteAppGroupUser", data);
  };

  /**
   * 관리자 추가
   * @param {*} data
   * @returns
   */
  static saveAppAdmin = (data) => {
    return this.api.sendPost("/saveAppAdmin", data);
  };

  /**
   * 관리자 목록 호출
   * @param {*} data
   * @returns
   */
  static getAdminList = (data) => {
    return this.api.sendPost("/getAdminList", data);
  };

  /**
   * 관리자 삭제
   * @param {*} data
   * @returns
   */
  static deleteAdmin = (data) => {
    return this.api.sendPost("/deleteAdmin", data);
  };

  /**
   * 관리자 삭제
   * @param {*} data
   * @returns
   */
  static getGroupPermissionList = (data) => {
    return this.api.sendPost("/getGroupPermissionList", data);
  };

  /**
   * 그룹 권한 편집
   * @param {*} data
   * @returns
   */
  static editGroupPermission = (data) => {
    return this.api.sendPost("/editGroupPermission", data);
  };
}

export default SettingService;
