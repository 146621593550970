const { BaseService } = require("@alpha/com.bizentro.daaf.front.framework");

class DataStudioService extends BaseService {
  static api = new BaseService("/studio");

  /**
   * 테이블 목록 + 각 컬럼정보 + 릴레이션 정보 포함
   * @param {*} data
   * @returns
   */
  static getServerTableList = (data) => {
    return this.api.sendPost("/getServerTableList", data);
  };

  /**
   * 테이블 목록만 호출
   * @param {*} data
   * @returns
   */
  static getServerTableListOnly = (data) => {
    return this.api.sendPost("/getServerTableListOnly", data);
  };
}

export default DataStudioService;
