import {
  DaafModal,
  DaafModalTemplate,
} from "@alpha/com.bizentro.daaf.front.framework";
import Form from "components/common/form/Form";
import Grid from "components/grid/Grid";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import TrdService from "services/TrdService";

/**
 * 다른 TRD 의 테이블 목록을 볼수 있는 팝업
 * @returns
 */
function TrdTableListPopup({ trd, ...props }) {
  const [trdList, setTrdList] = useState([]);
  const [selectedTrd, setselectedTrd] = useState({});
  const [selectedTableList, setSelectedTableList] = useState([]);
  const [tableList, setTableList] = useState([]);

  useEffect(() => {
    getTrdList();
  }, []);

  const getTrdList = async () => {
    const result = await TrdService.getTrdList({});
    //현재 펼쳐진 trd제외함
    const tList = result.data.filter((t) => t.trdId !== trd.trdId);
    setTrdList(tList);
  };

  const getTrd = async (trd) => {
    const result = await TrdService.getTrd(trd);
    const pureTableList = result.data.tableList.filter(
      (t) => t.importTableId === null
    );
    setTableList(pureTableList);
  };

  const onChooseTable = (e, data, index) => {
    if (props.callback) {
      props.callback([data]);
    }
  };

  const onClickConfirm = () => {
    if (props.callback) {
      props.callback(selectedTableList);
    }
  };

  const columns = [
    { field: "tablePhysicalNm", headerName: "테이블 물리명" },
    { field: "tableLogicalNm", headerName: "테이블 논리명" },
    { field: "tableDesc", headerName: "테이블 설명" },
  ];

  return (
    <DaafModal>
      <DaafModal.Header title="TRD 검색" />
      <DaafModal.Body>
        <DaafModalTemplate>
          <Row className="mb-3">
            <Col xs={12}>
              <Form.Label>TRD 검색</Form.Label>
              <Form.ComboBox
                value={selectedTrd}
                options={trdList}
                getOptionLabel={(t) => t.trdNm}
                getOptionValue={(t) => t.trdId}
                onChange={getTrd}
                placeholder={"Input Trd Name Or Trd ID..."}
              />
            </Col>
          </Row>
          <Form.Label>TRD Table List</Form.Label>
          <Grid
            gridBoxStyle={{ height: "400px" }}
            stickyHeader
            columns={columns}
            rows={tableList}
            numbering
            onRowDoubleClick={onChooseTable}
            emptyMessage="TRD를 선택해주세요."
            useCheckBox={true}
            onChangeCheckbox={(dataList) => {
              setSelectedTableList(dataList);
            }}
            getId={(data) => data.tableMstId}
          />
        </DaafModalTemplate>
      </DaafModal.Body>
      <DaafModal.Footer>
        <DaafModal.Footer.Button onClick={onClickConfirm}>
          확인
        </DaafModal.Footer.Button>
      </DaafModal.Footer>
    </DaafModal>
  );
}

export default TrdTableListPopup;
