import TableTree from "components/common/TableTree";
import CommandLine from "components/common/command/CommandLine";
import React from "react";

const tempItem = [
  {
    name: "Table",
    child: [
      {
        name: "물류(B)",
        child: [
          {
            name: "b_table1",
          },
          {
            name: "b_table2",
          },
          {
            name: "b_table2",
          },
        ],
      },
      {
        name: "회계(A)",
        child: [
          {
            name: "a_table1",
          },
          {
            name: "a_table2",
          },
          {
            name: "a_table2",
          },
        ],
      },
      {
        name: "table3",
      },
    ],
  },
  {
    name: "View",
    child: [
      {
        name: "View1",
      },
      {
        name: "View2",
      },
      {
        name: "View3",
      },
    ],
  },
  {
    name: "Procedure",
    child: [
      {
        name: "Procedure1",
      },
      {
        name: "Procedure2",
      },
      {
        name: "Procedure3",
      },
    ],
  },
  {
    name: "Function",
    child: [
      {
        name: "Function1",
      },
      {
        name: "Function2",
      },
      {
        name: "Function3",
      },
    ],
  },
  {
    name: "Synonym",
    child: [
      {
        name: "Synonym1",
      },
      {
        name: "Synonym2",
      },
      {
        name: "Synonym3",
      },
    ],
  },
];

const DataStudio = () => {
  return (
    <div className="data-studio-wrapper">
      <CommandLine />
      <TableTree treeItem={tempItem} />
    </div>
  );
};

export default DataStudio;
