import { DaafModal } from "@alpha/com.bizentro.daaf.front.framework";
import UModalTemplate from "@alpha/com.bizentro.daaf.front.framework/dist/component/modal/UModalTemplate";
import Grid from "components/grid/Grid";
import React from "react";
import { Table } from "react-bootstrap";

/**
 * 이력에서 관계 목록 확인 하는 팝업
 * @returns
 */
function TableRelationDetailPopup({ table, ...props }) {
  //관계 대상 테이블, 관계 대상필드를 전부 찾아야함
  return (
    <DaafModal>
      <DaafModal.Header title={`${table.tablePhysicalNm} 관계 상세`} />
      <DaafModal.Body>
        <UModalTemplate>
          {table.relation.map((rel) => {
            const targetTable = rel.targetTable;
            return (
              <Table key={rel.relationId}>
                <thead>
                  <tr>
                    <th>
                      {table.tableLogicalNm}({table.tablePhysicalNm})
                    </th>
                    <th>
                      {targetTable.tableLogicalNm}({targetTable.tablePhysicalNm}
                      )
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rel.joinColumns.map((join) => {
                    return (
                      <tr key={join.relationDtlId}>
                        <td>{join.fromField.element.elementNm}</td>
                        <td>{join.toField.element.elementNm}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            );
          })}
        </UModalTemplate>
      </DaafModal.Body>
      <DaafModal.Footer></DaafModal.Footer>
    </DaafModal>
  );
}

export default TableRelationDetailPopup;
