const Title = ({ breadcrumb = true, divider = true, style, ...props }) => {
  return (
    <div className="page-title-wrapper" style={style}>
      <div className="page-title">
        <h3 className="dd-title">{props.children}</h3>
        {breadcrumb && (
          <div className="breadcrumb">
            {breadcrumb ? (
              breadcrumb
            ) : (
              <>
                <div>home</div>/<div>Data Dictinary</div>/<div>목록</div>
              </>
            )}
          </div>
        )}
      </div>
      <div className="title-under-line">
        <div className="color-block"></div>
        {divider && <div className="none-color-block"></div>}
      </div>
    </div>
  );
};

export default Title;
