import ACTION_TYPE from "../actionType";

/**
 * App 작업 환경 설정
 * @param {*} data
 * @returns
 */
export const setAppEnv = (data) => {
  return {
    type: ACTION_TYPE.AppEnv.set,
    payload: data,
  };
};
