import React from "react";
import { useState } from "react";
import { FaFile, FaFolder, FaFolderOpen } from "react-icons/fa";
import { AiOutlineCaretLeft, AiOutlineCaretRight } from "react-icons/ai";

const TableTree = ({ style, treeItem, ...props }) => {
  const [treeHide, setTreeHide] = useState(false);

  const onClickTreeExpand = () => {
    setTreeHide(!treeHide);
  };

  return (
    <div className={`table-tree ${treeHide ? "hidden" : "show"}`} style={style}>
      <div className="expand-button" onClick={onClickTreeExpand}>
        {treeHide ? <AiOutlineCaretRight /> : <AiOutlineCaretLeft />}
      </div>
      {treeItem &&
        Array.isArray(treeItem) &&
        treeItem.map((item, index) => {
          return (
            <TreeItem key={index} item={item} name={item.name} level={1} />
          );
        })}
    </div>
  );
};

export default TableTree;

export const TreeItem = ({
  name,
  item,
  style,
  level,
  child,
  Icon,
  onClick,
  ...props
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const [selected, setIsSlected] = useState(false);

  const onClickTreeItem = () => {
    // setIsSlected(!selected);
    if (child) {
      setIsOpened(!isOpened);
    }
    if (onClick) {
      onClick(item);
    }
  };

  return (
    <>
      <div
        className={`tree-item ${selected ? "selected" : ""}`}
        onClick={onClickTreeItem}
        style={{ paddingLeft: `calc(${level} * 30px)` }}
      >
        {Icon ? (
          <Icon />
        ) : child ? (
          <div className="icon">
            {isOpened ? <FaFolderOpen /> : <FaFolder />}
          </div>
        ) : (
          <FaFile />
        )}

        <div>{name}</div>
      </div>
      <div className={`tree-item-child ${isOpened ? "show" : ""}`}>
        {isOpened && child}
        {/* {child?.map((child, index) => {
          return (
            <TreeItem
              key={index}
              name={child.name}
              item={child}
              style={{ paddingLeft: `calc(${level} * 30px)` }}
              level={level + 1}
            />
          );
        })} */}
      </div>
    </>
  );
};
